import axios from "axios";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  Box,
  Button,
} from "@mui/material";

import { theme } from "../../../../theme";

const CardForm = ({ setDefaultPaymentMethod, setPaymentMethods, user, setModalView, modalView }) => {
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '18px',
        color: '#000',
        '::placeholder': {
          color: 'gray',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error(error);
      setModalView({
        ...modalView,
        view: "fail",
        data: {
          ...modalView.data,
          error,
        }
      });
      
    } else {

      let currentUser = JSON.parse(localStorage.getItem("user"));
      
      // attach the payment method to customer and make it default payment method
      await axios.post("/api/users/payment-method/attach", {
        customerId: currentUser.stripeCustomerId, 
        paymentMethodId: paymentMethod.id, 
        email: user.email,
       })

      // Update defaultPaymentMethod and paymentMethods state
      setDefaultPaymentMethod(paymentMethod.id);
      setPaymentMethods([paymentMethod]);

      // update localStorage user
      localStorage.setItem('user', JSON.stringify({...currentUser, stripeDefaultPaymentMethod: paymentMethod.id}));
    }
  };

  return (
    <Box sx={{ 
      display: "flex", 
      justifyContent: "center", 
      padding: { xs: "0", md: "0 40px"}
    }}>
      <form onSubmit={handleSubmit} style={{width: "100%"}}>
        <CardElement options={cardElementOptions} />
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "24px"}}>
          <Button
            sx={{
              ...theme.secondaryButton,
              width: { xs: "100%", sm: "50%", md: "25%" },
            }}
            type="submit"
            disabled={!stripe}
          >
            Add Card
          </Button>
        </Box>
      </form>
    </Box>
    
  );
}

export default CardForm