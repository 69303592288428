/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "center",
    marginTop: "3.2rem !important",
  },
  headlines: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "2rem !important",
      minWidth: "500px",
    },
  },
  subHeading1: {
    ...theme.textStyle,
    color: theme.palette.secondary.light,
  },
  button: {
    // ...theme.containedButtonWhite,
    "&:hover": {
      backgroundColor: "#f7f7f7",
    },
  },
  textStyle: { ...theme.textStyle },
}));

export default function CommitmentMessage() {
  const classes = useStyles();
  let navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "#fd7762",
        py: { xs: 6, md: 8 },
        mb: 5,
        width: "100%",
      }}>
      <Container maxWidth="lg">
        <Typography
          align="center"
          sx={{
            color: "white",
            mb: 2,
            fontSize: { xs: "2rem", md: "3rem", lg: "3.5rem" },
            fontFamily: "Moul",
          }}>
          A Commitment to <br /> Quality and Customer Satisfaction
        </Typography>
        <Typography
          align="center"
          sx={{
            color: "white",
            mb: 4,
            fontSize: { xs: "1", md: "1.5rem" },
            fontFamily: "Inter",
            px: { xs: 2, sm: 4, md: 0 },
          }}>
          At CakeyHand, our mission is to bring joy to every celebration with
          our artisanal, high-quality cakes and confections. We cherish our
          family-style roots and ensure every treat is baked with the finest
          ingredients, just like we serve our own family.
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => navigate("/about-us")}
            sx={{
              borderRadius: "8px",
              backgroundColor: "#fff",
              color: "#fd7762",
              fontWeight: "bold",
              fontSize: { xs: "0.875rem", md: "1rem" }, // Adjust button font size
              px: { xs: 3, md: 5 }, // Adjust padding inside the button
              py: { xs: 1.5, md: 2 }, // Adjust padding inside the button
            }}>
            Get to know us
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
