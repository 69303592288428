/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box, Grid, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../theme";
import coverPhoto from "../../assets/images/home_baking.jpg";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
  valueDesc: {
    color: theme.palette.secondary.main,
    fontFamily: "Inter"
  },
  button: {
    // ...theme.containedButtonDark,
    backgroundColor: "#fd7762",
    color: "#fff",
    borderRadius: "8px",
    fontFamily: "Inter",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "1rem !important",
    },
  },
}));

export default function ValuesSection() {
  const classes = useStyles();
  let navigate = useNavigate();

  return (
    <Box>
      <Box sx={{ padding: "40px", backgroundColor: "#fff" }}>
        <Grid container spacing={4} alignItems="center">
          {/* Left Image */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={coverPhoto}
              alt="CakeyHand"
              sx={{
                width: "100%",
                borderRadius: "5%",
                display: "block",
                mx: "auto",
              }}
            />
          </Grid>

          {/* Right Content */}
          <Grid item xs={12} md={6}>
            <Typography variant="h3" component="h2" mb={4} sx={{fontFamily: "Moul"}}>
              Values That Set Us Apart
            </Typography>
            <Typography paragraph mb={4} sx={{fontFamily: "Inter"}}>
              CakeyHand started in a home kitchen, driven by a passion for
              baking and a love for community. Over the years, we've grown into
              a renowned bakery, but our values have remained unchanged.
            </Typography>
            <Grid container spacing={3}>
              {/* Value 1 */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" mb={1}>
                  <VerifiedOutlinedIcon sx={{ marginRight: 1 }} />
                  <Typography variant="h6" component="h4" sx={{fontFamily: "Inter"}}>
                    Uncompromising Quality
                  </Typography>
                </Box>
                <Typography variant="body2" className={classes.valueDesc}>
                  We source the finest ingredients and follow time-tested
                  recipes to deliver exceptional taste.
                </Typography>
              </Grid>

              {/* Value 2 */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" mb={1}>
                  <HeadsetMicOutlinedIcon sx={{ marginRight: 1 }} />
                  <Typography variant="h6" component="h4" sx={{fontFamily: "Inter"}}>
                    Superior Customer Service
                  </Typography>
                </Box>
                <Typography variant="body2" className={classes.valueDesc}>
                  Our team is passionate about exceeding expectations. We
                  provide prompt communication, ensure on-time delivery, and
                  value your feedback.
                </Typography>
              </Grid>

              {/* Value 3 */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" mb={1}>
                  <EmojiObjectsOutlinedIcon sx={{ marginRight: 1 }} />
                  <Typography variant="h6" component="h4" sx={{fontFamily: "Inter"}}>
                    Innovation at Our Core
                  </Typography>
                </Box>
                <Typography variant="body2" className={classes.valueDesc}>
                  We constantly introduce new and exciting offerings, keeping
                  our menu fresh and exceeding your dessert desires.
                </Typography>
              </Grid>

              {/* Value 4 */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" mb={1}>
                  <PsychologyOutlinedIcon sx={{ marginRight: 1 }} />
                  <Typography variant="h6" component="h4" sx={{fontFamily: "Inter"}} >
                    Customization Expertise
                  </Typography>
                </Box>
                <Typography variant="body2" className={classes.valueDesc}>
                  Dreaming of a unique cake design? Our skilled decorators can
                  transform your vision into a reality.
                </Typography>
              </Grid>
            </Grid>
            <Box mt={4} sx={{ textAlign: "left" }}>
              <Button
                onClick={() => navigate("/about-us")}
                variant="outlined"
                className={classes.button}>
                Learn more
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Divider />
      </Box>
    </Box>
  );
}
