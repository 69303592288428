import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import CoroporateEventsSplash from "./coroporateEventsSplash";
import CompanyAnniversary from "./companyAnniversary";
import OfficeSnacks from "./officeSnacks";
import EmployeeAppreciation from "./employeeAppreciation";
import FloatingActionButton from "../../components/FloatingActionButton";

import { setCorporateBoxes, setCorporateCakes } from "../../redux/reducers/corporateProducts"

export default function Main() {
  const dispatch = useDispatch();

  useEffect(() => {

    const getCorporateCakes = axios.get("/api/app/data/corporate-cakes");
    const getCorporateGiftBoxes = axios.get("/api/app/data/corporate-gift-boxes");
    
    axios.all([getCorporateCakes, getCorporateGiftBoxes])
    .then(axios.spread((respCorporateCakes, respCorporateGiftBoxes) => {
      if (respCorporateCakes?.data) {
        dispatch(setCorporateCakes({ corporateCakes: respCorporateCakes.data}))
      } 

      if (respCorporateGiftBoxes?.data) {
        dispatch(setCorporateBoxes({ corporateBoxes: respCorporateGiftBoxes.data}))
      } 

    }))
    .catch(error => {
      console.error(error);
    });
  }, []);

  return (
    <>
      <Box sx={{ margin: "0 6% 112px 6% ", marginTop: 1 }}>
        <CoroporateEventsSplash></CoroporateEventsSplash>
        <CompanyAnniversary></CompanyAnniversary>
        <EmployeeAppreciation></EmployeeAppreciation>
        <OfficeSnacks></OfficeSnacks>
        <FloatingActionButton></FloatingActionButton>
      </Box>
    </>
  );
}
