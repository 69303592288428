import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAccessoryInfo } from "../../../../../redux/reducers/inStoreStandardCake";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StandardCandleReview from "../candles/standardCandleReview";
import ToppersSubcategoryReview from "./toppersSubcategoryReview";

const useStyles = makeStyles(() => ({
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  gridItem: {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    color: "grey !important",
    marginBottom: "0.5rem !important",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
}));

export default function ToppersReview({ dbToppers }) {
  const classes = useStyles();

  const { toppers } = useSelector(selectAccessoryInfo);

  let selectedTopperTypes = [];
  for (const key in toppers) {
    if (toppers[key].optionIsSelected) {
      selectedTopperTypes.push(key);
    }
  }

  return (
    <>
      {dbToppers.content.map((topper) => {
        if (selectedTopperTypes.includes(topper.subtype)) {
          return (
            <ToppersSubcategoryReview
              topperSubcategory={topper}
              userSelection={toppers[topper.subtype]}
            ></ToppersSubcategoryReview>
          );
        }
      })}
    </>
  );
}
