/** @format */

import { React } from "react";
import { connect } from "react-redux";
import { Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
    textAlign: "center",
    marginTop: "4rem",
  },
  heading: {
    color: theme.palette.primary.dark,
    ...theme.textStyle,
  },
  subHeading: {
    color: theme.palette.secondary.main,
    margin: "20px 0 40px 0 !important",
    ...theme.textStyle,
  },
}));

function Main(props) {
  const classes = useStyles();
  const { products, addOns, coupon } = props;

  let totalAmount = 0;
  let totalNumberOfProducts = 0;
  
  products.forEach((e) => {
    let productPrice = 0;
    if(e.featuredProduct){
      if(e.featuredProduct.discount.type === 'percentage'){
        productPrice = e.price - (e.price * e.featuredProduct.discount.amount / 100);
      }
      else{
        productPrice = e.price - e.featuredProduct.discount.amount
      } 
    }
    else{
      productPrice = e.price;
    }
    totalAmount += productPrice * e.quantity;
    totalNumberOfProducts += e.quantity;
  });

  addOns.forEach((e) => {
    totalAmount += e.price;
  });

  if (coupon) {
    totalAmount -= coupon.appliedAmount;
  }

  return (
    <Container>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          <Typography variant={"h2"} className={classes.heading}>
            Cart
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"} className={classes.subHeading}>
            {totalNumberOfProducts} item(s) |{" "}
            {"$" + totalAmount.toFixed(2) + " CAD"}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

function mapStateToProps(state) {
  const { products, addOns, coupon } = state.cart;
  return { products, addOns, coupon };
}

export default connect(mapStateToProps)(Main);
