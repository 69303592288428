import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
} from "@mui/material";

import { theme } from "../../../../theme";



const NotLoggedIn = () => {
  const navigate = useNavigate()

  return (
    <Box sx={{ 
      display: "flex", 
      paddingTop: "40px",
      flexFlow: "column", 
    }}>
      <Box sx={{ display: "flex", justifyContent: "center", margin: "24px" }}>
        <Typography>
          <h3>You need to log-in to subscribe.</h3>
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", margin: "0 48px" }}>
        <Button
          sx={{
            ...theme.secondaryButton,
            width: { xs: "100%", sm: "50%", md: "25%" },
          }}
          onClick={() => navigate("/login")}
        >
          Login
        </Button>

        <Button
          sx={{
            ...theme.secondaryButton,
            width: { xs: "100%", sm: "50%", md: "25%" },
          }}
          onClick={() => navigate("/signup")}
        >
          Signup
        </Button>
      </Box>
    </Box>
  )
}

export default NotLoggedIn