import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EnhancedFormControlFlavor from "../../../components/enhancedFormControl/enhancedFormControlFlavor";
import EnhancedFormControlColor from "../../../components/enhancedFormControl/enhancedFormControlColor";

const useStyles = makeStyles((theme) => ({
  cakeDesc: {
    height: "12rem !important",
    backgroundColor: "white",
    [theme.breakpoints.between("sm", "md")]: {
      height: "15rem !important",
    },
  },
  cakeName: {
    marginTop: "16px !important",
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  cakeNameMain: {
    textAlign: "center",
    color: "#fd7762",
    marginTop: "16px !important",
    marginBottom: "16px !important",
    textTransform: "uppercase !important",
    fontWeight: "bold",
  },
  cakeNameGlutenFree: {
    textAlign: "center",
    color: "green",
    textTransform: "uppercase !important",
    fontWeight: "bold",
    marginLeft: "8px",
  },
  optionSelectors: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
    },
  },
  textStyle: { ...theme.textStyle },
  description: {
    textAlign: "center !important",
    paddingLeft: "32px !important",
    paddingRight: "32px !important",
    paddingTop: "8px !important",
  },
}));

export default function CakeContent({ content }) {
  const classes = useStyles();

  return (
    <Box className={classes.cakeDesc}>
      <Box className={classes.cakeName}>
        <Typography
          className={`${classes.cakeNameMain} ${classes.textStyle}`}
          variant={"h6"}>
          {content.name}
        </Typography>
        {content.glutenFree && (
          <Typography
            className={`${classes.cakeNameGlutenFree} ${classes.textStyle}`}
            variant={"h6"}>
            gf
          </Typography>
        )}
      </Box>
      {content.name !== "Cheesecake" && (
        <Box className={classes.optionSelectors}>
          {content.flavors.length > 1 && (
            <EnhancedFormControlFlavor
              cake={content}></EnhancedFormControlFlavor>
          )}
          {content.colors.length > 1 && (
            <EnhancedFormControlColor cake={content}></EnhancedFormControlColor>
          )}
        </Box>
      )}

      <Typography className={classes.description} variant="body2">
        {content.description}
      </Typography>
    </Box>
  );
}
