import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Grid, Box, Typography, Container, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../auth";

import Size from "./size";
import Flavor from "./flavor";
import {
  selectPrice,
  startingPriceSet,
  selectFlavourChosen,
  selectSizeChosen,
  selectCustomizationOptions,
  selectCakeId,
  selectCake,
  setCakeImg,
  flavorSelected,
  currentPriceUpdated,
} from "../../redux/reducers/cake.js";

import CustomizationOptionsSection from "./customizationOptionsSection";
import NoteSection from "./notes/notesSection";
import FlavorSelectMultiple from "../../components/flavorSelectMultiple";

const useStyles = makeStyles((theme) => ({
  cakePrimaryImg: {
    width: "100%",
    height: "450px",
    marginBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      height: "600px",
    },
  },
  cakeSecondaryImg: {
    height: "6rem",
    width: "6rem",
    marginBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      height: "150px",
      width: "150px",
    },
  },
  secondaryImgWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  addToCartBtn: {
    width: "100%",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.light,
    marginBottom: "15px !important",
    marginTop: "50px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.dark,
    },
  },
  textStyle: { ...theme.textStyle },
}));


export default function BodyModal({ activeStep, setActiveStep }) {
  initializeApp(firebaseConfig());

  const classes = useStyles();
  const dispatch = useDispatch();

  const price = useSelector(selectPrice);
  const [cake, setCake] = useState(null);
  const [primaryImg, setPrimaryImg] = useState("");
  const [sizes, setSizes] = useState([]);
  const [state, setState] = useState({ flavor: "", flavorOptions: [] });
  const [customizationOptions, setCustomizationOptions] = useState([]);
  const [productNotes, setNotes] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const timer = useRef();

  const finalSize = useSelector(selectSizeChosen);
  const finalFlavor = useSelector(selectFlavourChosen);
  const finalCustomizationOptions = useSelector(selectCustomizationOptions);
  let sizeList = [];
  let flavorList = [];
  let customizationOptionsList = [];
  let cakeNotes = [];
  let validationErrorMessage = "";

  const cakeId = useSelector(selectCakeId);
  const selectedCake = useSelector(selectCake);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(async () => {
    if (!cakeId) return;
    
    try {
      let res = await axios.get(
        `/api/app/data/customCake?customCakeId=${cakeId}`
      );

      if (res.status != 200) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      setCake(res.data);

      setPrimaryImg(res.data.images.primary);
      dispatch(setCakeImg({ imgPath: res.data.images.primary } ))
      dispatch(startingPriceSet({ startingPrice: res.data.startingPrice }));

      const { flavorOptions, sizeOptions, customizationOptions, notes } =
        res.data;

      for (let i = 0; i < sizeOptions.length; i++) {
        res = await axios.get("/api/app/data/size?sizeId=" + sizeOptions[i]);
        if (res.status != 200) {
          throw new Error(`HTTP error: ${res.status}`);
        }
        sizeList.push(res.data);
      }

      for (let i = 0; i < flavorOptions.length; i++) {
        res = await axios.get(
          "/api/app/data/flavor?flavorId=" + flavorOptions[i]
        );
        if (res.status != 200) {
          throw new Error(`HTTP error: ${res.status}`);
        }
        flavorList.push(res.data);
      }

      for (let i = 0; i < customizationOptions.length; i++) {
        res = await axios.get(
          "/api/app/data/customizationOption?optionId=" +
            customizationOptions[i]
        );
        if (res.status != 200) {
          throw new Error(`HTTP error: ${res.status}`);
        }
        customizationOptionsList.push(res.data);
      }

      for (let i = 0; i < notes.length; i++) {
        res = await axios.get("/api/app/data/note?noteId=" + notes[i]);
        if (res.status != 200) {
          throw new Error(`HTTP error: ${res.status}`);
        }
        cakeNotes.push(res.data);
      }
      setSizes(sizeList);
      setState({ 
        ...state, 
        flavorOptions: flavorList.map(f=> ({ name: f.name, price: f.price, displayName: f.displayName }))
      });
      setCustomizationOptions(customizationOptionsList);
      setNotes(cakeNotes);
      setDataIsReady(true);
    } catch (error) {
      console.log(error);
    }
  }, [dataIsReady]);

  const userSelectionAreValid = () => {

    if (!finalSize.label) {
      validationErrorMessage = "Please select a size";
      return false;
    }

    if (finalFlavor.length === 0) {
      validationErrorMessage = "Please select a flavor";
      return false;
    }

    if (cake.customizationOptions.length > 0) {
      for (let i = 0; i < cake.customizationOptions.length; i++) {
        let option = cake.customizationOptions[i];

        if (
          option.name === "flakes" &&
          finalCustomizationOptions.flakeColor === null
        ) {
          validationErrorMessage = "Please choose the type of flakes";
          return false;
        }

        if (
          option.name === "colorTheme" &&
          finalCustomizationOptions.colorTheme === null
        ) {
          validationErrorMessage = "Please choose a color theme";
          return false;
        }

        if (
          option.name === "flower" &&
          finalCustomizationOptions.flower === null
        ) {
          validationErrorMessage =
            "Please choose with or without flower decorations";
          return false;
        }

        if (
          option.name === "numberCake" &&
          finalCustomizationOptions.numberCakeNumber === null
        ) {
          validationErrorMessage =
            "Please choose a two digit number for the cake";
          return false;
        }
      }
    }

    return true;
  };

  const addToPackage = () => {
    if (!userSelectionAreValid()) {
      return alert(validationErrorMessage);
    }

    let applicableCustomizations = {};

    for (const optionKey in finalCustomizationOptions) {
      if (finalCustomizationOptions[optionKey] != null) {
        applicableCustomizations[optionKey] =
          finalCustomizationOptions[optionKey];
      }
    }

    // if not 2-tier cake selected, pick the first flavor selection
    if (!(finalSize.label.toLowerCase().startsWith("2 tier")) && finalFlavor.length > 1) {
      dispatch(flavorSelected([finalFlavor[0]]));
      dispatch(currentPriceUpdated({ flavor: [finalFlavor[0]] }));
    }

    setActiveStep(2);

  }

  useEffect(()=>{
    if (state.flavor) {
      console.log("state.flavor", state.flavor)
      console.log("finalFlavor", finalFlavor)

      const selectedFlavorObjs = state.flavor
        .split(",")
        .map(flavor => ({
          name: flavor, 
          price: state.flavorOptions.find(f=>f.name===flavor).price,
          displayName: state.flavorOptions.find(f=>f.name===flavor).displayName 
        }))
      
      dispatch(flavorSelected(selectedFlavorObjs));
      dispatch(currentPriceUpdated({ flavor: selectedFlavorObjs }));
    }
   
  }, [state.flavor])

  return (
    <Container data-testid="product-body" style={{ minHeight: "600px"}}>
      <Grid container sx={{ mt: 10, mb: 10 }}>
        {dataIsReady && (
          <Grid item xs={12} sm={6.5}>
            <img className={classes.cakePrimaryImg} src={primaryImg}></img>
            <Box className={classes.secondaryImgWrapper}>
              {cake.images.secondary.length > 0 &&
                cake.images.secondary.map((imgPath) => {
                  return (
                    <img
                      className={classes.cakeSecondaryImg}
                      src={imgPath}
                      onClick={() => setPrimaryImg(imgPath)}
                    ></img>
                  );
                })}
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sm={0.5}></Grid>

        <Grid item xs={12} sm={5}>
          {dataIsReady && (
            <div>
              <Typography
                className={classes.textStyles}
                sx={{
                  typography: { xs: "h5", sm: "h4" },
                  mb: 4,
                  color: "#fd7762",
                }}
              >{`Starting at $${price.toFixed(2)} CAD`}</Typography>

              {sizes && (sizes.length >= 1) && <Size sizes={sizes}></Size>}

              {
                state.flavorOptions.length >= 1 && !(finalSize.label.toLowerCase().startsWith("2 tier")) &&
                <Flavor flavors={state.flavorOptions}></Flavor>
              }

              {
                state.flavorOptions.length >= 1 &&  finalSize.label.toLowerCase().startsWith("2 tier") &&
                <FlavorSelectMultiple  
                  flavorOptions={state.flavorOptions}
                  setState={setState}
                  state={state}
                  maxSelections={2}
                  rounded={false}
                />
              }


              {/* {flavors.length >= 1 && <Flavor flavors={flavors}></Flavor>} */}

              {/* <FlavourSection flavors={flavors}></FlavourSection> */}

              {customizationOptions.length > 1 && (
                <CustomizationOptionsSection
                  options={customizationOptions}
                ></CustomizationOptionsSection>
              )}

              <NoteSection notes={productNotes}></NoteSection>

              <Box sx={{ position: "relative" }}>
                <Button
                  className={classes.addToCartBtn}
                  variant="contained"
                  onClick={addToPackage}
                >
                  <Typography className={classes.textStyle}>
                    Add to Package
                  </Typography>
                </Button>
              </Box>

            </div>
          )
        }
        </Grid>

        {
          !dataIsReady && 
          <Grid item xs={12} sm={12}>
            <Box style={{ 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center",
                marginTop: "10rem"
              }}>
              <CircularProgress size={100}/>
            </Box>
          </Grid>
           
        }
        
      </Grid>
    </Container>
  );
}
