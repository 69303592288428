import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import { collectionEmailChosen } from "../../../redux/reducers/cart";
import { selectCollection } from "../../../redux/reducers/cart";

const useStyles = makeStyles(() => ({
  textField: {
    borderRadius: "20px",
    marginBottom: "30px",
  },
}));

const Email = ({ placeholder, icon }) => {
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch();
  const collectionInfo = useSelector(selectCollection);
  const [email, setEmail] = useState(collectionInfo?.email);
  const [emailError, setEmailError] = useState(false);
  const classes = useStyles();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    const input = event.target.value;
    const guestEmail = input.trim().toLowerCase();
    if (!guestEmail) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    dispatch(collectionEmailChosen(guestEmail));
    setEmail(guestEmail);
  };

  const getLabelStyles = () => {
    if (isFocused) {
      return {
        paddingLeft: "0",
        transform: "translate(15px, -20px) scale(0.75)",
        marginTop: "10px",
      };
    } else {
      if (email) {
        return { paddingLeft: "0" };
      } else {
        return { paddingLeft: "35px" };
      }
    }
  };

  return (
    <FormControl>
      <TextField
        className={classes.textField}
        error={emailError}
        helperText={emailError ? "invalid email address" : ""}
        type="text"
        variant="outlined"
        value={email}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        InputProps={{
          sx: { borderRadius: "20px" },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton edge="start" onClick={() => {}}>
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: isFocused || email.length > 0,
          style: getLabelStyles(),
        }}
        label={placeholder}
      />
    </FormControl>
  );
};

export default Email;
