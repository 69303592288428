import React from "react";
import { useDispatch } from "react-redux";
import { accessoryCateogryCheckedChange } from "../../../../redux/reducers/inStoreStandardCake";
import { Box, Checkbox, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapperBox: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
    justifyContent: "space-between !important",
    padding: "1rem 0 1rem 1rem !important",
  },
  accessName: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    fontSize: "22px !important",
    color: "#fd7762 !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      fontWeight: "bold !important",
      whiteSpace: "nowrap !important",
    },
  },
  checkbox: {
    "&.Mui-checked": {
      color: "#fd7762 !important",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
    "& .MuiTouchRipple-root": {
      display: "none  !important",
    },
    "& .MuiSvgIcon-root": {
      width: "2.15rem !important",
      height: "2.15rem !important",
    },
  },
  price: {
    fontSize: "22px !important",
    fontWeight: "bold !important",
    color: "black !important",
    marginLeft: "32px !important",
  },
}));

export default function Header({ accessory, checked }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAccessoryCategoryChange = (e) => {
    dispatch(
      accessoryCateogryCheckedChange({
        isChecked: e.target.checked,
        accessoryType: accessory.subtype,
        unitPrice: accessory.price,
      })
    );
    // check here if accessory.requiresSubselection (from db) is false, then dispatch subtotalUpdated action
    // otherwise this action should be dispatched from subselection section (i.e. upon color or number selection change)
    // the above commented implementation is put on hold until the next refactoring
  };

  return (
    <Box className={classes.wrapperBox}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Checkbox
          checked={checked}
          onClick={handleAccessoryCategoryChange}
          color="primary"
          className={classes.checkbox}
        />
        <Typography variant={"h6"} className={classes.accessName}>
          {accessory.subtypeDisplayName}
        </Typography>
      </Box>
      <Typography variant={"h6"} className={classes.price}>
        {`$ ${accessory.price.toFixed(2)}`}
      </Typography>
    </Box>
  );
}
