import React from "react";
import { Typography, Box } from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../../theme";

export default function ContactUsModal({ handleClose }) {
  return (
    <>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "end",
          cursor: "pointer",
          alignContent: "space-evenly",
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h4">CONTACT US</Typography>
      </Box>
      <Box
        sx={{
          margin: "36px",
          display: "flex",
          justifyContent: "space-around",
          color: theme.palette.primary.dark,
          flexWrap: "wrap",
          alignContent: "center",
          height: "40%",
        }}
      >
        <Box sx={{ display: "flex", alignContent: "baseline", margin: "12px" }}>
          <LocalPhoneOutlinedIcon />
          <Typography sx={{ marginLeft: "8px" }}> 709 781 6341</Typography>
        </Box>
        <Box sx={{ display: "flex", alignContent: "baseline", margin: "12px" }}>
          <EmailOutlinedIcon />
          <Typography
            sx={{
              textDecoration: "underline",
              marginLeft: "8px",
              color: theme.palette.primary.dark,
            }}
            component="a"
            href={`mailto:contact@cakeyhand.com`}
          >
            {" "}
            contact@cakeyhand.com
          </Typography>
        </Box>
      </Box>
    </>
  );
}
