/** @format */

import { React, useEffect, useState } from "react";
import { Grid, Box, Container } from "@mui/material";
import axios from "axios";
import Cheesecake from "./cheesecake";

export default function Cheesecakes() {
  const [dataIsReady, setDataIsReady] = useState(false);
  const [cheesecakesList, setCheesecakesList] = useState([]);

  useEffect(() => {
    async function fetchCakes() {
      const res = await axios.get("/api/app/data/cheesecakes/allCheesecakes");
      setCheesecakesList(res.data);
      setDataIsReady(true);
    }

    fetchCakes();
  }, []);

  return (
    <Container>
      <Grid container sx={{ width: "100%" }}>
        {!dataIsReady ? (
          <Box sx={{ mb: 3 }}>Loading...</Box>
        ) : (
          cheesecakesList.map((cheesecake) => {
            return <Cheesecake cheesecake={cheesecake} />;
          })
        )}
      </Grid>
    </Container>
  );
}
