import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cake: {
    flavor: null,
    size: null,
    startingPrice: 125,
    currentPrice: 0,
  }
}

export const holidayProductSlice = createSlice({
  name: 'holidayProduct',
  initialState,
  reducers: {
    sizeSelected: (state, action) => {
      state.cake.size = action.payload;
    },
    flavorSelected: (state, action) => {
      state.cake.flavor = action.payload
    },
    startingPriceSet: (state, action) => {
      state.cake.startingPrice = action.payload.startingPrice
      state.cake.currentPrice = action.payload.startingPrice
    },
    currentPriceUpdated: (state, action) => {
      state.cake.currentPrice += state.cake.startingPrice;
      if(state.cake.flavor != null){
        state.cake.currentPrice += state.cake.flavor.price;
      }
      if(state.cake.size != null){
        state.cake.currentPrice += state.cake.size.price;
      }
    },
    resetToInitial: () => initialState
  }
});

export const selectSizeChosen = (state) => state.holidayProduct.cake.size;
export const selectFlavourChosen = (state) => state.holidayProduct.cake.flavor.name;
export const selectPrice = (state) => state.holidayProduct.cake.currentPrice;
export const selectCake = (state) => state.holidayProduct.cake;

export const { 
  sizeSelected, 
  flavorSelected, 
  currentPriceUpdated, 
  startingPriceSet,
  resetToInitial,
} = holidayProductSlice.actions

export default holidayProductSlice.reducer