/** @format */

import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Auth from "./Auth";
import AccordionOrderList from "./mobile/accordionOrderList";
import OrderModal from "./modalContent/OrderModal";
import TableOrderList from "./desktop/TableOrderList";
import DeleteOrder from "./DeleteOrder";
import SearchHeader from "./search/searchHeader";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAuth,
  selectOrders,
  orderSet,
  selectOrderInfo,
  orderInfoSet,
} from "../../../redux/reducers/admin";
import { changeOrder } from "./utils/orderEditing";
import FetchHeader from "./fetch/fetchHeader";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    margin: "0",
  },
  authContainer: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
  table: {
    display: "flex !important",
    width: "90% !important",
    margin: "20px 0 20px 0 !important",
    borderRadius: "10px !important",
    overflow: "hidden !important",
  },
}));

const OrderTable = () => {
  const classes = useStyles();

  const [orderOpen, setOrderOpen] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

  const [editOrder, setEditOrder] = useState(false);

  const [dataIsReady, setDataIsReady] = useState(false);
  const [searchHeaderVisible, setSearchHeaderVisible] = useState(true);
  const [fetchHeaderVisible, setFetchHeaderVisible] = useState(false);

  const auth = useSelector(selectAuth);
  const orders = useSelector(selectOrders);
  const orderInfo = useSelector(selectOrderInfo);

  const handleClose = () => {
    setOrderOpen(false);
    setEditOrder(false);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const dispatch = useDispatch();

  async function fetchAllOrders() {
    console.log("fetching...")
    let orderWithNames = [];

    const res = await axios.get("/api/admin/order/getAllFutureOrders");

    for (let i = 0; i < res.data.length; i++) {
      let user = null;
      let fullName = null;

      if (
        res.data[i].userRole === "registered_user" ||
        res.data[i].userRole === "registered"
      ) {
        user = await axios.get(`/api/users/user/`, {
          params: { userEmail: res.data[i].email },
        });
        if (user.data) {
          fullName = `${user.data.firstName} ${user.data.lastName}`;
        } else {
          fullName = "no name";
        }
      } else {
        user = res.data[i].customerInfo;
        if (user) {
          fullName = user.name;
        } else {
          fullName = "no name";
        }
      }

      const buff = {
        ...res.data[i],
      };

      orderWithNames.push(buff);
    }

    dispatch(orderSet(orderWithNames));
    setDataIsReady(true);
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    if (!dataIsReady) fetchAllOrders();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(orderInfo).length !== 0 &&
      orderInfo.orderStatus === "cancelled"
    ) {
      dispatch(orderSet(changeOrder(orders, orderInfo)));
      if (!orderOpen) dispatch(orderInfoSet({}));
    }
  }, [orderInfo.orderStatus]);

  const handleFetchMenuClick = () => {
    setFetchHeaderVisible(!fetchHeaderVisible);
  };

  return (
    <Box>
      {auth ? (
        <Box className={classes.chartContainer}>
          {searchHeaderVisible && <SearchHeader />}

          <Button
            onClick={handleFetchMenuClick}
            sx={{ mt: 3 }}
            variant="outlined">
            {fetchHeaderVisible ? "hide fetch menu" : "show fetch menu"}
          </Button>

          {fetchHeaderVisible && <FetchHeader />}

          <Button
            onClick={fetchAllOrders}
            sx={{ mt: 3, mb: 3 }}
            variant="contained">
            REFRESH
          </Button>

          {screenWidth <= 1537 ? (
            <AccordionOrderList
              setOrderOpen={setOrderOpen}
              setDeleteDialogOpen={setDeleteDialogOpen}
            />
          ) : (
            <Paper className={classes.table} elevation={3}>
              <TableOrderList
                setOrderOpen={setOrderOpen}
                setDeleteDialogOpen={setDeleteDialogOpen}
              />
            </Paper>
          )}

          <OrderModal open={orderOpen} handleClose={handleClose} />

          <DeleteOrder
            open={deleteDialogOpen}
            handleClose={handleDeleteDialogClose}
          />
        </Box>
      ) : (
        <Box className={classes.authContainer}>
          <Auth />
        </Box>
      )}
    </Box>
  );
};

export default OrderTable;
