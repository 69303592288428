/** @format */

import React from "react";
import { Box, Typography } from "@mui/material";
import NumberCake from "./numberCake";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "70px",
    height: "70px",
    border: "1px solid #000 !important",
    borderRadius: "5px",
    marginBottom: "10px !important",
    position: "relative",
  },
  colorName: {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translate(-50%)",
    ...theme.textStyle,
  },
  checkIcon: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  textStyle: { ...theme.textStyle },
}));

export default function NumberCakeSection({ info }) {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.textStyle} variant="h6" sx={{ mb: 1 }}>
        {info.displayableName}
      </Typography>
      <Box data-testid="number-cake-section">
        <NumberCake
          parentId={info._id}
          price={info.price}
          parentDisplayName={info.parentDisplayName}
        ></NumberCake>
      </Box>
    </div>
  );
}
