// src/AboutUs.js
import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import aboutUsBanner from "../../assets/images/aboutUs/about_us_banner.png";
import community from "../../assets/images/aboutUs/community.jpg";
import satisfiedCustomer from "../../assets/images/aboutUs/satisfied_customer.png";
import cakeyhandVision from "../../assets/images/aboutUs/joyful_moment.jpg";
import unwaveringQuality from "../../assets/images/aboutUs/unwavering_quality.jpg";
import customerCentric from "../../assets/images/aboutUs/customer_centric.jpg";
import communityFamily from "../../assets/images/aboutUs/community_family.jpeg";
import diversityInclusion from "../../assets/images/aboutUs/diversity_inclusion.jpg";
import innovation from "../../assets/images/aboutUs/innovation.jpg";

// Styles for the banner
const bannerStyles = {
  position: "relative",
  textAlign: "center",
  color: "#fff",
  width: "100%",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)", // Darkening effect
  },
  img: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
  },
};

// Styles for the heading inside the banner
const headingStyles = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2, // Ensures the text is above the darkened banner background
  color: "#fff",
  fontFamily: "Moul"
};

const overlayStyles = {
  position: "relative",
  top: "-100px", // Adjust this value as per the overlap needed
  zIndex: 1,
  width: "100%", // Ensure no overflow here
  backgroundColor: "#fff",
};

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Detect tablet screens

  return (
    <div style={{ overflowX: "hidden" }}>
      {" "}
      {/* Prevent horizontal scroll */}
      {/* Ensure entire page doesn't cause horizontal overflow */}
      <style jsx global>{`
        html,
        body {
          overflow-x: hidden;
        }
      `}</style>
      {/* Banner Section */}
      <Box sx={bannerStyles}>
        <img src={aboutUsBanner} alt="Banner Background" />
        {/* "About Us" Heading inside the banner */}
        <Typography variant="h2" sx={headingStyles}>
          About Us
        </Typography>
      </Box>
      {/* Main Section (overlaying the banner) */}
      <Container maxWidth="xl" sx={overlayStyles}>
        <Grid container spacing={4}>
          {/* "A Legacy of Quality" Section */}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ width: "100%", margin: 0 }}>
            <Grid item xs={12} sm={7} order={isMobile ? 2 : 1}>
              <Box sx={{ paddingRight: { md: "80px" } }}>
                <Typography
                  gutterBottom
                  sx={{
                    mb: 5,
                    maxWidth: { md: "600px" },
                    fontSize: { xs: "35px", md: "36px" },
                    fontFamily: "Moul",
                    color: "#515151"
                  }}>
                  A Legacy of Quality, Baked for the Community
                </Typography>
                <Typography variant="body1" sx={{fontFamily: "Inter", color: "#909090"}}>
                  CakeyHand is more than just a bakery; it's a dream brought to
                  life with a focus on quality and passion. We started in a home
                  kitchen, inspired by our love for baking and a desire to share
                  our creations with the local community. To us, desserts are
                  more than just sweets - they're a way to connect, celebrate,
                  and make lasting memories with those we care about
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} order={isMobile ? 1 : 2}>
              <img
                src={community}
                alt="CakeyHand baking image"
                style={{ width: "100%", display: "block" }}
              />
            </Grid>
          </Grid>

          {/* "A Commitment to Quality" Section */}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ width: "100%", margin: 0 }}>
            <Grid item xs={12} sm={6} order={isMobile ? 1 : 1}>
              <img
                src={satisfiedCustomer}
                alt="CakeyHand Commitment"
                style={{ width: "100%", display: "block" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} order={isMobile ? 2 : 2}>
              <Box sx={{ paddingRight: { xs: "0px", md: "80px" } }}>
                <Typography
                  fontWeight="bold"
                  gutterBottom
                  sx={{
                    mb: 5,
                    maxWidth: { md: "600px" },
                    fontSize: { xs: "35px", md: "36px" },
                    fontFamily: "Moul",
                    color: "#515151"
                  }}>
                  A Commitment to Quality and Customer Satisfaction
                </Typography>
                <Typography variant="body1" sx={{fontFamily: "Inter", color: "#909090"}}>
                  Our mission is to bring joy to every celebration with our
                  artisanal, high-quality cakes and confections. We cherish our
                  family-style roots and ensure every treat is baked with the
                  finest ingredients.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Third Section - "CakeyHand Vision" */}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ width: "100%", margin: 0 }}>
            <Grid item xs={12} sm={6} order={isMobile ? 2 : 1}>
              <Box sx={{ paddingRight: { xs: "0px", md: "80px" } }}>
                <Typography
                  fontWeight="bold"
                  gutterBottom
                  sx={{
                    mb: 5,
                    maxWidth: { md: "600px" },
                    fontSize: { xs: "35px", md: "36px" },
                    fontFamily: "Moul",
                    color: "#515151"
                  }}>
                  The CakeyHand Vision
                </Typography>
                <Typography variant="body1" sx={{fontFamily: "Inter", color: "#909090"}}>
                  To be the premier choice for innovative, high-quality cakes
                  that create joyful moments and lasting memories for everyone.
                  We aspire to be a beloved name known for celebration,
                  creativity, and community connection.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} order={isMobile ? 1 : 2}>
              <img
                src={cakeyhandVision}
                alt="CakeyHand Vision"
                style={{ width: "100%", display: "block" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* CakeyHand Vision Section - Values */}
      <Container maxWidth="xl" sx={{ marginTop: "50px", overflowX: "hidden" }}>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{fontFamily: "Moul", color: "#515151"}}
          gutterBottom>
          Our Values
        </Typography>
        <Typography
          variant="h6"
          textAlign="center"
          sx={{fontFamily: "Inter", color: "#909090", mb: 5}}
          gutterBottom>
          Everything we do at CakeyHand is rooted in the values that have built our reputation
        </Typography>

        <Grid container spacing={4}>
          {/* First Value (double width) */}
          <Grid item xs={12} sm={isTablet ? 6 : isMobile ? 12 : 2.4}>
            <Card variant="outlined" sx={{ minHeight: "400px" }}>
              <CardContent>
                {/* Image for the first value */}
                <img
                  src={unwaveringQuality}
                  alt="Unwavering Quality"
                  style={{
                    width: "100%",
                    height: isMobile ? "250px" : isTablet ? "300px" : "150px",
                    objectFit: "cover",
                  }}
                />
                <Typography variant="h6" gutterBottom sx={{fontFamily: "Inter", color: "#000", fontWeight: "bold"}}>
                  Unwavering Quality
                </Typography>
                <Typography variant="body2" sx={{fontFamily: "Inter", color: "#000"}}>
                  Our uncompromising commitment to using only the finest
                  ingredients guarantees freshness, purity, and taste.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* The remaining 4 values */}
          <Grid item xs={12} sm={isTablet ? 6 : isMobile ? 12 : 2.4}>
            <Card variant="outlined" sx={{ minHeight: "400px" }}>
              <CardContent>
                {/* Image for the second value */}
                <img
                  src={customerCentric}
                  alt="Customer-Centric Approach"
                  style={{
                    width: "100%",
                    height: isMobile ? "250px" : isTablet ? "300px" : "150px",
                    objectFit: "cover",
                  }}
                />
                <Typography variant="h6" gutterBottom sx={{fontFamily: "Inter", color: "#000", fontWeight: "bold"}}>
                  Customer Centric
                </Typography>
                <Typography variant="body2" sx={{fontFamily: "Inter", color: "#000"}}>
                  Your satisfaction is our single most important goal. We
                  prioritize your needs over everything else.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={isTablet ? 6 : isMobile ? 12 : 2.4}>
            <Card variant="outlined" sx={{ minHeight: "400px" }}>
              <CardContent>
                {/* Image for the third value */}
                <img
                  src={communityFamily}
                  alt="Community and Family"
                  style={{
                    width: "100%",
                    height: isMobile ? "250px" : isTablet ? "300px" : "150px",
                    objectFit: "cover",
                  }}
                />
                <Typography variant="h6" gutterBottom sx={{fontFamily: "Inter", color: "#000", fontWeight: "bold"}}>
                  Community & Family
                </Typography>
                <Typography variant="body2" sx={{fontFamily: "Inter", color: "#000"}}>
                  We foster a welcoming environment, both within our team and
                  for our customers.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={isTablet ? 6 : isMobile ? 12 : 2.4}>
            <Card variant="outlined" sx={{ minHeight: "400px" }}>
              <CardContent>
                {/* Image for the fourth value */}
                <img
                  src={diversityInclusion}
                  alt="Diversity and Inclusion"
                  style={{
                    width: "100%",
                    height: isMobile ? "250px" : isTablet ? "300px" : "150px",
                    objectFit: "cover",
                  }}
                />
                <Typography variant="h6" gutterBottom sx={{fontFamily: "Inter", color: "#000", fontWeight: "bold"}}>
                  Diversity & Inclusion
                </Typography>
                <Typography variant="body2" sx={{fontFamily: "Inter", color: "#000"}}>
                  We welcome everyone and celebrate all backgrounds.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={isTablet ? 6 : isMobile ? 12 : 2.4}>
            <Card variant="outlined" sx={{ minHeight: "400px", mb: 5 }}>
              <CardContent>
                {/* Image for the fifth value */}
                <img
                  src={innovation}
                  alt="Innovation"
                  style={{
                    width: "100%",
                    height: isMobile ? "250px" : isTablet ? "300px" : "150px",
                    objectFit: "cover",
                  }}
                />
                <Typography variant="h6" gutterBottom sx={{fontFamily: "Inter", color: "#000", fontWeight: "bold"}}>
                  Innovation & Growth
                </Typography>
                <Typography variant="body2" sx={{fontFamily: "Inter", color: "#000"}}>
                  We embrace creativity and constantly add new products and
                  ideas to our offerings.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutUs;
