import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { cakeSelected, activeStepUpdated } from "../../redux/reducers/wedding";
import { setCakeId } from "../../redux/reducers/cake";

import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    standardCakeImg: {
        width: "100% !important",
        height: "300px !important",
        objectFit: "cover !important",
        borderRadius: "20px !important"
    },
    flexStyle: {
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
    },
    primaryBtn: {
        backgroundColor: "white !important",
        color: "#fd7762 !important",
        borderRadius: "50px !important",
        width: "100% !important",
        padding: "1rem !important",
        textTransform: "capitalize !important",
        border: "2px solid #fd7762 !important",
        "&:hover": {
            backgroundColor: "#fd7762 !important",
            color: "white !important",
            border: "2px solid #fd7762 !important" 
        },
    },
}));

export default function CakeSelection({ activeStep, setActiveStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [cakesList, setCakesList] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);

  const handleCakeSelection = (cake) => {
    dispatch(setCakeId({ cakeId: cake._id }))
    // dispatch(activeStepUpdated(1));
    setActiveStep(1)
  };

  useEffect(() => {
    async function fetchCakes() {
      const res = await axios.get(
        // "/api/app/data/standardCakes/allStandardCakes"
        "/api/app/data/allCustomCakes"
      );
      // setCakesList(res.data);
      setCakesList(res.data.filter(item=>item.filters.occasionFilterTags.indexOf("wedding") !== -1));
      setDataIsReady(true);
    }
    fetchCakes();
  }, [dataIsReady]);

  if (!dataIsReady) {
    return (
      <Box sx={{
        minWidth: "40vw !important", 
        minHeight: "70vh !important",
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important"
      }}>
        <CircularProgress size={100}/>
      </Box>
    );
  } else {
    return (
      <>
        <Grid container >
          {cakesList.map((cake, i) => {
            return (
              <Grid item xs={12} sm={6} lg={4} key={cake.id}>
                <Box sx={{m: "2rem !important"}}>
                    <img
                        className={classes.standardCakeImg}
                        src={cake.images.primary}
                        alt="cakeImage"
                        style={{minWidth: "260px", minHeight: "300px"}}
                    />
                    <Box 
                      className={classes.flexStyle} 
                      sx={{m: "1rem 0 2rem 0 !important"}}
                    >
                        <Typography 
                          sx={{color: "gray !important", mr: "1rem !important"}}
                        >starting at</Typography>
                        <Typography 
                          sx={{fontWeight: "bold !important", fontSize: "1.2rem"}}
                        >{`$${cake.startingPrice.toFixed(2)} CAD`}</Typography>
                    </Box>
                    <Button 
                        className={classes.primaryBtn}
                        onClick={() => handleCakeSelection(cake)}
                    >
                        Choose Option
                    </Button>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }
}
