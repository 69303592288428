import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowForward,
  ArrowBack,
  Warning,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  quantityUpdated,
  selectStandardCandleOptionUserSelection,
  selectUserSelection,
} from "../../../../redux/reducers/inStoreStandardCake";

export default function QuantitySelector({
  color,
  accessoryType,
  accessoryPrice,
}) {
  const dispatch = useDispatch();
  let currentSelection = null;

  const userSelection = useSelector(selectUserSelection(accessoryType));

  if (accessoryType === "numberCandle") {
    currentSelection = userSelection.find((e) => e.number === color);
  } else {
    currentSelection = userSelection.userSelection.find(
      (e) => e.color === color
    );
  }

  const handleIncrement = () => {
    dispatch(
      quantityUpdated({
        color,
        accessoryType,
        updateType: "increment",
        unitPrice: accessoryPrice,
      })
    );
  };

  const handleDecrement = () => {
    dispatch(
      quantityUpdated({
        color,
        accessoryType,
        updateType: "decrement",
        unitPrice: accessoryPrice,
      })
    );
  };

  return (
    <>
      <Button
        size="small"
        onClick={handleDecrement}
        disabled={currentSelection == undefined ? true : false}
        sx={{
          color: "black",
        }}
        data-testid="decrement-btn"
      >
        <RemoveIcon />
      </Button>
      <Typography
        sx={{
          mx: 2,
          border: "2px solid #fd7762 !important",
          borderRadius: "5px",
          width: "50px",
          height: "40px",
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "0 !important",
          marginRight: "0 !important",
          backgroundColor: "white",
        }}
      >
        {currentSelection == undefined ? 1 : currentSelection.qty}
      </Typography>
      <Button
        size="small"
        onClick={handleIncrement}
        disabled={currentSelection == undefined ? true : false}
        sx={{ color: "black" }}
        data-testid="increment-btn"
      >
        <AddIcon />
      </Button>
    </>
  );
}
