import React, { useState } from "react";
import { Container, Button, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import FlavorColorSelect from "./flavorColorSelect";
import FlavorSelect from "./flavorSelect";
import { useDispatch } from "react-redux";
import { addToSelectedDesserts } from "../../../redux/reducers/wedding";
import QuantitySelector from "../../../components/quantitySelector";


const useStyles = makeStyles((theme) => ({
    flexStyle: {
        display: "flex !important",
        flexDirection: "column !important",
        alignItems: "center !important",
    },
    primaryBtn: {
        backgroundColor: "white !important",
        color: "#fd7762 !important",
        borderRadius: "50px !important",
        width: "100% !important",
        padding: "1rem !important",
        textTransform: "capitalize !important",
        border: "2px solid #fd7762 !important",
        marginTop: "2rem !important",
        "&:hover": {
            backgroundColor: "#fd7762 !important",
            color: "white !important",
            border: "2px solid #fd7762 !important"
        },
    },
}));

export default function Dessert({dessert, activeStep, setActiveStep}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedFlavors, setSelectedFlavors] = useState([]);
    const [flavorColorsSelectors, setFlavorColorsSelectors] = useState([{}]);
    const [flavorsSelectors, setFlavorsSelectors] = useState([{}]);

    const [quantity, setQuantity] = useState(dessert.itemsPerSet);


    const handleQuantityChange = (quantity) => {
        setQuantity(quantity);
    }

    const addToPackage = () => {
        dispatch(addToSelectedDesserts({
            ...dessert,
            quantity,
        }));
        setActiveStep(3)
    }

    const handleColorSelection = (flavor, color) => {
        const existingFlavor = selectedFlavors.find(f => f.name === flavor.name);
        
        if (existingFlavor) {
            if (existingFlavor.colors.includes(color)) {
                existingFlavor.colors = existingFlavor.colors.filter(c => c !== color);
            } else {
                existingFlavor.colors.push(color);
            }

            setSelectedFlavors(prevFlavors =>
                prevFlavors.map(f => f.name === flavor.name ? existingFlavor : f)
            );
        } else {
            setSelectedFlavors(prevFlavors => [...prevFlavors, {...flavor, colors: [color] }]);
        }
    }
    const flavorColorAddRemove = (action, indexToRemove) => {
        if (action === "add") {
            setFlavorColorsSelectors(prev => [...prev, {}]);
        } else if (action === "remove") {
            setFlavorColorsSelectors(prev => prev.filter((_, index) => index !== indexToRemove));
        }
    }
    const flavorAddRemove = (action, indexToRemove) => {
        if (action === "add") {
            const nextFlavor = dessert.flavors[flavorsSelectors.length];
            if (nextFlavor) {
                setFlavorsSelectors(prev => [...prev, nextFlavor]);
            }
        } else if (action === "remove") {
            setFlavorsSelectors(prev => prev.filter((_, index) => index !== indexToRemove));
        }
    };

    return (
        <Container sx={{
            display: "flex !important",
            flexDirection: "column !important",
            justifyContent: "center !important",
            alignItems: "center !important",
            minWidth: "260px",
            }}>
            <Typography sx={{fontWeight: "bold", mt: 1}}>{dessert.name}</Typography>


            <Box sx={{
                display: "flex !important",
                flexDirection: "row !important",
                justifyContent: "space-between !important",
                alignItems: "center !important",
                width: "100% !important",
                mt: 2, 
            }}>
                <QuantitySelector 
                    itemsPerSet={dessert.itemsPerSet} 
                    handleQuantityChange={handleQuantityChange}
                    initialQuantity={dessert.itemsPerSet}
                /> 
                <Box>
                    <Typography sx={{fontSize: "1.2rem !important", fontWeight: "bold !important"}}>
                        {`$${dessert.basePrice.toFixed(2)} CAD`}
                    </Typography>
                </Box>
            </Box>
            


            {/* {dessert.name === "Macarons" ? 
                <>
                    {flavorColorsSelectors.map((_, index) => (   
                        <FlavorColorSelect 
                            dessert={dessert} 
                            selectedFlavors={selectedFlavors} 
                            handleColorSelection={handleColorSelection} 
                            index={index} 
                            flavorColorAddRemove={flavorColorAddRemove}
                        />
                    ))}
                    {(flavorColorsSelectors.length < dessert.flavors.length) &&
                        <Box 
                            onClick={() => flavorColorAddRemove("add")}
                            sx={{mt: 2, cursor: "pointer !important", display: "flex !important", flexDirection: "row !important", justifyContent: "center !important", alignItems: "center !important",}}
                        >
                            <AddCircleRoundedIcon sx={{mr: 1, color: "grey !important"}}/>
                            <Typography>More Flavor & Color</Typography>
                        </Box>
                    }
                </>
                :
                <>
                    {flavorsSelectors.map((_, index) => (
                        <FlavorSelect dessert={dessert} index={index} flavorAddRemove={flavorAddRemove} handleQuantityChange={handleQuantityChange}/>
                    ))}
                    {(flavorsSelectors.length < dessert.flavors.length) &&
                        <Box 
                            onClick={() => flavorAddRemove("add")}
                            sx={{mt: 2, cursor: "pointer !important", display: "flex !important", flexDirection: "row !important", justifyContent: "center !important", alignItems: "center !important",}}
                        >
                            <AddCircleRoundedIcon sx={{mr: 1, color: "grey !important"}}/>
                            <Typography>More Flavor</Typography>
                        </Box>
                    }
                </>
            }  */}
            <Button className={classes.primaryBtn} onClick={addToPackage}>Add To Package</Button>
        </Container>
    );
}
