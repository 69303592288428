/** @format */
import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectOrderInfo } from "../../../../../redux/reducers/admin";
import CakeDetail from "./cakeDetail";

export default function ProductDetail() {
  const orderInfo = useSelector(selectOrderInfo);

  return (
    <Grid container>
      {orderInfo.products.map((product, i) => {
        return <CakeDetail product={product} productIndex={i} />;
      })}
    </Grid>
  );
}
