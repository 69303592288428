/** @format */

import React from "react";
import { Container, Typography, Grid, Paper, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import cara from "../../assets/images/reviews/cara.jpg";
import francesca from "../../assets/images/reviews/francesca.jpg";
import reema from "../../assets/images/reviews/reema.jpg";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "center",
    marginTop: "2rem",
    marginBottom: "5rem",
    padding: "6rem 0"
  },
  textStyle: { ...theme.textStyle },
  sectionTitle: {
    marginBottom: "1rem",
    color: theme.palette.primary.dark,
  },
  sectionSubTitle: {
    color: theme.palette.secondary.main.main,
    fontSize: "25px",
    marginBottom: "3rem",
  },
  paper: {
    marginBottom: "30px",
    borderRadius: "10px",
    padding: "15px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "0px",
      marginRight: "30px",
    },
  },
  reviewImg: {
    marginBottom: "15px",
  },
  customerImage: {
    borderRadius: "50%",
  },
  reviewName: {
    fontSize: "25px !important",
    fontWeight: "bold !important",
    marginBottom: "15px !important",
    ...theme.textStyle,
  },
  reviewFeedback: {
    color: theme.palette.secondary.main.main,
    ...theme.textStyle,
  },

  heading: {
    fontSize: "3rem !important",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
        fontSize: "3rem !important",
    },
    color: "#000"
  },

  subHeading: {
    fontSize: "1rem !important",
    color: "#fd7762 !important",
    textAlign: "center !important",
    fontWeight: "bold !important",
    [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem !important",
    },
  },

}));

export default function Testimonials() {
  const classes = useStyles();
  const reviews = [
    {
      customerName: "Cara Berglund",
      customerFeedback:
        "WOW!! Pastries like no other!! Best cakes and pastries around! Packed with flavor and leaves you wishing you ordered more! Highly recommend!",
      customerPic: cara,
    },
    {
      customerName: "Francesca Boschetti",
      customerFeedback: "Macarons are great! Best macarons in town!",
      customerPic: francesca,
    },
    {
      customerName: "Reema Kalani",
      customerFeedback:
        "CakeyHand is the BEST bakery in town. Tried their cakes and pastries at the Farmer's Market. They were excellent and not overly sweet, a perfect combination of flavors!",
      customerPic: reema,
    },
  ];

  return (
    <div className={classes.wrapper} data-testid="reviews">
      <Container>
        <Grid xs={12} className={classes.sectionTitle}>
          <Typography className={classes.heading}>Testimonials</Typography>
        </Grid>
        <Grid xs={12} className={classes.sectionSubTitle}>
          <Typography className={classes.subHeading}>
            We have 100s of couple have the best wedding cake and the<br/> most special day of their lives each year. Be one of them!
          </Typography>
        </Grid>
        <Grid container>
          {[0, 1, 2].map((value) => {
            return (
              <Grid item xs={12} sm={4}>
                <Paper className={classes.paper} elevation={3}>
                  <Box className={classes.reviewImg}>
                    <img
                      src={reviews[value].customerPic}
                      className={classes.customerImage}
                      width={"40%"}
                      height={"40%"}
                      alt="customer"
                    />
                  </Box>
                  <Typography className={classes.reviewName}>
                    {reviews[value].customerName}
                  </Typography>
                  <Typography className={classes.reviewFeedback}>
                    {reviews[value].customerFeedback}
                  </Typography>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
