/** @format */

import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  comingSoon: {
    padding: "5px !important",
    backgroundColor: "#fedc56 !important",
    textAlign: "center !important",
    borderRadius: "40px !important",
    width: "70px !important",
  },
  text: {
    fontWeight: "bold !important",
    fontSize: "10px !important",
  },
}));

export default function ComingSoon() {
  const classes = useStyles();

  return (
    <Box className={classes.comingSoon}>
      <Typography className={classes.text}>coming soon</Typography>
    </Box>
  );
}
