/** @format */

import React from "react";
import { Box, Popover, Button } from "@mui/material";

import { makeStyles } from "@mui/styles";
import FlavorGuideContent from "./flavorGuideContent";

const useStyles = makeStyles((theme) => ({
  addToCartBtn: {
    ...theme.containedButtonDark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.dark,
    },
  },
  textStyle: { ...theme.textStyle },
}));

export default function FlavorGuide({ flavors }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ mb: 2 }} data-testid="flavor">
      <Button
        aria-describedby={id}
        variant="contained"
        className={classes.addToCartBtn}
        onClick={handleClick}>
        more info about the flavors
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "top",
        }}>
        <FlavorGuideContent flavors={flavors} />
      </Popover>
    </Box>
  );
}
