/** @format */

import { Typography, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  orderDetailText: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
  orderDetailSubText: {
    fontSize: "12px !important",
    color: "#676767 !important",
  },

  avatar: {
    width: "150px",
    height: "auto",
    borderRadius: "25px",
  },
}));

function CakeTableRow({ product }) {
  const classes = useStyles();

  return (
    <TableRow data-testid="cake-table-row">
      <TableCell style={{ border: "none" }}>
        <img className={classes.avatar} src={product.imgPath} alt="cake-pic" />
      </TableCell>
      <TableCell style={{ border: "none", width: "35%" }}>
        <Typography className={classes.orderDetailText}>
          {product.size}
        </Typography>
      </TableCell>
      <TableCell style={{ border: "none", width: "20%" }}>
        <Typography className={classes.orderDetailText}>
          {product.name}
        </Typography>
        {product.name.toLowerCase() !== product.flavor && (
          <Typography className={classes.orderDetailSubText}>
            {product.flavor}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
}

export default CakeTableRow;
