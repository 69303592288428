import React from "react";
import TopAppBar from "../../components/appbar";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";
import Main from "./main";

export default function Accessories() {
  return (
    <>
      <NewTopAppBar id="top"></NewTopAppBar>
      <Main></Main>
      <Footer bgColor="#FFADA0"></Footer>
    </>
  );
}
