/** @format */

import { React } from "react";
import { Grid, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import customCakeBg from "../../assets/images/customCakes/customCakeBg.png";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: `url(${customCakeBg})`,
    backgroundColor: "#fffbfa",
    backgroundSize: "contain",
    marginBottom: "5%",
  },
  headlines: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "2rem !important",
    },
  },
  heading: {
    color: "#fd7762 !important",
    fontFamily: "Playfair Display !important",
    marginTop: "40px !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "75px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "55px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "45px !important",
    },
  },
  subHeading: {
    color: "#242424 !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
    marginTop: "25px !important",
    marginBottom: "60px !important",
    ...theme.textStyle,
    [theme.breakpoints.up("md")]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
  },
  subHeading1: {
    color: theme.palette.secondary.main,
    ...theme.textStyle,
    marginTop: "20px !important",
    marginBottom: "80px !important",
  },
  centerWrapperLg: {
    display: "flex",
    position: "relative !important",
    flexDirection: "row !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    margin: "0 auto 0 auto !important",
    width: "70%",
    [theme.breakpoints.down("md")]: { width: "90%" },
    [theme.breakpoints.down("sm")]: { display: "none" },
  },
  centerWrapperSm: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column !important",
      alignItems: "center !important",
    },
  },
  circle: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FFDDD8",
    alignItems: "center",
    borderRadius: "50%",
    color: "#FD7762",
    fontSize: "13px",
    fontWeight: "700",
    width: "2rem",
    height: "2rem",

    margin: "5px",
    zIndex: "1",

    "&:hover": {
      cursor: "pointer",
      borderStyle: "solid",
      borderWidth: "5px",
      borderColor: "#ffebe9",
      margin: "0",
    },
  },
  line: {
    position: "absolute",
    backgroundColor: "#FFDDD8",
    width: "90%",
    height: "2px",
  },
  lableText: {
    fontSize: "15px",
    width: "100%",
    ...theme.textStyle,
    [theme.breakpoints.down("sm")]: { width: "auto" },
  },
  arrow: {
    width: "36px",
    height: "auto",
    margin: "0 0 0 10px",
  },
}));

export default function Main() {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.wrapper} data-testid="specialtycakes-main">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography className={classes.heading}>
            YOU ARE SPECIAL !!!
          </Typography>
        </Grid>
        <Container style={{ padding: "0" }}>
          <Grid container className={classes.wrapper}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant={"subtitle1"} className={classes.subHeading}>
                You deserve a cake that's just as special -{" "}
                <b>ORDER YOURS TODAY !</b>
              </Typography>
              <Grid
                item
                xs={12}
                className={classes.centerWrapperLg}
                style={{ padding: "0 5px 5px 5px" }}
              >
                <div className={classes.circle} style={{ marginRight: "40%" }}>
                  01
                </div>
                <div className={classes.circle}>02</div>
                <div className={classes.line}></div>
                <div className={classes.circle} style={{ marginLeft: "40%" }}>
                  03
                </div>
              </Grid>
              <Grid item xs={12} className={classes.centerWrapperLg}>
                <Typography
                  className={classes.lableText}
                  style={{ textAlign: "left" }}
                >
                  <b>Choose</b> a design
                </Typography>
                <Typography className={classes.lableText}>
                  <b>Choose</b> a size, flavor, etc.
                </Typography>
                <Typography
                  className={classes.lableText}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <b>Pay</b> online and relax
                </Typography>
              </Grid>
              <Grid item={12} className={classes.centerWrapperSm}>
                <div className={classes.circle}>01</div>
                <Typography className={classes.lableText}>
                  <b>Choose</b> a design
                </Typography>
                <br></br>
                <div className={classes.circle}>02</div>
                <Typography className={classes.lableText}>
                  <b>Choose</b> a size, flavor, etc.
                </Typography>
                <br></br>
                <div className={classes.circle}>03</div>
                <Typography className={classes.lableText}>
                  <b>Pay</b> online and complete the booking
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
}
