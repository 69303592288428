import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { theme, stepsOuterCircle, stepsInnerCircle } from "../../../theme";

export default function Steps({ word, number }) {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Typography sx={{ fontWeight: "600" }}>STEP {number}</Typography>
        <Box sx={stepsOuterCircle}>
          <Box sx={stepsInnerCircle}>
            {word === "THE PRODUCT" && (
              <FactCheckOutlinedIcon
                sx={{ color: theme.palette.secondary.light }}
              ></FactCheckOutlinedIcon>
            )}
            {word === "THE FREQUENCY" && (
              <EditOutlinedIcon
                sx={{ color: theme.palette.secondary.light }}
              ></EditOutlinedIcon>
            )}
            {word === "THE BUDGET" && (
              <CreditCardOutlinedIcon
                sx={{ color: theme.palette.secondary.light }}
              ></CreditCardOutlinedIcon>
            )}
          </Box>
        </Box>
        <Typography sx={{ margin: "12px" }}>
          CHOOSE{" "}
          <span
            style={{
              fontWeight: "600",
            }}
          >
            {word}
          </span>
        </Typography>
      </Grid>
    </>
  );
}
