/** @format */

import { React, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Box,
  Checkbox,
  Button,
  Paper,
  FormControl,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { updateCart } from "../../redux/reducers/cart";
import {
  cakeSelected,
  sizeSelected,
  flavorSelected,
  colorSelected,
  selectCakeInfo,
  resetToInitial,
} from "../../redux/reducers/websiteStandardCake";
import MultiOptionsInput from "./multiOptionsInput";

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.containedButtonDark,
    width: "250px !important",
    marginBottom: "0.5rem !important",
    marginTop: "2rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  standardCakeImg: {
    height: "100%",
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  checkbox: {
    "&.Mui-checked": {
      color: "#fd7762 !important",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
    "& .MuiTouchRipple-root": {
      display: "none  !important",
    },
    "& .MuiSvgIcon-root": {
      width: "2.15rem !important",
      height: "2.15rem !important",
    },
  },
  textStyle: { ...theme.textStyle },
}));

export default function StandardCake({ cake }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [availableSizes, setAvailableSizes] = useState([]);
  const sizeList = [];
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sizeDataIsReady, setSizeDataIsReady] = useState(false);
  const selectedCake = useSelector(selectCakeInfo);

  const timer = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  let tempEmail = localStorage.getItem("tempEmail");

  useEffect(async () => {
    const fetchSizes = async () => {
      for (let size of cake.sizes) {
        try {
          const res = await axios.get("/api/app/data/size?sizeId=" + size);
          sizeList.push(res.data);
        } catch (error) {
          return;
        }
      }
      setAvailableSizes(sizeList);
      setSizeDataIsReady(true);
    };

    fetchSizes();
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    setTimeout(function () {
      setSuccess(false);
    }, 3000);
  }, [success]);

  const handleAddToCart = async () => {
    if (cake.name !== selectedCake.name) {
      return alert(
        "You're trying to add another cake, please check the checkbox for this cake and reselect the options available"
      );
    }

    if (!selectedCake.size) return alert("Please select a size");
    if (cake.flavors.length > 0 && !selectedCake.flavorSection.flavor)
      return alert("Please select a flavor");
    if (cake.colors.length > 0 && !selectedCake.colorSection.color)
      return alert("Please select a color");

    setSuccess(false);
    setLoading(true);

    const product = {
      id: Date.now(),
      productId: cake._id,
      name: "Standard Cake",
      category:
        selectedCake.name.toLowerCase() !==
        selectedCake.flavorSection.flavor.toLowerCase()
          ? selectedCake.name
          : "",
      flavor: selectedCake.flavorSection.flavor,
      color: selectedCake.colorSection.color,
      imgPath: selectedCake.imgPath,
      size: selectedCake.size,
      quantity: 1,
      minQTY: 1,
      type: "standardCake",
      price: selectedCake.price,
    };

    const res = await axios.post("/api/cart/addProduct/", {
      userEmail: currentUser ? currentUser.email : tempEmail,
      product,
    });

    timer.current = window.setTimeout(() => {
      if (res.status === 200) {
        dispatch(updateCart(res.data));
        dispatch(resetToInitial());
        setSuccess(true);
        setLoading(false);
      }
    }, 1000);
  };

  const handleCakeSelectionChange = () => {
    dispatch(cakeSelected(cake));
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      key={cake.name}
      sx={{ mt: 3, mb: 3 }}
      data-testid="standard-cake">
      <Paper
        elevation={10}
        sx={{
          marginRight: "20px",
          p: 2,
          textAlign: "center",
        }}>
        <Box
          sx={{
            height: "350px",
            width: "100%",
          }}>
          <img
            className={classes.standardCakeImg}
            src={cake.images.primary}
            alt="standard cake"
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            checked={cake.name === selectedCake.name}
            onClick={handleCakeSelectionChange}
            color="primary"
            className={classes.checkbox}
          />
          <Typography
            className={classes.textStyle}
            variant={"h6"}
            sx={{
              textAlign: "center",
              color: "#fd7762",
              mt: 2,
              mb: 2,
              fontWeight: "bold",
            }}>
            {cake.name}
          </Typography>
        </Box>

        {sizeDataIsReady && (
          <FormControl fullWidth>
            <MultiOptionsInput
              cake={cake}
              label={"Choose Size"}
              options={availableSizes}
              action={sizeSelected}
              selectedValue={selectedCake.size}></MultiOptionsInput>
          </FormControl>
        )}

        {cake.flavors.length > 0 && (
          <FormControl fullWidth>
            <MultiOptionsInput
              cake={cake}
              label={"Choose Flavor"}
              options={cake.flavors}
              action={flavorSelected}
              selectedValue={
                selectedCake.flavorSection.flavor
              }></MultiOptionsInput>
          </FormControl>
        )}

        {cake.colors.length > 0 && (
          <FormControl fullWidth>
            <MultiOptionsInput
              cake={cake}
              label={"Choose Color"}
              options={cake.colors}
              action={colorSelected}
              selectedValue={
                selectedCake.colorSection.color
              }></MultiOptionsInput>
          </FormControl>
        )}

        <Box sx={{ position: "relative" }}>
          {
            <Button
              size={"large"}
              variant="contained"
              className={classes.button}
              // disabled={cake.name !== selectedCake.name}
              onClick={() => {
                handleAddToCart(cake);
              }}>
              <Typography className={classes.textStyle}>Add to Cart</Typography>
            </Button>
          }

          {loading && cake.name === selectedCake.name && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-0.1rem",
                marginLeft: "65px",
                color: "#fff",
              }}
            />
          )}
        </Box>
        {success && cake.name === selectedCake.name && (
          <Typography className={classes.textStyle} sx={{ color: "green" }}>
            Added!
          </Typography>
        )}
      </Paper>
    </Grid>
  );
}
