import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import { useDispatch } from "react-redux";
import { imgUploaded } from "../../redux/reducers/admin";

export default function ImageUploader() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [s3credentials, setS3Credentials] = useState(null);
  const [dataIsReady, setDataIsReady] = useState(false);

  useEffect(async () => {
    try {
      const res = await axios.get("/api/admin/aws/s3credentials");
      setS3Credentials(res.data);
      setDataIsReady(true);
    } catch (error) {
      console.log(error);
    }
  }, [dataIsReady]);

  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (!selectedFile) return;

    const s3 = new AWS.S3({
      accessKeyId: s3credentials.accessKey,
      secretAccessKey: s3credentials.secretKey,
      region: s3credentials.bucketRegion,
    });

    const params = {
      Bucket: s3credentials.bucketName,
      Key: selectedFile.name,
      Body: selectedFile,
    };

    setUploading(true);

    s3.upload(params, (error, data) => {
      if (error) {
        console.error(error);
        setUploading(false);
      } else {
        setUploadedUrl(data.Location);
        setUploading(false);
        dispatch(imgUploaded(data.Location));
      }
    });
  };

  return (
    <div data-testid="imageuploader">
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleFileUpload} disabled={uploading || !selectedFile}>
        Upload
      </button>
      {uploading && <div>Uploading...</div>}
      {uploadedUrl && (
        <img
          src={uploadedUrl}
          alt="Uploaded"
          style={{ width: "200px", height: "200px" }}
        />
      )}
    </div>
  );
}
