import React from "react";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";
import Main from "./main";
import Header from "../../components/header";
import DecorateCakeLikeAPro from "./articles/decorateCakeLikeAPro";

const Blog = () => {
  return (
    <>
    {/* <DecorateCakeLikeAPro /> */}
      {/* <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      <Main></Main>
      <Footer></Footer> */}
    </>
  );
};

export default Blog;
