import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import { useDropzone } from 'react-dropzone';
import { Typography, Box } from "@mui/material";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";


export default function Brand({ setState, state }) {
  const [dataIsReady, setDataIsReady] = useState(false);
  const [s3credentials, setS3Credentials] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const s3 = new AWS.S3({
          accessKeyId: s3credentials.accessKey,
          secretAccessKey: s3credentials.secretKey,
          region: s3credentials.bucketRegion,
        });

        const params = {
          Bucket: s3credentials.bucketName,
          Key: acceptedFiles[0].name,
          Body: acceptedFiles[0],
        };

        s3.upload(params, (error, data) => {
          if (error) {
            console.error(error);
          } else {
            setState({
              ...state,
              companyLogo: data.Location
            })
          }
        });
      }
    },
    // Setting maxFiles will reject multiple selections. 
    // So we disable it for now and accept only the first item when multiple files are selected
    // maxFiles: 1,   
    accept: {
      'image/*': [],
    }
  });



  useEffect(async () => {
    if(!dataIsReady) {
      try {
        const res = await axios.get("/api/admin/aws/s3credentials");
        setS3Credentials(res.data);
        setDataIsReady(true);
      } catch (error) {
        console.log(error);
      }
    }
    
  }, [dataIsReady]);
  

  return (
    <>
      <Box sx={{ margin: "12px" }}>
        <Typography sx={{ color: "#676767" }}>
          Upload your company logo
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FDFDFD",
          display: "flex",
          alignItems: "center",
          padding: "24px",
          border: "1px dashed #E0E0E0",
          borderRadius: "20px",
          flexDirection: "column",
        }}
        {...getRootProps()}
      >
        {/* <div  style={{ alignItems: "center" }}> */}
          <input {...getInputProps()} />
          
          <FileUploadOutlinedIcon
            sx={{
              margin: "12px",
              border: "1px solid #E0E0E0",
              padding: "8px",
              borderRadius: "8px",
            }}
            
          />
          <Typography sx={{ margin: "12px" }}>  
            Click or drag file to upload
          </Typography>
          <Typography sx={{ color: "gray", fontSize: "12px", margin: "12px" }}>
            SVG, PNG, JPG OR GIF (max. 800x400px)
          </Typography>

        {/* </div> */}
        

      </Box>

      {
        state.companyLogo &&
        <Box
          component="img"
          sx={{
            paddingTop: "16px",
            height: 60,
            width: 60,
          }}
          src={state.companyLogo}
        />
      }
    </>
  );
}
