/** @format */

import React from "react";
import { Grid, Typography} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  orderDetailText: {
    display: "flex",
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
  orderDetailSubText: {
    fontSize: "12px !important",
    color: "#676767 !important",
  },
  avatar: {
    width: "150px",
    height: "auto !important",
    borderRadius: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "75px",
      borderRadius: "10px",
    },
  },
}));

export default function OrderPartialDetail({label, val}) {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={7} sx={{mb: 2}}>
                <Typography className={classes.orderDetailText}>
                        {label}
                    </Typography>
            </Grid>
            <Grid item xs={5} sx={{mb: 2}} data-testid="order-partial-detail">
            {label === "Image" ? <img
                    className={classes.avatar}
                    src={val}
                    alt="cake-pic"
                /> : <Typography className={classes.orderDetailSubText}>
                        {val}
                    </Typography>
            }
            </Grid>
        </>
    );
}