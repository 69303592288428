import React, { useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  Typography,
  Box,
  FormControlLabel,
  Container,
  Paper,
  Link,
  CircularProgress,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import axios from "axios";

const Main = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;

    let rawNumber = value.replace(/[^\d]/g, "");
    console.log(rawNumber);
    if (rawNumber.length > 10) {
      rawNumber = rawNumber.toString().slice(0, 10);
    } else if (rawNumber.length === 10) {
      const formattedNumber = `(${rawNumber.slice(0, 3)}) ${rawNumber.slice(
        3,
        6
      )}-${rawNumber.slice(6)}`;
      setFormData({ ...formData, [name]: formattedNumber });
    } else {
      setFormData({ ...formData, [name]: rawNumber });
    }
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, agree: e.target.checked });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is not valid";
    if (!formData.phoneNumber)
      newErrors.phoneNumber = "Phone number is required";
    if (!formData.message) newErrors.message = "Message is required";
    if (!formData.agree)
      newErrors.agree = "You must agree to the privacy policy to proceed";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setIsSubmitting(true);

      try {
        const res = await axios.post("/api/contact", {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          message: formData.message,
          agree: formData.agree,
        });

        setIsSubmitting(false);
        setIsSubmitted(true);
        // Reset form after 5 seconds
        setTimeout(() => {
          setIsSubmitted(false);
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            message: "",
            agree: false,
          });
        }, 5000);
      } catch (error) {
        // Handle error if necessary
      }
    }
  };

  return (
    <Box sx={{ backgroundColor: "#fafafa" }}>
      <Box sx={{ p: 3 }}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ fontFamily: "Moul" }}>
          We would love to hear from you!
        </Typography>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{ fontFamily: "Inter" }}>
          At CakeyHand, we're dedicated to providing you with an exceptional
          experience.
        </Typography>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{ fontFamily: "Inter" }}>
          If you don’t find what you’re looking for, please contact us directly
          — we're always here to help!
        </Typography>
      </Box>

      <Container sx={{ mt: 4, pb: 7 }}>
        <Paper
          elevation={3}
          sx={{ p: 4, maxWidth: 600, mx: "auto", borderRadius: "20px" }}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
                sx={{ backgroundColor: "#fafafa", fontFamily: "Inter" }} // Set background color to grey
              />
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
                sx={{ backgroundColor: "#fafafa", fontFamily: "Inter" }} // Set background color to grey
              />
            </Box>

            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ mb: 2, backgroundColor: "#fafafa", fontFamily: "Inter" }} // Set background color to grey
            />

            <TextField
              label="Phone Number" // Added Phone Number label
              variant="outlined"
              fullWidth
              name="phoneNumber" // Set name for the phone number field
              value={formData.phoneNumber} // Set value from formData
              onChange={handlePhoneChange} // Handle change event
              error={!!errors.phoneNumber} // Display error if exists
              helperText={errors.phoneNumber} // Show error message
              sx={{ mb: 2, backgroundColor: "#fafafa", fontFamily: "Inter" }} // Set background color to grey
            />

            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              name="message"
              value={formData.message}
              onChange={handleChange}
              error={!!errors.message}
              helperText={errors.message}
              sx={{ mb: 2, backgroundColor: "#fafafa", fontFamily: "Inter" }} // Set background color to grey
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.agree}
                  onChange={handleCheckboxChange}
                  name="agree"
                  sx={{
                    color: formData.agree ? "#fd7762" : "grey", // When unchecked, color is grey
                    "&.Mui-checked": {
                      color: "#fd7762", // Checked color
                    },
                  }}
                />
              }
              label={
                <>
                  I agree with your
                  <Link component={NavLink} to="/privacy-policy">
                    {" "}
                    privacy policy{" "}
                  </Link>
                  and{" "}
                  <Link component={NavLink} to="/terms-and-conditions">
                    terms of use
                  </Link>
                </>
              }
            />
            {errors.agree && (
              <Typography variant="body2" color="error">
                {errors.agree}
              </Typography>
            )}

            <Box sx={{ position: "relative", mb: 2, mt: 4 }}>
              <Button
                type="submit"
                variant="contained"
                // color={isSubmitted ? 'success' : 'primary'}
                fullWidth
                sx={{
                  borderRadius: "8px",
                  padding: "15px 0",
                  backgroundColor: isSubmitting
                    ? "#9e9e9e" // Show grey background when submitting
                    : isSubmitted
                    ? "green" // Change to green when submitted
                    : "#fd7762", // Set default button color
                  color: isSubmitted ? "#fff" : "#fff", // White text on success
                  "&:hover": {
                    backgroundColor: "#fd7762cc",
                  },
                }}
                disabled={isSubmitting}
                startIcon={isSubmitted ? <Check /> : null}>
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                    fontWeight="bold"
                  />
                ) : isSubmitted ? (
                  "Message Received"
                ) : (
                  "Send Message"
                )}
              </Button>
            </Box>

            {isSubmitted && (
              <Typography variant="body2" align="center" color="success.main">
                We will get back to you within 1-2 business days.
              </Typography>
            )}
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default Main;
