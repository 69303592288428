import React from "react";
import { Box } from "@mui/material";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Main from "../Patisserie/main";
import Products from "../Patisserie/products";
import NewTopAppBar from "../../components/newAppBar";

export default function Patisserie({ category }) {
  return (
    <>
      <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      <Main category={category}></Main>
      <Box>
        <Products category={category}></Products>
      </Box>
      <Footer bgColor="#fd7762cc"></Footer>
    </>
  );
}
