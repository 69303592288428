import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  Typography,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Header from "../common/header";
import SingleImage from "../common/singleImage";
import {
  pipingTextChange,
  selectTextPiping,
  selectTextPipingOptionIsSelected,
} from "../../../../redux/reducers/inStoreStandardCake";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "310px",
    display: "grid !important",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: "0.5rem",
  },
  gridColumn: {
    gridColumn: "1 / 4",
    gridRow: "1 / 4",
  },
  standardAccessoriesImg: {
    width: "100% !important",
    height: "310px !important",
    objectFit: "contain !important",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
}));

export default function TextPiping({ accessory }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const textPiping = useSelector(selectTextPiping);
  const { text, optionIsSelected } = textPiping;

  const handleTextChange = (e) => {
    if (e.target.value.length <= 30) {
      dispatch(pipingTextChange(e.target.value));
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        lg={4}
        key={accessory.subtype}
        sx={{ mt: 8 }}
        data-testid="piping">
        <Paper
          elevation={10}
          sx={{
            margin: "0 1rem 0 1rem",
            p: 2,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
          }}>
          <SingleImage accessory={accessory.content[0]}></SingleImage>
          <Header
            accessory={accessory.content[0]}
            checked={optionIsSelected}></Header>
          {optionIsSelected && (
            <Box>
              <TextField
                label="Text Piping"
                variant="outlined"
                inputProps={{
                  maxLength: 30,
                }}
                value={text}
                onChange={handleTextChange}
                sx={{
                  backgroundColor: "white",
                  "& .MuiInputBase-root": {
                    width: "20rem !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgrey ! important",
                    },
                    "& .MuiInputLabel-outlined": {
                      "&.Mui-focused": {
                        color: "grey !important",
                      },
                    },
                    "& .MuiInputLabel-shrink ~ .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "grey !important",
                      },
                  },
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <Warning sx={{ fontSize: "16px", mr: 1, color: "grey" }} />
                <Typography variant="body2" sx={{ color: "grey" }}>
                  {accessory.content[0].maxTextLengthWarning}
                </Typography>
              </Box>
            </Box>
          )}
        </Paper>
      </Grid>
    </>
  );
}
