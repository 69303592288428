/** @format */

import { React, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Box, Button, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { addedToTheCart, updateCart } from "../../redux/reducers/cart";
import { selectCheesecake } from "../../redux/reducers/cheesecake";

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.containedButtonDark,
    marginBottom: "0.5rem !important",
    marginTop: "2rem !important",
    padding: "10px 50px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  cheesecakeImg: {
    height: "100%",
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  textStyle: { ...theme.textStyle },
}));

export default function Cheesecake({ cheesecake }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [clickedButton, setClickedButton] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCheesecake, setSelectedCheesecake] = useState(null);

  const timer = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  let tempEmail = localStorage.getItem("tempEmail");

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    setTimeout(function () {
      setSuccess(false);
      setClickedButton("");
    }, 3000);
  }, [success]);

  const handleAddToCart = async () => {
    setSelectedCheesecake(cheesecake);

    setSuccess(false);
    setLoading(true);

    const sizeObj = await axios.get(
      `/api/app/data/size?sizeId=${cheesecake.size}`
    );

    const cheesecakeObject = {
      id: Date.now(),
      productId: cheesecake._id,
      name: cheesecake.name,
      flavor: cheesecake.flavor,
      price: cheesecake.price,
      imgPath: cheesecake.images.primary,
      size: sizeObj.data.name,
      quantity: 1,
      minQTY: 1,
      type: "cheesecake",
    };

    const res = await axios.post("/api/cart/addProduct/", {
      userEmail: currentUser ? currentUser.email : tempEmail,
      product: cheesecakeObject,
    });

    timer.current = window.setTimeout(() => {
      if (res.status === 200) {
        dispatch(updateCart(res.data));
        setSuccess(true);
        setLoading(false);
      }
    }, 1000);
  };

  return (
    <Grid
      item
      xs={12}
      sm={3}
      key={cheesecake.name}
      sx={{ mt: 3, mb: 3 }}
      data-testid="cheese-cake">
      <Paper
        elevation={10}
        sx={{
          marginRight: "20px",
          p: 2,
          textAlign: "center",
        }}>
        <Box
          sx={{
            height: "350px",
            width: "100%",
          }}>
          <img
            className={classes.cheesecakeImg}
            src={cheesecake.images.primary}
            alt="standard cake"
          />
        </Box>
        <Typography
          className={classes.textStyle}
          variant={"h6"}
          sx={{
            textAlign: "center",
            color: "#fd7762",
            mt: 2,
            mb: 2,
            fontWeight: "bold",
          }}>
          {cheesecake.flavor.toUpperCase()}
        </Typography>

        {cheesecake.glutenFree && (
          <Typography
            className={classes.textStyle}
            variant={"h6"}
            sx={{
              textAlign: "center",
              color: "#30b05a",
              mt: 2,
              mb: 2,
              fontWeight: "bold",
            }}>
            gluten free
          </Typography>
        )}

        <Typography
          className={classes.textStyle}
          variant={"subtitle1"}
          sx={{ textAlign: "center", color: "#000000", mt: 2 }}>
          {`$${cheesecake.price.toFixed(2)} CAD`}
        </Typography>

        <Box sx={{ position: "relative" }}>
          {
            <Button
              size={"large"}
              variant="contained"
              className={classes.button}
              onClick={() => {
                handleAddToCart(cheesecake);
              }}>
              <Typography className={classes.textStyle}>Add to Cart</Typography>
            </Button>
          }

          {loading && cheesecake.name === selectedCheesecake?.name && (
            <CircularProgress
              size={18}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "0.3rem",
                marginLeft: "65px",
                color: "#ffffff",
              }}
            />
          )}
        </Box>
        {success && cheesecake.name === selectedCheesecake.name && (
          <Typography className={classes.textStyle} sx={{ color: "green" }}>
            Added!
          </Typography>
        )}
      </Paper>
    </Grid>
  );
}
