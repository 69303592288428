import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  colorSelected,
  selectColorChosen,
  selectCakeChosen,
} from "../../redux/reducers/inStoreStandardCake";

const useStyles = makeStyles(() => ({
  inputLabel: {
    color: "grey !important",
    fontSize: "14px !important",
    "&.MuiInputLabel-shrink": {
      color: "grey !important",
    },
  },
  options: {
    backgroundColor: "white",
    width: "100%",
    borderRadius: "10px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f6725c",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    fontSize: "0.8rem !important",
    height: "3rem",
  },
}));

export default function EnhancedFormControlColor({ cake }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedColor = useSelector(selectColorChosen);
  const selectedCakeName = useSelector(selectCakeChosen).name;

  const handleColorChange = (event) => {
    if (cake.colors.length > 1) {
      dispatch(
        colorSelected({
          newColor: event.target.value,
          cakeName: cake.name,
          required: true,
        })
      );
    }
  };

  return (
    <FormControl sx={{ width: "95% !important", ml: 1, mr: 1, mt: 1 }} data-testid="enhanced-formcontrol-color">
      <InputLabel
        id="demo-simple-select-autowidth-label"
        classes={{ root: classes.inputLabel }}
        sx={{ fontSize: "0.8rem !important" }}
      >
        Choose Color
      </InputLabel>
      <Select
        className={classes.options}
        value={cake.name === selectedCakeName ? selectedColor.color : null}
        onChange={handleColorChange}
        label="Choose Option"
      >
        {cake.colors.map((option, idx) => (
          <MenuItem key={idx} value={option.name}>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
