import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAccessoryInfo } from "../../../../../redux/reducers/inStoreStandardCake";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: "2rem !important",
    marginBottom: "2rem !important",
    width: "100% !important",
    borderRadius: "10px !important",
    boxShadow: "none",
    backgroundColor: "white",
    padding: 0,
  },
  tableCellCenter: {
    fontSize: "1rem !important",
    textAlign: "center !important",
  },
  tableCellLeft: {
    fontSize: "1rem !important",
    textAlign: "left !important",
  },
  tableCellRight: {
    fontSize: "1rem !important",
    textAlign: "right !important",
  },
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "grey !important",
    marginBottom: "0.5rem",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
}));

export default function StandardCandleReview({ standardCandle, unitPrice }) {
  const classes = useStyles();
  let totalQuantity = 0;

  standardCandle.userSelection.map((e) => {
    totalQuantity += e.qty;
  });

  return (
    <>
      <TableContainer className={classes.tableContainer} data-testid="standard-candle-review">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                className={classes.tableCellLeft}
                sx={{
                  textTransform: "capitalize",
                  fontSize: "18px",
                  padding: "1rem 0 1rem 1rem !important",
                  width: "40%",
                }}
              >
                standard candle
              </TableCell>
              <TableCell
                className={classes.tableCellCenter}
                sx={{ fontSize: "18px", color: "grey", width: "30%" }}
              >
                {standardCandle.userSelection.map((e) => {
                  return (
                    <Box>
                      <Typography>{`color: ${e.color}, quantity: ${e.qty}`}</Typography>
                    </Box>
                  );
                })}
              </TableCell>

              <TableCell
                sx={{ fontWeight: "bold" }}
                className={classes.tableCellRight}
              >
                {`$ ${(unitPrice * totalQuantity).toFixed(2)}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
