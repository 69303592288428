/** @format */

import { Box, Modal, Typography, Button, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { selectOrderInfo, orderDelete } from "../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  deleteModal: {
    position: "absolute !important",
    display: "block !important",
    top: "40% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important",
    backgroundColor: "#fff !important",
    border: "none !important",
    borderRadius: "10px !important",
    maxWidth: "40% !important",
    zIndex: "5 !important",
  },
  deleteModalText: {
    fontWeight: "700 !important",
    fontSize: "24px !important",
    color: "#242424 !important",
    textAlign: "center !important",
    padding: " 70px 100px 30px 100px !important",
  },
  deleteModalControls: {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "center !important",
    margin: "0 0 30px 0 !important",
  },

  cancelBtn: {
    ...theme.containedButton,
  },

  confirmBtn: {
    ...theme.outlinedButton,
    width: "130px !important",
    height: "47px !important",
    margin: "0 0 0 45px !important",
  },
}));

function DeleteOrder({ open, handleClose }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const orderInfo = useSelector(selectOrderInfo);

  return (
    <Modal open={open} onClose={handleClose} data-testid="delete-order">
      <Paper className={classes.deleteModal} elevation={3}>
        <Typography className={classes.deleteModalText}>
          ARE YOU SURE YOU WANT TO DELETE THIS ORDER?
        </Typography>

        <Box className={classes.deleteModalControls}>
          <Button
            className={classes.cancelBtn}
            variant={"outlined"}
            onClick={() => handleClose()}
          >
            No
          </Button>

          <Button
            className={classes.confirmBtn}
            variant={"outlined"}
            onClick={() => {
              dispatch(orderDelete(orderInfo.orderId));
              handleClose();
            }}
          >
            Yes
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}

export default DeleteOrder;
