/** @format */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Toolbar,
  Typography,
  Button,
  Box,
  AppBar,
  Link,
  Drawer,
  MenuItem,
  Menu,
  Badge,
  Grid,
} from "@mui/material";
import {
  productsPopulatedFromDB,
  addOnsPopulatedFromDB,
  selectProducts,
  updateCart,
  resetToInitial,
} from "../redux/reducers/cart";
import logo from "../assets/images/logo.png";
import CustomDrawer from "./customDrawer";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import IconButton from "@mui/material/IconButton";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../auth";
import { getAuth, signOut } from "firebase/auth";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { generateRandomString } from "../utils/common";

const CORPORATE_CAKES_TYPES = [
  "branded-cake",
  "number-cake",
  "name-cake",
  "number-cupcake",
];
const CORPORATE_BOXES_TYPES = ["individual-box", "group-box"];

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "sticky !important",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
  },
  drawer: {
    backgroundColor: theme.palette.secondary.light,
    width: "17rem !important",
  },
  signinBtn: {
    ...theme.outlinedButton,
    marginRight: "10px !important",
    "&:hover": {
      color: theme.palette.secondary.light,
      borderColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "20px !important",
    },
  },
  signupBtn: {
    ...theme.outlinedButton,
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    color: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.dark,
    },
  },
  toolbar: {
    justifyContent: "space-between",
    padding: 0,
  },
  menuItems: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "13px !important",
    },
    ...theme.textStyle,
  },
  menuItem: {
    margin: "0 15px !important",
    fontSize: "15px !important",
    fontWeight: "bold !important",
  },
  hamburgerMenuIcon: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  logo: {
    display: "none !important",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block !important",
    },
  },
  cartIcon: {
    fontSize: "32px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  accountIcon: {
    fontSize: "32px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  welcomeText: {
    fontWeight: "500",
    marginRight: "20px !important",
    fontSize: "18px !important",
  },

  textStyle: { ...theme.textStyle },
}));

export default function TopAppBar() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  initializeApp(firebaseConfig());

  const auth = getAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const productsInCart = useSelector(selectProducts);

  // calculates the total number of products in the cart upon initial loading
  // to show in the shopping bag badge
  let totalNumberOfProducts = 0;
  for (let i = 0; i < productsInCart.length; i++) {
    totalNumberOfProducts += productsInCart[i].quantity;
  }

  const navigate = useNavigate();
  let userEmail = null;
  let products = [];

  useEffect(() => {
    let storedTempEmail = localStorage.getItem("tempEmail");
    let randomString = null;
    let tempEmail = null;

    if (!storedTempEmail || storedTempEmail === "") {
      randomString = generateRandomString();
      tempEmail = randomString + "@outlook.com";
      localStorage.setItem("tempEmail", tempEmail);
    }

    userEmail = currentUser
      ? currentUser.email
      : storedTempEmail
      ? storedTempEmail
      : tempEmail;
  }, []);

  useEffect(() => {
    async function fetchCartProducts() {
      const res = await axios.get("/api/cart?email=" + userEmail);
      if (res.status === 200) {
        
        const userCart = res.data;
        console.log("cart is" + userCart);
        if (userCart && userCart.products) {
          dispatch(updateCart(userCart.products));
        }

        if (userCart) {
          for (let i = 0; i < userCart?.products.length; i++) {
            let product = null;
            let productType = userCart.products[i].type;

            // making sure the product in the user's cart does exist in standard cakes collection
            if (productType === "standardCake") {
              let res = await axios.get(
                "/api/app/data/standardCakes?productId=" +
                  userCart.products[i].productId
              );

              product = res.data;
            }

            // making sure the product in the user's cart does exist in standard cakes collection
            if (productType === "cheesecake") {
              let res = await axios.get(
                "/api/app/data/cheesecakes?cheesecakeId=" +
                  userCart.products[i].productId
              );

              product = res.data;
            }

            

            // making sure the product in the user's cart does exist in custom cakes collection
            if (productType === "custom-cake") {
              let res = await axios.get(
                "/api/app/data/customCake?customCakeId=" +
                  userCart.products[i].cakeId
              );

              product = res.data;
            }

            if (CORPORATE_CAKES_TYPES.indexOf(productType) !== -1) {
              let res = await axios.get(
                "/api/app/data/corporate-cake?cakeId=" +
                  userCart.products[i].productId
              );

              product = res.data;
            }

            if (CORPORATE_BOXES_TYPES.indexOf(productType) !== -1) {
              let res = await axios.get(
                "/api/app/data/corporate-gift-box?boxId=" +
                  userCart.products[i].productId
              );

              product = res.data;
            }

            // if the product in the cart exists, then continue with the logic,
            // otherwise it's no longer available and needs to be ignored
            if (product) {
              products.push(userCart.products[i]);
            }
          }

          dispatch(productsPopulatedFromDB(products));

          if (userCart.addOns) {
            dispatch(addOnsPopulatedFromDB(userCart.addOns));
          } else {
            dispatch(addOnsPopulatedFromDB([]));
          }
        }
      }
    }

    fetchCartProducts();
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    navigate("/my-orders");
  };

  const logOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("user");
        dispatch(resetToInitial());
        navigate("/");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <AppBar className={classes.appBar} data-testid="appbar">
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box
          className={classes.drawer}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%", // This will make the box take the full height of the Drawer
            padding: 2, // Adjust padding as needed
          }}>
          <CustomDrawer />
        </Box>
      </Drawer>
      <Container>
        <Toolbar className={classes.toolbar}>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid item xs={1} className={classes.hamburgerMenuIcon}>
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2, color: "#fd7762" }}
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={2} className={classes.logo}>
              <Box component="div" onClick={() => navigate("/")}>
                <img
                  src={logo}
                  width={"150px"}
                  height={"100%"}
                  alt="cakeyhand logo"
                />
              </Box>
            </Grid>

            <Grid item xs={6} className={classes.menuItems}>
              <Link
                href="/cakes/specialty-cakes"
                color="inherit"
                underline="none"
                fontFamily="Inter"
                className={classes.menuItem}>
                SPECIALTY CAKES
              </Link>
              <Link
                href="/cakes/standard-cakes"
                color="inherit"
                underline="none"
                fontFamily="Inter"
                className={classes.menuItem}>
                STANDARD CAKES
              </Link>
              <Link
                href="/cakes/cheesecakes"
                color="inherit"
                underline="none"
                fontFamily="Inter"
                className={classes.menuItem}>
                CHEESECAKES
              </Link>
            </Grid>

            {currentUser && (
              <Grid item>
                <Box sx={{ mt: "9px" }}>
                  <span className={classes.welcomeText}>
                    Hi {currentUser.firstName}
                  </span>
                  <Badge
                    badgeContent={totalNumberOfProducts}
                    sx={{
                      mr: 3,
                      "& .MuiBadge-badge": {
                        color: "#fff",
                        backgroundColor: "#fd7762",
                      },
                    }}
                    onClick={() => navigate("/cart")}>
                    <ShoppingBagOutlinedIcon className={classes.cartIcon} />
                  </Badge>
                  <Badge>
                    <AccountCircleOutlinedIcon
                      className={classes.accountIcon}
                      onClick={handleMenu}
                    />
                  </Badge>

                  <Menu
                    id="menu-appbar"
                    data-testid="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <MenuItem fontFamily="Inter" onClick={() => handleClose()}>My Orders</MenuItem>
                    <MenuItem
                    fontFamily="Inter"
                      onClick={() => {
                        handleClose();
                        logOut();
                      }}>
                      Log Out
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
            )}

            {!currentUser && (
              <Grid item>
                <Box sx={{ display: "flex" }}>
                  <Badge
                    badgeContent={totalNumberOfProducts}
                    sx={{
                      mt: 1,
                      mr: 3,
                      "& .MuiBadge-badge": {
                        color: "#fff",
                        backgroundColor: "#fd7762",
                      },
                    }}
                    onClick={() => navigate("/cart")}>
                    <ShoppingBagOutlinedIcon className={classes.cartIcon} />
                  </Badge>
                  <Button
                    variant={"outlined"}
                    className={classes.signinBtn}
                    onClick={() => navigate("/login")}
                    data-testid="login-btn">
                    <Typography
                      className={classes.textStyle}
                      sx={{
                        fontFamily: "Inter",
                        color: "#e84513",
                        padding: isSmallScreen ? "3px 0px" : "5px 5px",
                      }}>
                      Log in
                    </Typography>
                  </Button>
                  <Button
                    variant={"outlined"}
                    className={classes.signupBtn}
                    onClick={() => navigate("/signup")}
                    data-testid="signup-btn">
                    <Typography
                      className={classes.textStyle}
                      sx={{ fontFamily:"Inter", padding: isSmallScreen ? "3px 0px" : "5px 5px" }}>
                      Sign Up
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
