/** @format */

import { React } from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import desserts from "../../assets/images/patisserie/desserts.jpg";
import cupcakes from "../../assets/images/patisserie/cupcakes.jpg";
import macarons from "../../assets/images/patisserie/macarons.jpg";
import cheesecakes from "../../assets/images/patisserie/cheesecakes.jpg";
import cookies from "../../assets/images/patisserie/cookies.jpg";
import ErrorIcon from "@mui/icons-material/Error";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
    textAlign: "center",
    marginTop: "4rem !important",
  },
  contentWrapper: {
    borderRadius: "20px !important",
    backgroundColor: "#fcefeb !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex !important",
      justifyContent: "space-between",
    },
  },
  headerWrapper: {
    padding: "15px !important",
    [theme.breakpoints.up("sm")]: {
      padding: "25px 100px 25px 25px !important",
    },
  },
  heading: {
    color: theme.palette.header.main,
    ...theme.textStyle,
    fontSize: "65px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "85px !important",
    },
  },
  cheesecake: {
    fontSize: "50px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "85px !important",
    },
  },
  imgStyle: {
    width: "100% !important",
    height: "100% !important",
    borderTopLeftRadius: "20px !important",
    borderTopRightRadius: "20px !important",
    [theme.breakpoints.up("sm")]: {
      width: "600px !important",
      borderTopRightRadius: "0px !important",
      borderBottomLeftRadius: "20px !important",
    },
  },
  minQTYBox: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    backgroundColor: theme.palette.newSecondary.light,
    borderRadius: "40px",
    textAlign: "center",
    marginTop: "20px !important",
    padding: "10px !important",
  },
  minQTYText: {
    color: theme.palette.primary.dark,
    fontWeight: "bold !important",
    fontSize: "13px !important",
    marginLeft: "20px !important",
  },
  warningIcon: {
    color: theme.palette.primary.dark,
  },
}));

const headers = [
  {
    category: "dessert",
    texts: ["Desserts", "&", "Pastries"],
    imgPath: desserts,
  },
  {
    category: "cupcake",
    texts: ["Cupcakes", "&", "Romance"],
    imgPath: cupcakes,
  },
  { category: "macaron", texts: ["French", "Macarons"], imgPath: macarons },
  {
    category: "cheesecake",
    texts: ["Bake &", "No Bake", "Cheesecakes"],
    imgPath: cheesecakes,
  },
  { category: "cookie", texts: ["Cookies", "&", "Magic"], imgPath: cookies },
];

export default function Main({ category }) {
  const classes = useStyles();

  const componentDetails = headers.find((obj) => obj.category === category);

  return (
    <Container>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.imgWrapper}>
              <img
                src={componentDetails.imgPath}
                className={classes.imgStyle}
              ></img>
            </Box>
            <Box className={classes.headerWrapper}>
              {componentDetails.texts.map((text) => (
                <Typography
                  className={`${classes.heading} ${
                    category === "cheesecake" ? classes.cheesecake : ""
                  }`}
                >
                  {text}
                </Typography>
              ))}

              <Box className={classes.minQTYBox}>
                <ErrorIcon className={classes.warningIcon} />
                <Typography className={classes.minQTYText}>
                  minimum quantity applies
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
