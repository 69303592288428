/** @format */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { querySet, selectQuery } from "../../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  searchField: {
    margin: "0 0 25px 0 !important",
    [theme.breakpoints.up("sm")]: {
      margin: "0 15px 0 0 !important",
      width: "200px !important",
    },
  },
}));

function SearchField({ labelText, queryBy, queryProp }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useSelector(selectQuery);

  return (
    <TextField
      className={classes.searchField}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      label={labelText}
      value={queryBy}
      onChange={(e) =>
        dispatch(querySet({ ...query, [queryProp]: e.target.value }))
      }
      data-testid="search-field"
    />
  );
}

export default SearchField;
