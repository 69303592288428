/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, InputAdornment, Button, Box } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { makeStyles } from "@mui/styles";
import { pastOrdersSet } from "../../../../redux/reducers/admin";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  searchField: {
    margin: "0 0 15px 0 !important",
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 25px 0 !important",
    },
  },
}));

function FetchField({ labelText, fetchParam }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [queryParam, setQueryParam] = useState("");

  const handleFetchClick = async () => {
    let res;
    if (fetchParam === "name") {
      console.log("sending request");
      res = await axios.get(
        `/api/admin/order/getOrderByName?name=${queryParam}`
      );
    } else if (fetchParam === "customerEmail") {
      res = await axios.get(
        `/api/admin/order/getOrderByCustomerEmail?email=${queryParam}`
      );
    }
    console.log(res.data);
    dispatch(pastOrdersSet(res.data));
  };

  const handleClearClick = async () => {
    setQueryParam("");
    dispatch(pastOrdersSet([]));
  };

  const handleChange = (e) => {
    setQueryParam(e.target.value);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        className={classes.searchField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <GetAppIcon />
            </InputAdornment>
          ),
        }}
        label={labelText}
        value={queryParam}
        onChange={(e) => handleChange(e)}
        data-testid="search-field"
      />
      <Box sx={{ mb: 2 }}>
        <Button variant="contained" onClick={handleFetchClick} sx={{ mr: 2 }}>
          fetch
        </Button>
        <Button variant="contained" onClick={handleClearClick}>
          clear
        </Button>
      </Box>
    </Box>
  );
}

export default FetchField;
