import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import image from "../../assets/images/services/wedding-bg.png";

const useStyles = makeStyles({
  heading: {
    fontSize: "56px !important",
    textTransform: "uppercase",
    color: "#ffffff !important",
  },
  subHeading: {
    fontSize: "1rem !important",
    color: "#ffffff !important",
    marginTop: "4rem !important"
  },
  wrapperBox: {
    width: "100% !important",
    height: "50vh",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    backgroundImage: `url(${image})`,
  },
});

export default function Header() {
  const classes = useStyles();

  return (
    <Box className={classes.wrapperBox}>
      <Typography align="center" className={classes.heading}>
        elegant & exquisite
      </Typography>
      <Typography align="center" className={classes.subHeading}>
        Trust us to create a stunning and delicious centerpiece for your reception
      </Typography>
    </Box>
  );
}
