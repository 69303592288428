/** @format */

import React from "react";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Body from "./body";

const SpecialtyCakes = () => {
  return (
    <>
      <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      <Body></Body>
      <Footer bgColor="#fd7762cc"></Footer>
    </>
  );
};

export default SpecialtyCakes;
