/** @format */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newOrder: {
    cakeInfo: {
      flavor: "",
      size: "",
      quantity: 1,
      cakeType: "",
      price: 0,
      imgPath: "",
    },
    customerInfo: {
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
    },
    eventInfo: {
      date: null,
      timeSlot: null,
      pickupLocation: null,
    },
    accessoryInfo: {
      piping: "",
      other: "",
    },
    accessoryInfo: [
      {
        type: "piping",
        content: {
          options: "",
          isSelected: false,
        },
      },
      {
        type: "standardCandle",
        content: {
          options: [],
          isSelected: false,
        },
      },
    ],
    specialNotes: "",
    total: 0,
  },
  booking: {
    auth: false,
    orders: [],
    pastOrders: [],
    query: {},
    orderInfo: {},
    productIndex: -1,
  },
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    flavorSelected: (state, action) => {
      state.newOrder.cakeInfo.flavor = action.payload;
    },
    sizeSelected: (state, action) => {
      state.newOrder.cakeInfo.size = action.payload;
    },
    quantitySelected: (state, action) => {
      state.newOrder.cakeInfo.quantity = action.payload;
    },
    cakeTypeSelected: (state, action) => {
      state.newOrder.cakeInfo.cakeType = action.payload.displayName;
    },
    priceSelected: (state, action) => {
      state.newOrder.cakeInfo.price = action.payload;
    },
    imgUploaded: (state, action) => {
      state.newOrder.cakeInfo.imgPath = action.payload;
    },
    nameSelected: (state, action) => {
      state.newOrder.customerInfo.name = action.payload;
    },
    emailSelected: (state, action) => {
      state.newOrder.customerInfo.email = action.payload;
    },
    phoneNumberSelected: (state, action) => {
      state.newOrder.customerInfo.phoneNumber = action.payload;
    },
    addressSelected: (state, action) => {
      state.newOrder.customerInfo.address = action.payload;
    },
    dateSelected: (state, action) => {
      state.newOrder.eventInfo.date = action.payload;
    },
    timeSlotSelected: (state, action) => {
      state.newOrder.eventInfo.timeSlot = action.payload;
    },
    pickupLocationSelected: (state, action) => {
      state.newOrder.eventInfo.pickupLocation = action.payload;
    },
    textPipingSelected: (state, action) => {
      if (action.payload.val !== "") {
        state.newOrder.accessoryInfo[0].content.options = action.payload.val;
        state.newOrder.accessoryInfo[0].content.isSelected = true;
      } else {
        state.newOrder.accessoryInfo[0].content.isSelected = false;
      }
    },
    specialNotesSelected: (state, action) => {
      state.newOrder.specialNotes = action.payload;
    },
    totalUpdated: (state, action) => {
      state.newOrder.total += action.payload;
    },
    resetToInitial: () => initialState,

    authSet: (state, action) => {
      state.booking.auth = action.payload;
    },
    orderSet: (state, action) => {
      state.booking.orders = action.payload;
    },
    pastOrdersSet: (state, action) => {
      state.booking.pastOrders = action.payload;
    },
    orderDelete: (state, action) => {
      const indexToDelete = state.booking.orders.findIndex(
        (item) => item.orderId === action.payload
      );

      state.booking.orders.splice(indexToDelete, 1);
    },
    orderInfoSet: (state, action) => {
      state.booking.orderInfo = action.payload;
    },
    productIndexSet: (state, action) => {
      state.booking.productIndex = action.payload;
    },
    querySet: (state, action) => {
      state.booking.query = action.payload;
    },
  },
});

export const selectNewOrder = (state) => state.admin.newOrder;
export const selectEventInfo = (state) => state.admin.newOrder.eventInfo;
export const selectAuth = (state) => state.admin.booking.auth;
export const selectOrders = (state) => state.admin.booking.orders;
export const selectPastOrders = (state) => state.admin.booking.pastOrders;
export const selectOrderInfo = (state) => state.admin.booking.orderInfo;
export const selectProductIndex = (state) => state.admin.booking.productIndex;
export const selectQuery = (state) => state.admin.booking.query;

export const {
  flavorSelected,
  sizeSelected,
  quantitySelected,
  cakeTypeSelected,
  priceSelected,
  imgUploaded,
  nameSelected,
  emailSelected,
  phoneNumberSelected,
  addressSelected,
  dateSelected,
  timeSlotSelected,
  pickupLocationSelected,
  textPipingSelected,
  specialNotesSelected,
  totalUpdated,
  resetToInitial,
  authSet,
  orderSet,
  pastOrdersSet,
  orderDelete,
  orderInfoSet,
  productIndexSet,
  querySet,
} = adminSlice.actions;

export default adminSlice.reducer;
