import React from "react";
import { Box } from "@mui/material";
import CustomizationOption from "./customizationOption";

export default function CustomizationOptionsSection({ options }) {
  return (
    <Box sx={{ mb: 2 }} data-testid="customization-option-section">
      {options.map((option) => {
        return <CustomizationOption option={option}></CustomizationOption>;
      })}
    </Box>
  );
}
