import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cakeSelected,
  cheesecakeSelected,
  selectCakeChosen,
} from "../../../redux/reducers/inStoreStandardCake";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MultiImageShow from "../../../components/multiImageShow";
import CakeContent from "./cakeContent";
import CheesecakeContent from "./cheesecakeContent";

const useStyles = makeStyles(() => ({
  paper: {
    elevation: "10 !important",
    padding: "10px !important",
    marginLeft: "15px !important",
    marginRight: "15px !important",
    textAlign: "center !important",
    boxShadow: "none",
    borderRadius: "20px !important",
  },
}));

export default function Cake({ cake, category }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedCake = useSelector(selectCakeChosen);

  const handleCakeSelection = (cake) => {
    if (category === "cheesecake") {
      dispatch(cheesecakeSelected(cake));
    } else {
      dispatch(cakeSelected(cake));
    }
  };

  console.log(selectedCake.flavor, cake.flavor);

  return (
    <Paper
      className={classes.paper}
      sx={{
        outline:
          (selectedCake.name &&
            selectedCake.name === cake.name &&
            category !== "cheesecake") ||
          selectedCake.flavorSection.flavor === cake.flavor
            ? "2px solid #fd7762 !important"
            : "1px solid #828282 !important",
      }}
      onClick={() => handleCakeSelection(cake)}>
      <MultiImageShow images={cake.images}></MultiImageShow>
      {category === "cheesecake" ? (
        <CheesecakeContent content={cake} />
      ) : (
        <CakeContent content={cake}></CakeContent>
      )}
    </Paper>
  );
}
