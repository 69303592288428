/** @format */

import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

// const useStyles = makeStyles((theme) => ({
//   secondaryBtn: {
//     ...theme.containedButtonLight,
//     padding: "20px",
//     width: "100%",
//   },
// }));

export default function SecondaryActionButton({
  children,
  clickEvent,
  testId,
  styleObj,
}) {
  // const classes = useStyles();

  return (
    <Button className={styleObj} onClick={clickEvent} data-testid={testId}>
      {children}
    </Button>
  );
}
