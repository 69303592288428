/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  flowerOptionSelected,
  selectFlowerOptionChosen,
  currentPriceUpdated,
} from "../../../../redux/reducers/cake.js";
import CheckIcon from "@mui/icons-material/Check";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "70px",
    height: "70px",
    border: "1px solid #000 !important",
    borderRadius: "5px",
    marginBottom: "10px !important",
    position: "relative",
  },
  colorName: {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translate(-50%)",
    ...theme.textStyle,
  },
  checkIcon: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function Flower({ flower, parentId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedFlower = useSelector(selectFlowerOptionChosen);

  return (
    <Box
      className={classes.wrapper}
      sx={{
        backgroundColor: "#fff",
        mr: 2,
      }}
      onClick={() => {
        flower["parentId"] = parentId;
        flower["selection"] = flower.name;
        dispatch(flowerOptionSelected(flower));
        dispatch(currentPriceUpdated());
      }}
      data-testid="flower"
    >
      {selectedFlower && selectedFlower.name === flower.name && (
        <CheckIcon
          className={classes.checkIcon}
          sx={{
            color: "#fd7762",
          }}
        />
      )}

      <Typography className={classes.colorName}>{flower.name}</Typography>
    </Box>
  );
}
