import * as React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid,
  Modal,
  Box,
} from "@mui/material";
import { theme } from "../../theme";
import BrandedCakeModalContent from "./modal-contents/brandedCakeModalContent";
import NumberCakeModalContent from "./modal-contents/numberCakeModalContent";
import NameCakeModalContent from "./modal-contents/nameCakeModalContent";
import NumberCupcakesModalContent from "./modal-contents/numberCupcakesModalContent";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    height: "80%",
    width: "80%",
    padding: 20,
    overflowY: "scroll",
    //[theme.breakpoints.up("sm")]: {
    //width: "40%",
    //},
  },
}));

const buildModalContent = (product, handleClose) => {
  switch (product.type) {
    case "branded-cake":
      return <BrandedCakeModalContent product={product} handleClose={handleClose}/>;
    case "number-cake":
      return <NumberCakeModalContent product={product} handleClose={handleClose}/>;
    case "name-cake":
      return <NameCakeModalContent product={product} handleClose={handleClose}/>;
    case "number-cupcake":
      return <NumberCupcakesModalContent product={product} handleClose={handleClose}/>;
    default:
      return <BrandedCakeModalContent product={product} handleClose={handleClose}/>;
  }
}

export default function ItemCard({ product }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Grid item xs={12} sm={4} md={3}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            alignItems: "center",
          }}
        >
          <CardMedia
            sx={{
              height: 200,
              width: 200,
              borderRadius: "20px",
            }}
            image={product.imgPath}
            title={product.name}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
            }}
            style={{paddingBottom: 0}}
          >
            <Typography variant="h5" sx={{ ...theme.productCardText }}>
              {product.name}
            </Typography>
          </CardContent>
        </Card>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              ...theme.secondaryButton,
              width: "100%",
              borderRadius: "74px",
              textTransform: "none",
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            Customize
          </Button>
        </CardActions>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          { buildModalContent(product, handleClose)}
        </Box>
      </Modal>
    </>
  );
}
