/** @format */

import { Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProductSelect from "../editOrder/ProductSelect";
import { useSelector } from "react-redux";
import { selectOrderInfo } from "../../../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  sectionHeadText: {
    color: "#242424 !important",
    fontWeight: "700 !important",
    fontSize: "18px !important",
    marginBottom: "15px !important",
  },
  itemGridContainer: {
    display: "flex !important",
    flexDirection: "row !important",
  },
  cell: {
    padding: "0 1rem !important",
  },
  productImg: {
    width: "150px !important",
    height: "150px !important",
    borderRadius: "20px !important",
  },
  fieldHead: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    padding: "0 0 18px 0 !important",
    color: "#242424 !important",
  },

  fieldDescription: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    color: "#676767 !important",
    marginBottom: "10px !important",
  },

  priceText: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    color: theme.palette.primary.dark,
  },

  flexCell: {
    display: "flex !important",
    flexDirection: "row !important",
    margin: "0 0 1rem 0 !important",
  },
  addOnCell: { margin: "0 0 1rem 1rem !important" },

  quantityCell: {
    display: "inline-flex !important",
    flexDirection: "column !important",
    margin: "0 0 1rem 0 !important",
  },

  quantityGrid: {
    display: "flex !important",
    paddingRight: "100px !important",
    justifyContent: "flex-end !important",
    margin: "0 0 1rem 0 !important",
  },
  linkText: {
    color: "blue !important",
    cursor: "pointer !important",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
  itemContainer: {},
}));

export default function CakeDetail({ product }) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={3} data-testid="cake-detail">
        <Typography className={classes.sectionHeadText}>Cake Info</Typography>
      </Grid>
      <Grid item xs={6}>
        <img
          src={product.imgPath}
          alt="cake-pic"
          className={classes.productImg}
        />
      </Grid>
      <Grid item xs={6}>
        {product.size && (
          <Typography className={classes.fieldDescription}>
            Size: {product.size.split("-")[0]}
          </Typography>
        )}
        {product.flavor && (
          <Typography className={classes.fieldDescription}>
            Flavor: {product.flavor}
          </Typography>
        )}

        <Typography className={classes.fieldDescription}>
          Quantity: {product.quantity}
        </Typography>
        <Typography className={classes.fieldDescription}>
          Price: ${product.price}
        </Typography>
      </Grid>
    </>
  );
}
