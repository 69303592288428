/** @format */

import {
  Box,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrders,
  selectQuery,
  orderInfoSet,
  selectPastOrders,
} from "../../../../redux/reducers/admin";
import Status from "../Status";
import CakeTableRow from "./CakeTableRow";
import OrderMenu from "../OrderMenu";
import SortProperty from "../sortProperty";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: "#F1F1F1 !important",
    borderColor: "#8B8A98 !important",
    border: "none none solid none !important",
  },
  dateLabelContainer: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
  },
  orderIdLink: {
    fontSize: "14px !important",
    color: "blue !important",
    cursor: "pointer !important",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },

  orderDetailText: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },

  orderDetailSubText: {
    fontSize: "12px !important",
    color: "#676767 !important",
  },
  statusContainer: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
  },
}));

function TableOrderList({ setOrderOpen, setDeleteDialogOpen }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const sortedOrders = useSelector(selectOrders);
  const pastOrders = useSelector(selectPastOrders);
  let orderListToBeRendered = [];
  {
    /*  if the list of pastOrders is empty it means the admin has not requested to see past orders */
  }
  if (pastOrders.length === 0) {
    orderListToBeRendered = sortedOrders;
  } else {
    orderListToBeRendered = pastOrders;
  }
  const query = useSelector(selectQuery);

  let totalFilteredCost = 0;

  return (
    <TableContainer data-testid="table-order-list">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} style={{ width: "10%" }}>
              <Box className={classes.dateLabelContainer}>
                <SortProperty up="orderIdAsc" down="orderIdDec">
                  Order ID
                </SortProperty>
              </Box>
            </TableCell>
            <TableCell className={classes.tableHead} style={{ width: "15%" }}>
              <Box className={classes.dateLabelContainer}>
                <SortProperty up="nameAsc" down="nameDec">
                  Customer Name
                </SortProperty>
              </Box>
            </TableCell>
            <TableCell
              className={classes.tableHead}
              style={{
                width: "10%",
              }}>
              <Box className={classes.dateLabelContainer}>
                <SortProperty up="orderDateAsc" down="orderDateDec">
                  Order Date
                </SortProperty>
              </Box>
            </TableCell>
            <TableCell className={classes.tableHead} style={{ width: "10%" }}>
              <Box className={classes.dateLabelContainer}>
                <SortProperty up="pickupDateAsc" down="pickupDateDec">
                  Pick Up Date
                </SortProperty>
              </Box>
            </TableCell>

            <TableCell className={classes.tableHead}>
              <TableCell
                className={classes.tableHead}
                style={{
                  border: "none",
                }}>
                Image
              </TableCell>
              <TableCell
                className={classes.tableHead}
                style={{ border: "none", width: "31%" }}>
                Size
              </TableCell>

              <TableCell
                className={classes.tableHead}
                style={{ border: "none", width: "23%" }}>
                Item / Flavor
              </TableCell>
            </TableCell>

            <TableCell className={classes.tableHead} style={{ width: "8%" }}>
              Additional Notes
            </TableCell>

            <TableCell className={classes.tableHead} style={{ width: "8%" }}>
              Total
            </TableCell>
            <TableCell className={classes.tableHead} style={{ width: "8%" }}>
              Pickup Location
            </TableCell>
          </TableRow>
        </TableHead>

        {orderListToBeRendered &&
          orderListToBeRendered.length > 0 &&
          orderListToBeRendered.map((order) => {
            let singleOrder = null;
            if (order.edits && order.edits.length > 0) {
              const lastElement = order.edits.slice(-1).pop();
              singleOrder = lastElement.order;
            } else {
              singleOrder = order;
            }
            const orderDate = dayjs(singleOrder.orderDate).format("YYYY-MM-DD");
            const pickupDate = dayjs(singleOrder.collectionDate).format(
              "YYYY-MM-DD"
            );

            if (
              singleOrder.orderId.search(query.orderId) !== -1 &&
              singleOrder.customerInfo.name.search(query.customerName) !== -1 &&
              singleOrder.email.search(query.customerEmail) !== -1 &&
              pickupDate.search(query.pickupDate) !== -1
            ) {
              totalFilteredCost = totalFilteredCost + singleOrder.total;

              return (
                <>
                  <TableRow>
                    <TableCell>
                      <Typography
                        className={classes.orderIdLink}
                        onClick={() => {
                          dispatch(orderInfoSet(singleOrder));
                          setOrderOpen(true);
                        }}>
                        {singleOrder.orderId}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.orderDetailText}>
                        {singleOrder.customerInfo.name}
                      </Typography>
                      <Typography className={classes.orderDetailSubText}>
                        {singleOrder.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.orderDetailText}>
                        {orderDate}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.orderDetailText}>
                        {pickupDate}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      {singleOrder.products.map((product, i) => {
                        return <CakeTableRow product={product} />;
                      })}
                    </TableCell>
                    <TableCell>{singleOrder.additionalNotes}</TableCell>
                    <TableCell>{singleOrder.total.toFixed(2)}$</TableCell>
                    <TableCell>{singleOrder.collectionLocation}</TableCell>
                  </TableRow>
                </>
              );
            }
          })}
      </Table>
    </TableContainer>
  );
}

export default TableOrderList;
