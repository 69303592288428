import React from "react";
import { 
  Typography, 
  Box, 
  TextField, 
} from "@mui/material";

import { inputColors, pinkText, theme } from "../../../theme";
import FlavorSelectMultiple from "../../../components/flavorSelectMultiple";
import DecorationSelect from "./decorationSelect";


export default function Name({ product, state, setState }) {
  const flavorsOpts = product.customizationOptions.filter(opt => opt.name==="corporate-name-cake-flavors");
  const decorations = product.customizationOptions.filter(opt => opt.name==="corporate-name-cake-decorations");
  const decorationOption = decorations.length > 0 ? decorations[0] : null

  const handleChange = (e)=>{
    setState({
      ...state,
      companyName: e.target.value
    })
  }

  return(
      <>
          <Box sx={{margin: "12px"}}>
          <Typography sx={{color: "#676767"}}>Enter name of company</Typography>
          </Box>
          <TextField
            id="name"
            label="Enter name of Company"
            helperText="*Only English Letters"
            InputProps={{
              style: {
                borderRadius: "24px",
              },
            }}
            sx={inputColors}
            onChange={handleChange}
          />

          <FlavorSelectMultiple 
            flavorOptions={flavorsOpts[0].values} 
            setState={setState} 
            state={state}
          />

          <DecorationSelect 
            decorationOption={decorationOption} 
            state={state} 
            setState={setState}
          />

      </>
  )
}
