export default function firebaseConfig(){
    return {
        apiKey: "AIzaSyAZzZaw_BZwFfruvGhfjtohT6bwiQp7aYw",
        authDomain: "cakeyhand-93d0a.firebaseapp.com",
        databaseURL: "https://cakeyhand-93d0a.firebaseio.com",
        projectId: "cakeyhand-93d0a",
        storageBucket: "cakeyhand-93d0a.appspot.com",
        messagingSenderId: "88227364588",
        appId: "1:88227364588:web:21947954c0706a8a096fc9",
        measurementId: "G-HL7RG0JY0D",
    }
  };