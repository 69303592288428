import { React } from "react";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SectionHeadings from "../../../components/sectionHeadings";
import PickupLocation from "./pickupLocation";
import Email from "./email";
import FirstName from "./firstName";
import PhoneNumber from "./phoneNumber";
import LastName from "./lastName";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DateSelection from "./dateSelection";

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: "2rem !important",
    marginBottom: "1rem !important",
    padding: "15px 10px",
  },
  collectionTypesWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
  },
}));

export default function Collection() {
  const classes = useStyles();
  const signedIn = localStorage.getItem("user");

  return (
    <Paper elevation={10} className={classes.wrapper}>
      <Grid container>
        <Grid item xs={12}>
          <SectionHeadings heading="Collection & Contact Info"></SectionHeadings>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6}>
          <PickupLocation />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateSelection />
        </Grid>
      </Grid>

      {!signedIn && (
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <FirstName placeholder="First name" icon={<PersonOutlinedIcon />} />
          </Grid>
          <Grid item xs={6}>
            <LastName placeholder="Last name" icon={<PersonOutlinedIcon />} />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PhoneNumber
            placeholder="Phone number"
            icon={<LocalPhoneOutlinedIcon />}
          />
        </Grid>
        <Grid item xs={6}>
          {!signedIn && (
            <Email placeholder="Email address" icon={<MailOutlineIcon />} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
