/** @format */
import React from "react";
import { Grid } from "@mui/material";
import General from "./general";
import ProductDetail from "./productDetail";
import AccessoriesDetailWrapper from "./accessoriesDetailWrapper";

export default function OrderDetail() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <General />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProductDetail />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AccessoriesDetailWrapper />
      </Grid>
    </Grid>
  );
}
