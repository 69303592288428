/** @format */

export function makeArrayReadable(list, fn) {
  return [...list.map((item) => ({ ...item }))].sort(fn);
}

export function orderSorting(sortQuery, orders) {
  let sortOrders = orders;

  switch (sortQuery) {
    case "orderIdDec": {
      sortOrders = makeArrayReadable(
        orders,
        (a, b) =>
          parseInt(a.orderId.replace(/-/g, "")) -
          parseInt(b.orderId.replace(/-/g, ""))
      );

      break;
    }
    case "orderIdAsc": {
      sortOrders = makeArrayReadable(
        orders,
        (a, b) =>
          parseInt(b.orderId.replace(/-/g, "")) -
          parseInt(a.orderId.replace(/-/g, ""))
      );

      break;
    }

    case "nameDec": {
      sortOrders = makeArrayReadable(orders, (a, b) =>
        a.name.localeCompare(b.name)
      );

      break;
    }
    case "nameAsc": {
      sortOrders = makeArrayReadable(orders, (a, b) =>
        b.name.localeCompare(a.name)
      );
      break;
    }

    case "orderDateDec": {
      sortOrders = makeArrayReadable(
        orders,
        (a, b) => Date.parse(a.orderDate) - Date.parse(b.orderDate)
      );

      break;
    }
    case "orderDateAsc": {
      sortOrders = makeArrayReadable(
        orders,
        (a, b) => Date.parse(b.orderDate) - Date.parse(a.orderDate)
      );
      break;
    }

    case "pickupDateDec": {
      sortOrders = makeArrayReadable(
        orders,
        (a, b) => Date.parse(a.collectionDate) - Date.parse(b.collectionDate)
      );

      break;
    }
    case "pickupDateAsc": {
      sortOrders = makeArrayReadable(
        orders,
        (a, b) => Date.parse(b.collectionDate) - Date.parse(a.collectionDate)
      );
      break;
    }
    default:
      break;
  }

  return sortOrders;
}
