/** @format */

import { createStore, combineReducers, compose } from "redux";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from "redux-persist/lib/storage";
import macarons from "./reducers/macarons";
import cart from "./reducers/cart";
import cake from "./reducers/cake";
import holidayProduct from "./reducers/holidayProduct";
import inStoreStandardCake from "./reducers/inStoreStandardCake";
import websiteStandardCake from "./reducers/websiteStandardCake";
import admin from "./reducers/admin";
import wedding from "./reducers/wedding";
import cheesecake from "./reducers/cheesecake";
import corporateProducts from "./reducers/corporateProducts";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2
};

export const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({ 
    macarons, 
    cart, 
    cake, 
    holidayProduct,
    inStoreStandardCake, 
    websiteStandardCake, 
    admin, 
    wedding, 
    cheesecake,
    corporateProducts,
   })
);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(persistedReducer, composeEnhancers());
