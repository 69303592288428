import React from "react";
import { Typography, Box } from "@mui/material";
import FlavorSelectMultiple from "../../../components/flavorSelectMultiple";

import NumberSelect from "./numberSelect";
import DecorationSelect from "./decorationSelect";

export default function Numbers({ options, productType, setState, state }) {

    const OPTIONS_BY_CORP_PRODUCT_TYPE_MAP = {
      "number-cake": {
        flavor: "corporate-number-cake-flavors",
        decorations: "corporate-number-cake-decorations",
      },
      "number-cupcake": {
        flavor: "corporate-number-cupcake-flavors",
        decorations: "corporate-number-cupcake-decorations",
      }
    }

    const flavors = options.filter(opt => opt.name===OPTIONS_BY_CORP_PRODUCT_TYPE_MAP[productType].flavor);
    const flavorOptions = flavors.length > 0 ? flavors[0] : null;

    const decorations = options.filter(opt => opt.name===OPTIONS_BY_CORP_PRODUCT_TYPE_MAP[productType].decorations);
    const decorationOption = decorations.length > 0 ? decorations[0] : null

    return(
      <>
        <Box sx={{margin: "12px"}}>
          <Typography sx={{color: "#676767"}}>Enter Numbers</Typography>
          <Typography sx={{color: "gray", fontSize: "12px"}}>
              * The number can be up to 3 digits, 0-100
          </Typography>
        </Box>

        <NumberSelect
          state={state}
          setState={setState}
        />

        <FlavorSelectMultiple 
          flavorOptions={flavorOptions.values} 
          setState={setState} 
          state={state}
        />

        <DecorationSelect 
          decorationOption={decorationOption} 
          state={state} 
          setState={setState}
        />
      </>
    )
}
