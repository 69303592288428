import React, { useEffect, useState } from "react";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { pinkText, theme } from "../../../theme";
import Brand from "../components/brand";
import CompanyColors from "../components/companyColors";
import BrandBottom from "../components/brandBottom";
import { updateCart } from "../../../redux/reducers/cart";
import {
  flavorsReset,
  selectedPackagingReset,
  selectedSizeReset,
} from "../../../redux/reducers/macarons";
import { useDispatch } from "react-redux";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
}));

export default function BrandedCakeModalContent({ handleClose, product }) {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  let tempEmail = localStorage.getItem("tempEmail");

  const getPrice = (size) => {
    const sizeOpts = product.customizationOptions.filter(opt=>opt.name==="corporate-branded-cake-size")[0].values
    return sizeOpts.filter(sizeOpt => sizeOpt.name===size)[0].price
  }

  const initColors = { light:[], pastel:[], normal:[], dark:[] };

  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [state, setState] = useState({
    name: "",
    size: "",
    price: "",
    flavor: "",
    color: initColors,
    companyLogo: "", 
    quantity: 1,
    type: product.type,
  });

  useEffect(()=>{
    if (state.size) {
      setState({ ...state, price: getPrice(state.size)})
    }
  }, [state.size])

  const addToCart = async () => {

    const selectedColors = [
      ...state.color.light,
      ...state.color.pastel,
      ...state.color.normal,
      ...state.color.dark,
    ].join(",")

    if (!state.companyLogo) {
      return alert("Please upload a company logo");
    }

    if (!selectedColors) {
      return alert("Please select company colors");
    }

    if (!state.size) {
      return alert("Please select a size");
    }

    if (!state.flavor) {
      return alert("Please select a flavor");
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);

      let productToAdd = {
        id: Date.now(),
        name: product.name,
        productId: product._id,
        size: state.size,
        imgPath: product.imgPath,  
        flavor: state.flavor,
        type: product.type,
        quantity: 1,
        price: state.price,
        customizations: [{
          parentDisplayName: "Colors",
          selection: selectedColors
        }, {
          parentDisplayName: "Logo",
          selection: state.companyLogo
        }]
      };

      try {
        const res = await axios.post("/api/cart/addProduct/", {
          userEmail: currentUser ? currentUser.email : tempEmail,
          product: productToAdd,
        });

        if (res.status === 200) {
          handleClose();
          window.setTimeout(() => {
            dispatch(updateCart(res.data));
            dispatch(selectedSizeReset());
            dispatch(flavorsReset());
            dispatch(selectedPackagingReset());
            setSuccess(true);
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        console.error(error)
        alert(
          "We could not add the product. Please make sure all required fields are provided."
        );
        setSuccess(false);
        setLoading(false);
      }
    }
  };
  return (
    <div sx={{ overflow: "hidden" }}>
      <Typography
        sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}
        onClick={handleClose}
      >
        <CloseIcon />
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ textTransform: "uppercase" }}>
          {product.name}
        </Typography>
        <Box sx={{ display: "flex" }}>
          {product.basePrices.map((basePrice, i) => {
            return (
              <>
                <Typography
                  key={i}
                  sx={{ ...pinkText, marginRight: "24px", fontWeight: "600" }}
                >
                  { `$${(state.price || basePrice.price).toFixed(2)} CAD` }
                </Typography>
              </>
            );
          })}
        </Box>
        <Box sx={{ display: "flex" }}>
          {product.basePrices.map((basePrice, i) => {
            return (
              <>
                <Typography
                  key={i}
                  sx={{ color: "gray", marginRight: "24px", fontSize: "12px" }}
                >
                  {basePrice.description}
                </Typography>
              </>
            );
          })}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "row",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", margin: "24px" }}>
            <Brand setState={setState} state={state}></Brand>
          </Box>
          
          <Box sx={{ display: "flex", flexDirection: "column", margin: "24px" }}>
            <CompanyColors setState={setState} state={state}></CompanyColors>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", margin: "24px" }}>
        <BrandBottom setState={setState} state={state} product={product}></BrandBottom>
      </Box>
      
      <Box sx={{ display: "flex", justifyContent: "center", margin: "24px" }}>
        <Button
          sx={{
            ...theme.secondaryButton,
            width: { xs: "100%", sm: "50%", md: "25%" },
          }}
          onClick={() => addToCart()}
          disabled={loading ? true : false}
        >
          Add To Cart
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "65px",
                color: "#fff",
              }}
            />
          )}
        </Button>
        {success && (
          <Typography className={classes.textStyle} sx={{ color: "green" }}>
            Success! The item is added to the cart
          </Typography>
        )}
      </Box>
    </div>
  );
}
