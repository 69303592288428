/** @format */

import { React, useEffect, useState } from "react";
import { Grid, Typography, Box, Container, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../auth";
import { getAuth } from "firebase/auth";

import DessertCard from "./dessertCard";

const useStyles = makeStyles((theme) => ({
  flexStyle: {
    position: "relative",
    display: "flex !important",
    flexDirection: "column !important",
  },
  gridContainer: {
    gap: "2rem !important",
    margin: "3rem !important",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100% !important",
      gap: "2rem !important",
    },
  },
  gridItem: {
    maxWidth: "30% !important",
    [theme.breakpoints.down("md")]: {
      maxWidth: "80% !important",
    },
  },
  image: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    borderRadius: "10% !important",
    minWidth: "310px",
    minHeight: "310px",
    backgroundColor: "gray !important",
    [theme.breakpoints.down("md")]: {
      width: "4rem !important",
      height: "4rem !important",
      marginTop: "1rem !important",
    },
  },
  glutenFreeText: {
    position: "absolute",
    left: "0px !important",
    top: "15px !important",
    backgroundColor: "#30b05a !important",
    color: "#fff !important",
    padding: "10px !important",
    borderRadius: "35px 0px 35px 0px !important",
    fontWeight: "bold !important",
    [theme.breakpoints.up("sm")]: {
      top: "0px !important",
    },
  },
}));

export default function Products({ category }) {
  const classes = useStyles();

  const [dataIsReady, setDataIsReady] = useState(false);
  const initialDesserts = [];
  const [desserts, setDesserts] = useState(initialDesserts);

  useEffect(() => {
    setDesserts([]);
    setDataIsReady(false);
  }, [category]);

  useEffect(async () => {
    const res = await axios.get("/api/app/data/patisseries", {
      headers: {
        category: category,
      },
    });

    const regularDesserts = res.data.map((dessert) => {
      const images = dessert.images.filter(
        (img) => img.name === "regular-order-img"
      );
      const basePrices = dessert.basePrices.filter(
        (img) => img.name === "regular-order-price"
      );
      return {
        ...dessert,
        image: images.length > 0 ? images[0].value : "/no-image",
        basePrice: basePrices.length > 0 ? basePrices[0].price : 0,
        itemsPerSet: dessert.productConfig.filter(
          (config) => config.name === "regular-order-items-per-set"
        )[0].value,
      };
    });

    setDesserts(regularDesserts);
    setDataIsReady(true);

    return () => {
      setDataIsReady(false);
    };
  }, [category]);

  return (
    <Container
      sx={{
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
      }}
    >
      {dataIsReady ? (
        <Grid container className={classes.gridContainer} spacing={3}>
          {desserts.map((dessert, index) => (
            <Grid item xs={4} key={index} className={classes.gridItem}>
              <Box className={classes.flexStyle}>
                {dessert.isGlutenFree && (
                  <Box className={classes.glutenFreeText}>
                    <Typography
                      sx={{
                        fontSize: "14px !important",
                      }}
                    >
                      Gluten Free
                    </Typography>
                  </Box>
                )}

                <img src={dessert.image} className={classes.image} />
                <DessertCard dessert={dessert} />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box>Loading...</Box>
      )}
    </Container>
  );
}
