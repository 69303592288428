/** @format */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Box,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import firebaseConfig from "../auth";
import cakeIcon from "../assets/images/drawerIcons/cakeIcon.png";
import macaronIcon from "../assets/images/drawerIcons/macaronIcon.png";
import assortmentsIcon from "../assets/images/drawerIcons/assortmentsIcon.png";
import ordersIcon from "../assets/images/drawerIcons/ordersIcon.png";

const useStyles = makeStyles((theme) => ({
  drawerSectionTitle: {
    color: theme.palette.primary.dark,
    ...theme.textStyle,
  },
  drawerProfileName: {
    color: theme.palette.primary.dark,
    fontSize: "2rem !important",
    ...theme.textStyle,
  },
  drawerProfileEmail: {
    fontSize: "0.85rem !important",
    ...theme.textStyle,
  },
  listItemsContainer: {
    height: "100vh",
    boxSizing: "border-box",
  },
}));

export default function CustomDrawer() {
  initializeApp(firebaseConfig());

  const auth = getAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const [dataIsReady, setDataIsReady] = useState(false);
  const [user, setUser] = useState(null);

  const menuIcons = [cakeIcon, cakeIcon, macaronIcon, assortmentsIcon];
  const menuTitles = [
    "Specialty Cakes",
    "Standard Cakes",
    "Cheesecakes",
    // "Macarons",
    // "Desserts",
  ];
  const profileIcons = [ordersIcon];
  const profileTitles = [
    {
      name: "My Orders",
      url: "my-orders",
    },
  ];

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(JSON.parse(localStorage.getItem("user")));
      }

      setDataIsReady(true);
    });
  }, [dataIsReady]);

  if (!dataIsReady) {
    return <Box>Loading...</Box>;
  } else {
    return (
      <List className={classes.listItemsContainer} data-testid="custom-drawer">
        {user && (
          <Box>
            <ListItem sx={{ paddingBottom: 0 }}>
              <Typography className={classes.drawerProfileName}>
                {user.firstName + " " + user.lastName}
              </Typography>
            </ListItem>
            <ListItem sx={{ paddingTop: 0 }}>
              <Typography className={classes.drawerProfileEmail}>
                {user.email}
              </Typography>
            </ListItem>
            <Divider />
          </Box>
        )}

        <ListItem>
          <Typography className={classes.drawerSectionTitle}>Menu</Typography>
        </ListItem>

        {menuTitles.map((text, index) => (
          <ListItem
            button
            key={text}
            onClick={() => {
              if (text === "Specialty Cakes") {
                navigate("/cakes/specialty-cakes");
              } else if (text === "Standard Cakes") {
                navigate("/cakes/standard-cakes");
              } else if (text === "Cheesecakes") {
                navigate("/cakes/cheesecakes");
              }
            }}
          >
            <ListItemIcon>
              <img
                src={menuIcons[index]}
                style={{ width: 25, height: 25 }}
                alt="menu item"
              />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}

        <Divider />

        {user && (
          <Box>
            <ListItem>
              <Typography className={classes.drawerSectionTitle}>
                Profile
              </Typography>
            </ListItem>

            {profileTitles.map((text, index) => (
              <ListItem
                button
                key={text.name}
                onClick={() => navigate("/" + text.url)}
              >
                <ListItemIcon>
                  <img
                    src={profileIcons[index]}
                    style={{ width: 25, height: 25 }}
                    alt="profile item"
                  />
                </ListItemIcon>
                <ListItemText primary={text.name} />
              </ListItem>
            ))}

            <ListItem
              button
              onClick={() => {
                signOut(auth)
                  .then(() => {
                    localStorage.removeItem("user");
                    navigate("/");
                  })
                  .catch((error) => {
                    console.log(error.message);
                  });
              }}
              sx={{ position: "absolute", bottom: 40 }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItem>
          </Box>
        )}
      </List>
    );
  }
}
