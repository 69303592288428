import React from "react";
import { Box, Container, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  resetSubtotal,
} from "../../../redux/reducers/inStoreStandardCake";
import { makeStyles } from "@mui/styles";
import { ArrowBack } from "@mui/icons-material";
import Customer from "./customer";
import Event from "./event";
import Order from "./order";
import AccessoriesReview from "./accessories/index";
import Summary from "./summary";
import Notes from "./notes";
import RefundPolicy from "./refundPolicy";
import Submission from "./submission";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    padding: "0px !important",
  },
  arrowsBox: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white !important",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: "white !important",
    },
    zIndex: 10,
  },
  backButton: {
    "&.MuiButton-root": {
      color: "#f6725c !important",
      fontSize: "18px !important",
    },
  },
}));

export default function Review({ setActiveStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(resetSubtotal());
    setActiveStep(3)
  };

  return (
    <>
      <Container className={classes.container} data-testid="review">
        <Box sx={{ width: "80%", mt: 6, mb: 4 }}>
          <Customer></Customer>
          <Event></Event>
          <Order></Order>
          <AccessoriesReview></AccessoriesReview>
          <Summary></Summary>
          <RefundPolicy></RefundPolicy>
          <Submission></Submission>
        </Box>

        <Notes></Notes>
      </Container>
      <Box className={classes.arrowsBox}>
        <Button className={classes.backButton} onClick={handleBack} data-testid="back-button">
          <ArrowBack sx={{ mr: 1 }} />
          Customer Info
        </Button>
      </Box>
    </>
  );
}
