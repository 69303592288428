import React from "react";
import { Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";

import { theme } from "../../theme";
import IndividualAppreciation from "./components/individualAppreciation";
import TeamAppreciation from "./components/teamAppreciation";

export default function EmployeeAppreciation() {
  const { boxes } = useSelector(({ corporateProducts }) => corporateProducts);

  let individualGiftBoxProduct = boxes.filter(box => box.type==="individual-box")
  individualGiftBoxProduct = individualGiftBoxProduct.length > 0 ? individualGiftBoxProduct[0] : null

  let teamGiftBoxProduct = boxes.filter(box => box.type==="group-box") 
  teamGiftBoxProduct = teamGiftBoxProduct.length > 0 ? teamGiftBoxProduct[0] : null
  
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "54px 0",
          flexDirection: "column",
          alignItems: "center",
        }}
        id="employeeAppreciation"
      >
        <Box sx={{ margin: "12px" }}>
          <Typography sx={{ ...theme.titleText, ...theme.titleText.sectionText }}>
            EMPLOYEE APPRECIATION
          </Typography>
        </Box>
        <Box sx={{ margin: "12px", maxWidth: "627px" }}>
          <Typography
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: "800",
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            TREAT YOUR TEAM FOR A SUCCESSFUL PROJECT
          </Typography>
        </Box>

        {
          individualGiftBoxProduct &&
          <IndividualAppreciation
            product={individualGiftBoxProduct}
            number={"1"}
            sx={{
              margin: "12px",
            }}
          />
        }

        {
          teamGiftBoxProduct &&
          <TeamAppreciation
            product={teamGiftBoxProduct}
            number={"2"}
            sx={{
              margin: "12px",
            }}
          />
        }
      </Box>
    </>
  );
}
