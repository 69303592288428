import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FreeTextInput from "../admin/freeTextInput";

const useStyles = makeStyles(() => ({}));

export default function CustomerInfo() {
  const labels = ["Name", "Email", "Phone Number", "Address"];

  return (
    <Grid container data-testid="customer-info">
      <Grid item xs={12}>
        <Typography variant="h6">CUSTOMER INFORMATION</Typography>
      </Grid>
      {labels.map((label, i) => {
        return (
          <Grid item xs={12} sm={3} key={label}>
            <FreeTextInput label={label} val={label}></FreeTextInput>
          </Grid>
        );
      })}
    </Grid>
  );
}
