import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAccessoryInfo } from "../../../../redux/reducers/inStoreStandardCake";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import CandlesReview from "./candles/candlesReview";
import ToppersReview from "./toppers/toppersReview";
import GreetingCardReview from "./greetingCard/greetingCardReview";
import PipingReview from "./piping/pipingReview";

const useStyles = makeStyles(() => ({
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "grey !important",
    marginBottom: "0.5rem",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
}));

export default function AccessoriesReview() {
  const classes = useStyles();

  const [accessoriesList, setAccessoriesList] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);

  useEffect(() => {
    async function fetchCakes() {
      const res = await axios.get("/api/app/data/accessories");
      setAccessoriesList(res.data.accessories);
      setDataIsReady(true);
    }
    fetchCakes();
  }, [dataIsReady]);

  return (
    <>
      {dataIsReady && (
        <Box data-testid="accessories-review">
          <Typography className={classes.heading}>accessories</Typography>
          <PipingReview
            dbPiping={accessoriesList.find((e) => e.type === "piping")}
          ></PipingReview>
          <CandlesReview
            dbCandles={accessoriesList.find((e) => e.type === "candle")}
          ></CandlesReview>
          <ToppersReview
            dbToppers={accessoriesList.find((e) => e.type === "topper")}
          ></ToppersReview>
          <GreetingCardReview
            dbGreetingCard={accessoriesList.find(
              (e) => e.type === "greetingCard"
            )}
          ></GreetingCardReview>
        </Box>
      )}
    </>
  );
}
