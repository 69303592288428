/** @format */
import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Separator from "../../components/Separator";

const useStyles = makeStyles((theme) => ({
  filterMenu: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "20px !important",
    width: "100% !important",
    height: "fit-content",
    padding: "15px 40px 15px 40px !important",
    margin: "5px !important",
    cursor: "default !important",
  },
  filterMenuSection: {
    display: "flex",
    flexDirection: "column",
  },
  filterMenuHeader: {
    ...theme.textStyle,
    fontSize: "18px !important",
    fontWeight: "700 !important",
    paddingTop: "5px !important",
  },

  checkLabel: {
    ...theme.textStyle,
    fontSize: "12px !important",
    fontWeight: "400 !important",
  },

  checkGroup: {
    padding: "20px 0 0 0 !important",
  },
}));

function FilterMenu({ query, setQuery }) {
  const classes = useStyles();

  const designList = ["classic", "minimalistic", "fun", "exotic"];
  const occasionList = [
    "anniversary",
    "family gathering",
    "graduation",
    "birthday",
    "kids",
    "retirement",
    "baby shower",
  ];
  const decorationList = [
    "flowerful",
    "macarons",
    "sprinkles",
    "fruitful",
    "chocolatey",
  ];

  useEffect(() => {
    const design = Object.assign(
      ...designList.map((key) => ({ [key]: false }))
    );

    const occasion = Object.assign(
      ...occasionList.map((key) => ({ [key]: false }))
    );

    const decoration = Object.assign(
      ...decorationList.map((key) => ({ [key]: false }))
    );

    setQuery({ ...design, ...occasion, ...decoration });

    console.log(query);
  }, []);

  return (
    <Paper className={classes.filterMenu}>
      <Box className={classes.filterMenuSection}>
        <Typography className={classes.filterMenuHeader}>
          Design Style
        </Typography>

        <Separator />

        <FormGroup>
          <Grid container spacing={0} className={classes.checkGroup}>
            {designList.map((design) => {
              return (
                <Grid item xs={12} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkLabel}
                        onChange={(e) =>
                          setQuery({ ...query, [design]: e.target.checked })
                        }
                      />
                    }
                    label={design}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </Box>

      <Box className={classes.filterMenuSection}>
        <Typography className={classes.filterMenuHeader}>Occasion</Typography>
        <Separator />
        <FormGroup>
          <Grid container spacing={0} className={classes.checkGroup}>
            {occasionList.map((occasion) => {
              return (
                <Grid item xs={12} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkLabel}
                        onChange={(e) =>
                          setQuery({ ...query, [occasion]: e.target.checked })
                        }
                      />
                    }
                    label={occasion}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </Box>

      <Box className={classes.filterMenuSection}>
        <Typography className={classes.filterMenuHeader}>Decoration</Typography>
        <Separator />
        <FormGroup>
          <Grid container spacing={0} className={classes.checkGroup}>
            {decorationList.map((decoration) => {
              return (
                <Grid item xs={12} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkLabel}
                        onChange={(e) =>
                          setQuery({ ...query, [decoration]: e.target.checked })
                        }
                      />
                    }
                    label={decoration}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </Box>
    </Paper>
  );
}

export default FilterMenu;
