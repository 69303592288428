import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import axios from "axios";

import {
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Modal,
  Popover,
  ListItemText,
  OutlinedInput,
  ListItem,
  RadioGroup,
  Radio,
  TextareaAutosize,
  CircularProgress,
} from "@mui/material";

import {
  theme,
  stepsInnerCircle,
  pinkText,
  inputColors,
  selectColors,
  selectColorsOutline,
  listDesserts,
} from "../../../theme";
import image from "../../../assets/images/assortments/gift-box.png";
import ContactUsModal from "../modal-contents/contactUsModal";
import { updateCart } from "../../../redux/reducers/cart";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    height: "40%",
    width: "90%",
    padding: 20,
    overflow: "scroll",
  },
}));

export default function TeamAppreciation({ product, number }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const currentUser = JSON.parse(localStorage.getItem("user"));
  let tempEmail = localStorage.getItem("tempEmail");

  const groupBoxDecorationsList = product.customizationOptions.filter(opt => opt.name === "corporate-group-box-desserts");
  const groupBoxDecorations = groupBoxDecorationsList.length > 0 ? groupBoxDecorationsList[0] : null

  const initialSelections = {
    price: "",
    excludedDesserts: "",
    numberOfEmployees: "",
    size: "",
    haveAllergies: false,
    allergyNotes: ""
  };

  const [selections, setSelections] = useState(initialSelections)

  const currentExcludedDessertsArr = selections.excludedDesserts.split(",").filter(n => n!=="")

  const addToCart = async() => {    
    if (!selections.price) {
      return alert("Please select Price");
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);

      let productToAdd = {
        id: Date.now(),
        name: product.name,
        productId: product._id,
        imgPath: product.imgPath,  
        type: product.type,
        quantity: 1,
        price: selections.price,
        customizations: [{
          parentDisplayName: "Allergies?",
          selection: selections.haveAllergies ? "Yes" : "No"
        }]
      };

      if (selections.haveAllergies) {
        productToAdd.customizations.push({
          parentDisplayName: "Allergy Notes",
          selection: selections.allergyNotes
        })
      }

      if (selections.excludedDesserts) {
        productToAdd.customizations.push({
          parentDisplayName: "Exclude",
          selection: selections.excludedDesserts
        })
      }

      

      try {
        const res = await axios.post("/api/cart/addProduct/", {
          userEmail: currentUser ? currentUser.email : tempEmail,
          product: productToAdd,
        });

        if (res.status === 200) {
          handleClose();
          window.setTimeout(() => {
            dispatch(updateCart(res.data));
            setSelections(initialSelections);
            setSuccess(true);
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        console.error(error)
        alert(
          "We could not add the product. Please make sure all required fields are provided."
        );
        setSuccess(false);
        setLoading(false);
      }
    }
    
  }
  
  const handleBasePriceChange = (e) => {

    const targetPrice = parseFloat(e.target.value);

    if (selections.price === targetPrice) {
      setSelections({ ...selections, price: "" })
    } else {
      setSelections({ ...selections, price: targetPrice })
    }
  }

  const handleExcludeDessertsChange = (e) => {
    const newExcludedDessertArr = [...e.target.value]
    setSelections({
      ...selections,
      excludedDesserts: newExcludedDessertArr.join(",")
    })
  };

  const handleHaveAllergyChange = (e) => {
    if (e.target.value === "Yes") {
      setSelections({...selections, haveAllergies: true});
    } else {
      setSelections({...selections, haveAllergies: false});
    }
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopperClose = () => {
    setAnchorEl(null);
  };
  const openPopper = Boolean(anchorEl);
  const id = openPopper ? "simple-popover" : undefined;

  return (
    <>
      <Box sx={{ ...stepsInnerCircle, margin: "12px" }}>
        <Typography
          sx={{ color: theme.palette.secondary.light, fontWeight: "600" }}
        >
          {number}
        </Typography>
      </Box>
      <Typography
        sx={{ fontWeight: "800", margin: "12px", fontSize: "1.5rem" }}
      >
        TEAM APPRECIATION
      </Typography>

      <Box sx={{ margin: "12px", maxWidth: "498px" }}>
        <Typography sx={pinkText}>
          <Box component="span" sx={{ fontWeight: "600" }}>
            Need a treat for the whole team?
          </Box>{" "}
          Choose a budget from below options and we'll put together a box.
        </Typography>
      </Box>

      <Box sx={{ height: "fit-content", width: "100%", margin: "64px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            padding: "24px",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              // backgroundSize should be small
              backgroundSize: "cover",
              borderRadius: "24px",
              backgroundColor: "gray",
              minHeight: "292px",
              width: { xs: "100%", sm: "100%", md: "30%" },
            }}
          ></Box>
          <Box
            sx={{
              textAlign: { xs: "center", sm: "center", md: "start" },
              width: { xs: "100%", sm: "100%", md: "40%" },
              paddingLeft: {
                xs: "0px",
                sm: "0px",
                md: "44px",
              },
            }}
          >

          <Typography
            sx={{
              fontWeight: "600",
              marginLeft: "24px",
              fontSize: "large",
            }}
          >
            Assorted Box
          </Typography>
            
            
            
            <FormGroup
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "row",
                margin: "24px",
                justifyContent: "space-between",
              }}
            >
              <RadioGroup
                aria-labelledby="price-radio-group"
                name="radio-buttons-group"
              >
                <div style={{ display: "flex", alignItems: "stretch"}}>
                  {
                    product.basePrices.map(basePrice => 
                        <div style={{ minWidth: "33%" }}>
                          <FormControlLabel 
                            value={basePrice.price} control={<Radio />} 
                            label={`$${basePrice.price.toFixed(2)} CAD`}
                            className="radioButton"
                            onChange={handleBasePriceChange}
                            checked={selections.price === basePrice.price}
                          />
                        </div>
                    )
                  }
                </div>
              </RadioGroup>
            </FormGroup>



            <Box
                sx={{
                  display: "flex",
                  marginLeft: "24px",
                  marginTop: "44px",
                  marginBottom: "24px",
                  justifyContent: { xs: "center", sm: "center", md: "start" },
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "600", marginRight: "8px" }}>
                  CUSTOM AMOUNT?
                </Typography>
                <Typography
                  sx={{ ...pinkText, cursor: "pointer" }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Contact us
                </Typography>
              </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "center", md: "start" },
                flexDirection: { xs: "column", sm: "column", md: "row" },
                padding: "10px",
                gap: "24px",
                marginLeft: "2%",
              }}
            >
              <Button
                sx={{
                  ...theme.thirdButton,
                  width: { xs: "100%", sm: "100%", md: "40%" },
                  minWidth: { md: "273px" },
                  textTransform: "none",
                }}
                onClick={handleClick}
              >
                List of Desserts
              </Button>
              <Popover
                id={id}
                open={openPopper}
                anchorEl={anchorEl}
                onClose={handlePopperClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >

              <Box sx={listDesserts}>
                {groupBoxDecorations.values.map((decoration, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      primary={decoration.name}
                      sx={{ scrollbarColor: "white" }}
                    />
                  </ListItem>
                ))}
              </Box>
                
              </Popover>
              
              <FormControl sx={{ m: 1, minWidth: 270 }}>
                <InputLabel id="Not include in the box" sx={selectColors}>
                  Not include in the box
                </InputLabel>
                <Select
                  labelId="Not include in the box"
                  id="demo-multiple-checkbox"
                  multiple
                  value={currentExcludedDessertsArr}
                  onChange={handleExcludeDessertsChange}
                  input={<OutlinedInput label="Not include in the box" />}
                  renderValue={(selected) => selected.join(", ")}
                  sx={{
                    ...selectColorsOutline,
                    borderRadius: "24px",
                    backgroundColor: "#F9F9F9",
                  }}
                >

                  {
                    groupBoxDecorations.values.map((decoration, i) => (
                      <MenuItem key={decoration.name} value={decoration.name}>
                        <Checkbox
                          checked={currentExcludedDessertsArr.indexOf(decoration.name) > -1}
                          sx={{ "&.Mui-checked": { color: theme.palette.primary.dark } }}
                        />
                        <ListItemText primary={decoration.name} />
                      </MenuItem>
                    ))
                    
                  }
                </Select>
              </FormControl>
            </Box>
            

            {/* TODO  Record Allergies */}

            <Box
              sx={{
                display: "flex",
                margin: "24px 0 16px 0",
                justifyContent: { xs: "center", sm: "center", md: "start" },
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <Typography sx={{ fontWeight: "600" }}>
                ANY ALLERGIES?
              </Typography>
              <FormGroup
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                <RadioGroup
                  aria-labelledby="allergy-radio-group"
                  name="radio-buttons-group"
                >
                  <div style={{ display: "flex", alignItems: "stretch"}}>
                    <div style={{ minWidth: "50%" }}>
                      <FormControlLabel 
                        value="No" control={<Radio />} 
                        label="No"
                        className="radioButton"
                        onChange={handleHaveAllergyChange}
                        checked={!selections.haveAllergies}
                      />
                    </div>

                    <div style={{ minWidth: "50%" }}>
                      <FormControlLabel 
                        value="Yes" control={<Radio />} 
                        label="Yes"
                        className="radioButton"
                        onChange={handleHaveAllergyChange}
                        checked={selections.haveAllergies}
                      />
                    </div>
                    

                  </div>

                  
                </RadioGroup>

              </FormGroup>
            </Box>


            {
              selections.haveAllergies &&
              <Box style={{ display: "flex", justifyContent: "center", marginTop: 0, paddingTop: 0 }}>
                <TextareaAutosize 
                    minRows={4} 
                    maxRows={4} 
                    className="textarea-allergy-notes"
                    onChange={(e)=> setSelections({...selections, allergyNotes: e.target.value})}
                    value={selections.allergyNotes}
                    placeholder="Enter notes"
                    style={{ width: 200 }}
                  />
                
              </Box>
            }
            
            <Box sx={{ margin: "12px" }}>
              <Button
                sx={{
                  ...theme.secondaryButton,
                  width: { xs: "100%", sm: "100%", md: "50%" },
                }}
                onClick={addToCart}
              >
                Add To Cart
                {
                  loading &&
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "65px",
                      color: "#fff",
                    }}
                  />
                }
              </Button>
              {
                success && 
                <Typography className={classes.textStyle} sx={{ color: "green" }}>
                  Success! The item is added to the cart
                </Typography>
              }
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <ContactUsModal handleClose={handleClose}></ContactUsModal>
        </Box>
      </Modal>
    </>
  );
}
