/** @format */

import { Box } from "@mui/material";

function Separator() {
  return (
    <Box
      style={{
        height: "3px",
        width: "100%",
        background: "#F7F7F7",
      }}
    >
      <Box
        style={{
          height: "100%",
          width: "33%",
          backgroundColor: "#242424",
        }}
      ></Box>
    </Box>
  );
}

export default Separator;
