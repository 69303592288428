/** @format */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { querySet, selectQuery } from "../../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  filter: {
    border: "solid !important",
    borderWidth: "1px !important",
    borderColor: "#828282 !important",
    color: "#828282 !important",
    margin: "0 10px 0 0 !important",
    [theme.breakpoints.up("sm")]: {
        padding: "14px !important"
      },
  },
}));

function QuickSearchButton({label, dateVal}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useSelector(selectQuery);

  return (
    <Button
        className={classes.filter}
        onClick={() =>
        dispatch(
            querySet({
            ...query,
            pickupDate: dateVal,
            })
        )
        }
        data-testid="quick-search-btn"
    >
        {label}
    </Button>
  );
}

export default QuickSearchButton;
