/** @format */

import React, { useEffect, useState } from "react";
import { Container, Box, Grid } from "@mui/material";
import FeaturedProductCard from "./featuredProductCard";
import axios from "axios";

export default function FeaturedProducts() {
  const [featuredProductsList, setFeaturedProductsList] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const { data: featuredProducts } = await axios.get(
          "/api/app/data/featuredProduct/getAllFeaturedProducts"
        );

        let activeFeaturedProducts = featuredProducts.filter(
          (fp) =>
            new Date(fp.startDate).getTime() < Date.now() &&
            new Date(fp.endDate).getTime() > Date.now()
        );

        const productDetailsPromises = activeFeaturedProducts.map(
          async (featuredProduct) => {
            const { productType, product } = featuredProduct;
            const productEndpoint = `/api/app/data/${productType}/featured?productId=${product}`;
            const { data: productDetails } = await axios.get(productEndpoint);
            return { ...featuredProduct, productDetails };
          }
        );

        const productsWithDetails = await Promise.all(productDetailsPromises);
        setFeaturedProductsList(productsWithDetails);
        setDataIsReady(true);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchFeaturedProducts();
  }, [dataIsReady]);

  console.log(featuredProductsList);

  return (
    <>
      <Grid container spacing={2}>
        {!dataIsReady ? (
          <Box sx={{ mb: 3 }}>Loading...</Box>
        ) : featuredProductsList.length === 0 ? (
          <Box>there are no featued products at the moment</Box>
        ) : (
          // <Box>sfsf</Box>
          featuredProductsList.map((fp, index) => {
            console.log(fp);
            return (
              <Grid item xs={6} md={3} key={fp?._id || index}>
                <FeaturedProductCard featuredProduct={fp}></FeaturedProductCard>
              </Grid>
            );
          })
        )}
      </Grid>
    </>
  );
}
