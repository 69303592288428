import {
  Typography,
  Box,
  Button,
} from "@mui/material";

import {
  CheckCircle,
} from '@mui/icons-material';

import { theme } from "../../../../theme";


const Success = ({ modalView, handleClose }) => {

  return (
    <Box sx={{ 
      display: "flex", 
      flexFlow: "column", 
    }}>
      <Box sx={{ display: "flex", justifyContent: "center", margin: "100px 0 24px" }}>
        <Typography style={{ textAlign: "center"}}>
          <CheckCircle style={{ fontSize: 64, color: "green"}}/>
          <h1>THANK YOU!</h1>
          <p>You have successfully subscribed to <strong>{modalView.data.subscription?.product?.name} </strong></p>
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", margin: "24px" }}>
        <Button
          sx={{
            ...theme.secondaryButton,
            width: { xs: "100%", sm: "50%", md: "25%" },
          }}
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>

    </Box>
  );

}

export default Success