/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import mainPic from "../../assets/images/hero_section_family_image.png";
import { theme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // textAlign: "center",
    marginTop: "2rem !important",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      textAlign: "left",
      justifyContent: "space-between",
    },
  },
  headlines: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "2rem !important",
      minWidth: "500px",
    },
  },
  heading1: {
    // ...theme.textStyle,
    color: theme.palette.primary.dark,
  },
  heading2: {
    // ...theme.textStyle,
    marginBottom: "1rem !important",
  },
  subHeading1: {
    // ...theme.textStyle,
    color: theme.palette.secondary.main,
  },
  button: {
    // ...theme.containedButtonDark,
    marginBottom: "3rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "1rem !important",
    },
  },
  imgWrapper: {
    width: "350px",
    height: "300px",
    [theme.breakpoints.up("sm")]: {
      width: "550px",
      height: "400px",
    },
    [theme.breakpoints.up("md")]: {
      width: "850px",
      height: "500px",
    },
  },
  // textStyle: { ...theme.textStyle },
}));

export default function Main() {
  const classes = useStyles();
  let navigate = useNavigate();

  return (
    <Container data-testid="main" fixed maxWidth="xl">
      <Box className={classes.wrapper} style={{ backgroundColor: "#fff" }}>
        <Box className={classes.headlines}>
          <Box className={classes.heading2}>
            <Typography
              className={classes.heading1}
              sx={{
                fontSize: { xs: "3rem", md: "4rem", lg: "4rem", xl: "4rem" },
                fontFamily: "Moul"
              }}>
              Baking Joy
            </Typography>

            <Typography
              className={classes.heading2}
              sx={{
                fontSize: { xs: "3rem", md: "4rem", lg: "4rem", xl: "4rem" },
                fontFamily: "Moul",
                color: "#515151"
              }}>
              Into Every Slice
            </Typography>
          </Box>

          <Box sx={{ maxWidth: "450px", mb: 3 }}>
            <Typography variant="subtitle1" className={classes.subHeading1} sx={{fontFamily: "Inter", color: "#909090"}}>
              Indulge in our high-quality desserts, created to elevate every
              occasion
            </Typography>
          </Box>

          <Button
            size="large"
            variant="contained"
            className={classes.button}
            sx={{ textTransform: "none", fontFamily: "Inter", borderRadius: "8px", backgroundColor: "#fd7762" }}
            onClick={() => navigate("/product-categories")}>
              Shop Now
          </Button>
        </Box>
        <Box className={classes.imgWrapper}>
          <img src={mainPic} width="100%" height="100%" alt="cake" />
        </Box>
      </Box>
    </Container>
  );
}
