import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  RadioGroup,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Modal,
  Popover,
  ListItemText,
  OutlinedInput,
  ListItem,
  CircularProgress,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {
  theme,
  stepsInnerCircle,
  pinkText,
  inputColors,
  selectColors,
  selectColorsOutline,
  listDesserts,
} from "../../../theme";
import image from "../../../assets/images/assortments/gift-box.png";
import ContactUsModal from "../modal-contents/contactUsModal";
import { updateCart } from "../../../redux/reducers/cart";

import "./styles/individualAppreciation.css"

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    height: "40%",
    width: "90%",
    padding: 20,
    overflow: "scroll",
  }
}));

 
export default function IndividualAppreciation({ product, number }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const currentUser = JSON.parse(localStorage.getItem("user"));
  let tempEmail = localStorage.getItem("tempEmail");

  const individualBoxDecorationsList = product.customizationOptions.filter(opt => opt.name === "corporate-individual-box-desserts");
  const individualBoxDecorations = individualBoxDecorationsList.length > 0 ? individualBoxDecorationsList[0] : null

  const initialSelections = {
    price: "",
    excludedDesserts: "",
    numberOfEmployees: "",
    size: ""
  };
  const [selections, setSelections] = useState(initialSelections)

  const currentExcludedDessertsArr = selections.excludedDesserts.split(",").filter(n => n!=="")

  const addToCart = async() => {    
    if (!selections.price) {
      return alert("Please select Price");
    }

    if (!selections.numberOfEmployees || parseInt(selections.numberOfEmployees) < 5) {
      return alert("Please enter the number of Employee. Minimum of 5.");
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);

      let productToAdd = {
        id: Date.now(),
        name: product.name,
        productId: product._id,
        imgPath: product.imgPath,  
        type: product.type,
        quantity: selections.numberOfEmployees,
        price: selections.price * selections.numberOfEmployees,
      };

      if (selections.excludedDesserts) {
        productToAdd.customizations = [{
          parentDisplayName: "Exclude",
          selection: selections.excludedDesserts
        }]
      }

      try {
        const res = await axios.post("/api/cart/addProduct/", {
          userEmail: currentUser ? currentUser.email : tempEmail,
          product: productToAdd,
        });

        if (res.status === 200) {
          handleClose();
          window.setTimeout(() => {
            dispatch(updateCart(res.data));
            setSelections(initialSelections);
            setSuccess(true);
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        console.error(error)
        alert(
          "We could not add the product. Please make sure all required fields are provided."
        );
        setSuccess(false);
        setLoading(false);
      }
    }
    
  }

  const handleBasePriceChange = (e) => {

    const targetPrice = parseFloat(e.target.value);

    if (selections.price === targetPrice) {
      setSelections({ ...selections, price: "" })
    } else {
      setSelections({ ...selections, price: targetPrice })
    }
  }


  const handleNumberEmployeeChange = (e)=>{
    setSelections({ ...selections, numberOfEmployees: e.target.value })
  }

  const handleExcludeDessertsChange = (e) => {
    const newExcludedDessertArr = [...e.target.value]
    setSelections({
      ...selections,
      excludedDesserts: newExcludedDessertArr.join(",")
    })
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handlePopperClose = () => {
    setAnchorEl(null);
  };
  const openPopper = Boolean(anchorEl);
  const id = openPopper ? "simple-popover" : undefined;

  return (
    <>
      <Box sx={{ ...stepsInnerCircle, margin: "12px" }}>
        <Typography sx={{ color: theme.palette.secondary.light, fontWeight: "600" }}>
          {number}
        </Typography>
      </Box>
      
      <Typography sx={{ fontWeight: "800", margin: "12px", fontSize: "1.5rem" }}>
        INDIVIDUAL EMPLOYEE APPRECIATION
      </Typography>

      <Box sx={{ margin: "12px", maxWidth: "578px" }}>
        <Typography sx={pinkText}>
          Send out an{" "}
          <Box component="span" sx={{ fontWeight: "600" }}>
            individual assorted box
          </Box>{" "}
          of desserts to each of your team members
        </Typography>
      </Box>

      <Box sx={{ height: "fit-content", width: "100%", margin: "64px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            padding: "24px",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              // backgroundSize should be small
              backgroundSize: "cover",
              borderRadius: "24px",
              backgroundColor: "gray",
              minHeight: "292px",
              width: { xs: "100%", sm: "100%", md: "30%" },
            }}
          ></Box>
          
          <Box
            sx={{
              textAlign: { xs: "center", sm: "center", md: "start" },
              width: { xs: "100%", sm: "100%", md: "40%" },
              paddingLeft: {
                xs: "0px",
                sm: "0px",
                md: "44px",
              },
            }}
          >

            <Typography sx={{ fontWeight: "600", marginLeft: "24px", fontSize: "large" }}>
              Gift Box
            </Typography>
          
            <FormGroup
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "row",
                margin: "24px",
                justifyContent: "space-between",
              }}
            >

              <RadioGroup
                aria-labelledby="price-radio-group"
                name="price-radio-group"
              >
                <div style={{ display: "flex", alignItems: "stretch"}}>
                  {
                    product.basePrices.map(basePrice => 
                        <div style={{ minWidth: "33%" }}>
                          <FormControlLabel 
                            value={basePrice.price} control={<Radio />} 
                            label={`$${basePrice.price.toFixed(2)} CAD`}
                            className="radioButton"
                            onChange={handleBasePriceChange}
                            checked={selections.price === basePrice.price}
                          />
                        </div>
                    )
                  }
                </div>
              </RadioGroup>

            </FormGroup>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "center", md: "start" },
                flexDirection: { xs: "column", sm: "column", md: "row" },
                padding: "10px",
                gap: "24px",
                marginLeft: "2%",
              }}
            >
              <Button
                sx={{
                  ...theme.thirdButton,
                  width: { xs: "100%", sm: "100%", md: "40%" },
                  minWidth: { md: "273px" },
                  textTransform: "none",
                }}
                onClick={handleClick}
              >
                List of Desserts
              </Button>
              
              <Popover
                id={id}
                open={openPopper}
                anchorEl={anchorEl}
                onClose={handlePopperClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box sx={listDesserts}>
                  {individualBoxDecorations.values.map((decoration, i) => (
                    <ListItem key={i}>
                      <ListItemText
                        primary={decoration.name}
                        sx={{ scrollbarColor: "white" }}
                      />
                    </ListItem>
                  ))}
                </Box>
              </Popover>

              <FormControl sx={{ m: 1, minWidth: 270 }}>
                <InputLabel id="Not include in the box" sx={selectColors}>
                  Not include in the box
                </InputLabel>
                <Select
                  labelId="Not include in the box"
                  id="demo-multiple-checkbox"
                  multiple
                  value={currentExcludedDessertsArr}
                  onChange={handleExcludeDessertsChange}
                  input={<OutlinedInput label="Not include in the box" />}
                  renderValue={(selected) => selected.join(", ")}
                  sx={{
                    ...selectColorsOutline,
                    borderRadius: "24px",
                    backgroundColor: "#F9F9F9",
                  }}
                >

                  {
                    individualBoxDecorations.values.map((decoration, i) => (
                      <MenuItem key={decoration.name} value={decoration.name}>
                        <Checkbox
                          checked={currentExcludedDessertsArr.indexOf(decoration.name) > -1}
                          sx={{ "&.Mui-checked": { color: theme.palette.primary.dark } }}
                        />
                        <ListItemText primary={decoration.name} />
                      </MenuItem>
                    ))
                    
                  }
                </Select>
              </FormControl>
              
            </Box>
            
            <Box sx={{ padding: "24px" }}>
              <TextField
                id="number"
                label="Number of Employees"
                helperText={
                  <Typography
                    sx={{
                      color: "gray",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      width: "240px",
                    }}
                  >
                    <InfoIcon fontSize="small" /> Minimum 5 employees
                  </Typography>
                }
                InputProps={{
                  style: {
                    borderRadius: "24px",
                    fontSize: "14px",
                    decoration: "none"
                  }
                }}
                sx={inputColors}
                value={selections.numberOfEmployees}
                onChange={handleNumberEmployeeChange}
                type="number" 
                
              />
            </Box>
              
            <Box sx={{ margin: "12px" }}>
              <Button
                sx={{
                  ...theme.secondaryButton,
                  width: { xs: "100%", sm: "100%", md: "50%" },
                }}
                onClick={addToCart}
              >
                Add To Cart
                {
                  loading &&
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "65px",
                      color: "#fff",
                    }}
                  />
                }
              </Button>
              {
                success && 
                <Typography className={classes.textStyle} sx={{ color: "green" }}>
                  Success! The item is added to the cart
                </Typography>
              }

            </Box>
          
          </Box>


        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <ContactUsModal handleClose={handleClose}></ContactUsModal>
        </Box>
      </Modal>

    </>
  );
}
