/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#fd776280",
    position: "relative",
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "2rem 1rem",
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "30%",
    },
  },
  signinBtn: {
    ...theme.outlinedButton,
    marginRight: "10px",
    marginTop: "40px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.light,
      borderColor: theme.palette.primary.dark,
    },
    textStyle: { ...theme.textStyle },
  },
}));

export default function SignupConfirmation() {
  const classes = useStyles();
  let navigate = useNavigate();

  return (
    <Box className={classes.wrapper}>
      <Paper className={classes.container} elevation="10">
        <Box sx={{ textAlign: "center" }}>
          <Typography
            className={classes.textStyle}
            variant="h4"
            sx={{ color: "green", mb: 3 }}
          >
            You're almost there!
          </Typography>
          <Typography className={classes.textStyle} variant="subtitle1">
            We've sent a link to your email. Please click on it to verify your
            email before you can log in. Make sure to also check your spam/junk
            folders
          </Typography>
          <Button
            variant="outlined"
            className={classes.signinBtn}
            onClick={() => navigate("/login")}
          >
            <Typography className={classes.textStyle}>Log In</Typography>
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
