/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cake: {
    name: null,
    flavorSection: {
      flavor: null,
      required: true,
    },
    colorSection: {
      color: null,
      required: true,
    },
    imgPath: null,
    size: null,
    price: null,
    flavors: [],
    colors: [],
  },
  desserts: {
    tiramisu: {
      optionIsSelected: false,
      flavorSelection: [],
      quantity: 0,
      price: 0,
    },
    macaron: {
      optionIsSelected: false,
      flavorSelection: [],
      colorSelection: [],
      quantity: 0,
      price: 0,
    },
    honeyCake: {
      optionIsSelected: false,
      flavorSelection: [],
      quantity: 0,
      price: 0,
    },
  },
  selectedDesserts: [],
  activeStep: 0,
  availableSizes:[],
  packageType: null,
  setup: {
    name: "SETUP",
    price: 0,
  },
};

export const weddingSlice = createSlice({
  name: "wedding",
  initialState,
  reducers: {
    cakeSelected: (state, action) => {
      if(action.payload.flavors.length < 1){
        state.cake.flavorSection.flavor = null;
        state.cake.flavorSection.required = false;
        state.cake.colorSection.color = null;
        state.cake.colorSection.required = false;
      }
      else{
        if(state.cake.name !== action.payload.name){
          state.cake.flavorSection.flavor = null;
          state.cake.colorSection.color = null;
        }
        state.cake.flavorSection.required = true;
        if(action.payload.colors.length > 1){
          state.cake.colorSection.required = true;
        }else{
          state.cake.colorSection.required = false;
        }
      }
      state.cake.name = action.payload.name;
      state.cake.imgPath = action.payload.images.primary;
      state.availableSizes = action.payload.sizes;
      state.cake.flavors = action.payload.flavors;
      state.cake.colors = action.payload.colors;
    },
    sizeSelected: (state, action) => {
      state.cake.size = action.payload;
    },
    priceSelected: (state, action) => {
      state.cake.price = action.payload;
      state.cakeSubtotal = action.payload;
    },
    flavorSelected: (state, action) => {
      state.cake.name = action.payload.cakeName;
      state.cake.flavorSection.flavor = action.payload.newFlavor;
    },
    colorSelected: (state, action) => {
      state.cake.name = action.payload.cakeName;
      state.cake.colorSection.color = action.payload.newColor;
    },
    dessertCheckedChange: (state, action) => {
      const {isChecked, dessertType, flavors, colors, quantity, price} = action.payload;

      for(const key in state.desserts){
        if(key === dessertType){
          state.desserts[key].optionIsSelected = isChecked;
          state.desserts[key].flavorSelection = flavors;
          state.desserts[key].colorSelection = colors;
          state.desserts[key].quantity = quantity;
          state.desserts[key].price = price;
        }
      }
    },
    activeStepUpdated: (state, action) => {
      state.activeStep = action.payload;
    },
    packageTypeUpdated: (state, action) => {
      state.packageType = action.payload;
    },
    incrementDessertQuantity: (state, action) => {
      const dessertType = action.payload;
      state.desserts[dessertType].quantity += 1;
      state.desserts[dessertType].price += 50;
    },
    decrementDessertQuantity: (state, action) => {
      const dessertType = action.payload;
      if (state.desserts[dessertType].quantity > 0) {
        state.desserts[dessertType].quantity -= 1;
        state.desserts[dessertType].price -= 50;
      }
    },
    setSetupService: (state, action) =>{
      state.setup = action.payload;
    },
    removeSetupService: (state, action) =>{
      state.setup = {
        name: "SETUP",
        price: 0,
      };
    },
    resetSetupService: (state) =>{
      state.setup = initialState.setup
    },
    addToSelectedDesserts: (state, action) => {
      state.selectedDesserts = [...state.selectedDesserts, action.payload]
    },
    removeDessert: (state, action) => {
      state.selectedDesserts = [...state.selectedDesserts].filter(dessert => dessert._id !== action.payload._id)
    },
    resetToInitial: () => initialState
  },
});

// cake selectors
export const selectCakeChosen = (state) => state.wedding.cake;
export const selectSizeChosen = (state) => state.wedding.cake.size;
export const selectFlavorChosen = (state) => state.wedding.cake.flavorSection;
export const selectColorChosen = (state) => state.wedding.cake.colorSection;
export const selectPrice = (state) => state.wedding.currentPrice;
export const selectActiveStep = (state) => state.wedding.activeStep;
export const selectAvailableSizes = (state) => state.wedding.availableSizes;
export const selectPackageType = (state) => state.wedding.packageType;
export const selectSetup = (state) => state.wedding.setup;
export const selectDesserts = (state) => state.wedding.selectedDesserts;

// generic dessert selectors
export const selectDessertOptionIsSelected = (dessertType) => (state) => state.wedding.desserts[dessertType].optionIsSelected;
export const selectDessertFlavorSelection = (dessertType) => (state) => state.wedding.desserts[dessertType].flavorSelection;
export const selectDessertColorSelection = (dessertType) => (state) => state.wedding.desserts[dessertType].colorSelection;
export const selectDessertQuantitySelection = (dessertType) => (state) => state.wedding.desserts[dessertType].quantity;
export const selectDessertPriceSelection = (dessertType) => (state) => state.wedding.desserts[dessertType].price;

export const {
  cakeSelected,
  sizeSelected,
  priceSelected,
  flavorSelected,
  colorSelected,
  dessertCheckedChange,
  activeStepUpdated,
  packageTypeUpdated,
  incrementDessertQuantity,
  decrementDessertQuantity,
  resetToInitial,
  setSetupService,
  removeSetupService,
  resetSetupService,
  addToSelectedDesserts,
  removeDessert,
} = weddingSlice.actions;

export default weddingSlice.reducer;
