/** @format */

import React from "react";
import { Typography, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pastDay: {
    textAlign: "center",
    backgroundColor: "#fd776280 !important",
    color: "#fff !important",
    paddingTop: "15px !important",
    minHeight: "91.5px !important",
    marginRight: "10px !important",
    marginBottom: "10px !important",
  },
  textStyle: { ...theme.textStyle },
}));

export default function Past(props) {
  const classes = useStyles();
  const { month, day } = props;

  return (
    <Grid item xs={12} sm={2}>
      <Paper className={classes.pastDay}>
        <Typography
          className={classes.textStyle}
          sx={{ fontWeight: "bold !important" }}
        >
          {month.toUpperCase() + " " + day}
        </Typography>
      </Paper>
    </Grid>
  );
}
