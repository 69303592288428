import React from "react";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CandleAccessory from "./candleAccessory";

const useStyles = makeStyles(() => ({
  paper: {
    margin: "0 1rem 0 1rem",
    padding: "16px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
  },
}));

export default function Candle({ accessory }) {
  const classes = useStyles();

  return (
    <>
      {accessory.content.map((accessoryItem) => {
        return (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              key={accessory.subtype}
              sx={{ mt: 8 }}
              data-testid="candle"
            >
              <Paper elevation={10} className={classes.paper}>
                <CandleAccessory
                  accessoryItem={accessoryItem}
                ></CandleAccessory>
              </Paper>
            </Grid>
          </>
        );
      })}
    </>
  );
}
