import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" gutterBottom>
          1. Introduction
        </Typography>
        <Typography paragraph>
          At CAKEYHAND, we take your privacy seriously. This privacy policy outlines the types of personal information we collect, how we use it, and the steps we take to safeguard it.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography paragraph>
          We may collect personal information when you place an order, subscribe to our newsletter, or interact with our website. This may include your name, email address, phone number, billing and shipping address, payment information, and any other information you provide voluntarily.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography paragraph>
          We use the information we collect to:
          <ul>
            <li>Process your orders and payments</li>
            <li>Communicate with you about your orders or inquiries</li>
            <li>Send you promotional emails or updates (with your consent)</li>
            <li>Improve our products, services, and website</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Data Security
        </Typography>
        <Typography paragraph>
          We implement security measures to protect your personal information from unauthorized access, misuse, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Sharing Your Information
        </Typography>
        <Typography paragraph>
          We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted service providers who assist us in operating our business and providing services to you, but only to the extent necessary for those purposes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Cookies and Tracking Technologies
        </Typography>
        <Typography paragraph>
          Our website may use cookies to enhance your browsing experience and collect data on site traffic. You can choose to disable cookies in your browser settings, but this may affect the functionality of our website.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Your Rights
        </Typography>
        <Typography paragraph>
          You have the right to access, update, or request the deletion of your personal information at any time. If you wish to exercise these rights, please contact us at [Your Company Email].
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Changes to This Policy
        </Typography>
        <Typography paragraph>
          We may update this privacy policy from time to time. Any changes will be posted on this page, and we encourage you to review it periodically to stay informed.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions or concerns about this privacy policy, please contact us at contact@cakeyhand.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
