import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({}));

export default function Notes() {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ backgroundColor: "#f2f2f2", width: "100%" }} data-testid="notes-review">
        <Typography
          sx={{
            color: "#676767",
            padding: "3rem 0 0 7rem",
            fontWeight: "bold",
          }}
        >
          IMPORTANT: a few things to be aware of
        </Typography>
        <Typography
          sx={{
            color: "#fd7762",
            padding: "1rem 5rem 0 7rem",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          1. while we try to make the cake as close to the picture as possible
          we do not guarantee 100% identical outcome
        </Typography>
        <Typography sx={{ color: "#676767", padding: "1rem 0 0 7rem" }}>
          2. Our kitchen in general is NOT gluten or nut free. Cross
          contamination is always possible.
        </Typography>
        <Typography sx={{ color: "#676767", padding: "1rem 0 2rem 7rem" }}>
          3. Our refund and cancellation policy will strictly be applied
        </Typography>
      </Box>
    </>
  );
}
