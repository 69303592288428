/** @format */

import React, { useState } from "react";
import {
  Box,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import EditCakeOrder from "./EditCakeOrder";
import EditMacaronOrder from "./EditMacaronOrder";
import EditAssortments from "./EditAssortments";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectOrderInfo } from "../../../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  itemGridContainer: {
    margin: "0 0 0 1rem !important",
  },
  cell: { margin: "0 0 1rem 0 !important" },

  fieldHead: {
    fontWeight: "bold !important",
  },
}));

function ProductSelect(props) {
  const classes = useStyles();
  const orderInfo = useSelector(selectOrderInfo);

  const index = props.productIndex;

  const [productType, setProductType] = useState(
    orderInfo.products[index].type
  );

  return (
    <>
      <Grid container className={classes.itemGridContainer} data-testid="product-select">
        <Grid item className={classes.cell} xs={3}>
          <Box className={classes.cell} style={{ paddingTop: "16px" }}>
            <FormControl style={{ width: "100%" }} variant="outlined">
              <InputLabel shrink={true} id="type">
                Type
              </InputLabel>
              <Select
                label="Type"
                labelId="type"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              >
                <MenuItem value="standardCake">Standard Cake</MenuItem>
                <MenuItem value="specialtyCake">Specialty Cake</MenuItem>
                <MenuItem value="custom-cake">Custom Cake</MenuItem>
                <MenuItem value="cake">Cake</MenuItem>
                <MenuItem value="macaron">Macaron Box</MenuItem>
                <MenuItem value="assortment">Assortment</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      {productType === "custom-cake" ||
      productType === "specialtyCake" ||
      productType === "standardCake" ||
      productType === "cake" ? (
        <EditCakeOrder index={index} />
      ) : null}
      {productType === "macaron" ? <EditMacaronOrder index={index} /> : null}
      {productType === "assortment" ? <EditAssortments index={index} /> : null}
    </>
  );
}

export default ProductSelect;
