/** @format */

import { Grid, Typography, Box, Container, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import QuantitySelector from "../../../components/quantitySelector";
import MainActionButton from "../../../components/buttons/mainActionButton";

import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import CircularProgress from "@mui/material/CircularProgress";

import { updateCart } from "../../../redux/reducers/cart.js";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
  addToCartButton: {
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export default function FeaturedProductCard({ featuredProduct }) {
  const classes = useStyles();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const tempEmail = localStorage.getItem("tempEmail");
  const quantitySelectorStyle = {
    icon: {
      fontSize: 15,
      color: "#fd7762",
    },
    quantityText: {
      width: "20px",
      height: "20px",
      fontSize: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "0 !important",
      marginRight: "0 !important",
      backgroundColor: "white",
      color: "#fd7762",
    },
  };

  const dispatch = useDispatch();

  const minimumQuantity =
    featuredProduct.productType === "patisseries"
      ? featuredProduct.productDetails.productConfig[1].value
      : 1;
  const [quantity, setQuantity] = useState(minimumQuantity);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = useRef();

  useEffect(() => {
    setTimeout(function () {
      setSuccess(false);
    }, 5000);
  }, [success]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  let imgPath = null;
  let price = null;
  let discountedAmount = 0;

  // some patisserie products have flavors and some do not
  // we need to check and show the flavor where it's available
  let flavor = null;

  switch (featuredProduct.productType) {
    case "patisseries":
      imgPath = featuredProduct.productDetails.images[0].value;
      price = featuredProduct.productDetails.basePrices[0].price;
      if (featuredProduct.discount.type === "percentage") {
        discountedAmount =
          price - featuredProduct.discount.amount * price * 0.01;
      } else {
        discountedAmount = price - featuredProduct.discount.amount;
      }
      flavor = featuredProduct.productDetails?.flavor;
      break;
    case "standardCakes":
      imgPath = featuredProduct.productDetails.images.primary;
      price = featuredProduct.productDetails.prices[0];
      break;
  }

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity);
  };

  const addToCart = async () => {
    let productToAdd = null;

    switch (featuredProduct.productType) {
      case "patisseries":
        productToAdd = {
          id: Date.now(),
          featuredProductId: featuredProduct._id,
          dessertId: featuredProduct.productDetails._id,
          quantity,
          minQTY: minimumQuantity,
          type: "regular-order-dessert",
          name: featuredProduct.productDetails.name,
          flavor: featuredProduct.productDetails.flavor
            ? featuredProduct.productDetails.flavor
            : "",
          imgPath: featuredProduct.productDetails.images.find(
            (img) => img.name === "regular-order-img"
          ).value,
          price: featuredProduct.productDetails.basePrices[0].price,
          itemsPerSet: minimumQuantity,
        };
        break;
    }

    try {
      setLoading(true);
      setSuccess(false);
      const res = await axios.post("/api/cart/addProduct/", {
        userEmail: currentUser ? currentUser.email : tempEmail,
        product: productToAdd,
      });

      if (res.status === 200) {
        window.setTimeout(() => {
          dispatch(updateCart(res.data));
          setLoading(false);
          setSuccess(true);
        }, 1000);
      }
    } catch (error) {
      alert(
        "We could not add the product. Please make sure all required fields are provided."
      );
    }
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="240"
        image={imgPath}
        alt={featuredProduct.productDetails.name}
      />
      <CardContent>
        <Typography
          component="div"
          sx={{ fontWeight: "bold", mb: 2, color: "#515151", fontFamily: "Inter", fontSize: "24px" }}>
          {featuredProduct.productDetails.name}
        </Typography>
        <Typography variant="h6" component="div">
          {flavor}
        </Typography>
        <Box
          sx={{
            display: { xs: "block", lg: "flex" },
            alignItems: "center",
            mb: 5,
          }}>
          <Box sx={{ display: "flex", mb: { xs: 1, md: 0 } }}>
            <Typography
              sx={{
                textDecoration: "line-through",
                marginRight: 1,
                color: "#c2c2c2",
                fontSize: { xs: "16px", md: "18px", lg: "20px" },
                fontFamily: "Inter",
              }}>
              ${price.toFixed(2)}
            </Typography>
            <Typography
              sx={{
                color: "#04CA18",
                marginRight: 1,
                fontSize: { xs: "16px", md: "18px", lg: "20px" },
                fontFamily: "Inter",
              }}>
              ${discountedAmount.toFixed(2)}/each
            </Typography>
          </Box>

          <Typography
            sx={{
              display: "inline-block",
              backgroundColor: "#D9FFDD",
              padding: "2px 6px",
              borderRadius: "4px",
              fontSize: "14px",
              fontFamily: "Inter",
            }}>
            {featuredProduct.discount.amount}% Off
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: "block", lg: "flex" },
            alignItems: "center",
            justifyContent: { lg: "space-between !important" },
          }}>
          <Box sx={{ mb: { xs: 2, lg: 0 } }}>
            <QuantitySelector
              itemsPerSet={minimumQuantity}
              handleQuantityChange={handleQuantityChange}
              setMinimumOnly={true}
              initialQuantity={minimumQuantity}
              minQTY={minimumQuantity}
              styleObj={quantitySelectorStyle}
            />
          </Box>

          <Box
            sx={{
              position: "relative",
            }}>
            {
              <Button
                className={classes.addToCartButton}
                variant="contained"
                size={"small"}
                onClick={addToCart}
                sx={{ backgroundColor: "#fd7762", padding: "6px 12px" }}>
                <Typography
                  // className={classes.textStyle}
                  sx={{
                    textTransform: "none",
                    fontFamily: "Inter",
                  }}>
                  Add to cart
                </Typography>
              </Button>
            }

            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "4px !important",
                  marginLeft: "65px !important",
                  color: "#fff",
                }}
              />
            )}

            {success && (
              <Typography
                className={classes.textStyle}
                sx={{ color: "green", textAlign: "center", marginTop: "16px" }}>
                Added!
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
      {/* <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} color="error">
        <FavoriteBorder />
    </IconButton> */}
    </Card>
  );
}
