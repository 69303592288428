import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  flavorSelected,
  selectFlavorChosen,
  selectCakeChosen,
} from "../../redux/reducers/inStoreStandardCake";

const useStyles = makeStyles(() => ({
  inputLabel: {
    color: "grey !important",
    fontSize: "14px !important",
    "&.MuiInputLabel-shrink": {
      color: "grey !important",
    },
  },
  options: {
    backgroundColor: "white",
    width: "100%",
    borderRadius: "10px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f6725c",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    fontSize: "0.8rem !important",
    height: "3rem",
  },
}));

export default function EnhancedFormControlFlavor({ cake }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedFlavor = useSelector(selectFlavorChosen);
  const selectedCakeName = useSelector(selectCakeChosen).name;

  const handleFlavorChange = (event) => {
    if (cake.flavors.length > 1) {
      dispatch(
        flavorSelected({
          newFlavor: event.target.value,
          cakeName: cake.name,
        })
      );
    }
  };

  return (
    <FormControl sx={{ width: "95% !important", ml: 1, mr: 1, mt: 1 }} data-testid="enhanced-formcontrol-flavor">
      <InputLabel
        id="demo-simple-select-autowidth-label"
        classes={{ root: classes.inputLabel }}
        sx={{ fontSize: "0.8rem !important" }}
      >
        Choose Flavor
      </InputLabel>
      <Select
        className={classes.options}
        value={cake.name === selectedCakeName ? selectedFlavor.flavor : null}
        onChange={handleFlavorChange}
        label="Choose Option"
      >
        {cake.flavors.map((option, idx) => (
          <MenuItem key={idx} value={option.name}>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
