import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box } from "@mui/material";

export default function AdminHomePage() {
  let navigate = useNavigate();

  return (
    <>
      <Box>Admin Home Page</Box>
      <Button onClick={() => navigate("/admin/new-order")}>Create Order</Button>
      <Button onClick={() => navigate("/admin/booking")}>Booking</Button>
    </>
  );
}
