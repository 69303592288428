/** @format */

import { React } from "react";
import { Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
    textAlign: "center",
    marginTop: "4rem !important",
  },
  heading: {
    color: theme.palette.primary.dark,
    ...theme.textStyle,
  },
  subHeading: {
    color: theme.palette.secondary.main.main,
    ...theme.textStyle,
    margin: "20px 0 60px 0 !important",
  },
}));

export default function Main() {
  const classes = useStyles();

  return (
    <Container>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          <Typography variant={"h2"} className={classes.heading}>
            Order History
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"} className={classes.subHeading}>
            See your current and previous orders here
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
