/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cake: {
    name: null,
    flavorSection: {
      flavor: null,
      required: true,
    },
    colorSection: {
      color: null,
      required: true,
    },
    imgPath: null,
    size: null,
    price: null,
  },
  accessories: {
    candles: {
      standardCandle: {
        optionIsSelected: false,
        userSelection: [],
      },
      numberCandle: {
        optionIsSelected: false,
        userSelection: {
          color: null,
          numbers: [],
        },
      },
      spiralCandle: {
        optionIsSelected: false,
        userSelection: [],
      },
      shapesCandle: {
        optionIsSelected: false,
        userSelection: [],
      },
      happyBirthdayCandle: {
        optionIsSelected: false,
        userSelection: [],
      },
    },
    toppers: {
      rings: {
        optionIsSelected: false,
        userSelection: [],
      },
      proposal: {
        optionIsSelected: false,
        userSelection: [],
      },
      mrms: {
        optionIsSelected: false,
        userSelection: [],
      },
      kiss: {
        optionIsSelected: false,
        userSelection: [],
      },
      happyBirthday: {
        optionIsSelected: false,
        userSelection: [],
      },
    },
    piping: {
      optionIsSelected: false,
      text: null,
    },
    greetingCard: {
      optionIsSelected: false,
    },
  },
  customer: {
    name: null,
    phone: null,
    address: null,
    email: null,
  },
  collectionInfo: {
    pickUpDate: null,
    pickUpTime: null,
    pickUpLocation: null,
  },
  activeStep: 0,
  cakeSubtotal: 0,
  accessoriesSubtotal: 0,
  subtotal: 0,
  tax: 0,
  refundPolicyAgrred: false,
  availableSizes: [],
  orderId: null,
};

export const inStoreStandardCakeSlice = createSlice({
  name: "inStoreStandardCake",
  initialState,
  reducers: {
    cakeSelected: (state, action) => {
      if (action.payload.flavors.length < 1) {
        state.cake.flavorSection.flavor = null;
        state.cake.flavorSection.required = false;
        state.cake.colorSection.color = null;
        state.cake.colorSection.required = false;
      } else {
        if (state.cake.name !== action.payload.name) {
          state.cake.flavorSection.flavor = null;
          state.cake.colorSection.color = null;
        }
        state.cake.flavorSection.required = true;
        if (action.payload.colors.length > 1) {
          state.cake.colorSection.required = true;
        } else {
          state.cake.colorSection.required = false;
        }
      }
      state.cake.name = action.payload.name;
      state.cake.imgPath = action.payload.images.primary;
      state.availableSizes = action.payload.sizes;
    },
    cheesecakeSelected: (state, action) => {
      state.cake.flavorSection.flavor = action.payload.flavor;
      state.cake.flavorSection.required = true;
      state.cake.colorSection.color = null;
      state.cake.colorSection.required = false;

      state.cake.name = action.payload.name;
      state.cake.imgPath = action.payload.images.primary;
      state.cake.size = action.payload.size;
      state.cake.price = action.payload.price;
      state.availableSizes = [action.payload.size];
    },
    sizeSelected: (state, action) => {
      state.cake.size = action.payload.size;
    },
    priceSelected: (state, action) => {
      state.cake.price = action.payload.price;
      state.cakeSubtotal = action.payload.price;
    },
    flavorSelected: (state, action) => {
      state.cake.name = action.payload.cakeName;
      state.cake.flavorSection.flavor = action.payload.newFlavor;
    },
    colorSelected: (state, action) => {
      state.cake.name = action.payload.cakeName;
      state.cake.colorSection.color = action.payload.newColor;
    },
    activeStepUpdated: (state, action) => {
      state.activeStep = action.payload;
    },

    // actions for accessories
    accessoryCateogryCheckedChange: (state, action) => {
      const { isChecked, accessoryType, unitPrice } = action.payload;

      // text piping
      if (accessoryType === "piping") {
        if (isChecked) {
          state.accessoriesSubtotal += unitPrice;
        } else {
          state.accessoriesSubtotal -= unitPrice;
        }
        state.accessories.piping.optionIsSelected = isChecked;
      }

      // greeting card
      if (accessoryType === "greetingCard") {
        if (isChecked) {
          state.accessoriesSubtotal += unitPrice;
        } else {
          state.accessoriesSubtotal -= unitPrice;
        }
        state.accessories.greetingCard.optionIsSelected = isChecked;
      }

      for (const key in state.accessories.candles) {
        if (key === accessoryType) {
          state.accessories.candles[key].optionIsSelected = isChecked;
        }
      }

      for (const key in state.accessories.toppers) {
        if (key === accessoryType) {
          state.accessories.toppers[key].optionIsSelected = isChecked;
        }
      }
    },
    pipingTextChange: (state, action) => {
      state.accessories.piping.text = action.payload;
    },
    numberSelectionChange: (state, action) => {
      const { number, isChecked, unitPrice } = action.payload;

      if (isChecked) {
        const selectedColor =
          state.accessories.candles.numberCandle.userSelection.numbers.find(
            (e) => e.number === number
          );
        if (!selectedColor) {
          const newNumberChosen = {
            number: number,
            qty: 1,
          };
          state.accessories.candles.numberCandle.userSelection.numbers.push(
            newNumberChosen
          );
          state.accessoriesSubtotal += unitPrice * newNumberChosen.qty;
        }
      } else {
        const selectedColor =
          state.accessories.candles.numberCandle.userSelection.numbers.find(
            (e) => e.number === number
          );
        state.accessoriesSubtotal -= unitPrice * selectedColor.qty;
        state.accessories.candles.numberCandle.userSelection.numbers =
          state.accessories.candles.numberCandle.userSelection.numbers.filter(
            (e) => e.number != number
          );
      }
    },
    colorSelectionChange: (state, action) => {
      const { color, accessoryType, isChecked, unitPrice } = action.payload;

      // candles
      for (const key in state.accessories.candles) {
        if (key === accessoryType && key !== "numberCandle") {
          if (isChecked) {
            const selectedColor = state.accessories.candles[
              key
            ].userSelection.find((e) => e.color === color);
            if (!selectedColor) {
              const newColorChosen = {
                color: color,
                qty: 1,
              };
              state.accessories.candles[key].userSelection.push(newColorChosen);
              // update subtotal here (add): subtotal += price (coming as a parameter) * newColorChosen.qty
              state.accessoriesSubtotal += unitPrice * newColorChosen.qty;
            }
          } else {
            const selectedColor = state.accessories.candles[
              key
            ].userSelection.find((e) => e.color === color);
            if (selectedColor) {
              // update subtotal here (minus): subtotal -= price (coming as a parameter) * selectedColor.qty;
              state.accessoriesSubtotal -= unitPrice * selectedColor.qty;
              state.accessories.candles[key].userSelection =
                state.accessories.candles[key].userSelection.filter(
                  (e) => e.color != color
                );
            }
          }
        } else {
          if (isChecked) {
            state.accessories.candles.numberCandle.userSelection.color = color;
          } else {
            state.accessories.candles.numberCandle.userSelection.color = null;
          }
        }
      }

      // toppers
      for (const key in state.accessories.toppers) {
        if (key === accessoryType) {
          if (isChecked) {
            const selectedColor = state.accessories.toppers[
              key
            ].userSelection.find((e) => e.color === color);
            if (!selectedColor) {
              const newColorChosen = {
                color: color,
                qty: 1,
              };
              state.accessories.toppers[key].userSelection.push(newColorChosen);
              state.accessoriesSubtotal += unitPrice * newColorChosen.qty;
            }
          } else {
            const selectedColor = state.accessories.toppers[
              key
            ].userSelection.find((e) => e.color === color);
            state.accessoriesSubtotal -= unitPrice * selectedColor.qty;
            state.accessories.toppers[key].userSelection =
              state.accessories.toppers[key].userSelection.filter(
                (e) => e.color != color
              );
          }
        }
      }
    },
    quantityUpdated: (state, action) => {
      const { color, accessoryType, updateType, unitPrice } = action.payload;

      for (const key in state.accessories.candles) {
        if (key === accessoryType && key === "numberCandle") {
          const selectedNumber =
            state.accessories.candles.numberCandle.userSelection.numbers.find(
              (e) => e.number === color
            );
          const selectedNumberIndex =
            state.accessories.candles.numberCandle.userSelection.numbers.findIndex(
              (e) => e.number === color
            );
          if (updateType === "increment") {
            state.accessories.candles.numberCandle.userSelection.numbers[
              selectedNumberIndex
            ] = { ...selectedNumber, qty: selectedNumber.qty + 1 };
            state.accessoriesSubtotal += unitPrice;
          } else {
            state.accessories.candles.numberCandle.userSelection.numbers[
              selectedNumberIndex
            ] = {
              ...selectedNumber,
              qty: selectedNumber.qty - 1 < 1 ? 1 : selectedNumber.qty - 1,
            };
            state.accessoriesSubtotal -= unitPrice;
          }
        }

        if (key === accessoryType && accessoryType !== "numberCandle") {
          const selectedColor = state.accessories.candles[
            key
          ].userSelection.find((e) => e.color === color);
          const selectedColorIndex = state.accessories.candles[
            key
          ].userSelection.findIndex((e) => e.color === color);
          if (updateType === "increment") {
            state.accessories.candles[key].userSelection[selectedColorIndex] = {
              ...selectedColor,
              qty: selectedColor.qty + 1,
            };
            state.accessoriesSubtotal += unitPrice;
          } else {
            state.accessories.candles.standardCandle.userSelection[
              selectedColorIndex
            ] = {
              ...selectedColor,
              qty: selectedColor.qty - 1 < 1 ? 1 : selectedColor.qty - 1,
            };
            state.accessoriesSubtotal -= unitPrice;
          }
        }
      }

      for (const key in state.accessories.toppers) {
        if (key === accessoryType) {
          const selectedColor = state.accessories.toppers[
            key
          ].userSelection.find((e) => e.color === color);
          const selectedColorIndex = state.accessories.toppers[
            key
          ].userSelection.findIndex((e) => e.color === color);
          if (updateType === "increment") {
            state.accessories.toppers[key].userSelection[selectedColorIndex] = {
              ...selectedColor,
              qty: selectedColor.qty + 1,
            };
            state.accessoriesSubtotal += unitPrice;
          } else {
            state.accessories.toppers[key].userSelection[selectedColorIndex] = {
              ...selectedColor,
              qty: selectedColor.qty - 1 < 1 ? 1 : selectedColor.qty - 1,
            };
            state.accessoriesSubtotal -= unitPrice;
          }
        }
      }
    },
    customerInfoUpdated: (state, action) => {
      state.customer = action.payload;
    },
    eventInfoUpdated: (state, action) => {
      state.collectionInfo = action.payload;
    },
    orderIdUpdated: (state, action) => {
      state.orderId = action.payload;
    },
    refundPolicyAgreeChanged: (state, action) => {
      state.refundPolicyAgrred = action.payload;
    },
    resetSubtotal: (state, action) => {
      state.subtotal = 0;
    },
    resetToInitial: () => initialState,
  },
});

// cake selectors
export const selectCakeChosen = (state) => state.inStoreStandardCake.cake;
export const selectSizeChosen = (state) => state.inStoreStandardCake.cake.size;
export const selectFlavorChosen = (state) =>
  state.inStoreStandardCake.cake.flavorSection;
export const selectColorChosen = (state) =>
  state.inStoreStandardCake.cake.colorSection;
export const selectPrice = (state) => state.inStoreStandardCake.currentPrice;
export const selectActiveStep = (state) => state.inStoreStandardCake.activeStep;
export const selectAvailableSizes = (state) =>
  state.inStoreStandardCake.availableSizes;

// DO NOT DELETE THIS COMMENT: this is a special way of "making" the selector to take in an additional parameter -> accessoryType in this case.
export const selectUserSelection = (accessoryType) => (state) => {
  if (accessoryType === "standardCandle") {
    return state.inStoreStandardCake.accessories.candles.standardCandle;
  }

  if (accessoryType === "numberCandle") {
    return state.inStoreStandardCake.accessories.candles.numberCandle
      .userSelection.numbers;
  }

  if (accessoryType === "spiralCandle") {
    return state.inStoreStandardCake.accessories.candles.spiralCandle;
  }

  if (accessoryType === "shapesCandle") {
    return state.inStoreStandardCake.accessories.candles.shapesCandle;
  }

  if (accessoryType === "happyBirthdayCandle") {
    return state.inStoreStandardCake.accessories.candles.happyBirthdayCandle;
  }

  if (accessoryType === "happyBirthday") {
    return state.inStoreStandardCake.accessories.toppers.happyBirthday;
  }

  if (accessoryType === "kiss") {
    return state.inStoreStandardCake.accessories.toppers.kiss;
  }

  if (accessoryType === "mr&ms") {
    return state.inStoreStandardCake.accessories.toppers.mrms;
  }

  if (accessoryType === "proposal") {
    return state.inStoreStandardCake.accessories.toppers.proposal;
  }

  if (accessoryType === "rings") {
    return state.inStoreStandardCake.accessories.toppers.rings;
  }
};

// text piping selector
export const selectTextPiping = (state) =>
  state.inStoreStandardCake.accessories.piping;
export const selectTextPipingOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.piping.optionIsSelected;

// standard candle selectors
export const selectStandardCandleOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.candles.standardCandle.optionIsSelected;
export const selectStandardCandleOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.candles.standardCandle.userSelection;

// number candle selectors
export const selectNumberCandleOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.candles.numberCandle.optionIsSelected;
export const selectNumberCandleOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.candles.numberCandle.userSelection;

// spiral candle selectors
export const selectSpiralCandleOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.candles.spiralCandle.optionIsSelected;
export const selectSpiralCandleOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.candles.spiralCandle.userSelection;

// happy birthday candle selectors
export const selectHappyBirthdayCandleOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.candles.happyBirthdayCandle
    .optionIsSelected;
export const selectHappyBirthdayCandleOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.candles.happyBirthdayCandle
    .userSelection;

// shapes candle selectors
export const selectShapesCandleOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.candles.shapesCandle.optionIsSelected;
export const selectShapesCandleOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.candles.shapesCandle.userSelection;

// happy birthday topper selectors
export const selectHappyBirthdayTopperOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.toppers.happyBirthday.optionIsSelected;
export const selectHappyBirthdayTopperOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.toppers.happyBirthday.userSelection;

// kiss topper selectors
export const selectKissTopperOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.toppers.kiss.optionIsSelected;
export const selectKissTopperOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.toppers.kiss.userSelection;

// rings topper selectors
export const selectRingsTopperOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.toppers.rings.optionIsSelected;
export const selectRingsTopperOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.toppers.rings.userSelection;

// mrms topper selectors
export const selectMrMsTopperOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.toppers.mrms.optionIsSelected;
export const selectMrMsTopperOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.toppers.mrms.userSelection;

// proposal topper selectors
export const selectProposalTopperOptionIsSelected = (state) =>
  state.inStoreStandardCake.accessories.toppers.proposal.optionIsSelected;
export const selectProposalTopperOptionUserSelection = (state) =>
  state.inStoreStandardCake.accessories.toppers.proposal.userSelection;

// review page selectors
export const selectCakeInfo = (state) => state.inStoreStandardCake.cake;
export const selectAccessoryInfo = (state) =>
  state.inStoreStandardCake.accessories;
export const selectCustomerInfo = (state) => state.inStoreStandardCake.customer;
export const selectEventInfo = (state) =>
  state.inStoreStandardCake.collectionInfo;
export const selectCakeSubtotal = (state) =>
  state.inStoreStandardCake.cakeSubtotal;
export const selectAccessoriesSubtotal = (state) =>
  state.inStoreStandardCake.accessoriesSubtotal;
export const selectTax = (state) => state.inStoreStandardCake.tax;
export const selectRefundPolicyAgreed = (state) =>
  state.inStoreStandardCake.refundPolicyAgrred;
export const selectOrderId = (state) => state.inStoreStandardCake.orderId;

export const {
  cakeSelected,
  cheesecakeSelected,
  sizeSelected,
  priceSelected,
  flavorSelected,
  colorSelected,
  activeStepUpdated,
  accessoryCateogryCheckedChange,
  pipingTextChange,
  numberSelectionChange,
  colorSelectionChange,
  quantityUpdated,
  customerInfoUpdated,
  eventInfoUpdated,
  refundPolicyAgreeChanged,
  orderIdUpdated,
  resetSubtotal,
  resetToInitial,
} = inStoreStandardCakeSlice.actions;

export default inStoreStandardCakeSlice.reducer;
