import React from "react";
import NewTopAppBar from "../../../components/newAppBar";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import {
  Container,
  Box,
  Typography,
  Card,
  CardMedia,
  Divider,
  Avatar,
  Stack,
} from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import cakeCooler from "../../../assets/images/blog/cakeCooler.jpg";
import decorateLikeAPro from "../../../assets/images/blog/decorateLikeAPro.jpg";
import weddingCakeTrend from "../../../assets/images/blog/weddingCakeTrend.jpg";


const WeddingCakeTrend = () => {
  return (
    <>
        <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      
    <Container maxWidth="xl">
       {/* Blog Title */}
       <Typography
        align="center"
        sx={{fontFamily: "Moul", fontSize: "34px", color: "#fd7762", mt: 6, mb: 3}}>
        9 Wedding Cake Trends That Will Wow Your Guests in 2024
      </Typography>
      

      {/* Blog Subtitle */}
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        sx={{ fontStyle: "italic", mb: 2, fontSize: "1.1rem" }}>
        Keep Your Masterpiece Perfect from Kitchen to Celebration!
      </Typography>

      {/* Blog Meta Information */}
      <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            alt="Anna Paytyan"
            src="/path-to-author-image.jpg"
            sx={{ width: 24, height: 24 }}
          />
          <Typography variant="body2" color="textSecondary">
            Anna Paytyan
          </Typography>
          <AccessTime fontSize="small" color="action" />
          <Typography variant="body2" color="textSecondary">
            22 October 2024
          </Typography>
        </Stack>
      </Box>

      {/* Blog Image */}
      <Card sx={{ maxHeight: "650px", mt: 2, mb: 4, boxShadow: 3 }}>
        <CardMedia
          component="img"
          height="650"
          image={weddingCakeTrend}
          alt="cake image"
        />
      </Card>

      <Box sx={{mb: 9}}>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76", textAlign: "center"}}>
        Wedding cakes are no longer just a sweet treat at the end of a reception—it’s a way for couples to express their personal style on their special day. The trends for wedding cakes in 2024 reflect individuality, creativity, and a nod toward sustainability and innovation. At CakeyHand, we pride ourselves on working closely with the couples to ensure every cake we create is as unique as the couples we serve. Here’s a sneak peek at what’s trending for 2024 weddings.
        </Typography>
      </Box>

      {/* Blog Content */}
      <Box my={4}>
        {/* Section 1 */}
        <Typography sx={{fontFamily: "Moul", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2}}>
        1. Inclusive Wedding Cake
        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76",}}>
        In 2024, couples are becoming more mindful of dietary restrictions when choosing their wedding cakes. Many are choosing options that accommodate a range of needs, such as vegan and gluten-free varieties. At CakeyHand, we proudly offer a selection of cakes designed to meet diverse dietary preferences, ensuring that every guest can enjoy a delicious and thoughtful treat at your celebration.
        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Section 2 */}
        <Typography sx={{fontFamily: "Moul", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2}}>
        2. Bold, Vibrant Colors
        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76", mb: 2}}>
        Say goodbye to the of all-white, plain wedding cakes! This year, couples are gravitating towards bold, vibrant colors that stand out as much as their love story. Bright blues, deep burgundies, and rich jewel tones are being incorporated into both the cake’s design and flavor choices. Colorful watercolor brushstrokes, ombre effects, and splashes of gold or metallics are also popular, turning cakes into true centerpieces.
        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76",}}>
        <span style={{fontWeight: "bold", color: "#000"}}>Pro Tip:</span> Match your color palette with your venue and overall wedding theme for a cohesive look. CakeyHand’s expert decorators are skilled in matching your vision with artistic designs that pop.
        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Section 3 */}
        <Typography sx={{fontFamily: "Moul", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2}}>
        3. Geometric and Asymmetrical Shapes
                </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76",}}>
        Couples are moving away from traditional round or square-tiered cakes in favor of geometric and asymmetrical designs that bring an extra level of visual interest. Whether it’s hexagonal tiers, angular cuts, or leaning layers, these cakes create a modern feel. In addition, many cakes in 2024 are embracing negative space, giving the impression that the cake is floating or suspended—perfect for couples wanting something truly out-of-the-box.        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Section 4 */}
        <Typography sx={{fontFamily: "Moul", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2}}>
        4. Textured Buttercream and Minimalist Designs        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76",}}>
        For couples seeking a more understated yet elegant look, textured buttercream is becoming more and more popular. Rough, rustic finishes—like palette knife strokes or ruffled edges—are becoming popular for adding dimension without being too dramatic. Paired with minimalist designs, such as single-color cakes or monochromatic looks with simple adornments like fresh herbs or flowers, this style is perfect for modern weddings where simplicity reigns.        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Section 5 */}
        <Typography sx={{fontFamily: "Moul", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2}}>
        5. Hand-Painted Cakes        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76",}}>
        The 2024 wedding cake scene has turned into a canvas for bakers. Couples are asking for cakes that resemble fine art, featuring intricate hand-painted designs, from romantic florals to abstract brushstrokes. Gold leaf, edible watercolors, and even personalized artwork that reflects the couple’s journey are trending.        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Section 6 */}
        <Typography sx={{fontFamily: "Moul", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2}}>
        6. Fresh Flowers        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76",}}>
        Incorporating fresh flowers and edible botanicals continues to be a favorite in 2024, but with a twist. Cakes adorned with unexpected blooms like dried wildflowers, cascading arrangements of ferns, and even edible pressed flowers. These elements add natural beauty and elegance, with a new trend of wild, overgrown looks that give a fairytale vibe.        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Section 7 */}
        <Typography sx={{fontFamily: "Moul", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2}}>
        7. Personalized Cake Toppers and Unique Embellishments        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76",}}>
        Couples are getting creative with cake toppers, moving away from the traditional bride and groom figurines. Personalized touches are key, with cake toppers reflecting the couple’s hobbies, pets, or even pop-culture references. From custom-made figurines to unique 3D-printed shapes, these little details add a playful and meaningful element to the cake.        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Section 8 */}
        <Typography sx={{fontFamily: "Moul", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2}}>
        8. Tiny Treats        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76",}}>
        As weddings become more intimate, couples are opting for smaller cakes, but that doesn’t mean they’re sacrificing style. Microcakes and petite tiered designs are a major trend for 2024, with couples choosing quality over quantity. Additionally, dessert bars filled with mini cakes, cupcakes, macarons, and even cookies are replacing large cakes altogether, giving guests a variety of treats to enjoy. If you’re thinking about going down this route, check out our wide array of desserts!        </Typography>

        <Divider sx={{ my: 4 }} />

        {/* Section 9 */}
        <Typography sx={{fontFamily: "Moul", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2}}>
        9. Unique Flavors with a Twist        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76",}}>
        While vanilla and chocolate remain wedding cake staples, 2024 is the year for flavor experimentation. Couples are opting for unique flavor combinations like blueberry honey, chocolate caramel pecan, and strawberry lemon. These fresh and bold flavors bring a modern twist to the classic wedding cake, allowing couples to wow their guests not just visually but through taste as well.        </Typography>

        <Divider sx={{ my: 4 }} />

        <Box sx={{mb: 9, textAlign: "center"}} >
        <Typography sx={{fontFamily: "Inter", lineHeight: "38px", fontSize: "24px", color: "#232536", mb: 2, fontWeight: "bold"}}>
        Make Your 2024 Wedding Cake a Masterpiece
        </Typography>
        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76", textAlign: "center"}}>
        No matter what trend speaks to you, your wedding cake should be a reflection of your love story. At CakeyHand, we’re committed to working with couples to create something truly memorable. Whether you’re drawn to bold colors, intricate textures, or unique flavors, our team of skilled bakers and decorators is ready to bring your vision to life.
        </Typography>

        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76", textAlign: "center"}}>
        Ready to design the cake of your dreams? Contact us today or visit one of our locations to start designing your one-of-a-kind wedding cake.        </Typography>

        <Typography sx={{fontFamily: "Inter", lineHeight: "28px", fontSize: "16px", color: "#6D6E76", textAlign: "center"}}>
        Let’s make your special day even sweeter!
        </Typography>
      </Box>

      </Box>
    </Container>
    <Footer></Footer>
    </>

  );
};

export default WeddingCakeTrend;
