/** @format */
import React, { useState, useEffect, useRef } from "react";
import { Box, Paper, Typography } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { makeStyles } from "@mui/styles";
import editIcon from "../../../assets/icons/adminBooking/edit.png";
import deleteIcon from "../../../assets/icons/adminBooking/delete.png";

import { useDispatch } from "react-redux";
import { orderInfoSet } from "../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  orderMenu: {
    color: "#B2B7BD",
    cursor: "pointer",
  },

  menuContainer: {
    position: "relative",
    margin: "0 0 0 0",
  },

  menuPopUp: {
    position: "absolute",
    padding: "10px 25px",
    right: "0",
    borderRadius: "10px",
    zIndex: "2",
  },

  menuText: {
    fontWeight: "700",
    fontSize: "16px",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "9px 0",
    color: "#444A6D",
    cursor: "pointer !important",
    "&:hover": {
      color: "#FFDDD8",
    },
  },
  controlIcons: {
    height: "16px !important",
    width: "auto !important",
    margin: "0 10px 0 0",
  },
}));

function OrderMenu({ setEdit, setDelete, order }) {
  const [showMenu, setShowMenu] = useState(false);

  const classes = useStyles();

  const dispatch = useDispatch();

  const menuPopUp = useRef();

  const handleOpen = () => {
    setShowMenu(!showMenu);
  };

  const handleOutsideClick = () => (event) => {
    if (menuPopUp.current && !menuPopUp.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleOutsideClick());

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick());
    };
  }, []);

  return (
    <Box className={classes.menuContainer} ref={menuPopUp} data-testid="order-menu">
      <MoreVertOutlinedIcon
        className={classes.orderMenu}
        onClick={() => handleOpen(!showMenu)}
      />
      <Paper
        className={classes.menuPopUp}
        style={{ display: showMenu ? "block" : "none" }}
        elevation={2}
      >
        <Box
          className={classes.menuItem}
          onClick={() => {
            //setOrderInfo(order);
            dispatch(orderInfoSet(order));
            setEdit();
            setShowMenu(false);
          }}
        >
          <img
            className={classes.controlIcons}
            src={editIcon}
            alt="Edit order icon"
          />
          <Typography className={classes.menuItem}>Edit</Typography>
        </Box>
        <Box
          className={classes.menuItem}
          onClick={() => {
            setDelete();
            setShowMenu(false);
          }}
        >
          <img
            className={classes.controlIcons}
            src={deleteIcon}
            alt="Delete Item icon"
          />
          <Typography className={classes.menuItem}>Delete</Typography>
        </Box>
        <Box
          className={classes.menuItem}
          onClick={() => {
            dispatch(orderInfoSet({ ...order, orderStatus: "cancelled" }));
            setShowMenu(false);
          }}
        >
          <CancelOutlinedIcon className={classes.controlIcons} />
          <Typography className={classes.menuItem}>Cancel Order</Typography>
        </Box>
      </Paper>
    </Box>
  );
}

export default OrderMenu;
