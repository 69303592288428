/** @format */

import { Button } from "@mui/material";

export default function MainActionButton({
  children,
  clickEvent,
  testId,
  styleObj,
}) {
  return (
    <Button className={styleObj} onClick={clickEvent} data-testid={testId}>
      {children}
    </Button>
  );
}
