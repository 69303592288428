/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Paper, Typography, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CircularProgress from "@mui/material/CircularProgress";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../auth.js";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#fd776280",
    position: "relative",
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "2rem 3rem",
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
  },
  title: {
    color: "#fd7762",
    fontSize: "32px",
    ...theme.textStyle,
    marginBottom: "2rem",
  },
  button: {
    ...theme.containedButton,
    marginTop: "20px !important",
    marginBottom: "1rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#fd7762",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd7762",
      },
    },
  },
  joinNow: {
    color: "#fd7762",
  },
  textStyle: { ...theme.textStyle },
  inputFieldText: {
    ...theme.textStyle,
    color: "#000",
  },
}));

export default function ResetPassword() {
  initializeApp(firebaseConfig());

  const classes = useStyles();
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [requestSumbitted, setRequestSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (input) => {
    if (!input) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(input);
  };

  const resetPassword = async () => {
    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      return setEmailError(true);
    }

    const auth = getAuth();
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setRequestSubmitted(true);
    } catch (error) {
      setLoading(false);
      if (error)
        return alert(
          "There is no user with the specified email. Please make sure the email is correct."
        );
    }
  };

  return (
    <Box className={classes.wrapper} data-testid="reset">
      <Paper className={classes.container} elevation="10">
        {requestSumbitted ? (
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ color: "green", fontSize: "20px" }}>
              Success! We've sent you a link. Reset your password and try
              logging in again.
            </Typography>
            <Button
              size={"large"}
              variant="contained"
              fullwidth
              className={classes.button}
              onClick={() => navigate("/login")}
            >
              <Typography className={classes.textStyle}>Log In</Typography>
            </Button>
          </Box>
        ) : (
          <Box>
            <KeyboardBackspaceIcon
              fontSize="large"
              onClick={() => navigate(-1)}
            />
            <Box sx={{ textAlign: "center" }}>
              <Typography className={classes.title}>Reset Password</Typography>
              <Typography sx={{ mb: 2 }}>
                Enter the email you used the first time when you created your
                account. You'll get a link to your email to reset your password.{" "}
              </Typography>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": {
                    width: "100%",
                    boxShadow: 3,
                  },
                }}
                noValidate
              >
                <TextField
                  error={emailError}
                  fullWidth
                  id="outlined-required"
                  label="Email"
                  defaultValue=""
                  margin="normal"
                  helperText={emailError ? "Enter a valid email" : ""}
                  className={classes.root}
                  InputLabelProps={{
                    className: classes.inputFieldText,
                  }}
                  InputProps={{ className: classes.textStyle }}
                  FormHelperTextProps={{ className: classes.textStyle }}
                  onChange={(e) => {
                    handleEmailChange(e.target.value);
                  }}
                  data-testid="reset-email"
                />

                <Box sx={{ position: "relative" }}>
                  <Button
                    size={"large"}
                    variant="contained"
                    fullwidth
                    className={classes.button}
                    onClick={resetPassword}
                    data-testid="reset-link"
                  >
                    <Typography className={classes.textStyle}>
                      Get a Link
                    </Typography>
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-6px",
                        marginLeft: "65px",
                        color: "#fff",
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  color: "#828282",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px" }}>
                  {"CakeyHand"} {new Date().getFullYear()}{" "}
                  {"© All rights reserved"}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
