import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Grid, Box, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddOnItem from "./addOnItem";
import SectionHeadings from "../../../components/sectionHeadings";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "2rem !important",
    marginBottom: "1rem !important",
    padding: "15px 10px",
  },
  sectionTitle: {
    color: theme.palette.secondary.dark,
    marginBottom: "20px !important",
    textAlign: "center",
  },
  sectionSubTitle: {
    color: theme.palette.secondary.main.main,
    textAlign: "center",
    marginBottom: "40px !important",
  },
}));

function AddOns(props) {
  const classes = useStyles();
  const [addOnItems, setAddOnItems] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);

  useEffect(() => {
    const fetchAddOns = async () => {
      const res = await axios.get("/api/app/data/addOns");
      setAddOnItems(res.data.addOns);
      setDataIsReady(true);
    };

    fetchAddOns();
  }, [dataIsReady]);

  return (
    <Paper elevation={10} className={classes.wrapper}>
      <Grid container>
        <SectionHeadings
          heading="Add Ons"
          subheading="Save a trip to the store and add the following to your order all at
          once"
        ></SectionHeadings>

        {dataIsReady ? (
          addOnItems.map((addOnItem) => {
            return (
              <AddOnItem addon={addOnItem} key={addOnItem.name}></AddOnItem>
            );
          })
        ) : (
          <Box>Loading...</Box>
        )}
      </Grid>
    </Paper>
  );
}

function mapStateToProps(state) {
  const { addOns } = state.cart;
  return { addOns };
}

export default connect(mapStateToProps)(AddOns);
