/** @format */

import { useEffect, useState } from "react";
import {
  Button,
  Paper,
  TextField,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import KeyIcon from "@mui/icons-material/Key";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useDispatch } from "react-redux";
import { authSet } from "../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  frameStyle: {
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px !important",
    padding: "40px !important",
  },
  inputControls: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
  },
  confirmBtn: {
    ...theme.outlinedButton,
    width: "136px !important",
    height: "47px !important",
    margin: "0 0 0 20px !important",
  },
  errorText: {
    color: "red",
    marginTop: "20px !important",
  },
  lock: {},
}));

function Auth() {
  const [employeeId, setEmployeeId] = useState("");
  const [invalidEntry, setInvalidEntry] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const getAuth = async () => {
    try {
      const auth = await axios.post(`/api/admin/auth/authAdmin`, {
        employeeID: employeeId,
      });

      if (auth.data === false) {
        dispatch(authSet(false));
        setInvalidEntry(true);
      } else if (typeof auth.data === "string") {
        localStorage.setItem("session", auth.data);
        dispatch(authSet(true));
      } else {
        dispatch(authSet(false));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const checkForSession = async () => {
      try {
        let sID = localStorage.getItem("session");
        let sessionId = null;

        if (sID !== null) {
          sessionId = await axios.post(`/api/admin/auth/authAdmin`, {
            sessionID: sID,
          });
          dispatch(authSet(sessionId.data));
        } else {
          dispatch(authSet(false));
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkForSession();
  }, []);

  const handleKeyDown = (e) => {
    if(e.key === "Enter"){
      getAuth();
    }
    else{
      console.log("erer")
    }
  }

  return (
    <Paper className={classes.frameStyle} elevation={3} data-testid="booking-auth">
      <Box sx={{display: "flex", mb: 2}}>
        <LockIcon sx={{mr: 2}}/> 
        <Typography style={{ marginBottom: "20px" }}>
          Enter your employee ID to continue.{" "}
        </Typography>
      </Box>
      <Box className={classes.inputControls}>
        <TextField
          label="Employee ID"
          type="password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <KeyIcon />
              </InputAdornment>
            ),
          }}
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value)}
        />
        <Button
          className={classes.confirmBtn}
          variant={"outlined"}
          onClick={() => getAuth()}
          onKeyDown={handleKeyDown}
        >
          Submit
        </Button>
      </Box>
      <Typography
        className={classes.errorText}
        style={{ display: invalidEntry ? "block" : "none" }}
      >
        Invalid employee ID.
      </Typography>
    </Paper>
  );
}

export default Auth;
