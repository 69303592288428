import React from "react";
import { useSelector } from "react-redux";
import { selectUserSelection } from "../../../../redux/reducers/inStoreStandardCake";
import { Box } from "@mui/material";
import Header from "../common/header";
import MultiImageShow from "../../../../components/multiImageShow";
import AccessoryCustomFormControl from "../enhancedFormControls/accessoryCustomFormControl";
import EnhancedFormControlNumberCandleColorSelection from "../enhancedFormControls/enhancedFormControlNumberCandleColorSelection";
import EnhancedFormControlNumberCandleNumberSelection from "../enhancedFormControls/enhancedFormControlNumberCandleNumberSelection";

export default function CandleAccessory({ accessoryItem }) {
  const optionIsSelected = useSelector(
    selectUserSelection(accessoryItem.subtype)
  ).optionIsSelected;

  return (
    <>
      <MultiImageShow images={accessoryItem.images}></MultiImageShow>
      <Header accessory={accessoryItem} checked={optionIsSelected}></Header>
      {accessoryItem.subtype === "numberCandle" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row !important",
            width: "100% !important",
          }}
          data-testid="candle-accessory"
        >
          <EnhancedFormControlNumberCandleColorSelection
            accessory={accessoryItem}
          ></EnhancedFormControlNumberCandleColorSelection>
          <EnhancedFormControlNumberCandleNumberSelection
            accessory={accessoryItem}
          ></EnhancedFormControlNumberCandleNumberSelection>
        </Box>
      ) : (
        <AccessoryCustomFormControl
          accessory={accessoryItem}
        ></AccessoryCustomFormControl>
      )}
    </>
  );
}
