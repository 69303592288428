import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  standardCakeImg: {
    width: "100% !important",
    height: "300px !important",
    objectFit: "contain !important",
  },
  secondaryCakeImg: {
    width: "100% !important",
    height: "90px !important",
    objectFit: "contain !important",
  },
  gridContainer: {
    height: "310px",
    width: "100% !important",
    display: "grid !important",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: "10px",
  },
  gridColumn: {
    gridColumn: "1 / 4",
    gridRow: "1 / 4",
  },
  scrollContainer: {
    height: "310px !important",
    overflowY: "scroll",
  },
}));

export default function MultiImageShow({ images }) {
  const classes = useStyles();
  const [primaryImg, setPrimaryImg] = useState(images.primary);

  const handleImageClick = (img, i) => {
    let imgTemp = primaryImg;
    setPrimaryImg(img);
    images.secondary[i] = imgTemp;
  };

  return (
    <Box data-testid="multi-image-show">
      <Grid container className={classes.gridContainer}>
        <Grid item className={classes.gridColumn}>
          <img
            className={classes.standardCakeImg}
            src={primaryImg}
            alt="image"
            data-testid="primary-image"
          />
        </Grid>

        <Box className={images.secondary.length > 3 && classes.scrollContainer}>
          {images.secondary.map((image, index) => (
            <Grid item>
              <img
                className={classes.secondaryCakeImg}
                src={image}
                alt="image"
                onClick={() => handleImageClick(image, index)}
                data-testid="secondary-image"
              />
            </Grid>
          ))}
        </Box>
      </Grid>
    </Box>
  );
}
