/** @format */
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DropDownMenu from "./dropdownMenu";
import corporateEventsImage from "../../assets/images/appbar/corporate_events.png";
import weddingsImage from "../../assets/images/appbar/weddings.png";

const shopDropdownMenu = [
  {
    menuTitle: "cakes",
    menuItems: [
      {
        href: "/cakes/specialty-cakes",
        type: "text",
        value: "Specialty Cakes",
      },
      {
        href: "/cakes/standard-cakes",
        type: "text",
        value: "Standard Cakes",
      },
      {
        href: "/cakes/cheesecakes",
        type: "text",
        value: "Cheesecakes (whole)",
      },
    ],
  },
  {
    menuTitle: "patisserie",
    menuItems: [
      {
        href: "/patisserie/desserts",
        type: "text",
        value: "Desserts & Pastries",
      },
      {
        href: "/patisserie/cheesecakes",
        type: "text",
        value: "Cheesecakes (slice)",
      },
      { href: "/patisserie/cupcakes", type: "text", value: "Cupcakes" },
      { href: "/patisserie/macarons", type: "text", value: "Macarons" },
      { href: "/patisserie/cookies", type: "text", value: "Cookies" },
    ],
  },
  {
    menuTitle: "graze boxes",
    menuItems: [
      { href: "/graze-boxes/small", type: "text", value: "Small" },
      { href: "/graze-boxes/medium", type: "text", value: "Medium" },
      { href: "/graze-boxes/large", type: "text", value: "Large" },
    ],
  },
  {
    menuTitle: "accessories",
    menuItems: [
      { href: "/accessories/candles", type: "text", value: "Candles" },
      {
        href: "/accessories/cake-toppers",
        type: "text",
        value: "Cake Toppers",
      },
      {
        href: "/accessories/holiday-cards",
        type: "text",
        value: "Holiday Cards",
      },
      { href: "/accessories/balloons", type: "text", value: "Balloons" },
    ],
  },
];

const servicesDropdownMenu = [
  {
    menuTitle: "weddings",
    menuItems: [
      { href: "/services/wedding-packages", type: "img", value: weddingsImage },
    ],
  },
  {
    menuTitle: "corporate events",
    menuItems: [
      {
        href: "/services/corporate-events",
        type: "img",
        value: corporateEventsImage,
      },
    ],
  },
];

const useStyles = makeStyles(() => ({
  parent: {
    position: "relative",
    height: "300px !important",
    opacity: 1,
    transition: "opacity 0.7s ease-in-out, height 0.4s ease-in-out",
  },
}));

export default function DropDownMenuWrapper({
  menuType,
  setShopDropdownIsOpen,
  setServicesDropdownIsOpen,
}) {
  const classes = useStyles();
  const menuData =
    menuType === "shop" ? shopDropdownMenu : servicesDropdownMenu;

  return (
    <Box className={classes.parent}>
      <DropDownMenu
        data={menuData}
        menuType={menuType}
        setShopDropdownIsOpen={setShopDropdownIsOpen}
        setServicesDropdownIsOpen={setServicesDropdownIsOpen}
      ></DropDownMenu>
    </Box>
  );
}
