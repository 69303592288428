import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Container,
} from "@mui/material";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import weddingCouple from "../../assets/images/wedding_setup.jpg";
import corporateEvent from "../../assets/images/corporate_event.jpg";
import cakeDecoratingClass from "../../assets/images/cake_decorating_class.jpg";

export default function SpecialOfferings() {
  const offerings = [
    {
      title: "Weddings",
      description:
        "Celebrate your love story in style with our exquisite wedding cakes and elegant dessert tables, crafted to add sweetness and sophistication to your special day!",
      imageUrl: weddingCouple,
      buttonText: "Coming soon",
      icon: UpcomingIcon,
      ctaColor: "orange",
    },
    {
      title: "Decorating Classes",
      description:
        "Unlock your creativity and celebrate the art of cake decorating with our fun, hands-on, and expert-led decorating classes!",
      imageUrl: cakeDecoratingClass,
      buttonText: "Coming soon",
      icon: UpcomingIcon,
      ctaColor: "orange",
    },
    {
      title: "Corporate Events",
      description:
        "Celebrate success in style with our elegant, delicious cakes—perfect for any corporate event or milestone!",
      imageUrl: corporateEvent,
      buttonText: "Coming soon",
      icon: UpcomingIcon,
      ctaColor: "orange",
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ py: "40px" }}>
      <Typography
        variant="h3"
        component="h2"
        sx={{
          mb: 3,
          fontFamily: "Moul",
          fontSize: { xs: "2.2rem", md: "3rem" },
        }}>
        Special Offerings
      </Typography>
      <Typography
        variant="body1"
        paragraph
        sx={{ fontFamily: "Inter", color: "#909090" }}>
        Discover our curated services designed to bring creativity, celebration,
        and expertise to your most special moments.
      </Typography>
      <Grid container spacing={4}>
        {offerings.map((offering, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                borderRadius: "16px",
                boxShadow: 3,
                position: "relative",
              }}>
              <CardMedia
                component="img"
                height="300"
                image={offering.imageUrl}
                alt={offering.title}
              />
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    mb: "20px",
                    fontFamily: "Inter",
                    color: "#515151",
                    fontWeight: "bold",
                  }}>
                  {offering.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    minHeight: "80px",
                    fontFamily: "Inter",
                    color: "#909090",
                  }}>
                  {offering.description}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                }}>
                <Button
                  size="small"
                  endIcon={<offering.icon />}
                  sx={{ textTransform: "none", color: offering.ctaColor }}>
                  {offering.buttonText}
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
