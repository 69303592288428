/** @format */

import { React } from "react";
import { Container, Typography, Grid, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
    textAlign: "center",
    marginTop: "4rem",
  },
  heading: {
    color: theme.palette.primary.dark,
    ...theme.textStyle,
  },
  subHeading1: {
    color: theme.palette.secondary.main.main,
    margin: "20px 0 !important",
    ...theme.textStyle,
  },
  subHeading2: {
    color: theme.palette.secondary.main.main,
    marginBottom: "30px !important",
    ...theme.textStyle,
  },
}));

export default function Main() {
  const classes = useStyles();

  return (
    <Container>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          <Typography
            sx={{ typography: { sm: "h2", xs: "h3" } }}
            className={classes.heading}
          >
            Standard Cakes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h6"} className={classes.subHeading1}>
            Choose one of our simple but elegant designs
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"} className={classes.subHeading2}>
            Portion Sizes: 7" cakes feed up to 14 people and 9" cakes feed up to
            20 people
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
