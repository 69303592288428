import React from "react";
import { Typography, Grid, Container } from "@mui/material";
import CustomerInfo from "../../../components/order/customerInfo";
import EventInfo from "../../../components/order/eventInfo";
import OrderInfo from "../../../components/order/orderInfo";
import AccessoryInfo from "../../../components/order/accessoryInfo";
import Submission from "./submission";
import SpecialNotes from "../../../components/order/specialNotes";

export default function NewOrder({ category }) {
  return (
    <Container data-testid="new-order">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">
            {category.displayName.toUpperCase()}
          </Typography>
          <OrderInfo orderType={category.label}></OrderInfo>
          <CustomerInfo></CustomerInfo>
          <EventInfo></EventInfo>
          <AccessoryInfo></AccessoryInfo>
          <SpecialNotes></SpecialNotes>
          <Submission></Submission>
        </Grid>
      </Grid>
    </Container>
  );
}
