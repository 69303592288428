import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  colorSelectionChange,
  selectNumberCandleOptionIsSelected,
  selectNumberCandleOptionUserSelection,
} from "../../../../redux/reducers/inStoreStandardCake";
import {
  Box,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import QuantitySelector from "../common/quantitySelector";

const useStyles = makeStyles((theme) => ({
  nextButton: {
    "&.MuiButton-root": {
      color: "#f6725c",
      fontSize: "18px",
    },
    "&.Mui-disabled": {
      color: "lightgrey",
    },
  },
  backButton: {
    "&.MuiButton-root": {
      color: "#f6725c",
      fontSize: "18px",
    },
  },
  container: {
    width: "100%",
    padding: "0 3rem 3rem 3rem",
    [theme.breakpoints.between("sm", "md")]: {
      padding: 0,
    },
  },
  arrowsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: "white",
    },
    zIndex: 10,
  },
  standardAccessoriesImg: {
    width: "100% !important",
    height: "310px !important",
    objectFit: "contain !important",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  secondaryAccessoriesImg: {
    width: "100% !important",
    height: "99px !important",
    objectFit: "contain !important",
    [theme.breakpoints.up("sm")]: {
      width: "100% !important",
    },
  },
  scrollContainer: {
    height: "310px !important",
    overflowY: "scroll",
  },
  accessName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    color: "#fd7762",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      fontWeight: "bold !important",
      whiteSpace: "nowrap",
    },
  },
  gridContainer: {
    height: "310px",
    width: "100%",
    display: "grid !important",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: "0.5rem",
  },
  gridColumn: {
    gridColumn: "1 / 4",
    gridRow: "1 / 4",
  },
  textStyle: { ...theme.textStyle },
  inputLabel: {
    color: "grey !important",
    "&.MuiInputLabel-shrink": {
      color: "grey !important",
    },
  },
}));

export default function EnhancedFormControlNumberCandleColorSelection({
  accessory,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const numberCandleOptionIsSelected = useSelector(
    selectNumberCandleOptionIsSelected
  );
  const numberCandleUserSelection = useSelector(
    selectNumberCandleOptionUserSelection
  );

  let selectedNumbers = [];
  numberCandleUserSelection.numbers.map((e) => selectedNumbers.push(e.number));

  const handleColorSelectionChange = (e, color) => {
    dispatch(
      colorSelectionChange({
        color,
        accessoryType: accessory.subtype,
        isChecked: e.target.checked,
      })
    );
  };

  return (
    <FormControl sx={{ width: "100%", mt: 1 }}>
      <InputLabel
        id="demo-simple-select-autowidth-label"
        classes={{ root: classes.inputLabel }}
      >
        Choose Color
      </InputLabel>
      <Tooltip
        title={"Please select an accessory to see options"}
        arrow
        placement="top"
      >
        <span>
          <Select
            multiple
            value={[numberCandleUserSelection.color]}
            renderValue={(selected) => selected.join(", ")}
            disabled={!numberCandleOptionIsSelected}
            sx={{
              backgroundColor: "white",
              width: "100%",
              borderRadius: "50px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#f6725c",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey",
              },
            }}
            label="Choose Option"
          >
            {accessory.colors.map((color, idx) => (
              <MenuItem key={idx} value={color}>
                <Checkbox
                  onChange={(e) => handleColorSelectionChange(e, color)}
                  checked={
                    numberCandleUserSelection.color === color ? true : false
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: "#fd7762 !important",
                    },
                    "& .MuiIconButton-root": {
                      padding: 0,
                      "&:hover": {
                        backgroundColor: "transparent !important",
                      },
                    },
                    "& .MuiTouchRipple-root": {
                      display: "none !important",
                    },
                  }}
                />
                <ListItemText primary={color} />
              </MenuItem>
            ))}
          </Select>
        </span>
      </Tooltip>
      {/* <Box sx={{ minHeight: "2rem", textAlign: "left", pl: 1 }}>
        {accessories.alert &&
          (!selectedOptions[i] || selectedOptions[i].length === 0) && (
            <Typography variant="body2" sx={{ color: "#f6725c", mt: 1 }}>
              Please select an option for this accessory.
            </Typography>
          )}
      </Box> */}
    </FormControl>
  );
}
