import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebaseConfig from "../auth";
import { Box } from "@mui/material";

export default function PrivateRouter({ children }) {
  const [dataIsReady, setDataIsReady] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  initializeApp(firebaseConfig());

  useEffect(() => {
    async function fetchCakeDesigns() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setLoggedInUser(user);
        }

        setDataIsReady(true);
      });
    }

    fetchCakeDesigns();
  }, []);

  const location = useLocation();
  const auth = getAuth();

  return !dataIsReady ? (
    <Box sx={{ mb: 3 }}>Loading...</Box>
  ) : loggedInUser ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}
