import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectEventInfo } from "../../../redux/reducers/inStoreStandardCake";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "grey !important",
    marginBottom: "0.5rem",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
}));

export default function Event() {
  const classes = useStyles();
  const eventInfo = useSelector(selectEventInfo);

  return (
    <>
      <Typography className={classes.heading} sx={{ mt: 4 }}>
        pickup
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        data-testid="event-review"
      >
        <Grid item xs={4} className={classes.gridItem}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              className={classes.infoItems}
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Pick Up Date
            </Typography>
            <Typography className={classes.infoItems}>
              {dayjs(eventInfo.pickUpDate).format("YYYY-MM-DD")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              className={classes.infoItems}
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Pick Up Time
            </Typography>
            <Typography className={classes.infoItems}>
              {eventInfo.pickUpTime}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              className={classes.infoItems}
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Pick Up Location
            </Typography>
            <Typography className={classes.infoItems}>
              {eventInfo.pickUpLocation}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
