/** @format */
import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectOrderInfo } from "../../../../redux/reducers/admin";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonIcon from "@mui/icons-material/Person";

const useStyles = makeStyles(() => ({
  customerInfoItem: {
    display: "flex !important",
    flexDirection: "row !important",
    marginBottom: "15px !important",
  },
  icon: {
    marginRight: "10px !important",
    color: "#fd7762",
  },
  sectionHeadText: {
    color: "#242424 !important",
    fontWeight: "700 !important",
    fontSize: "18px !important",
    marginBottom: "15px !important",
  },
}));

export default function CustomerDetail() {
  const classes = useStyles();
  const orderInfo = useSelector(selectOrderInfo);

  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Typography className={classes.sectionHeadText}>CUSTOMER</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.customerInfoItem}>
          <PersonIcon className={classes.icon} />
          <Typography>{orderInfo.customerInfo.name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.customerInfoItem}>
          <EmailOutlinedIcon className={classes.icon} />
          <Typography>{orderInfo.email}</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.customerInfoItem}>
          <PhoneOutlinedIcon className={classes.icon} />
          {/* due to the inconsistent way of collecting the phone number on the orders
          (i.e. some orders take it as "phoneNumber" and some as "phone") 
          we need both of these here (but only one of them will be eventually applied)
          until the data itself is fixed */}
          <Typography>{orderInfo?.customerInfo.phoneNumber}</Typography>
          <Typography>{orderInfo?.customerInfo.phone}</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.customerInfoItem}>
          <HomeOutlinedIcon className={classes.icon} />
          <Typography>{orderInfo?.customerInfo.address}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
