import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  Link,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import whiteTextLogo from "../assets/images/logo_white_letters.png";
import openingSoon from "../assets/images/opening_soon.jpg";
import axios from "axios";

const Footer = () => {
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (val) => {
    if (!val) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(val.toLowerCase());
  };

  const handleNewsletterSignup = async () => {
    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      setEmailError(true);
    }

    try {
      const res = await axios.post("/api/newsletter/signup", { email });

      if (res.status === 200) {
        alert(`signup is successful with email ${email}`);
      } else if (res.status === 409) {
        throw new Error("Email is already subscribed");
      } else {
        throw new Error("unknown error occurred");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert(error.response.data.message); // Show custom message from server
      } else {
        alert(error.message); // Fallback to generic message
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f56958",
        color: "#fff",
        padding: "3rem 0",
        position: "relative",
        zIndex: 10,
      }}>
      <Container maxWidth="xl">
        {/* Top Navigation Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
            flexDirection: { xs: "column", sm: "row" },
          }}>
          {/* Logo */}
          <Box sx={{ width: "150px", mb: { xs: 4, sm: 0 } }}>
            <img src={whiteTextLogo} width="100%" height="100%" />
          </Box>
          {/* Navigation Links */}
          <Box
            sx={{
              display: "flex",
              gap: "2rem",
              flexDirection: "row",
              fontFamily: "Inter",
              textAlign: { xs: "center", sm: "left" },
            }}>
            <Link href="/" color="inherit" underline="none">
              Home
            </Link>
            <Link href="/about-us" color="inherit" underline="none">
              About
            </Link>
            <Link href="/faq" color="inherit" underline="none">
              FAQs
            </Link>
            <Link href="/contact-us" color="inherit" underline="none">
              Contact Us
            </Link>
          </Box>
        </Box>

        {/* Horizontal Divider */}
        <Divider sx={{ backgroundColor: "#fff", marginBottom: "2rem" }} />

        {/* Subscription Section */}
        <Box
          sx={{
            backgroundColor: "#f9d8d4",
            borderRadius: "8px",
            padding: "2rem",
            marginBottom: "3rem",
          }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontFamily: "Inter",
              fontWeight: "bold",
              marginBottom: "1rem",
              color: "#515151",
            }}>
            Subscribe and receive 10% OFF
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Inter",
              marginBottom: "2rem",
              color: "#515151",
            }}>
            your next purchase!
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
            }}>
            <TextField
              variant="outlined"
              placeholder="Email address"
              error={emailError}
              helperText={emailError ? "invalid email format" : ""}
              onChange={(e) => handleEmailChange(e.target.value)}
              sx={{
                fontFamily: "Inter",
                backgroundColor: "#fff",
                borderRadius: "4px",
                width: "100%",
                maxWidth: "300px",
                marginRight: { sm: "1rem" },
                marginBottom: { xs: "1rem", sm: 0 },
              }}
            />
            <Button
              variant="contained"
              onClick={handleNewsletterSignup}
              sx={{
                fontFamily: "Inter",
                backgroundColor: "#f56958",
                "&:hover": {
                  backgroundColor: "#fd7762cc",
                },
                color: "#fff",
                padding: "0.75rem 1.5rem",
                borderRadius: "8px",
                width: { xs: "100%", sm: "auto" },
              }}>
              Subscribe Now
            </Button>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
            <IconButton
              sx={{
                color: "#f56958",
                backgroundColor: "#fff",
                marginRight: "1rem",
              }}>
              <Link
                href="https://www.facebook.com/CakeyHand"
                color="inherit"
                underline="none"
                sx={{ height: "24px" }}>
                <FacebookIcon />
              </Link>
            </IconButton>
            <IconButton sx={{ color: "#f56958", backgroundColor: "#fff" }}>
              <Link
                href="https://www.instagram.com/cakeyhand/"
                color="inherit"
                underline="none"
                sx={{ height: "24px" }}>
                <InstagramIcon />
              </Link>
            </IconButton>
          </Box>
          <Typography
            variant="body2"
            sx={{ fontFamily: "Inter", marginTop: "1rem", color: "#515151" }}>
            Our monthly newsletters include new products, announcements, and
            sales.
          </Typography>
        </Box>

        {/* Footer Information */}
        <Grid container spacing={2} sx={{ flexWrap: { sm: "nowrap" } }}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              sx={{ fontFamily: "Inter", fontWeight: "bold" }}>
              Address:
            </Typography>
            <Typography variant="body1" fontFamily="Inter">
              1483 Topsail Rd., Paradise, NL, A1L 1P9
            </Typography>

            <Typography
              variant="h6"
              sx={{
                fontFamily: "Inter",
                fontWeight: "bold",
                marginTop: "1rem",
              }}>
              Hours:
            </Typography>
            <Typography variant="body1" fontFamily="Inter">
              Monday - Sunday: 9:00am - 7:00pm <br />
            </Typography>
            <Divider
              sx={{
                backgroundColor: "#fff",
                marginY: "1rem",
                display: { xs: "block", sm: "none" },
              }}
            />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: "none", sm: "block" },
              backgroundColor: "#fff",
            }}
          />
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              sx={{ fontFamily: "Inter", fontWeight: "bold" }}>
              Address:
            </Typography>
            <Typography variant="body1" fontFamily="Inter">
              16 Stavanger Dr, St. John's, NL A1A 5E8, Canada
            </Typography>

            <Typography
              variant="h6"
              sx={{
                fontFamily: "Inter",
                fontWeight: "bold",
                marginTop: "1rem",
              }}>
              Hours:
            </Typography>
            <Typography variant="body1" fontFamily="Inter">
              Monday - Saturday: 8:00am - 7:00pm <br />
              Sundays: 9:00am - 7:00pm
            </Typography>
            <Divider
              sx={{
                backgroundColor: "#fff",
                marginY: "1rem",
                display: { xs: "block", sm: "none" },
              }}
            />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: "none", sm: "block" },
              backgroundColor: "#fff",
            }}
          />
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              sx={{ fontFamily: "Inter", fontWeight: "bold" }}>
              Address:
            </Typography>
            <Typography variant="body1" fontFamily="Inter">
              48 Kenmount Rd., St. John's, NL A1B 1W3
            </Typography>

            <Typography
              variant="h6"
              sx={{
                fontFamily: "Inter",
                fontWeight: "bold",
                marginTop: "1rem",
              }}>
              Hours:
            </Typography>
            <Typography variant="body1" fontFamily="Inter">
              Monday - Saturday: 9:30am - 9:00pm <br />
              Sundays: 12:00pm - 5:00pm
            </Typography>
          </Grid>
        </Grid>

        {/* Horizontal Divider */}
        <Divider
          sx={{
            backgroundColor: "#fff",
            marginY: "2rem",
            display: { xs: "block", sm: "none" },
          }}
        />

        {/* Footer Bottom */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            mt: 4,
          }}>
          <Typography
            variant="body2"
            sx={{ color: "#fff", marginBottom: { xs: "1rem", sm: 0 } }}>
            {`© ${new Date().getFullYear()} CakeyHand Dessert Factory Inc.`}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#fff", textAlign: { xs: "center", sm: "right" } }}>
            Designed by{" "}
            <Link href="#" color="inherit" underline="hover">
              Design Design
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
