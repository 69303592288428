import { createSlice } from '@reduxjs/toolkit'

export const macaronSlice = createSlice({
  name: 'macarons',
  initialState: {
    unitPrice: 0,
    availableSizes: [],
    selectedSize: null,
    flavors: [],
    selectedFlavors: [],
    packages: [],
    totalQuantitySelected: 0,
    packaging: "",
    maxNumberOfFlavors: 0,
  },
  reducers: {
    setUnitPrice: (state, action) => {
      state.unitPrice = action.payload.unitPrice;
    },
    setAvailableSizes: (state, action) => {
      state.availableSizes = action.payload.availableSizes;
    },
    setFlavors: (state, action) => {
      state.flavors = action.payload.flavors;
    },
    setPackages: (state, action) => {
      state.packages = action.payload.packages;
    },
    sizeSelected: (state, action) => {
      state.selectedSize = action.payload;
    },
    flavorAdded: (state, action) => {
      state.selectedFlavors.push(action.payload.name);
  },
    totalIncremented: state => {
        state.totalQuantitySelected += 1;
    },
    flavorQuantityIncremented: (state, action) => {
        let selectedFlavor = state.flavors.find(f => f.name === action.payload);
        selectedFlavor.quantity += 1; 
    },
    flavorsReset: state => {
        state.flavors = state.flavors.map(f => {return { ...f, quantity: 0 };});
        state.totalQuantitySelected = 0;
        state.selectedFlavors = [];
    },
    packagingSelected: (state, action) => {
        state.packages = state.packages.map(e => {
            if (e.name === action.payload ){
                return {...e, isSelected: true}
            }else{
                return {...e, isSelected: false}
            }
        });
    },
    selectedSizeReset: state => {
      state.selectedSize = null;
    },
    selectedPackagingReset: state => {
      state.packages = state.packages.map(p => {return {...p, isSelected: false}})
    },
    maxNumberOfFlavorsSet: (state, action) => {
      state.maxNumberOfFlavors = action.payload;
  },
  }
})

export const selectUnitPrice = state => state.macarons.unitPrice;
export const selectAvailableSizes = state => state.macarons.availableSizes;
export const selectSelectedSize = state => state.macarons.selectedSize;
export const selectFlavors = state => state.macarons.flavors;
export const selectSelectedFlavors = state => state.macarons.selectedFlavors;
export const selectPackages = state => state.macarons.packages;
export const selectTotalQuantitySelected = state => state.macarons.totalQuantitySelected;
export const selectSelectedPackaging = state => {
    return state.macarons.packages.find(e => e.isSelected === true);
};
export const maxNumberOfFlavorsSelected = state => state.macarons.maxNumberOfFlavors;

export const { 
  setUnitPrice,
  setAvailableSizes,
  setPackages,
  sizeSelected, 
  totalIncremented, 
  setFlavors,
  flavorAdded,
  flavorQuantityIncremented, 
  flavorsReset, 
  packagingSelected, 
  selectedSizeReset, 
  selectedPackagingReset,
  maxNumberOfFlavorsSet
} = macaronSlice.actions

export default macaronSlice.reducer