import React from "react";
import { Grid, Typography } from "@mui/material";
import FreeTextInput from "../admin/freeTextInput";

export default function SpecialNotes() {
  return (
    <Grid container data-testid="special-notes">
      <Grid item xs={12}>
        <Typography variant="h6">ADDITIONAL INFO</Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FreeTextInput label={"Other"}></FreeTextInput>
      </Grid>
    </Grid>
  );
}
