import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRefundPolicyAgreed,
  refundPolicyAgreeChanged,
} from "../../../redux/reducers/inStoreStandardCake";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "grey !important",
    marginBottom: "0.5rem",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
}));

export default function RefundPolicy() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const refundPolicyAgreed = useSelector(selectRefundPolicyAgreed);

  const [openDialog, setOpenDialog] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleCheckboxChange = (event) => {
    dispatch(refundPolicyAgreeChanged(event.target.checked));
  };

  const handleOpen = (event, scrollType) => {
    setOpenDialog(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
          color: "grey",
        }}
        data-testid="refund-policy-review"
      >
        <Checkbox
          checked={refundPolicyAgreed}
          onChange={handleCheckboxChange}
          color="primary"
          sx={{
            "&.Mui-checked": {
              color: "#fd7762",
            },
            "& .MuiSvgIcon-root": {
              borderRadius: "50%",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            color: "grey",
          }}
        >
          <Typography
            sx={{
              mr: "0.4rem",
            }}
          >
            I agree with the
          </Typography>
          <Typography
            onClick={(event) => handleOpen(event)}
            sx={{
              color: "grey",
              textDecoration: "underline",
              cursor: "pointer",
              mr: "0.4rem",
            }}
          >
            refund and cancellation policy
          </Typography>
        </Box>
      </Box>
      <Dialog open={openDialog} onClose={handleClose} scroll={scroll}>
        <DialogTitle
          sx={{
            mt: 4,
            ml: 2,
            mb: 1,
            color: "#f6725c",
            fontSize: "22px !important",
          }}
        >
          Refund and Cancellation Policy
        </DialogTitle>
        <DialogContent sx={{ ml: 2, mb: 4, fontSize: "18px !important" }}>
          <List>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              Cancellation
            </Typography>
            <ListItem>
              <ListItemText primary="You can cancel your order at any time, however, the refund policy below will be applied." />
            </ListItem>
            <ListItem>
              <ListItemText primary="If you wish to change the date of the booking, instead of cancellation, we’ll be happy to assist you with that provided that there is an availability for the new requested date and the new date is within 1 month." />
            </ListItem>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              Refund
            </Typography>
            <ListItem>
              <ListItemText primary="1. Once the order (whether it is a cake or an assorted dessert box) is picked up, customers accept all responsibility for the transport of any product to their location. We will not give refunds if there is a mishap during transit. We are happy to advise all our customers as to the best way to transport their cakes should they ask for it, but it is the customer’s responsibility to exercise extreme care when moving and transporting their cake." />
            </ListItem>
            <ListItem>
              <ListItemText primary="2. Refunds requested due to decorating style, color shade or general decoration design after picking the order up, will not be accepted. If you are unhappy with any of the design factors upon pickup, we may be able to make adjustments subject to our discretion and the design of the original product." />
            </ListItem>
            <ListItem>
              <ListItemText primary="3. Refunds requested due to quality or flavor will only be determined at the discretion of our staff, and based upon the returned cake. We are unable to provide a refund on a cake that has been consumed." />
            </ListItem>
            <ListItem>
              <ListItemText primary="4. No refund will be issued for a booking which is due in less than 14 days." />
            </ListItem>
            <ListItem>
              <ListItemText primary="5. A refund of 50% will be issued If the booking is due in 15+ days." />
            </ListItem>
            <Typography
              sx={{ mt: 4, mb: 2 }}
              variant="subtitle2"
              component="div"
            >
              We only give refunds in accordance with refund policies stated
              above. All refunds are given as store credit via gift cards. NO
              CASH REFUNDS.
            </Typography>
            <Typography
              sx={{ mt: 4, mb: 2 }}
              variant="subtitle2"
              component="div"
            >
              We highly recommend planning well before making the booking. We
              understand that things change and sometimes the event may be
              canceled for various reasons, however, by making a booking and
              reserving your spot we decline other customer’s requests and
              unfortunately we cannot afford to carry the consequences of your
              canceled event or changed plans. We appreciate your understanding
              and your business with us.
            </Typography>
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
