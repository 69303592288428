import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessoryInfo } from "../../../../../redux/reducers/inStoreStandardCake";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: "2rem !important",
    marginBottom: "2rem !important",
    width: "100% !important",
    borderRadius: "10px !important",
    boxShadow: "none",
    backgroundColor: "white",
    padding: 0,
  },
  tableCellCenter: {
    fontSize: "1rem !important",
    textAlign: "center !important",
  },
  tableCellLeft: {
    fontSize: "1rem !important",
    textAlign: "left !important",
  },
  tableCellRight: {
    fontSize: "1rem !important",
    textAlign: "right !important",
  },
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "grey !important",
    marginBottom: "0.5rem",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
}));

export default function PipingReview({ dbPiping }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { piping } = useSelector(selectAccessoryInfo);

  return (
    <>
      {piping.optionIsSelected && (
        <TableContainer
          className={classes.tableContainer}
          data-testid="piping-review">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.tableCellLeft}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "18px",
                    padding: "1rem 0 1rem 1rem !important",
                    width: "40%",
                  }}>
                  Text Piping
                </TableCell>
                <TableCell
                  className={classes.tableCellCenter}
                  sx={{ fontSize: "18px", color: "grey", width: "30%" }}>
                  {piping.text.trim()}
                </TableCell>

                <TableCell
                  sx={{ fontWeight: "bold" }}
                  className={classes.tableCellRight}>
                  {`$ ${dbPiping.content[0].price.toFixed(2)}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
