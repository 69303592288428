import React from "react";
import { Typography, Box, Checkbox } from "@mui/material";
import { companyColors } from "../../../theme";

export default function CompanyColors({ setState, state }) {
  return (
    <>
      <Box sx={{ margin: "12px" }}>
        <Typography sx={{ color: "#676767" }}>
          Indicate your company colors
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{ display: "flex", flexDirection: "column", margin: "12px" }}
          >
            <Typography sx={{ color: "gray", fontSize: "12px" }}>
              Light
            </Typography>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#DCEBFE",
                  backgroundColor: "#DCEBFE",
                  "&.Mui-checked": {
                    color: "#DCEBFE",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedLightArray = isChecked
                      ? [...(prevState.color?.light || []), "#DCEBFE"]
                      : prevState.color?.light.filter(
                          (color) => color !== "#DCEBFE"
                        );
                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        light: updatedLightArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#EAD6FF",
                  backgroundColor: "#EAD6FF",
                  "&.Mui-checked": {
                    color: "#EAD6FF",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedLightArray = isChecked
                      ? [...(prevState.color?.light || []), "#EAD6FF"]
                      : prevState.color?.light.filter(
                          (color) => color !== "#EAD6FF"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        light: updatedLightArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#D9F99F",
                  backgroundColor: "#D9F99F",
                  "&.Mui-checked": {
                    color: "#D9F99F",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedLightArray = isChecked
                      ? [...(prevState.color?.light || []), "#D9F99F"]
                      : prevState.color?.light.filter(
                          (color) => color !== "#D9F99F"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        light: updatedLightArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#FCA6A6",
                  backgroundColor: "#FCA6A6",
                  "&.Mui-checked": {
                    color: "#FCA6A6",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedLightArray = isChecked
                      ? [...(prevState.color?.light || []), "#FCA6A6"]
                      : prevState.color?.light.filter(
                          (color) => color !== "#FCA6A6"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        light: updatedLightArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#FEF9C3",
                  backgroundColor: "#FEF9C3",
                  "&.Mui-checked": {
                    color: "#FEF9C3",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedLightArray = isChecked
                      ? [...(prevState.color?.light || []), "#FEF9C3"]
                      : prevState.color?.light.filter(
                          (color) => color !== "#FEF9C3"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        light: updatedLightArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#A26107",
                  backgroundColor: "#A26107",
                  "&.Mui-checked": {
                    color: "#A26107",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedLightArray = isChecked
                      ? [...(prevState.color?.light || []), "#A26107"]
                      : prevState.color?.light.filter(
                          (color) => color !== "#A26107"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        light: updatedLightArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  "&.Mui-checked": {
                    color: "#FFFFFF",
                    backgroundColor: "#E0E0E0",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedLightArray = isChecked
                      ? [...(prevState.color?.light || []), "#E0E0E0"]
                      : prevState.color?.light.filter(
                          (color) => color !== "#E0E0E0"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        light: updatedLightArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#FDBA72",
                  backgroundColor: "#FDBA72",
                  "&.Mui-checked": {
                    color: "#FDBA72",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedLightArray = isChecked
                      ? [...(prevState.color?.light || []), "#FDBA72"]
                      : prevState.color?.light.filter(
                          (color) => color !== "#FDBA72"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        light: updatedLightArray,
                      },
                    };
                  });
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", margin: "12px" }}
          >
            <Typography sx={{ color: "gray", fontSize: "12px" }}>
              Pastel
            </Typography>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#7ED4FC",
                  backgroundColor: "#7ED4FC",
                  "&.Mui-checked": {
                    color: "#7ED4FC",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedPastelArray = isChecked
                      ? [...(prevState.color?.pastel || []), "#7ED4FC"]
                      : prevState.color?.pastel.filter(
                          (color) => color !== "#7ED4FC"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        pastel: updatedPastelArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#BF83FC",
                  backgroundColor: "#BF83FC",
                  "&.Mui-checked": {
                    color: "#BF83FC",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedPastelArray = isChecked
                      ? [...(prevState.color?.pastel || []), "#BF83FC"]
                      : prevState.color?.pastel.filter(
                          (color) => color !== "#BF83FC"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        pastel: updatedPastelArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#BEF263",
                  backgroundColor: "#BEF263",
                  "&.Mui-checked": {
                    color: "#BEF263",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedPastelArray = isChecked
                      ? [...(prevState.color?.pastel || []), "#BEF263"]
                      : prevState.color?.pastel.filter(
                          (color) => color !== "#BEF263"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        pastel: updatedPastelArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#FD6B6B",
                  backgroundColor: "#FD6B6B",
                  "&.Mui-checked": {
                    color: "#FD6B6B",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedPastelArray = isChecked
                      ? [...(prevState.color?.pastel || []), "#FD6B6B"]
                      : prevState.color?.pastel.filter(
                          (color) => color !== "#FD6B6B"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        pastel: updatedPastelArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#FDDF49",
                  backgroundColor: "#FDDF49",
                  "&.Mui-checked": {
                    color: "#FDDF49",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedPastelArray = isChecked
                      ? [...(prevState.color?.pastel || []), "#FDDF49"]
                      : prevState.color?.pastel.filter(
                          (color) => color !== "#FDDF49"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        pastel: updatedPastelArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#864E0E",
                  backgroundColor: "#864E0E",
                  "&.Mui-checked": {
                    color: "#864E0E",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedPastelArray = isChecked
                      ? [...(prevState.color?.pastel || []), "#864E0E"]
                      : prevState.color?.pastel.filter(
                          (color) => color !== "#864E0E"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        pastel: updatedPastelArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#9D9D9D",
                  backgroundColor: "#9D9D9D",
                  "&.Mui-checked": {
                    color: "#9D9D9D",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedPastelArray = isChecked
                      ? [...(prevState.color?.pastel || []), "#9D9D9D"]
                      : prevState.color?.pastel.filter(
                          (color) => color !== "#9D9D9D"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        pastel: updatedPastelArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#FB923C",
                  backgroundColor: "#FB923C",
                  "&.Mui-checked": {
                    color: "#FB923C",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedPastelArray = isChecked
                      ? [...(prevState.color?.pastel || []), "#FB923C"]
                      : prevState.color?.pastel.filter(
                          (color) => color !== "#FB923C"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        pastel: updatedPastelArray,
                      },
                    };
                  });
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", margin: "12px" }}
          >
            <Typography sx={{ color: "gray", fontSize: "12px" }}>
              Normal
            </Typography>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#FB923C",
                  backgroundColor: "#FB923C",
                  "&.Mui-checked": {
                    color: "#FB923C",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedNormalArray = isChecked
                      ? [...(prevState.color?.normal || []), "#FB923C"]
                      : prevState.color?.normal.filter(
                          (color) => color !== "#FB923C"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        normal: updatedNormalArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#9234EA",
                  backgroundColor: "#9234EA",
                  "&.Mui-checked": {
                    color: "#9234EA",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedNormalArray = isChecked
                      ? [...(prevState.color?.normal || []), "#9234EA"]
                      : prevState.color?.normal.filter(
                          (color) => color !== "#9234EA"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        normal: updatedNormalArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#82CB15",
                  backgroundColor: "#82CB15",
                  "&.Mui-checked": {
                    color: "#82CB15",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedNormalArray = isChecked
                      ? [...(prevState.color?.normal || []), "#82CB15"]
                      : prevState.color?.normal.filter(
                          (color) => color !== "#82CB15"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        normal: updatedNormalArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#EF4343",
                  backgroundColor: "#EF4343",
                  "&.Mui-checked": {
                    color: "#EF4343",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedNormalArray = isChecked
                      ? [...(prevState.color?.normal || []), "#EF4343"]
                      : prevState.color?.normal.filter(
                          (color) => color !== "#EF4343"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        normal: updatedNormalArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#E7B008",
                  backgroundColor: "#E7B008",
                  "&.Mui-checked": {
                    color: "#E7B008",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedNormalArray = isChecked
                      ? [...(prevState.color?.normal || []), "#E7B008"]
                      : prevState.color?.normal.filter(
                          (color) => color !== "#E7B008"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        normal: updatedNormalArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#864E0E",
                  backgroundColor: "#864E0E",
                  "&.Mui-checked": {
                    color: "#864E0E",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedNormalArray = isChecked
                      ? [...(prevState.color?.normal || []), "#864E0E"]
                      : prevState.color?.normal.filter(
                          (color) => color !== "#864E0E"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        normal: updatedNormalArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#5C5C5C",
                  backgroundColor: "#5C5C5C",
                  "&.Mui-checked": {
                    color: "#5C5C5C",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedNormalArray = isChecked
                      ? [...(prevState.color?.normal || []), "#5C5C5C"]
                      : prevState.color?.normal.filter(
                          (color) => color !== "#5C5C5C"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        normal: updatedNormalArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#F67416",
                  backgroundColor: "#F67416",
                  "&.Mui-checked": {
                    color: "#F67416",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedNormalArray = isChecked
                      ? [...(prevState.color?.normal || []), "#F67416"]
                      : prevState.color?.normal.filter(
                          (color) => color !== "#F67416"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        normal: updatedNormalArray,
                      },
                    };
                  });
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", margin: "12px" }}
          >
            <Typography sx={{ color: "gray", fontSize: "12px" }}>
              Dark
            </Typography>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#0369A0",
                  backgroundColor: "#0369A0",
                  "&.Mui-checked": {
                    color: "#0369A0",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedDarkArray = isChecked
                      ? [...(prevState.color?.dark || []), "#0369A0"]
                      : prevState.color?.dark.filter(
                          (color) => color !== "#0369A0"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        dark: updatedDarkArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#6A21A6",
                  backgroundColor: "#6A21A6",
                  "&.Mui-checked": {
                    color: "#6A21A6",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedDarkArray = isChecked
                      ? [...(prevState.color?.dark || []), "#6A21A6"]
                      : prevState.color?.dark.filter(
                          (color) => color !== "#6A21A6"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        dark: updatedDarkArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#4C7B0F",
                  backgroundColor: "#4C7B0F",
                  "&.Mui-checked": {
                    color: "#4C7B0F",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedDarkArray = isChecked
                      ? [...(prevState.color?.dark || []), "#4C7B0F"]
                      : prevState.color?.dark.filter(
                          (color) => color !== "#4C7B0F"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        dark: updatedDarkArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#BA1C1C",
                  backgroundColor: "#BA1C1C",
                  "&.Mui-checked": {
                    color: "#BA1C1C",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedDarkArray = isChecked
                      ? [...(prevState.color?.dark || []), "#BA1C1C"]
                      : prevState.color?.dark.filter(
                          (color) => color !== "#BA1C1C"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        dark: updatedDarkArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#C88A04",
                  backgroundColor: "#C88A04",
                  "&.Mui-checked": {
                    color: "#C88A04",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedDarkArray = isChecked
                      ? [...(prevState.color?.dark || []), "#C88A04"]
                      : prevState.color?.dark.filter(
                          (color) => color !== "#C88A04"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        dark: updatedDarkArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#733F12",
                  backgroundColor: "#733F12",
                  "&.Mui-checked": {
                    color: "#733F12",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedDarkArray = isChecked
                      ? [...(prevState.color?.dark || []), "#733F12"]
                      : prevState.color?.dark.filter(
                          (color) => color !== "#733F12"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        dark: updatedDarkArray,
                      },
                    };
                  });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "2px" }}>
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#000000",
                  backgroundColor: "#000000",
                  "&.Mui-checked": {
                    color: "#000000",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedDarkArray = isChecked
                      ? [...(prevState.color?.dark || []), "#000000"]
                      : prevState.color?.dark.filter(
                          (color) => color !== "#000000"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        dark: updatedDarkArray,
                      },
                    };
                  });
                }}
              />
              <Checkbox
                sx={{
                  ...companyColors,
                  color: "#C03F0C",
                  backgroundColor: "#C03F0C",
                  "&.Mui-checked": {
                    color: "#C03F0C",
                    backgroundColor: "transparent",
                  },
                }}
                size="large"
                onChange={(e) => {
                  setState((prevState) => {
                    const isChecked = e.target.checked;
                    const updatedDarkArray = isChecked
                      ? [...(prevState.color?.dark || []), "#C03F0C"]
                      : prevState.color?.dark.filter(
                          (color) => color !== "#C03F0C"
                        );

                    return {
                      ...prevState,
                      color: {
                        ...prevState.color,
                        dark: updatedDarkArray,
                      },
                    };
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
