import React from "react";
import { Box } from "@mui/material";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Main from "../Cheesecakes/main";
import Boxes from "./cheesecakes";

export default function Cheesecakes() {
  return (
    <>
      <Header></Header>
      <NewTopAppBar id="top"></NewTopAppBar>
      <Main></Main>
      <Box
        sx={{ backgroundColor: "#fd7762cc" }}
        data-testid="cheesecakes-boxes"
      >
        <Boxes></Boxes>
      </Box>

      <Footer bgColor="#fff"></Footer>
    </>
  );
}
