import {
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  
} from "@mui/material";

import {
  AccountCircle,
  EmailRounded,
} from '@mui/icons-material';

const CustomerInformation = ({ customer }) => {
  return (
    <Box sx={{ 
      display: "flex", 
      flexFlow: "column", 
    }}>
      <Box sx={{ display: "flex", justifyContent: "center", margin: "24px" }}>
        <Typography>
          <h3>CUSTOMER INFORMATION</h3>
        </Typography>
      </Box>

      <Box sx={{ 
        display: "flex", 
        justifyContent: "center", 
        padding: { xs: "8px 10px", md: "8px 40px" },
      }}>
        <TextField
          label="Name"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <AccountCircle />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={`${customer.firstName} ${customer.lastName}`}
          style={{ width: "100%"}}
          readOnly
        />
      </Box>

      <Box sx={{ 
        display: "flex", 
        justifyContent: "center", 
        padding: { xs: "8px 10px", md: "8px 40px" },
      }}>
        <TextField
          label="Email"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <EmailRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={`${customer.email}`}
          style={{ width: "100%"}}
          readOnly
        />
      </Box>
    </Box>
  )
}


export default CustomerInformation