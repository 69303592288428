import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cakes: [],
  boxes: [],
}

export const corporateProductsSlice = createSlice({
  name: 'corporateProducts',
  initialState,
  reducers: {
    setCorporateCakes: (state, action) => {
      if (action.payload.corporateCakes) {
        return { 
          ...state,
          cakes: action.payload.corporateCakes
        }
      } else {
        return state;
      }
      
    },
    setCorporateBoxes: (state, action) => {
      if (action.payload.corporateBoxes) {
        return { 
          ...state,
          boxes: action.payload.corporateBoxes
        }
      } else {
        return state;
      }
    }
  }
})

export const { setCorporateCakes,  setCorporateBoxes } = corporateProductsSlice.actions

export default corporateProductsSlice.reducer