import React, { useState } from "react";
import { 
  FormControl, 
  InputLabel, 
  Select, 
  Checkbox, 
  MenuItem, 
  OutlinedInput, 
  ListItemText 
} from "@mui/material";

import {  selectColors, theme, selectColorsOutline } from "../theme";


const FlavorSelectMultiple = ({ flavorOptions, setState, state, maxSelections=-1, rounded=true }) => {

  const currentSelectedFlavorsArr = state.flavor.split(",").filter(n => n!=="")

  const handleMultipleValuesChange = (e) => {
    const newSelectedFlavorsArr = [...e.target.value]

    if (maxSelections == -1 || (newSelectedFlavorsArr.length <= maxSelections)) {
      setState({
        ...state,
        flavor: newSelectedFlavorsArr.join(",")
      })
    } 
    
  };

  return (

    <FormControl
      sx={{
        m: 1,
        width: { xs: 320, sm: "100%" },
        minWidth: 320,
      }}
    >
      <InputLabel id="flavor" sx={selectColors}>
        Choose Flavor
      </InputLabel>
      <Select
        labelId="flavor"
        id="flavor-multiple-checkbox"
        multiple
        value={currentSelectedFlavorsArr}
        onChange={handleMultipleValuesChange}
        input={<OutlinedInput label="Choose Flavor" />}
        renderValue={(selected) => selected.join(', ')}
        sx={{
          ...selectColorsOutline, 
          borderRadius: rounded ? "24px" : "5px",
          backgroundColor: "#F9F9F9",
        }}
        
      >

        {flavorOptions.map((flavor) => (
          <MenuItem key={flavor.name} value={flavor.name}>
            <Checkbox 
              checked={currentSelectedFlavorsArr.indexOf(flavor.name) > -1} 
              sx={{"&.Mui-checked":{color: theme.palette.primary.dark}}}
            />
            <ListItemText primary={flavor.name} />
          </MenuItem>
        ))}

      </Select>
    </FormControl>
  );
}

export default FlavorSelectMultiple