import React from "react";
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
} from "@mui/material";
  
import { selectColors } from "../../../theme";

const FlavorSelect = ({ flavorOptions, setState, state  }) => {


  const handleSingleValueChange = (e) => {
    setState({...state, flavor: e.target.value})
  }

  return (

    <FormControl
      sx={{
        m: 1,
        width: 340,
      }}
    >
      <InputLabel id="flavor" sx={selectColors}>
        Choose Flavor
      </InputLabel>
      <Select
        labelId="flavor"
        id="flavor"
        label="Choose Flavor"
        sx={{
          ...selectColors,
          borderRadius: "24px",
          backgroundColor: "#F9F9F9",
        }}
        onChange={handleSingleValueChange}
      >

        {
          flavorOptions.values.map(flavor => <MenuItem value={flavor.name}>{flavor.name}</MenuItem>)
        }

      </Select>
    </FormControl>
  );
}

export default FlavorSelect