export const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    let result = '';
    result += characters.charAt(Math.floor(Math.random() * characters.length)); // Start with a character
    for (let i = 0; i < 9; i++) {
      result += numbers.charAt(Math.floor(Math.random() * numbers.length)); // Add 9 random numbers
    }
    return result;
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}