/** @format */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
}));

const Fail = () => {
  let navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      navigate("/cart");
    }, 8000);
  });

  return (
    <Container>
      <Box>
        <Box>
          <Box marginTop={5} textAlign={"center"}>
            <CloseIcon style={{ fontSize: "7rem", color: "#dc3545" }} />
            <Typography
              className={classes.textStyle}
              sx={{ typography: { sm: "h3", xs: "h4" }, color: "#dc3545" }}
            >
              Payment Failed
            </Typography>
            <Typography
              className={classes.textStyle}
              variant={"h5"}
              style={{ color: "#888", marginTop: "2rem" }}
            >
              It looks like you have not completed the payment. You will now be
              redirected back to your shopping cart and you can try again!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Fail;
