import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: "",
  flavor: "",
  size: "8 inch",
  price: 60
}

export const cheesecakeSlice = createSlice({
  name: 'cheesecake',
  initialState,
  reducers: {
    sizeSelected: (state, action) => {
      state.size = action.payload;
    },
    resetToInitial: () => initialState
  }
})

export const selectCheesecake = state => state.cheesecake;

export const { sizeSelected } = cheesecakeSlice.actions

export default cheesecakeSlice.reducer