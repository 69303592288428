import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import axios from "axios";

import AntSwitch from "./antSwitch";
import CustomizeDialog from "./customizeDialog";
import { useDispatch } from "react-redux";
import { weddingSlice, packageTypeUpdated } from "../../redux/reducers/wedding";
import { resetToInitial } from "../../redux/reducers/cake";


import basicCakeImage from "../../assets/images/services/wedding-package-basic.png";
import premiumCakeImage from "../../assets/images/services/wedding-package-premium.png";
import dessertTableImage from "../../assets/images/services/wedding-package-dessert-table.png";
import BasicPackage from "./basicPackage";
import PremiumPackage from "./premiumPackage";

const useStyles = makeStyles((theme) => ({
    wrapperBox: {
        marginTop: "1rem !important",
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
    },
    heading: {
        fontSize: "3rem !important",
        textTransform: "uppercase !important",
    },
    packages: {
        display: "flex !important",
        flexDirection: "row !important",
        justifyContent: "space-between !important",
        alignItems: "center !important",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column !important",
            width: "80% !important",
        },
    },
    package: {
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        borderRadius: "20px !important",
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        padding: "2rem !important",
        width: "100% !important",
        margin: "2rem 4rem 0 4rem !important",
        position: "relative !important"
    },
    standardCakeImg: {
        width: "100% !important",
        height: "4rem !important",
        objectFit: "contain !important",
        padding: "2rem 0 1rem 2rem"
    },
    flexStyle: {
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
    },
    icon: {
        color: "#fd7762 !important"
    },
    basicBtn: {
        backgroundColor: "#ffddd8 !important",
        color: "#fd7762 !important",
        borderRadius: "50px !important",
        width: "20rem !important",
        padding: "1rem !important",
        textTransform: "capitalize !important",
        marginBottom: "6rem !important",
        "&:hover": {
            backgroundColor: "white !important",
            color: "#fd7762 !important",
            border: "2px solid #fd7762 !important"
        },
    },
    primaryBtn: {
        backgroundColor: "#fd7762 !important",
        color: "white !important",
        borderRadius: "50px !important",
        width: "20rem !important",
        padding: "1rem !important",
        textTransform: "capitalize !important",
        marginBottom: "6rem !important",
        "&:hover": {
            backgroundColor: "white !important",
            color: "#fd7762 !important",
            border: "2px solid #fd7762 !important"
        },
    },
    setup: {
        backgroundColor: "#fbfbfb !important",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        padding: "2rem 0 2rem 0",
        borderRadius: "0 0 20px 20px !important",
    },
    premium: {
        backgroundColor: "#fd7762 !important",
        position: "absolute !important",
        top: 0,
        left: 0,
        width: "100% !important",
        height: "1rem !important",
        borderRadius: "30px 30px 0 0 !important",
    },
    span: {
        fontWeight: "bold !important",
        textTransform: "uppercase !important",
        margin: "0 0.3rem 0 0.3rem !important"
    },
    customDialog: {
        overflowY: "auto !important",
        "&::-webkit-scrollbar": {
            display: "none !important"
        },
        "-ms-overflow-style": "none !important",
        "scrollbar-width": "none !important",
        margin: "16px"
    }
}));

export default function ChoosePackage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);

    const [setupService, setSetupService] = useState(null)

    useEffect(async()=>{
        if (!setupService) {
            const res = await axios.get("/api/app/data/addon-products/type?type=wedding-setup-service");
            setSetupService({
                name: res.data[0].name,
                price: res.data[0].basePrices && res.data[0].basePrices[0].price,
            })
        }
    }, [setupService])

    const handleOpen = (packageName) => {
        dispatch(packageTypeUpdated(packageName))
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        dispatch(resetToInitial());
        dispatch(weddingSlice.actions.resetToInitial());  // Reset the state
    };

    return (
        <Box className={classes.wrapperBox}>
            <Typography align="center" className={classes.heading}>
                choose a package
            </Typography>
            
            {
                setupService &&
                <Box className={classes.packages}>
                    <BasicPackage handleOpen={handleOpen} setupService={setupService}/>
                    <PremiumPackage handleOpen={handleOpen} setupService={setupService}/>
                </Box>
            }
            
            <Dialog 
                open={openDialog} 
                onClose={handleClose} 
                maxWidth={"lg"} 
                PaperProps={{
                    className: classes.customDialog
                }}
            >
                <CustomizeDialog handleClose={handleClose} setupService={setupService}/>
            </Dialog>
        </Box>
    );
}
