import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import CircularProgress from "@mui/material/CircularProgress";
import { Container, Button, Typography, Box } from "@mui/material";

import QuantitySelector from "../../components/quantitySelector";
import { updateCart } from "../../redux/reducers/cart.js";

const useStyles = makeStyles((theme) => ({
  flexStyle: {
    display: "flex !important",
    flexDirection: "column !important",
    alignItems: "center !important",
  },
  primaryBtn: {
    backgroundColor: "white !important",
    color: "#fd7762 !important",
    borderRadius: "50px !important",
    width: "100% !important",
    padding: "1rem !important",
    textTransform: "capitalize !important",
    border: "2px solid #fd7762 !important",
    marginTop: "2rem !important",
    "&:hover": {
      backgroundColor: "#fd7762 !important",
      color: "white !important",
      border: "2px solid #fd7762 !important",
    },
  },
  button: {
    ...theme.containedButton,
    width: "250px !important",
    marginBottom: "0.5rem !important",
    marginTop: "2rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  price1: {
    fontSize: "1.2rem !important",
    fontWeight: "bold !important",
  },
  price2: {
    fontSize: "1rem !important",
  },
}));

export default function DessertCard({ dessert }) {

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const tempEmail = localStorage.getItem("tempEmail");

  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [quantity, setQuantity] = useState(dessert.itemsPerSet);  

  const timer = useRef();

  const quantitySelectorStyle = {
    icon: {
      fontSize: 15,
      color: "#fd7762",
    },
    quantityText: {
      width: "20px",
      height: "20px",
      fontSize: "1.2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "0 !important",
      marginRight: "0 !important",
      backgroundColor: "white",
      color: "#fd7762",
    },
  };

  useEffect(() => {
    setTimeout(function () {
      setSuccess(false);
    }, 5000);
  }, [success]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity);
  };

  const addToCart = async () => {

    let dessertToAdd = {
      id: Date.now(),
      dessertId: dessert._id,
      quantity,
      minQTY: dessert.itemsPerSet,
      type: "regular-order-dessert",
      name: dessert.name,
      flavor: dessert.flavor ? dessert.flavor : "",
      imgPath: dessert.images.find((img) => img.name === "regular-order-img")
        .value,
      price: dessert.basePrice,
      itemsPerSet: dessert.itemsPerSet,
    };

    try {
      setLoading(true);
      setSuccess(false);
      const res = await axios.post("/api/cart/addProduct/", {
        userEmail: currentUser ? currentUser.email : tempEmail,
        product: dessertToAdd,
      });

      if (res.status === 200) {
        window.setTimeout(() => {
          dispatch(updateCart(res.data));
          setLoading(false);
          setSuccess(true);
        }, 1000);
      }
    } catch (error) {
      alert(
        "We could not add the product. Please make sure all required fields are provided."
      );
    }
  };

  return (
    <Container
      sx={{
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        minWidth: "310px",
      }}>
      <Typography sx={{ fontWeight: "bold", mt: 1, fontSize: "18px" }}>
        {`${
          dessert.name.toLowerCase() !== dessert.category.toLowerCase()
            ? dessert.name
            : ""
        } ${dessert.flavor ? `(${dessert.flavor})` : ""}`}
      </Typography>

      <Box
        sx={{
          display: "flex !important",
          flexDirection: "row !important",
          justifyContent: "space-between !important",
          alignItems: "center !important",
          width: "100% !important",
          mt: 2,
        }}>
        <QuantitySelector
          itemsPerSet={dessert.itemsPerSet}
          handleQuantityChange={handleQuantityChange}
          setMinimumOnly={true}
          initialQuantity={dessert.itemsPerSet}
          styleObj={quantitySelectorStyle}
          minQTY={dessert.itemsPerSet}
        />
        <Box>
          <span className={classes.price1}>{`$${dessert.basePrice.toFixed(
            2
          )}/`}</span>
          <span className={classes.price2}>each</span>
        </Box>
      </Box>

      {/* <Button className={classes.primaryBtn} onClick={addToCart}>Add To Cart</Button> */}

      <Box
        sx={{
          position: "relative",
          width: "100%",
          marginBottom: "3rem !important",
        }}>
        {
          <Button
            size={"medium"}
            className={classes.primaryBtn}
            onClick={addToCart}>
            <Typography className={classes.textStyle}>Add to Cart</Typography>
          </Button>
        }

        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "4px !important",
              marginLeft: "65px !important",
              color: "#fff",
            }}
          />
        )}

        {success && (
          <Typography
            className={classes.textStyle}
            sx={{ color: "green", textAlign: "center", marginTop: "16px" }}>
            Added!
          </Typography>
        )}
      </Box>
    </Container>
  );
}
