/** @format */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import listSorting from "../../assets/icons/listSorting.png";
import listFiltering from "../../assets/icons/listFiltering.png";
import collapse from "../../assets/icons/collapse.png";
import activeFilterView from "../../assets/icons/activeFilter.png";
import inactiveFilterView from "../../assets/icons/inactiveFilter.png";
import circleRows from "../../assets/icons/circleRows.png";
import circleRowsPink from "../../assets/icons/circleRowsPink.png";
import CakeListItem from "./cakeListItem";
import FilterMenu from "./FilterMenu";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex !important",
    flexDirection: "row !important",
    // width: "100%",
    justifyContent: "flex-start !important",
    margin: "-10px 0 10px 0 !important",
  },
  sortBar: {
    display: "flex",
    flexDirection: "row !important",
    width: "100% !important",
    marginBottom: "30px",
  },
  barItem: {
    position: "relative !important",
    display: "flex",
    flexDirection: "row !important",
    alignItems: "center !important",
    justifyContent: "flex-start !important",
    padding: "0 0 0 20px !important",
  },
  barItemIcon: {
    height: "16px !important",
    padding: "0 15px 0 15px !important",
  },
  sortBarLabel: {
    ...theme.textStyle,
    fontSize: "15px !important",
    fontWeight: "700 !important",
  },

  filterMenuWrapper: {
    position: "absolute !important",
    top: "100% !important",
    left: "0 !important",
    width: "300% !important",
    zIndex: "2 !important",
  },

  sortMenuWrapper: {
    position: "absolute !important",
    top: "100% !important",
    left: "0 !important",
    // width: "250% !important",
    zIndex: "2 !important",
  },

  barIconWrapper: {
    display: "flex",
    cursor: "pointer",
  },
  sortMenu: {
    display: "flex",
    flexDirection: "column !important",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "20px !important",
    width: "100% !important",
    padding: "15px 15px 15px 0 !important",
    margin: "5px !important",
    cursor: "default",
  },
  sortMenuItem: {
    ...theme.textStyle,
    fontSize: "14px !important",
    fontWeight: "400 !important",
    margin: "10px auto 10px 20px !important",
    cursor: "pointer",
  },
  filterMenu: {
    display: "flex",
    flexDirection: "column !important",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "20px !important",
    width: "100% !important",
    padding: "15px 40px 15px 40px !important",
    margin: "5px !important",
    cursor: "default",
  },
  filterMenuSection: {
    display: "flex",
    flexDirection: "column",
  },
  filterMenuHeader: {
    ...theme.textStyle,
    fontSize: "15px !important",
    fontWeight: "700 !important",
  },
  viewBar: {
    display: "flex",
    flexDirection: "row !important",
    justifyContent: "flex-end !important",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  viewBarItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center !important",
    marginRight: "25px !important",
    cursor: "pointer",
  },

  viewBarItemLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center !important",
    marginRight: "25px !important",
    fontSize: "15px !important",
    fontWeight: "700 !important",
  },
}));

export default function Cake() {
  const classes = useStyles();
  const [cakes, setCakes] = useState([]);
  const [sortCollapsed, setSortCollapsed] = useState(false);
  const [filterCollapsd, setFilterCollapsed] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [sortList, setSortList] = useState("");
  const [filterQuery, setFilterQuery] = useState({});
  const [skipCakes, setSkipCakes] = useState(0);
  const [dataEnd, setDataEnd] = useState(false);
  const theme = useTheme();
  const mediaMatch = useMediaQuery(theme.breakpoints.up("sm"));

  const sortHover = {
    padding: "0 15px 0 15px",
    width: "16px",
    height: "auto",
    transform: sortCollapsed ? "rotate(0)" : "rotate(-90deg)",
  };
  const filterHover = {
    padding: "0 15px 0 15px",
    width: "16px",
    height: "auto",
    transform: filterCollapsd ? "rotate(0)" : "rotate(-90deg)",
  };

  useEffect(async () => {
    fetchMoreCakes();
  }, [filterQuery]);

  switch (sortList) {
    case "Popularity":
      //popularity sorting function can go here.
      break;
    case "Low":
      cakes.sort((a, b) => {
        return a.startingPrice - b.startingPrice;
      });
      break;
    case "High":
      cakes.sort((a, b) => {
        return b.startingPrice - a.startingPrice;
      });
      break;
    default:
  }

  const fetchMoreCakes = async () => {
    try {
      const res = await axios.get("/api/app/data/allCustomCakes");

      const cakesPerFetch = 6;
      const fq = [];
      let filteredCakesBuff = [];

      for (const propertyName in filterQuery) {
        if (filterQuery[propertyName]) fq.push(propertyName);
      }

      if (res.status === 200) {
        for (let k = 0; k < res.data.length; k++) {
          let queriesMet = false;
          if (fq.length !== 0) {
            const allFilteredCriteria = [
              ...res.data[k].filters.designFilterTags,
              ...res.data[k].filters.occasionFilterTags,
              ...res.data[k].filters.decorationFilterTags,
            ];

            for (let i = 0; i < allFilteredCriteria.length; i++) {
              for (let j = 0; j < fq.length; j++) {
                if (allFilteredCriteria[i] == fq[j].toLowerCase()) {
                  queriesMet = true;
                  filteredCakesBuff = [...filteredCakesBuff, res.data[k]];
                  break;
                }
              }
              if (queriesMet) {
                break;
              }
            }
          } else {
            filteredCakesBuff = [...filteredCakesBuff, res.data[k]];
          }
        }

        if (filteredCakesBuff.length >= skipCakes + cakesPerFetch) {
          setTimeout(
            () =>
              setCakes(filteredCakesBuff.slice(0, skipCakes + cakesPerFetch)),
            500
          );
          setSkipCakes(skipCakes + cakesPerFetch);
        } else {
          setTimeout(() => setCakes(filteredCakesBuff), 500);
          setDataEnd(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <Grid container className={classes.sortBar} data-testid="specialtycakes-filter-sort">
        <Grid
          item
          xs={6}
          sm={2}
          style={{ display: toggleFilter ? "none" : "flex" }}
        >
          <Box
            className={classes.barItem}
            onMouseEnter={() => {
              setFilterCollapsed(true);
            }}
            onMouseLeave={() => {
              setFilterCollapsed(false);
            }}
            onClick={() => setFilterCollapsed(!filterCollapsd)}
          >
            <Box className={classes.barIconWrapper}>
              <FilterAltOutlinedIcon fontSize="medium" sx={{mr: 1}} />
              <Typography className={classes.sortBarLabel} sx={{mr:1}}>Filter</Typography>
              {filterCollapsd ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
            </Box>

            <Box
              className={classes.filterMenuWrapper}
              style={{
                display: filterCollapsd ? "flex" : "none",
              }}
              data-testid="specialtycakes-filter-container"
            >
              <FilterMenu query={filterQuery} setQuery={setFilterQuery} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} sm={4}>
          <Box
            className={classes.barItem}
            onMouseEnter={() => {
              setSortCollapsed(true);
            }}
            onMouseLeave={() => {
              setSortCollapsed(false);
            }}
            onClick={() => setSortCollapsed(!sortCollapsed)}
          >
            <Box className={classes.barIconWrapper}>
              <FilterListOutlinedIcon sx={{mr: 1}}/>
              <Typography className={classes.sortBarLabel} sx={{mr: 1}}>Sort by</Typography>
              {sortCollapsed ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/> }
            </Box>
            <Box
              className={classes.sortMenuWrapper}
              style={{ display: sortCollapsed ? "flex" : "none" }}
              data-testid="specialtycakes-sort-container"
            >
              <Paper className={classes.sortMenu}>
                {/* <Typography
                  className={classes.sortMenuItem}
                  onClick={() => {
                    setSortList("Popularity");
                    setSortCollapsed(false);
                  }}
                >
                  Popularity
                </Typography> */}
                <Typography
                  className={classes.sortMenuItem}
                  onClick={() => {
                    setSortList("Low");
                    setSortCollapsed(false);
                  }}
                >
                  Price Low to High
                </Typography>
                <Typography
                  className={classes.sortMenuItem}
                  onClick={() => {
                    setSortList("High");
                    setSortCollapsed(false);
                  }}
                >
                  Price High to Low
                </Typography>
              </Paper>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={toggleFilter ? 8 : 6} className={classes.viewBar}>
          <Typography className={classes.viewBarItemLabel}>
            Choose a View
          </Typography>
          <div
            className={classes.viewBarItem}
            onClick={() => setToggleFilter(true)}
          >
            <img src={toggleFilter ? activeFilterView : inactiveFilterView} />
          </div>
          <div
            className={classes.viewBarItem}
            onClick={() => setToggleFilter(false)}
          >
            <img src={toggleFilter ? circleRows : circleRowsPink} />
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.wrapper} data-testid="specialtycakes-grid">
        <Grid
          item
          xs={4}
          style={{
            display: toggleFilter && mediaMatch ? "flex" : "none",
          }}
        >
          <FilterMenu query={filterQuery} setQuery={setFilterQuery} />
        </Grid>
        <Grid item xs={toggleFilter && mediaMatch ? 8 : 12}>
          <InfiniteScroll
            dataLength={cakes.length}
            next={fetchMoreCakes}
            hasMore={!dataEnd}
            loader={<h4>Loading...</h4>}
            // endMessage={<h4>Done</h4>}
          >
            <Grid container className={classes.wrapper}>
              {cakes.map((cake) => (
                <CakeListItem showFilter={toggleFilter} item={cake} />
              ))}
            </Grid>
          </InfiniteScroll>
        </Grid>
      </Grid>
    </>
  );
}
