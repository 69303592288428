import React, { useState } from "react";
import { Container, Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import {
        selectCakeChosen,
        selectSizeChosen,
        selectFlavorChosen,
        selectColorChosen,
        selectSetup,
        removeSetupService,
    } from "../../redux/reducers/wedding";
import { selectCake } from "../../redux/reducers/cake.js";
import { updateCart } from "../../redux/reducers/cart.js";
import { selectDesserts, removeDessert } from "../../redux/reducers/wedding.js";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const useStyles = makeStyles((theme) => ({
    flexStyle: {
        display: "flex !important",
        flexDirection: "row !important",
        alignItems: "center !important",
    },
    primaryBtn: {
        backgroundColor: "white !important",
        color: "#fd7762 !important",
        borderRadius: "50px !important",
        width: "50% !important",
        padding: "1rem !important",
        textTransform: "capitalize !important",
        border: "2px solid #fd7762 !important",
        marginTop: "1rem !important",
        "&:hover": {
            backgroundColor: "#fd7762 !important",
            color: "white !important",
            border: "2px solid #fd7762 !important"
        },
    },
}));

export default function Review({ handleClose }) {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const tempEmail = localStorage.getItem("tempEmail");

    const classes = useStyles();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const selectedCake = useSelector(selectCake);
    const selectedSize = useSelector(selectSizeChosen);
    const selectedFlavor = useSelector(selectFlavorChosen);
    const selectedColor = useSelector(selectColorChosen);
    const selectedSetup = useSelector(selectSetup);

    const selectedDesserts = useSelector(selectDesserts)


    const getPrice = () => {
        let price = selectedCake.currentPrice;

        if (selectedSetup) {
            price += selectedSetup.price
        }

        selectedDesserts.forEach(dessert => price += (dessert.basePrice*dessert.quantity))

        return price;
    }

    const removeSetup = () => {
        dispatch(removeSetupService())
    }

    const handleRemoveDessert = (dessert) => {
        dispatch(removeDessert(dessert))
    }

    const addToCart = async() => {

        if (!loading) {
            setLoading(true);

            let productToAdd = {
                id: Date.now(),
                cakeId: selectedCake.cakeId,
                quantity: 1,
                type: "wedding-cake",
                name: selectedCake.name || "Wedding Cake",
                size: selectedCake.size.name,
                imgPath: selectedCake.imgPath,  
                flavor: selectedCake.flavor.map(f => f.name).join(", "),
                price: selectedCake.currentPrice,
            };

            if (selectedCake.customizationOptions.colorTheme)  {
                productToAdd.customizations = [{
                    parentDisplayName: "Colors",
                    selection: selectedCake.customizationOptions.colorTheme.colorName
                }]
            }
        
            try {
                const res = await axios.post("/api/cart/addProduct/", {
                    userEmail: currentUser ? currentUser.email : tempEmail,
                    product: productToAdd,
                });
        
                if (res.status === 200) {
                    window.setTimeout(() => {
                        dispatch(updateCart(res.data));
                        setLoading(false);
                    }, 1000);
                }
            } catch (error) {
                console.error(error)
                alert("We could not add the product. Please make sure all required fields are provided.");
                setLoading(false);
            }

            selectedDesserts.forEach(async(dessert) => {

                let dessertToAdd = {
                    id: Date.now(),
                    dessertId: dessert._id,
                    quantity: dessert.quantity,
                    type: "wedding-dessert",
                    name: dessert.name,
                    imgPath: dessert.images.find(img=>img.name==="wedding-package-img").value,  
                    price: dessert.basePrice,
                    itemsPerSet: dessert.itemsPerSet,
                };
    
                try {
                    const res = await axios.post("/api/cart/addProduct/", {
                        userEmail: currentUser ? currentUser.email : tempEmail,
                        product: dessertToAdd,
                    });
            
                    if (res.status === 200) {
                        window.setTimeout(() => {
                            dispatch(updateCart(res.data));
                            setLoading(false);
                        }, 1000);
                    }
                } catch (error) {
                    console.error(error)
                    alert("We could not add the product. Please make sure all required fields are provided.");
                    setLoading(false);
                }
            })


            if (selectedSetup) {
                let serviceToAdd = {
                    id: Date.now(),
                    quantity: 1,
                    type: "wedding-setup-service",
                    name: selectedSetup.name,
                    price: selectedSetup.price,
                };

                try {
                    const resp = await axios.post("/api/cart/addProduct/", {
                        userEmail: currentUser ? currentUser.email : tempEmail,
                        product: serviceToAdd,
                    });
            
                    if (resp.status === 200) {
    
                        window.setTimeout(() => {
                            dispatch(updateCart(resp.data));
                            setLoading(false);
                        }, 1000);
                    }
                } catch (error) {
                    console.error(error)
                    alert("We could not add the Wedding Setup Service. Please make sure all required fields are provided.");
                    setLoading(false);
                }

            }

            handleClose();
            
        }

    }

    return (
        <Container sx={{
            width: "100%",
            display: "flex !important",
            flexDirection: "column !important",
            justifyContent: "center !important",
            alignItems: "center !important"
            }}>
            <Box className={classes.flexStyle}
                sx={{
                    backgroundColor: "#defce9 !important",
                    borderRadius: "2rem !important",
                    padding: "1rem 2rem !important"
                 }}
            >
                <CheckCircleIcon sx={{color: "#219653 !important", pr: 1}}/>
                <Typography sx={{color: "#219653 !important"}}>Package is complete</Typography>
            </Box>
            
            <Box sx={{ borderTop: "1px solid lightGrey", borderBottom: "1px solid lightGrey", mt: 6, p: 2, width: "100% !important", justifyContent: "space-between !important" }} className={classes.flexStyle}>
                <Box>
                    <Typography>{selectedCake.name || "Wedding Cake"}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "bold !important" }}>Size:</Typography>
                        <Typography sx={{ ml: 1 }}>{selectedCake.size.name}</Typography>
                    </Box>
                    {selectedCake.flavor.length > 0 && 
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold !important" }}>Flavor:</Typography>
                            <Typography sx={{ ml: 1 }}>{selectedCake.flavor.map(f => f.name).join(", ")}</Typography>
                        </Box>
                    }
                    {selectedCake.customizationOptions.colorTheme && 
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold !important" }}>Color:</Typography>
                            <Typography sx={{ ml: 1 }}>{selectedCake.customizationOptions.colorTheme.colorName}</Typography>
                        </Box>
                    }
                    {selectedCake.customizationOptions.flakeColor && 
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold !important" }}>Flake Color:</Typography>
                            <Typography sx={{ ml: 1 }}>{selectedCake.customizationOptions.flakeColor.colorName}</Typography>
                        </Box>
                    }
                </Box>
                <Box sx={{display: "flex !important", flexDirection: "row !important"}}>
                    <Typography sx={{ fontWeight: "bold !important", mr: 3 }}>
                        {`$${selectedCake.currentPrice.toFixed(2)} CAD`}
                    </Typography>
                    <div style={{ width: 24, height: 24}}></div>
                </Box>
            </Box>

            { 
                selectedDesserts.map(dessert => (
                    <Box sx={{ borderBottom: "1px solid lightGrey", mt: 0, p: 2, width: "100% !important", justifyContent: "space-between !important" }} className={classes.flexStyle}>
                        <Box>
                            <Typography>{dessert.name || "Wedding Cake"}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold !important" }}>Quantity:</Typography>
                                <Typography sx={{ ml: 1 }}>{dessert.quantity}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display: "flex !important", flexDirection: "row !important"}}>
                            <Typography sx={{ fontWeight: "bold !important", mr: 3 }}>
                                {`$${(dessert.basePrice * dessert.quantity).toFixed(2)} CAD`}
                            </Typography>
                            <DeleteOutlineOutlinedIcon sx={{ color: "grey !important" }} onClick={()=>handleRemoveDessert(dessert)}/>
                        </Box>
                    </Box>
                ))
            }

            {
                selectedSetup.price !==0 &&
                <Box sx={{ borderBottom: "1px solid lightGrey", p: 2, width: "100% !important", justifyContent: "space-between !important" }} className={classes.flexStyle}>
                    <Typography sx={{ fontWeight: "bold !important", fontSize: "1rem !important"}}>{selectedSetup.name}</Typography>
                    <Box sx={{display: "flex !important", flexDirection: "row !important"}}>
                        <Typography sx={{ fontWeight: "bold !important", mr: 3 }}>{`$${selectedSetup.price.toFixed(2)} CAD`}</Typography>
                        <DeleteOutlineOutlinedIcon sx={{ color: "grey !important" }} onClick={removeSetup}/>
                    </Box>
                </Box>
            }
            
            <Box sx={{ p: 2, width: "100% !important", justifyContent: "space-between !important" }} className={classes.flexStyle}>
                <Typography sx={{ fontWeight: "bold !important", fontSize: "1.5rem !important", color: "#fd7762"}}>TOTAL</Typography>
                <div style={{ display: "flex"}}>
                    <Typography sx={{ 
                        fontWeight: "bold !important", 
                        color: "#fd7762",
                        marginRight: "24px"
                    }}>
                        {`$${getPrice().toFixed(2)} CAD`}
                    </Typography>
                    <div style={{ width: 24, height: 24}}></div>
                </div>
            </Box>

            <Button className={classes.primaryBtn} onClick={addToCart}>Add To Cart</Button>
        </Container>
    );
}
