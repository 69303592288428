/** @format */

import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Box,
  Checkbox,
  Button,
  List,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Available from "./schedule/available";
import Booked from "./schedule/booked";
import Past from "./schedule/past";
import axios from "axios";
import { selectProducts } from "../../redux/reducers/cart";

const useStyles = makeStyles((theme) => ({
  months: {
    maxHeight: "100%",
    display: "flex",
    overflow: "auto",
    marginBottom: "30px !important",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  monthOutlinedBtn: {
    ...theme.outlinedButton,
  },
  monthContainedBtn: {
    ...theme.containedButton,
  },
  infoCards: {
    minHeight: "280px",
    [theme.breakpoints.up("sm")]: {
      minHeight: "170px",
    },
  },
  textStyle: { ...theme.textStyle },
}));

function Availability(props) {
  const classes = useStyles();
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "june",
    "july",
    "aug",
    "sept",
    "oct",
    "nov",
    "dec",
  ];
  const [customCakeIsSelected, setCustomCakeIsSelected] = useState(false);
  const [standardCakeIsSelected, setStandardCakeIsSelected] = useState(false);
  const [macaronIsSelected, setMacaronIsSelected] = useState(false);
  const [assortmentIsSelected, setAssortmentIsSelected] = useState(false);
  const [customerPlaying, setCustomerPlaying] = useState(false);
  const [availability, setAvailability] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const { products } = props;
  const d = new Date();
  const currentDay = d.getDate();
  const currentMonthIndex = d.getMonth();
  const [selectedMonth, setSelectedMonth] = useState(months[currentMonthIndex]);
  const productsInCart = useSelector(selectProducts);
  let specialtyCakeExists = false;

  for (let i = 0; i < productsInCart.length; i++) {
    let item = productsInCart[i];
    if (item.type === "custom-cake" || item.type === "specialtyCake") {
      specialtyCakeExists = true;
    }
  }

  useEffect(() => {
    async function fetchAvailability() {
      const res = await axios.get("/api/app/data/availability");
      setAvailability(res.data);
      setDataIsReady(true);
    }

    fetchAvailability();

    if (!customerPlaying) {
      products.map((p) => {
        if (p.type === "custom-cake") {
          return setCustomCakeIsSelected(true);
        }
        if (p.type === "standardCake") {
          return setStandardCakeIsSelected(true);
        }
        if (p.type === "macaron") {
          return setMacaronIsSelected(true);
        }
        if (p.type === "assortment") {
          return setAssortmentIsSelected(true);
        }
      });
    }
  }, []);

  const handleChange = (e, id) => {
    setCustomerPlaying(true);
    if (id === "001") {
      setCustomCakeIsSelected(e.target.checked);
    }
    if (id === "002") {
      setStandardCakeIsSelected(e.target.checked);
    }
    if (id === "003") {
      setMacaronIsSelected(e.target.checked);
    }
    if (id === "004") {
      setAssortmentIsSelected(e.target.checked);
    }
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };

  return (
    <Box
      sx={{
        mt: 5,
        p: 2,
        textAlign: "center",
      }}
    >
      <Typography
        className={classes.textStyle}
        variant="h3"
        sx={{ mb: 5, color: "#fd7762" }}
      >
        Pick Date & Time Slot
      </Typography>

      <Typography className={classes.textStyle} variant="h6">
        Year of 2023
      </Typography>
      <List className={classes.months} data-testid="month-list">
        {months.map((month) => {
          return (
            <Button
              className={
                selectedMonth === month
                  ? classes.monthContainedBtn
                  : classes.monthOutlinedBtn
              }
              sx={{ mr: 2 }}
              onClick={() => handleMonthChange(month)}
            >
              <Typography className={classes.textStyle}>{month}</Typography>
            </Button>
          );
        })}
      </List>

      {dataIsReady ? (
        <Grid container data-testid="month-calendar">
          {availability.map((e) => {
            if (e.isActive && e.month === selectedMonth && e.month !== "sept") {
              return e.days.map((day) => {
                // check if the day of the month is in the past
                if (
                  (day.day < currentDay &&
                    months[currentMonthIndex] === e.month) ||
                  e.monthIndex < currentMonthIndex
                ) {
                  return <Past month={e.month} day={day.day}></Past>;
                }
                // else if (specialtyCakeExists) {
                //   return (
                //     <Box>WE ARE NOT TAKING ORDERS FOR SPECIALTY CAKES</Box>
                //   );
                // }

                // check if the day of the month is at least 2 days ahead of the current day. This gives 2 days advance notice
                else if (
                  !customCakeIsSelected &&
                  day.slots.length > 0 &&
                  ((day.day >= currentDay + 2 &&
                    months[currentMonthIndex] === e.month) ||
                    e.monthIndex > currentMonthIndex)
                ) {
                  return (
                    <Available
                      schedule={availability}
                      month={e.month}
                      day={day.day}
                      slots={day.slots}
                    ></Available>
                  );
                }

                // check if 2 days of advance notice is NOT provided in that month
                else if (
                  day.day < currentDay + 2 &&
                  months[currentMonthIndex] === e.month
                ) {
                  return <Booked month={e.month} day={day.day}></Booked>;
                } else {
                  if (
                    day.availabilityStatus === "available" &&
                    day.slots.length > 0
                  ) {
                    return (
                      <Available
                        schedule={availability}
                        month={e.month}
                        day={day.day}
                        slots={day.slots}
                      ></Available>
                    );
                  } else {
                    return <Booked month={e.month} day={day.day}></Booked>;
                  }
                }
              });
            } else if (
              e.isActive &&
              e.month === selectedMonth &&
              specialtyCakeExists
            ) {
              return (
                <Grid item xs={12} sm={6} sx={{ margin: "0 auto" }}>
                  <Paper sx={{ padding: "50px 0" }}>
                    <Typography sx={{ mb: 5 }}>
                      WE ARE NOT TAKING SPECIALTY CAKE ORDERS FOR SEPTEMBER.
                    </Typography>
                    <Typography sx={{ mb: 5 }}>
                      PLEASE REMOVE THE SPECIALTY CAKE FROM THE CART AND TRY
                      ORDERING ONE OF OUR STANDARD CAKES OR CHEESECAKES.
                    </Typography>
                    <Typography>
                      WE APOLOGIZE FOR ANY INCONVENIENCE THIS MAY CAUSE
                    </Typography>
                  </Paper>
                </Grid>
              );
            } else if (
              e.isActive &&
              e.month === selectedMonth &&
              !specialtyCakeExists
            ) {
              return e.days.map((day) => {
                // check if the day of the month is in the past
                if (
                  (day.day < currentDay &&
                    months[currentMonthIndex] === e.month) ||
                  e.monthIndex < currentMonthIndex
                ) {
                  return <Past month={e.month} day={day.day}></Past>;
                }
                // else if (specialtyCakeExists) {
                //   return (
                //     <Box>WE ARE NOT TAKING ORDERS FOR SPECIALTY CAKES</Box>
                //   );
                // }

                // check if the day of the month is at least 2 days ahead of the current day. This gives 2 days advance notice
                else if (
                  !customCakeIsSelected &&
                  day.slots.length > 0 &&
                  ((day.day >= currentDay + 2 &&
                    months[currentMonthIndex] === e.month) ||
                    e.monthIndex > currentMonthIndex)
                ) {
                  return (
                    <Available
                      schedule={availability}
                      month={e.month}
                      day={day.day}
                      slots={day.slots}
                    ></Available>
                  );
                }

                // check if 2 days of advance notice is NOT provided in that month
                else if (
                  day.day < currentDay + 2 &&
                  months[currentMonthIndex] === e.month
                ) {
                  return <Booked month={e.month} day={day.day}></Booked>;
                } else {
                  if (
                    day.availabilityStatus === "available" &&
                    day.slots.length > 0
                  ) {
                    return (
                      <Available
                        schedule={availability}
                        month={e.month}
                        day={day.day}
                        slots={day.slots}
                      ></Available>
                    );
                  } else {
                    return <Booked month={e.month} day={day.day}></Booked>;
                  }
                }
              });
            } else if (!e.isActive && e.month === selectedMonth) {
              return (
                <Box sx={{ textAlign: "center", margin: "0 auto" }}>
                  <Typography className={classes.textStyle} variant="h5">
                    Not Available At The Moment
                  </Typography>
                </Box>
              );
            }
          })}
        </Grid>
      ) : (
        "Loading..."
      )}
    </Box>
  );
}

function mapStateToProps(state) {
  const { products } = state.cart;
  return { products };
}

export default connect(mapStateToProps)(Availability);
