import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectOrderId,
  resetToInitial,
  selectSubtotal,
  selectTax,
} from "../../redux/reducers/inStoreStandardCake";
import Customer from "./review/customer";
import Order from "./review/order";
import AccessoriesReview from "./review/accessories";
import Summary from "./review/summary";

const useStyles = makeStyles(() => ({
  approveButton: {
    "&.MuiButton-root": {
      backgroundColor: "transparent !important",
      color: "#f6725c !important",
      marginTop: "1rem !important",
      width: "20rem !important",
      height: "5rem !important",
      border: "2px solid #f6725c !important",
      fontSize: "20px !important",
      borderRadius: "10px !important",
      "&:hover": {
        backgroundColor: "#f6725c !important",
        color: "white !important",
      },
    },
  },
  textField: {
    marginBottom: "1rem !important",
    marginTop: "2rem !important",
    backgroundColor: "white !important",
    borderRadius: "10px !important",
    width: "28rem !important",
    "& .MuiInputBase-root": {
      height: "4.5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiInputLabel-root": {
      fontWeight: "bold",
      top: "50%",
      transform: "translate(20%, -50%)",
    },
    "& .MuiInputLabel-shrink": {
      top: "-10%",
      left: "3%",
      transform: "none",
      fontSize: "12px",
    },
  },
}));

const ApprovalPage = () => {
  const classes = useStyles();
  const orderId = useSelector(selectOrderId);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [employees, setEmployees] = useState({});
  const [employeeId, setEmployeeId] = useState("");
  const [dataIsReady, setDataIsReady] = useState(false);
  const [error, setError] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleOpen = (event, scrollType) => {
    setOpenDialog(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(async () => {
    try {
      const response = await axios.get("/api/employees/");
      setEmployees(response.data);
      setDataIsReady(true);
    } catch (error) {
      console.error(error);
    }
  }, [dataIsReady]);

  const isValidEmployeeId = (id) => {
    const emp = employees.find((e) => e.employeeID === id);
    return emp == null || emp == undefined ? false : true;
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      const response = await axios.put(
        `/api/orders/updateOrder/?id=${orderId}`,
        { paymentStatus: newStatus, employeeID: employeeId }
      );
      if (response.status === 200) {
        alert("order submitted successfully");
      } else {
        alert("SERVER ERROR: ORDER NOT SUBMITTED. REPORT TO ADMIN");
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleApprove = async () => {
    if (isValidEmployeeId(employeeId)) {
      setError(null);
      const res = await updateOrderStatus(orderId, "successful");
      if (res.data.status === "success") {
        dispatch(resetToInitial());
        navigate("/cakes/order-form");
      }
    } else {
      setError("Invalid employee ID.");
    }
  };

  if (!dataIsReady) {
    return <Box>Loading...</Box>;
  } else {
    return (
      <Box
        sx={{
          minHeight: "87vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SentimentSatisfiedAltIcon
          sx={{ mb: 2, color: "#f6725c", fontSize: "6rem" }}
        />
        <Typography
          sx={{
            mb: 2,
            fontSize: "6rem",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Thank You!
        </Typography>
        <Typography sx={{ mb: 6, fontSize: "32px", color: "red" }}>
          NOT DONE YET!
        </Typography>
        <Typography
          sx={{
            mb: 6,
            fontSize: "32px",
            color: "#676767",
            width: "70%",
            textAlign: "center",
          }}
        >
          please return the terminal to the cashier and proceed with the payment
        </Typography>
        <TextField
          label="Employee ID"
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value.toLowerCase())}
          className={classes.textField}
          color="success"
          data-testid="employee-id-field"
        />
        {error && (
          <Alert severity="error" sx={{ mt: 1, maxWidth: "100%" }}>
            {error}
          </Alert>
        )}
        <Button
          className={classes.approveButton}
          variant="contained"
          onClick={handleApprove}
          data-testid="approve-btn"
        >
          Approve
        </Button>
        <Typography
          onClick={(event) => handleOpen(event)}
          sx={{
            mt: 6,
            fontSize: "18px",
            color: "#676767",
            textDecoration: "underline",
          }}
        >
          Review Order Details, then approve
        </Typography>
        <Dialog open={openDialog} onClose={handleClose} scroll={scroll}>
          <DialogTitle
            sx={{
              mt: 4,
              ml: 2,
              mb: 1,
              color: "#f6725c",
              fontSize: "22px !important",
            }}
          >
            Order Details
          </DialogTitle>
          <DialogContent sx={{ ml: 2, mb: 4, fontSize: "18px !important" }}>
            <Customer></Customer>
            <Order></Order>
            <AccessoriesReview></AccessoriesReview>
            <Summary></Summary>
          </DialogContent>
        </Dialog>
      </Box>
    );
  }
};

export default ApprovalPage;
