import React from "react";
import { ListItem, ListItemText, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({}));

export default function Note({ text, number }) {
  const classes = useStyles();

  return (
    <ListItem disablePadding sx={{ display: "list-item" }} data-testid="notes">
      <ListItemText primary={text} />
    </ListItem>
  );
}
