let STRIPE_PUBLISHABLE_KEY = "";

switch (window.location.origin) {
  case "https://cakeyhand.com":
  case "https://www.cakeyhand.com":
    STRIPE_PUBLISHABLE_KEY = "pk_live_myMbTYkwYNqbf5co6XYh8ozz00iftMNsCr";
    break;
  case "https://staging.cakeyhand.com":
    STRIPE_PUBLISHABLE_KEY = "pk_test_szwkzwxsYnEZ6uzwIwy9cAK400C31iFiLA";
    break;
  default:
    // for local development only
    STRIPE_PUBLISHABLE_KEY = "pk_test_51ImkrKLnVbKEJoRBekWacIV6dQRWtOrZKuSbvjZIR0cCvK5AKLtB5E3dHB3ShmdTRnKxgfoyWyJguk0BHc6lC9jo00ieXEQkWG";
}

export default {
  STRIPE_PUBLISHABLE_KEY
} 