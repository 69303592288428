/** @format */
import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import EditCollectionInfo from "./editOrder/EditCollectionInfo";
// import editIcon from "../../../../../assets/icons/adminBooking/edit.png";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectOrderInfo } from "../../../../../redux/reducers/admin";
import CommentIcon from "@mui/icons-material/Comment";
import EventIcon from "@mui/icons-material/Event";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";

const useStyles = makeStyles((theme) => ({
  orderDetailItem: {
    display: "flex !important",
    flexDirection: "row !important",
    marginBottom: "15px !important",
  },
  sectionHeadText: {
    color: "#242424 !important",
    fontWeight: "700 !important",
    fontSize: "18px !important",
    marginBottom: "15px !important",
  },
  icon: {
    marginRight: "5px !important",
    color: "#fd7762",
  },
}));

export default function General() {
  const classes = useStyles();

  const orderInfo = useSelector(selectOrderInfo);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.sectionHeadText}>
          GENERAL INFO
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.orderDetailItem}>
          <Grid3x3Icon className={classes.icon} />
          <Typography>{orderInfo.orderId}</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.orderDetailItem}>
          <EventIcon className={classes.icon} />
          <Box>
            <Typography>
              {dayjs(orderInfo.collectionDate).format("YYYY-MM-DD")}
            </Typography>
            <Typography>{" at " + orderInfo.collectionTime}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.orderDetailItem}>
          <CommentIcon className={classes.icon} />
          <Typography>{orderInfo.additionalNotes}</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.orderDetailItem}>
          <AttachMoneyIcon className={classes.icon} />
          <Typography>{orderInfo.total.toFixed(2) + " CAD"}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
