/** @format */

import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { collectionPhoneNumberChosen } from "../../../redux/reducers/cart";
import { selectCollection } from "../../../redux/reducers/cart";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
  textField: {
    borderRadius: "20px",
    marginBottom: "30px",
  },
}));

export default function PhoneNumber({ placeholder, icon }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const collectionInfo = useSelector(selectCollection);
  const [phoneNumber, setPhoneNumber] = useState(collectionInfo?.phoneNumber);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const formatPhoneNumber = (value) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  // const handleInput = (input) => {
  //   if (!input) {
  //     setPhoneNumberError(true);
  //   } else {
  //     setPhoneNumberError(false);
  //   }
  //   const formattedPhoneNumber = formatPhoneNumber(input);
  //   setPhoneNumber(formattedPhoneNumber);
  // };

  // const handleInputBlur = (input) => {
  //   // 14 is the correct number of characters that should exist in a properly formatted phone number
  //   // the proper format is: (111) 111-1111
  //   if (input.length < 14) {
  //     setPhoneNumberError(true);
  //   } else {
  //     dispatch(collectionPhoneNumberChosen({ phoneNumber: input }));
  //   }
  // };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    const input = event.target.value;
    setIsFocused(false);
    // 14 is the correct number of characters that should exist in a properly formatted phone number
    // the proper format is: (111) 111-1111
    if (input.length < 14) {
      setPhoneNumberError(true);
    } else {
      dispatch(collectionPhoneNumberChosen({ phoneNumber: input }));
    }
  };

  const handleChange = (event) => {
    const input = event.target.value;

    if (!input) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
    const formattedPhoneNumber = formatPhoneNumber(input);
    setPhoneNumber(formattedPhoneNumber);
  };

  const getLabelStyles = () => {
    if (isFocused) {
      return {
        paddingLeft: "0",
        transform: "translate(15px, -20px) scale(0.75)",
        marginTop: "10px",
      };
    } else {
      if (phoneNumber) {
        return { paddingLeft: "0" };
      } else {
        return { paddingLeft: "35px" };
      }
    }
  };

  return (
    <FormControl>
      <TextField
        className={classes.textField}
        autoComplete="off"
        error={phoneNumberError}
        helperText={phoneNumberError ? "invalid phone number" : ""}
        type="tel"
        variant="outlined"
        value={phoneNumber}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        InputProps={{
          sx: { borderRadius: "20px" },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton edge="start" onClick={() => {}}>
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: isFocused || phoneNumber.length > 0,
          style: getLabelStyles(),
        }}
        label={placeholder}
        data-testid="phone-number"
      />
      {/* 
<TextField
      autoComplete="off"
      error={phoneNumberError}
      helperText={phoneNumberError ? "invalid phone number" : ""}
      sx={{ mr: 5 }}
      type="tel"
      label="Enter phone number"
      value={phoneNumber}
      onChange={(e) => {
        handleInput(e.target.value);
      }}
      onBlur={(e) => {
        handleInputBlur(e.target.value);
      }}
      InputLabelProps={{
        className: classes.textStyle,
      }}
      InputProps={{ className: classes.textStyle }}
      FormHelperTextProps={{ className: classes.textStyle }}
      data-testid="phone-number"></TextField> */}
    </FormControl>
  );
}
