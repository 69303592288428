import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  flavorSelected,
  sizeSelected,
  quantitySelected,
  timeSlotSelected,
  pickupLocationSelected,
} from "../../redux/reducers/admin";

const useStyles = makeStyles(() => ({
  inputLabel: {
    color: "grey !important",
    fontSize: "14px !important",
    "&.MuiInputLabel-shrink": {
      color: "grey !important",
    },
  },
  options: {
    backgroundColor: "white",
    width: "100% !important",
    borderRadius: "10px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f6725c",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    fontSize: "0.8rem !important",
    height: "3rem",
  },
}));

export default function MultiSelectInput({ label, options }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    if (label === "Choose Flavor") {
      dispatch(flavorSelected(e.target.value));
    }
    if (label === "Choose Size") {
      dispatch(sizeSelected(e.target.value));
    }
    if (label === "Choose Quantity") {
      dispatch(quantitySelected(parseInt(e.target.value)));
    }
    if (label === "Choose Time") {
      dispatch(timeSlotSelected(e.target.value));
    }
    if (label === "Choose Pickup Location") {
      dispatch(pickupLocationSelected(e.target.value));
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <FormControl
        fullWidth
        sx={{ ml: 1, mr: 1, mt: 1 }}
        data-testid="multiselectinput"
      >
        <InputLabel
          id="demo-simple-select-autowidth-label"
          classes={{ root: classes.inputLabel }}
          sx={{ fontSize: "0.8rem !important" }}
        >
          {label}
        </InputLabel>
        <Select
          className={classes.options}
          value={selectedOption ? selectedOption : ""}
          onChange={handleChange}
          label="Choose Option"
        >
          {options.map((option, idx) => {
            return (
              <MenuItem key={idx} value={option.name}>
                <ListItemText primary={option.name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
