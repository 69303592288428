/** @format */

import React from "react";
import { Box, Typography, List } from "@mui/material";
import Note from "./note";
import { makeStyles } from "@mui/styles";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
}));

export default function NoteSection({ notes }) {
  const classes = useStyles();
  return (
    <>
      {notes.length > 0 && (
        <>
          <Box sx={{ display: "flex", mt: 10 }}>
            <ErrorOutlineOutlinedIcon sx={{ mr: 2, color: "red" }} />
            <Typography
              className={classes.textStyle}
              variant="subtitle1"
              sx={{ color: "red", fontSize: 17 }}
            >
              DISCLAIMERS
            </Typography>
          </Box>

          <List
            sx={{ listStyleType: "disc", pl: 4 }}
            data-testid="notes-section"
          >
            {notes.map((note, index) => {
              return <Note text={note.text} number={index}></Note>;
            })}
          </List>
        </>
      )}
    </>
  );
}
