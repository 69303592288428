import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Grid,
  Container,
} from "@mui/material";
import NewOrder from "./newOrder";
import { cakeTypeSelected } from "../../../redux/reducers/admin";

export default function OrderRoot() {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = [
    {
      label: "standardCake",
      displayName: "Standard Cake",
    },
    {
      label: "specialtyCake",
      displayName: "Specialty Cake",
    },
    {
      label: "cheesecake",
      displayName: "Cheesecake",
    },
    {
      label: "macaron",
      displayName: "Macaron",
    },
    {
      label: "assortment",
      displayName: "Assortment",
    },
  ];

  const handleCategorySelection = (category) => {
    dispatch(cakeTypeSelected(category));
    setSelectedCategory(category);
  };

  return (
    <Container>
      <Grid container>
        {categories.map((c) => {
          return (
            <Grid item xs={12} sm={2} key={c.label}>
              <Card sx={{ m: 2, textAlign: "center" }}>
                <CardActionArea onClick={() => handleCategorySelection(c)}>
                  <CardContent>
                    <Typography variant="subtitle1">
                      {c.displayName.toUpperCase()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
        {selectedCategory && <NewOrder category={selectedCategory}></NewOrder>}
      </Grid>
    </Container>
  );
}
