import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { selectNewOrder, resetToInitial } from "../../../redux/reducers/admin";

export default function Submission() {
  const newOrderRedux = useSelector(selectNewOrder);
  const dispatch = useDispatch();
  const selectedAccessories = [];

  const { customerInfo, cakeInfo, accessoryInfo, eventInfo, specialNotes } =
    newOrderRedux;

  useEffect(async () => {
    try {
      const res = await axios.get("/api/app/data/accessories");
      const accessories = res.data.accessories;

      accessoryInfo.map((a) => {
        if (a.content.isSelected) {
          let selectedAcessory = accessories.find(
            (accessory) => accessory.type === a.type
          );
          selectedAccessories.push(selectedAcessory);
        }
      });
    } catch (error) {}
  });

  const getPipingCost = () => {
    let pipingCost = 0;
    const pipingAccessory = selectedAccessories.find(
      (accessory) => accessory.type === "piping"
    );
    if (pipingAccessory) {
      pipingCost = pipingAccessory.content[0].price;
    }

    return pipingCost;
  };

  const constructAccessoriesForOrder = () => {
    let accessoryList = [];
    let accessory = {
      type: "",
      options: null,
    };
    accessoryInfo.map((a) => {
      if (a.type === "piping" && a.content.isSelected) {
        accessory.type = a.type;
        accessory.options = a.content.options;
        accessoryList.push(accessory);
      }
    });
    return accessoryList;
  };

  const handleSubmit = async () => {
    const newOrder = {
      customerInfo: customerInfo,
      products: [
        {
          name: cakeInfo.cakeType,
          size: cakeInfo.size,
          price: cakeInfo.price,
          flavor: cakeInfo.flavor,
          color: "n/a",
          imgPath: cakeInfo.imgPath,
          quantity: cakeInfo.quantity,
          type: cakeInfo.cakeType,
        },
      ],
      addOns: constructAccessoriesForOrder(),
      collectionDate: eventInfo.date,
      collectionTime: eventInfo.timeSlot,
      collectionLocation: eventInfo.pickupLocation,
      collectionType: "pickup",
      additionalNotes: specialNotes ? specialNotes : "n/a",
      coupon: {},
      orderDate: new Date(),
      total: (cakeInfo.price + getPipingCost()).toFixed(2),
      email: customerInfo.email.toLowerCase(),
      paymentStatus: "successful",
      paymentSuccess: true,
      employeeID: "sm111794",
      orderSource: "manual",
      orderStatus: "in-progress",
      refundPolicyAgreed: true,
      userRole: "guest",
    };

    try {
      const res = await axios.post("/api/orders/manualOrder", newOrder);
      if (res.status === 200) {
        dispatch(resetToInitial());
        alert("order submitted successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid item xs={12} sm={6} data-testid="submission">
      <Button onClick={handleSubmit}>Submit</Button>
    </Grid>
  );
}
