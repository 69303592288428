import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  numberSelectionChange,
  selectNumberCandleOptionIsSelected,
  selectNumberCandleOptionUserSelection,
} from "../../../../redux/reducers/inStoreStandardCake";
import QuantitySelector from "../common/quantitySelector";

const useStyles = makeStyles((theme) => ({
  wrapperBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem 0 1rem 1rem",
  },
  accessName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    color: "#fd7762",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      fontWeight: "bold !important",
      whiteSpace: "nowrap",
    },
  },
  checkbox: {
    "&.Mui-checked": {
      color: "#fd7762",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      width: "2.15rem !important",
      height: "2.15rem !important",
    },
  },
  price: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "black",
    marginLeft: "32px",
  },
}));

export default function EnhancedFormControlNumberCandleNumberSelection({
  accessory,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const numberCandleOptionIsSelected = useSelector(
    selectNumberCandleOptionIsSelected
  );

  const numberCandleUserSelection = useSelector(
    selectNumberCandleOptionUserSelection
  );

  let selectedNumbers = [];
  numberCandleUserSelection.numbers.map((e) => selectedNumbers.push(e.number));

  const handleNumberSelectionChange = (e, number) => {
    dispatch(
      numberSelectionChange({
        number,
        isChecked: e.target.checked,
        unitPrice: accessory.price,
      })
    );
  };

  return (
    <FormControl sx={{ width: "100%", mt: 1, ml: 1 }}>
      <InputLabel
        id="demo-simple-select-autowidth-label"
        classes={{ root: classes.inputLabel }}
      >
        Choose Number
      </InputLabel>
      <Select
        multiple
        value={selectedNumbers}
        renderValue={(selected) => selected.join(", ")}
        disabled={!numberCandleOptionIsSelected}
        sx={{
          backgroundColor: "white",
          width: "100%",
          borderRadius: "50px",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f6725c",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
          },
        }}
        label="Choose Number"
      >
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
          <MenuItem key={number} value={number}>
            <Checkbox
              onChange={(e) => handleNumberSelectionChange(e, number)}
              checked={
                numberCandleUserSelection.numbers.find(
                  (e) => e.number === number
                ) != null
                  ? true
                  : false
              }
              sx={{
                "&.Mui-checked": {
                  color: "#fd7762 !important",
                },
                "& .MuiIconButton-root": {
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "transparent !important",
                  },
                },
                "& .MuiTouchRipple-root": {
                  display: "none !important",
                },
              }}
            />
            <ListItemText primary={number} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <QuantitySelector
                color={number}
                accessoryType={accessory.subtype}
                accessoryPrice={accessory.price}
              />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
