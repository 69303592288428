/** @format */

import React from "react";
import { Box, Typography } from "@mui/material";
import ColorTheme from "./colorTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
}));

export default function ColorThemeSection({ info }) {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h6" sx={{ mb: 1 }} className={classes.textStyle}>
        {info.displayableName}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
        data-testid="color-theme-section"
      >
        {info.values.map((color) => {
          return <ColorTheme color={color} parentId={info._id}></ColorTheme>;
        })}
      </Box>
    </div>
  );
}
