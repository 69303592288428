import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { Container, Typography, Box, Grid, List, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import KeyIcon from '@mui/icons-material/Key';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AntSwitch from "./antSwitch";
import { useDispatch } from "react-redux";
import { selectSetup, resetSetupService, setSetupService } from "../../redux/reducers/wedding";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: "1.3rem !important",
        textTransform: "uppercase !important",
        fontWeight: "bold !important",
    },
    setpIcon: {
        marginRight: "1rem !important"
    },
    primaryBtn: {
        display: "flex !important",
        flexDirection: "row !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        backgroundColor: "#ffddd8 !important",
        color: "#fd7762 !important",
        borderRadius: "50px !important",
        padding: "1rem !important",
        textTransform: "capitalize !important",
        cursor: "pointer !important",
        margin: "4rem 0 2rem 0 !important",
        fontWeight: "bold !important",
        fontSize: "0.9rem !important",
    },
}));

export default function SetUpChance({ activeStep, setActiveStep }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedSetup = useSelector(selectSetup);

    const initialEnableSetup = selectedSetup.price !== 0
    const [ enableSetup, setEnableSetup ] = useState(initialEnableSetup)


    const [setupServiceData, setSetupServiceData] = useState(null)

    useEffect(async()=>{
        if (!setupServiceData) {
            const res = await axios.get("/api/app/data/addon-products/type?type=wedding-setup-service");
            setSetupServiceData({
                name: res.data[0].name,
                price: res.data[0].basePrices && res.data[0].basePrices[0].price,
            })
        }
    }, [setupServiceData])



    const handleEnableSetupChange = () => {
        const newState = !enableSetup
        setEnableSetup(newState);

        if(newState) {
            dispatch(setSetupService(setupServiceData));
        } else {
            dispatch(resetSetupService());
        }
    }
    
    const services = [
        {
            name: "delivery",
            items: "In St. John's, Mount Pearl, Surrounding area only, Paradise, CBS and surrounding area only",
            serviceIcon: <LocalShippingOutlinedIcon className={classes.setpIcon}/>
        }, 
        {
            name: "rental",
            items: "of Plates, Stands, Table cloth, Labels, Label holders and anything else needed for one day",
            serviceIcon: <KeyIcon className={classes.setpIcon}/>
        },
        {
            name: "arrangments",
            items: "of desserts and cakes on the table",
            serviceIcon: <CheckBoxOutlinedIcon className={classes.setpIcon}/>
        }
    ];

    const handleNextStep = ()=>{
        setActiveStep(4)
    }

    return (
        <Container sx={{
            display: "flex !important",
            flexDirection: "column !important",
            justifyContent: "flex-start !important",
            alignItems: "center !important",
            minWidth: "40vw !important", 
            minHeight: "80vh !important",
            }}
        >
            <Typography 
                className={classes.title} 
                sx={{mb: 8, textAlign: "center"}}>last chance to opt-in for a set up</Typography>
            <Grid container className={classes.gridContainer} spacing={3}>
                {services.map((serviceObj, index) => (
                <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                    <List key={index}>
                        <Box sx={{
                            display: "flex !important",
                            flexDirection: "row !important",
                            justifyContent: "center !important",
                            alignItems: "center !important",
                            }}>
                            {serviceObj.serviceIcon}
                            <Typography className={classes.title}>
                                {serviceObj.name}
                            </Typography>
                        </Box>
                        <Typography sx={{maxWidth: "20rem", textAlign: "center !important"}}>{serviceObj.items}</Typography>
                    </List>
                </Grid>
                ))}
            </Grid>
            <Box className={classes.primaryBtn}>
                <Box sx={{mr: "3rem !important"}}>
                    The SETUP fee is {`$${setupServiceData?.price.toFixed(2)} CAD`}
                </Box>
                <AntSwitch 
                    defaultChecked 
                    inputProps={{ 'aria-label': 'ant design' }}
                    checked={enableSetup}
                    onChange={handleEnableSetupChange}
                />
            </Box>
            <Button 
                sx={{color: "#fd7762 !important", fontWeight: "bold !important", fontSize: "0.9rem !important"}}
                onClick={handleNextStep}
            >
                Next Step
            </Button>
        </Container>
    );
}
