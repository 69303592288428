
import NotLoggedIn from "./notLoggedIn";
import CustomerInformation from "./customerInformation";
import PaymentMethods from "./paymentMethods";

const Subscription = ({ setModalView, modalView }) => {

  const { subscriptionProduct } = modalView.data;

  let currentUser = JSON.parse(localStorage.getItem("user"));   // FIXME -- should use backend session to detect currentUser
  
  return (
    <>
      {
        (currentUser)
        ?  <>
            <CustomerInformation customer={currentUser}/>
            <PaymentMethods 
              user={currentUser} 
              subscriptionProduct={subscriptionProduct} 
              setModalView={setModalView}
              modalView={modalView}
            />
           </>
        : <NotLoggedIn/>
      } 
    </>
    
  )
}

export default Subscription