/** @format */

import React from "react";
import { Typography, Grid, Box, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  bookedDay: {
    textAlign: "center",
    backgroundColor: "#ededed !important",
    paddingTop: "15px !important",
    minHeight: "91.5px !important",
    marginRight: "10px !important",
    marginBottom: "10px !important",
  },
  textStyle: { ...theme.textStyle },
}));

export default function Booked(props) {
  const classes = useStyles();
  const { month, day } = props;

  return (
    <Grid item xs={12} sm={2}>
      <Paper className={classes.bookedDay} elevation={10}>
        <Typography
          className={classes.textStyle}
          sx={{ mb: 3, fontWeight: "bold" }}
        >
          {month.toUpperCase() + " " + day}
        </Typography>
        <Typography className={classes.textStyle}>BOOKED</Typography>
      </Paper>
    </Grid>
  );
}
