/** @format */
import { Box, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  fieldLabel: {
    fontSize: "12px",
    color: "#676767",
  },

  quantityCell: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center",
    margin: "0 0 1rem 0 !important",
  },
}));

function Quantity({ quantity, setQuantity, width }) {
  const classes = useStyles();

  const changeQuantity = (q, add) => {
    let qInt = parseInt(q);
    if (add) {
      qInt = qInt + 1;
    } else if (qInt !== 0) {
      qInt = qInt - 1;
    }

    setQuantity(qInt.toString());
  };

  return (
    <Box className={classes.componentContainer} style={{ width: width }} data-testid="quantity">
      <Typography className={classes.fieldLabel}>Quantity</Typography>
      <Box className={classes.quantityCell}>
        <AddIcon
          style={{ cursor: "pointer" }}
          onClick={() => changeQuantity(quantity, true)}
        />
        <TextField
          inputProps={{ style: { textAlign: "center" } }}
          type="text"
          value={quantity}
          onChange={(e) => {
            const re = /^[0-9\b]+$/;

            if (e.target.value === "" || re.test(e.target.value)) {
              setQuantity(e.target.value);
            }
          }}
        />
        <RemoveIcon
          style={{ cursor: "pointer" }}
          onClick={() => changeQuantity(quantity, false)}
        />
      </Box>
    </Box>
  );
}

export default Quantity;
