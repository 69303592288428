import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectCakeSubtotal,
  selectAccessoriesSubtotal,
  selectTax,
} from "../../../redux/reducers/inStoreStandardCake";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: "2rem !important",
    marginBottom: "2rem !important",
    width: "100% !important",
    borderRadius: "10px !important",
    boxShadow: "none",
    backgroundColor: "white",
    padding: 0,
  },
  tableCellCenter: {
    fontSize: "1rem !important",
    textAlign: "center !important",
  },
  tableCellLeft: {
    fontSize: "1rem !important",
    textAlign: "left !important",
  },
  tableCellRight: {
    fontSize: "1rem !important",
    textAlign: "right !important",
  },
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "grey !important",
    marginBottom: "0.5rem",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
}));

export default function Summary() {
  const classes = useStyles();
  const cakeSubtotal = useSelector(selectCakeSubtotal);
  const accessoriesSubtotal = useSelector(selectAccessoriesSubtotal);
  const subtotal = cakeSubtotal + accessoriesSubtotal;
  const tax = useSelector(selectTax);

  return (
    <>
      <Typography className={classes.heading} sx={{ mt: 4 }}>
        Summary
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer} data-testid="summary-review">
        <Table sx={{ textTransform: "capitalize" }}>
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "#fafafa",
                textTransform: "uppercase",
                height: "3rem !important",
              }}
            >
              <TableCell className={classes.tableCellLeft}>Subtotal</TableCell>
              <TableCell className={classes.tableCellRight}>
                {`$ ${subtotal.toFixed(2)}`}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#fafafa",
                textTransform: "uppercase",
                height: "3rem !important",
              }}
            >
              <TableCell className={classes.tableCellLeft}>GST/HST</TableCell>
              <TableCell className={classes.tableCellRight}>
                {` ${tax.toFixed(2)}`}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#f6725c",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              <TableCell
                className={classes.tableCellLeft}
                sx={{
                  color: "white",
                  fontSize: "22px !important",
                  fontWeight: "bold",
                }}
              >
                Total
              </TableCell>
              <TableCell
                className={classes.tableCellRight}
                sx={{
                  color: "white",
                  fontSize: "22px !important",
                  fontWeight: "bold",
                }}
              >
                {`$ ${(subtotal + tax).toFixed(2)}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
