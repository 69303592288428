/** @format */

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomOptions from "../CustomOptions";
import axios from "axios";
import Quantity from "./Quantity";
import { useDispatch, useSelector } from "react-redux";
import {
  orderInfoSet,
  selectOrderInfo,
} from "../../../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  itemGridContainer: {
    display: "flex !important",
    flexDirection: "row !important",
    margin: "0 0 0 1rem !important",
  },
  cell: {
    display: "flex !important",
    flexDirection: "column !important",
    margin: "0 1rem 0 0 !important",
  },

  productImg: {
    width: "190px !important",
    height: "auto !important",
    borderRadius: "20px !important",
  },
  fieldHead: {
    fontWeight: "bold !important",
  },

  flexCell: {
    display: "flex !important",
    flexDirection: "row !important",
    margin: "0 0 1rem 0 !important",
  },

  dropDownSelect: {
    margin: "0 0 16px 0 !important",
  },

  addOnCell: { margin: "0 0 1rem 0rem !important" },

  quantityCell: {
    display: "inline-flex !important",
    flexDirection: "column !important",
    margin: "0 0 1rem 0 !important",
  },

  quantityGrid: {
    display: "flex !important",
    paddingRight: "100px !important",
    justifyContent: "flex-end !important",
    margin: "0 0 1rem 0 !important",
  },
  linkText: {
    color: "blue !important",
    cursor: "pointer !important",
    "&:hover": {
      textDecoration: "underline  !important",
    },
  },
  controls: {
    margin: "1rem 0 1rem 0 !important",
    padding: "0 0 0 1rem !important",
  },
  controlIcons: {
    height: "1.5rem !important",
    width: "auto !important",
    margin: "0 1rem 0 0 !important",
    cursor: "pointer !important",
    "&:hover": {},
  },

  buttonContainer: {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "center !important",
    margin: "2rem 0 0 0 !important",
  },

  submitBtn: {
    ...theme.outlinedButton,
  },
}));

function EditCakeOrder({ index }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const orderInfo = useSelector(selectOrderInfo);

  const [name, setName] = useState(orderInfo.products[index].name);
  const [size, setSize] = useState(orderInfo.products[index].size);
  const [flavor, setFlavor] = useState(orderInfo.products[index].flavor);
  const [quantity, setQuantity] = useState(orderInfo.products[index].quantity);
  const [openAddOn, setOpenAddOn] = useState(false);
  const [customization, setCustomization] = useState({});

  const [allSizes, setAllSizes] = useState([]);
  const [allFlavors, setAllFlavors] = useState([]);
  const [standardCakeList, setStandardCakeList] = useState([]);
  const [specialtyCakeList, setSpecialtyCakeList] = useState([]);
  const [editingOrder, setEditingOrder] = useState();

  let specialtyCakeFlavorIds = [];
  let specialtyCakeSizes = [];
  let cakeNames = [];
  let customizationOptions = [];

  useEffect(() => {
    const getSizes = async () => {
      try {
        const sizes = await axios.get(`/api/app/data/sizes`);
        setAllSizes(sizes.data);
      } catch (err) {}
    };

    const getFlavors = async () => {
      try {
        const flavors = await axios.get(`/api/app/data/flavors`);
        setAllFlavors(flavors.data);
      } catch (err) {
        console.log(err);
      }
    };

    const getStandardCakes = async () => {
      try {
        const standard = await axios.get(`/api/app/data/cakes/`);
        setStandardCakeList(standard.data);
      } catch (err) {
        console.log(err);
      }
    };

    const getSpecialtyCakes = async () => {
      try {
        const specialty = await axios.get(`/api/app/data/allCustomCakes/`);
        setSpecialtyCakeList(specialty.data);
      } catch (err) {
        console.log(err);
      }
    };

    const getOrderById = async () => {
      try {
        const order = await axios.get(`/api/orders/orderId`, {
          params: { orderId: orderInfo.orderId },
        });
        setEditingOrder(order.data);
      } catch (e) {
        console.log(e);
      }
    };

    getSpecialtyCakes();
    getStandardCakes();
    getSizes();
    getFlavors();
    getOrderById();
  }, []);

  useEffect(() => {
    if (editingOrder) {
      editOrder();
    }
  }, [name, size, customization, flavor, quantity]);

  const editOrder = () => {
    let flowerPrice = 0;

    if (customization.flower && customization.flower === "Yes")
      flowerPrice = 20;

    const newProduct = {
      id: editingOrder.products[index].id,
      imgPath: editingOrder.products[index].imgPath,
      ...customization,
      name: name,
      quantity: quantity,
      size: size,
      flavor: flavor,
      price: editingOrder.products[index].price + flowerPrice,
      type: editingOrder.type,
    };

    const newProductArray = editingOrder.products;
    newProductArray[index] = newProduct;

    const newOrder = {
      ...editingOrder,
      products: newProductArray,
    };

    dispatch(orderInfoSet(newOrder));
  };

  if (
    orderInfo.products[index].type === "specialtyCake" ||
    orderInfo.products[index].type === "custom-cake"
  ) {
    specialtyCakeList.map((sc) => {
      cakeNames.push(sc.name);
      customizationOptions = sc.customizationOptions;
      if (orderInfo.products[index].name === sc.name) {
        specialtyCakeFlavorIds = sc.flavorOptions;
      }
    });

    allSizes.map((s) => {
      if (s.cakeType === "specialtyCake") {
        specialtyCakeSizes.push(s);
      }
    });
  } else {
    allSizes.map((s) => {
      if (s.cakeType === "standardCake") {
        specialtyCakeSizes.push(s);
      }
    });

    standardCakeList.map((sc) => {
      cakeNames.push(sc.name);
    });
  }

  const getSizeShortName = (longName) => {
    const index = longName.indexOf("-");

    if (index !== -1) {
      const shortName = longName.substring(0, index - 1);

      return shortName;
    } else {
      return longName;
    }
  };

  return (
    <>
      <Box className={classes.itemGridContainer} data-testid="edit-cake-order">
        <Box className={classes.cell}>
          <FormControl
            variant="outlined"
            className={classes.dropDownSelect}
            style={{
              display:
                orderInfo.products[index].type === "standardCake"
                  ? "none"
                  : "flex",
            }}
          >
            <InputLabel shrink={true} id="name">
              Name
            </InputLabel>
            <Select
              label="Name"
              labelId="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            >
              {cakeNames.map((n) => {
                return <MenuItem value={n}>{n}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <FormControl className={classes.dropDownSelect} variant="outlined">
            <InputLabel shrink={true} id="size">
              Size
            </InputLabel>
            <Select
              label="Size"
              labelId="size"
              value={size}
              onChange={(e) => setSize(e.target.value)}
            >
              <MenuItem value={``}>{``}</MenuItem>
              {specialtyCakeSizes.length === 0
                ? allSizes.map((sizes) => {
                    return (
                      <MenuItem value={getSizeShortName(sizes.label)}>
                        {sizes.label}
                      </MenuItem>
                    );
                  })
                : specialtyCakeSizes.map((sizes) => {
                    return (
                      <MenuItem value={getSizeShortName(sizes.label)}>
                        {sizes.label}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>

          <Box className={classes.addOnCell}>
            <Typography>Customizations:</Typography>
            <Typography
              className={classes.linkText}
              onClick={() => setOpenAddOn(true)}
            >
              {Object.keys(customization).length !== 0
                ? Object.keys(customization).length
                : "0"}
            </Typography>
            <Modal open={openAddOn} onClose={() => setOpenAddOn(false)}>
              <CustomOptions
                customization={customization}
                setCustomization={setCustomization}
              />
            </Modal>
          </Box>
        </Box>

        <Box className={classes.cell}>
          <FormControl className={classes.dropDownSelect} variant="outlined">
            <InputLabel shrink={true} id="flavor">
              Flavor
            </InputLabel>
            <Select
              label="Flavor"
              labelId="flavor"
              value={flavor}
              onChange={(e) => setFlavor(e.target.value)}
            >
              {allFlavors.map((f) => {
                if (specialtyCakeFlavorIds.length !== 0) {
                  for (let i = 0; specialtyCakeFlavorIds.length > i; i++) {
                    if (specialtyCakeFlavorIds[i] === f._id) {
                      return <MenuItem value={f.name}>{f.name}</MenuItem>;
                    }
                  }
                } else {
                  return <MenuItem value={f.name}>{f.name}</MenuItem>;
                }
              })}
            </Select>
          </FormControl>

          <Box className={classes.quantityCell}>
            <Typography>Quantity:</Typography>
            <Quantity
              quantity={quantity}
              setQuantity={setQuantity}
              width="130px"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EditCakeOrder;
