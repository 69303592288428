import React from "react";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";
import Main from "./main";
import Header from "../../components/header";
import FeaturedProductsSection from "./featuredProducts";
import CommitmentMessage from "./commitmentMessage";
import ValuesSection from "./valuesSection";
import SpecialOfferings from "./specialOfferings";
import Banner from "./banner";
import ProductCategories from "./productCategories";
import BlogArticles from "./articles";

const LandingPage = () => {
  return (
    <>
      <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      <Main></Main>
      <CommitmentMessage></CommitmentMessage>
      <FeaturedProductsSection />
      <ValuesSection />
      <SpecialOfferings />
      <Banner />
      <ProductCategories />
      <BlogArticles />
      <Footer></Footer>
    </>
  );
};

export default LandingPage;
