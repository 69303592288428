import React from "react";
import { Grid, Typography } from "@mui/material";
import FreeTextInput from "../../components/admin/freeTextInput";

export default function AccessoryInfo() {
  return (
    <Grid container spacing={6} data-testid="accessory-info">
      <Grid item xs={12}>
        <Typography variant="h6">ACCESSORY INFORMATION</Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FreeTextInput label={"Piping"}></FreeTextInput>
      </Grid>
    </Grid>
  );
}
