import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Container,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  sizeSelected,
  priceSelected,
  selectAvailableSizes,
  selectSizeChosen,
  activeStepUpdated,
} from "../../../redux/reducers/inStoreStandardCake";

const useStyles = makeStyles((theme) => ({
  wrapperBox: {
    justifyContent: "center !important",
    maxWidth: "100% !important",
    display: "flex !important",
    alignItems: "center !important",
  },
  gridContainer: {
    justifyContent: "center !important",
    padding: "20px !important",
    maxWidth: "100% !important",
  },
  nextButton: {
    "&.MuiButton-root": {
      color: "#f6725c !important",
      fontSize: "18px !important",
    },
    "&.Mui-disabled": {
      color: "lightgrey !important",
    },
  },
  backButton: {
    "&.MuiButton-root": {
      color: "#f6725c !important",
      fontSize: "18px !important",
    },
  },
  arrowsBox: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white !important",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: "white !important",
    },
    zIndex: 10,
  },
  cardContent: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    minHeight: "5rem !important",
    width: "100% !important",
    border: "2px solid lightgrey !important",
    borderColor: "lightgrey !important",
    "&:hover": {
      backgroundColor: "#f6725c !important",
      color: "white !important",
      cursor: "pointer !important",
    },
  },
  sizeHeader: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    marginBottom: "5% !important",
    marginTop: "10% !important",
    fontSize: "22px !important",
    fontWeight: "bold !important",
    textTransform: "uppercase !important",
  },
  container: {
    minHeight: "60vh !important",
    [theme.breakpoints.between("sm", "md")]: {
      minHeight: "50vh !important",
    },
  },
}));

const SizeSelection = ({ setActiveStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const availableSizes = useSelector(selectAvailableSizes);
  const [dataIsReady, setDataIsReady] = useState(false);

  const size = useSelector(selectSizeChosen);
  const [sizeList, setSizeList] = useState([]);
  const [error, setError] = useState(null);

  let allSizesDetails = [];

  useEffect(() => {
    const fetchSizes = async () => {
      for (let size of availableSizes) {
        try {
          const res = await axios.get("/api/app/data/size?sizeId=" + size);
          allSizesDetails.push(res.data);
          setDataIsReady(true);
        } catch (error) {
          setError(error.message);
          return;
        }
      }
      setSizeList(allSizesDetails);
    };

    fetchSizes();
  }, [dataIsReady]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleSizeClick = (size) => {
    dispatch(sizeSelected({ size: size.label }));
    dispatch(priceSelected({ price: size.price }));
  };

  const handleNext = () => {
    setActiveStep(2);
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Container className={classes.container} data-testid="size-selection">
        <Typography className={classes.sizeHeader}>Choose Size</Typography>
        <Box className={classes.wrapperBox}>
          <Grid container className={classes.gridContainer} spacing={3}>
            {sizeList.map((sizeObj) => (
              <Grid item xs={6} key={sizeObj._id}>
                <Box
                  onClick={() => handleSizeClick(sizeObj)}
                  className={classes.cardContent}
                  sx={{
                    color: sizeObj.label === size ? "#ffffff" : "#000000",
                    backgroundColor:
                      sizeObj.label === size ? "#fd7762" : "#ffffff",
                  }}
                >
                  <Typography variant="h5" component="div">
                    {`${sizeObj.label} - $${sizeObj.price} CAD `}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" component="div">
                  {`feeds up to ${sizeObj.feedSize} people`}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Box className={classes.arrowsBox}>
        <Button className={classes.backButton} onClick={handleBack}>
          <ArrowBack sx={{ mr: 1 }} />
          Cakes
        </Button>
        <Button
          className={classes.nextButton}
          onClick={handleNext}
          disabled={!size}
        >
          Accessories
          <ArrowForward sx={{ ml: 1 }} />
        </Button>
      </Box>
    </>
  );
};

export default SizeSelection;
