import React from "react";
import { Box, Typography, Button, Grid, List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckIcon from '@mui/icons-material/Check';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import KeyIcon from '@mui/icons-material/Key';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';

const useStyles = makeStyles((theme) => ({
    wrapperBox: {
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
    },
    gridContainer: {
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        maxWidth: "70% !important",
        gap: "10rem !important",
        [theme.breakpoints.down("md")]: {
            maxWidth: "100% !important",
            gap: "2rem !important",
        },
    },
    gridItem: {
        maxWidth: "20% !important",
        [theme.breakpoints.down("md")]: {
            maxWidth: "80% !important",
        },
    },
    serviceItems: {
        display: "flex !important",
        flexDirection: "row !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        color: "#8d8d8d  !important"
    },
    title: {
        fontSize: "1.5rem !important",
        textTransform: "uppercase !important",
        fontWeight: "bold !important",
    },
    primaryBtn: {
        backgroundColor: "white !important",
        color: "#fd7762 !important",
        borderRadius: "50px !important",
        width: "20rem !important",
        border: "2px solid #fd7762 !important",
        padding: "1rem !important",
        textTransform: "capitalize !important",
        "&:hover": {
            backgroundColor: "#fd7762 !important",
            color: "white !important",
        },
    },
    eventCalendar: {
        display: "flex !important",
        flexDirection: "row !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        marginBottom: "3rem !important"
    },
    icon: {
        color: "#8d8d8d  !important",
    },
    setpIcon: {
        fontSize: "2.7rem  !important",
    },
    heading: {
    fontSize: "3rem !important",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
        fontSize: "2rem !important",
    },
    },
    subHeading: {
    fontSize: "1rem !important",
    color: "#fd7762 !important",
    textAlign: "center !important",
    fontWeight: "bold !important",
    [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem !important",
    },
    },
    middleTexts:{
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    marginTop: "10rem !important",
    [theme.breakpoints.down("md")]: {
        margin: "4rem 0 4rem 0 !important",
    },
    }
}));

export default function SetupService() {
    const classes = useStyles();
    const services = [
        {
            name: "delivery",
            items: ["In St. John's", "Mount Pearl, Surrounding area only", "Paradise", "CBS"],
            serviceIcon: <LocalShippingOutlinedIcon className={classes.setpIcon}/>
        }, 
        {
            name: "rental",
            items: ["Plates", "Stands", "Table cloth", "Labels", "Label holders"],
            serviceIcon: <KeyIcon className={classes.setpIcon}/>
        },
        {
            name: "arrangments",
            items: ["Cake placement", "Dessert arrangment", "Labels", "Label holders"],
            serviceIcon: <CheckBoxOutlinedIcon className={classes.setpIcon}/>
        }
    ];

    return (
        <Box className={classes.wrapperBox}>
            <Box className={classes.middleTexts} sx={{mb: "10rem !important"}}>
                <Typography className={classes.heading}>setup services</Typography>
                <Typography className={classes.subHeading}>Have a piece of mind and let the experts worry about your<br/> wedding dessert table. It's your special day, opt in for a setup</Typography>
            </Box>
            <Grid container className={classes.gridContainer} spacing={3}>
                {services.map((serviceObj, index) => (
                <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                    <List key={index}>
                        {serviceObj.serviceIcon}
                        <Typography className={classes.title}>
                            {serviceObj.name}
                        </Typography>
                        {serviceObj.items.map((item, i)=>(
                            <Box className={classes.serviceItems}>
                                <CheckIcon></CheckIcon>
                                <ListItem key={i}>{item}</ListItem>
                            </Box>
                        ))}
                    </List>
                </Grid>
                ))}
            </Grid>
            <Box className={classes.eventCalendar}>
                <EventAvailableOutlinedIcon className={classes.icon}/>
                <Typography className={classes.serviceItems}>Anything else needed for one day</Typography>
            </Box>
            <Button className={classes.primaryBtn}>have a setup</Button>
        </Box>
    );
}
