import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import basicCakeImage from "../../assets/images/services/wedding-package-basic.png";
import AntSwitch from "./antSwitch";

import { setSetupService, resetToInitial as weddingResetToInitial } from "../../redux/reducers/wedding";
import { resetToInitial as cakeResetToInitial } from "../../redux/reducers/cake";


const useStyles = makeStyles((theme) => ({
    package: {
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        borderRadius: "20px !important",
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        padding: "2rem !important",
        width: "100% !important",
        margin: "2rem 4rem 0 4rem !important",
        position: "relative !important"
    },
    standardCakeImg: {
        width: "100% !important",
        height: "4rem !important",
        objectFit: "contain !important",
        padding: "2rem 0 1rem 2rem"
    },
    flexStyle: {
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
    },
    icon: {
        color: "#fd7762 !important"
    },
    basicBtn: {
        backgroundColor: "#ffddd8 !important",
        color: "#fd7762 !important",
        borderRadius: "50px !important",
        width: "20rem !important",
        padding: "1rem !important",
        textTransform: "capitalize !important",
        marginBottom: "6rem !important",
        "&:hover": {
            backgroundColor: "white !important",
            color: "#fd7762 !important",
            border: "2px solid #fd7762 !important"
        },
    },
    setup: {
        backgroundColor: "#fbfbfb !important",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        padding: "2rem 0 2rem 0",
        borderRadius: "0 0 20px 20px !important",
    },
    span: {
        fontWeight: "bold !important",
        textTransform: "uppercase !important",
        margin: "0 0.3rem 0 0.3rem !important"
    }
}));

const BasicPackage = ({ handleOpen, setupService }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ enableSetup, setEnableSetup ] = useState(true)

  const weddingPackage = {
    name: "basic", 
    packageDetail: [{
      imageSrc: basicCakeImage, 
      title: "cake"
    }]
  };
  
  const openModal = () => {
    dispatch(weddingResetToInitial());
    dispatch(cakeResetToInitial());
    
    if (enableSetup) {
        dispatch(setSetupService(setupService));
    }

    handleOpen(weddingPackage.name)
  };

  return (
    <Box className={classes.package}>
        <Typography sx={{fontSize: "2rem !important", textTransform: "uppercase !important"}}>
            {weddingPackage.name}
        </Typography>
        <Box className={classes.flexStyle} sx={{borderBottom: "2px solid #d3d3d3", width: "100%", mb: "2rem"}}>
            {weddingPackage.packageDetail.map((details) => (
                <Box 
                    className={classes.flexStyle} 
                    sx={{flexDirection: "column !important", p: "1rem"}}
                >
                    <img alt="cake" src={details.imageSrc}
                    />
                    <Box className={classes.flexStyle} sx={{flexDirection: "row !important", mb: "1rem", paddingTop: "16px" }}>
                        <CheckBoxOutlinedIcon className={classes.icon} sx={{pr: "5px"}}/>
                        <Typography sx={{color: "#707070 !important", textTransform: "capitalize !important"}}>
                            {details.title}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Box>
        <Box>
            <Button className={classes.basicBtn} onClick={openModal}>
                Customize
            </Button>
            <Box className={classes.setup}>
                <Box sx={{mr: "1.2rem !important"}}>
                    Add <span className={classes.span}>setup</span> Service / <span className={classes.span}>{`$${setupService.price.toFixed(2)} CAD`}</span>
                </Box>
                <AntSwitch 
                  inputProps={{ 'aria-label': 'ant design' }} 
                  checked={enableSetup}
                  onChange={()=>setEnableSetup(!enableSetup)}
                />
            </Box>
        </Box>
    </Box>
  );
};

export default BasicPackage;