/** @format */
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import DropDownMenuItem from "./dropdownMenuItem";

const useStyles = makeStyles(() => ({
  child: {
    position: "absolute",
    top: 0, // Position below the parent container
    left: 0,
    width: "100%", // Adjust the width as needed
    zIndex: 2, // Ensure it's above other content
    overflow: "hidden",
    transition: "opacity 0.7s ease-in-out, height 0.4s ease-in-out",
    padding: "60px 0 60px 0",
    backgroundColor: "#ffffff !important",
    display: "flex",
  },
  shopDisplayStyle: {
    justifyContent: "space-between",
  },
  servicesDisplayStyle: {
    justifyContent: "start",
  },
}));

export default function DropDownMenu({
  data,
  menuType,
  setShopDropdownIsOpen,
  setServicesDropdownIsOpen,
}) {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.child} ${
        menuType === "shop"
          ? classes.shopDisplayStyle
          : classes.servicesDisplayStyle
      }`}
    >
      {data.map((menuItem) => {
        return (
          <DropDownMenuItem
            title={menuItem.menuTitle}
            values={menuItem.menuItems}
            menuType={menuType}
            setShopDropdownIsOpen={setShopDropdownIsOpen}
            setServicesDropdownIsOpen={setServicesDropdownIsOpen}
          ></DropDownMenuItem>
        );
      })}
    </Box>
  );
}
