/** @format */

export function makeArrayReadable(list) {
  return [...list.map((item) => ({ ...item }))];
}

export function changeOrder(orders, orderInfo) {
  let ordersBuffer = makeArrayReadable(orders);

  let newOrderCost = 0;

  for (let i = 0; i < orderInfo.products.length; i++) {
    newOrderCost =
      newOrderCost +
      orderInfo.products[i].price * parseInt(orderInfo.products[i].quantity);
  }

  for (let i = 0; i < ordersBuffer.length; i++) {
    if (ordersBuffer[i].orderId === orderInfo.orderId) {
      ordersBuffer[i] = { ...ordersBuffer[i], ...orderInfo };
      ordersBuffer[i].total = newOrderCost;
    }
  }

  return ordersBuffer;
}

export function deleteProductFromOrder(orders, orderId, productIndex) {
  let newOrders = makeArrayReadable(orders);

  let order = newOrders.find((o) => orderId === o.orderId);

  delete order.products[productIndex];

  for (let i = 0; i < orders.length; i++) {
    if (newOrders[i].orderId === orderId) {
      newOrders[i] = order;
    }
  }

  return newOrders;
}
