import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAccessoryInfo } from "../../../../../redux/reducers/inStoreStandardCake";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StandardCandleReview from "./standardCandleReview";
import CandlesSubcategoryReview from "./candlesSubcategoryReview";

const useStyles = makeStyles(() => ({
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "grey !important",
    marginBottom: "0.5rem",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
}));

export default function CandlesReview({ dbCandles }) {
  const classes = useStyles();

  const { candles } = useSelector(selectAccessoryInfo);

  let selectedCandleTypes = [];
  for (const key in candles) {
    if (candles[key].optionIsSelected) {
      selectedCandleTypes.push(key);
    }
  }

  return (
    <>
      {dbCandles.content.map((candle) => {
        if (selectedCandleTypes.includes(candle.subtype)) {
          return (
            <CandlesSubcategoryReview
              candleSubcategory={candle}
              userSelection={candles[candle.subtype]}
            ></CandlesSubcategoryReview>
          );
        }
      })}
      {/* {selectedCandleTypes.includes("standardCandle") && (
        <StandardCandleReview
          unitPrice={dbCandles.content[0].price}
          standardCandle={candles.standardCandle}
        ></StandardCandleReview>
      )} */}
    </>
  );
}
