import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  Box,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import weddingCouples from "../../assets/images/wedding_couple.png";
import cakeDecorationLikeAPro from "../../assets/images/blog/decorateLikeAPro.jpg"
import weddingCakeTrend from "../../assets/images/blog/weddingCakeTrend.jpg";
import cakeCooler from "../../assets/images/blog/cakeCooler.jpg";
import bakingTools from "../../assets/images/blog/bakingTools.png";



const articles = [
  {
    title: "10 Professional Ways to Decorate Cake Like a Pro",
    date: "22 Oct, 2024",
    image: cakeDecorationLikeAPro,
    category: "Decorating",
    urlPath: "10-ways-to-decorate-cake-like-a-pro"
  },
  {
    title:
      "9 Wedding Cake Trends That Will Wow Your Guests in 2024",
    date: "22 Oct, 2024",
    image: weddingCakeTrend, 
    category: "Trends",
    urlPath: "9-wedding-cake-trends-that-will-wow-your-guests-in-2024"
  },
  {
    title:
      "Baking Equipment Essentials: Tools Every Home Baker Needs",
    date: "22 Oct, 2024",
    image: bakingTools,
    category: "Baking",
    urlPath: "baking-equipment-essentials"
  },
  {
    title:
      "Storage and Transport Tips for Special Events Cakes",
    date: "22 Oct, 2024",
    image: cakeCooler,
    category: "Cake Care",
    urlPath: "storage-and-transport-tips"
  },
];

const StyledCard = styled(Card)(({ theme, image }) => ({
  borderRadius: "16px",
  overflow: "hidden",
  backgroundImage: `url(${image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  height: "300px",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
  "& .MuiCardContent-root": {
    position: "relative",
    zIndex: 2,
  },
}));

export default function BlogArticles() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl" sx={{ pb: "40px" }}>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{ fontFamily: "Moul" }}>
        Latest Articles
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{fontFamily: "Inter", color: "#909090", mb: 4}}>
        Stay updated with our latest news, baking tips, and more.
      </Typography>
      <Grid container spacing={4}>
        {articles.map((article, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StyledCard image={article.image} onClick={() => navigate(`/blog/article/${article.urlPath}`)} sx={{":hover": {cursor: "pointer"}}}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginBottom: "16px",
                  }}>
                  <Chip
                    label={article.category}
                    size="small"
                    sx={{ backgroundColor: "#FFC759", fontFamily: "Inter" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "inherit",
                    }}>
                    <CalendarTodayIcon fontSize="small" />
                    <Typography variant="body2" sx={{ marginLeft: "4px", fontFamily: "Inter" }}>
                      {article.date}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" component="p" sx={{ mb: 3, fontFamily: "Inter", color: "#fff", fontWeight: "bold" }}>
                  {article.title}
                </Typography>
                <Link
                  href={`/blog/article/${article.urlPath}`}
                  underline="hover"
                  color="inherit"
                  sx={{ display: "inline-flex", alignItems: "center", fontSize: "14px", fontFamily: "Inter" }}>
                  Read More →
                </Link>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
