import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GradingIcon from '@mui/icons-material/Grading';
import CreateIcon from '@mui/icons-material/Create';
import PaymentIcon from '@mui/icons-material/Payment';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "3rem !important",
    textTransform: "uppercase !important",
  },
  steps: {
    fontSize: "1.5rem !important",
    fontWeight: "bold !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  stepsText: {
    fontSize: "1.2rem !important",
    textTransform: "uppercase !important",
    textAlign: "center !important"
  },
  stepsWrapper: {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    width: "100% !important",
    marginTop: "3rem !important",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column !important",
      width: "100% !important",
    },
  },
  stepsContainer: {
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem !important",
    },
    minWidth: "360px",
  },
  wrapperBox: {
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    backgroundColor: "#fbfbfb !important",
    margin: "6rem 0 6rem 0 !important",
    padding: "6rem 0 6rem 0 !important",
    borderRadius: "10px !important"
  },
  span: {
    fontWeight: "bold !important"
  },
  iconContainer: {
    backgroundColor: "#fef3f2 !important",
    width: "6rem !important",
    height: "6rem !important",
    borderRadius: "50% !important",
    margin: "2rem 0 2rem 0 !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    "&:hover": {
        backgroundColor: "#ffddd8 !important",
    },
    "&:hover $subIconContainer": {
        backgroundColor: "#fd7762 !important",
    }
  },
  subIconContainer: {
    backgroundColor: "#ffddd8 !important",
    width: "4rem !important",
    height: "4rem !important",
    borderRadius: "50% !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  icon: {
    color: "white",
    fontSize: "3rem",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  }
}));

export default function OrderingProcess() {
  const classes = useStyles();
  const steps = [
    {
      stepNumber: "Step 1",
      stepContent: ["choose ", <br/>, <span className={classes.span}>a package</span>],
      stepIcon: <GradingIcon />,
    },
    {
      stepNumber: "Step 2",
      stepContent: [<span className={classes.span}>customize</span>, " to your liking", <br/>, "and number of guests"],
      stepIcon: <CreateIcon />,
    },
    {
      stepNumber: "Step 3",
      stepContent: [<span className={classes.span}>pay online</span>, " and let us", <br/>, "handle the rest"],
      stepIcon: <PaymentIcon />,
    },
  ];

  return (
    <Box className={classes.wrapperBox}>
      <Typography align="center" className={classes.heading}>
        ordering process
      </Typography>
      <Box className={classes.stepsWrapper}>
      {steps.map((step, index) => (
        <Box key={index} className={classes.stepsContainer}>
            <Typography className={classes.steps}>{step.stepNumber}</Typography>
            <Box className={classes.iconContainer}>
              <Box className={classes.subIconContainer}>
                <span className={classes.icon}>
                  {step.stepIcon}
                </span>
              </Box>
            </Box>
            <Typography className={classes.stepsText}>{step.stepContent}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
