import React from "react";
import { HashLink } from 'react-router-hash-link';
import { Fab } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { theme, sectionLink } from "../theme";

export default function FloatingActionButton() {
    return(
        <>
        <HashLink smooth to='#top' style={sectionLink}>
        <Fab
        sx={{
            display: {xs: "block", sm: "none"},
            position: "fixed",
            bottom: "24px",
            right: "24px",
            height: "64px",
            width: "64px",
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.light,
        }}
      >
        <KeyboardArrowUpIcon fontSize="large" />
      </Fab>
      </HashLink>
        </>
    )
}
