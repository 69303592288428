import React from "react";
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
} from "@mui/material";
  
import { selectColors } from "../../../theme";

const SizeSelect = ({ sizeOptions, setState, state}) => {

  const handleOnChange = (e) => {
    setState({...state, size: e.target.value})
  }

  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 220,
        // width: { xs: "100%", sm: "50%", md: "25%" },
      }}
    >
      <InputLabel id="size" sx={selectColors}>
        Choose Size
      </InputLabel>
      <Select
        labelId="size"
        id="size"
        label="Choose Size"
        sx={{
          ...selectColors,
          borderRadius: "24px",
          backgroundColor: "#F9F9F9",
        }}
        onChange={handleOnChange}
      >
        {
          sizeOptions.values.map(size => <MenuItem value={size.name}>{size.name}</MenuItem>)
        }
    
      </Select>
    </FormControl>
  )

}

export default SizeSelect