import React, { useState } from "react";
import {
  Button,
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  customerInfoUpdated,
  eventInfoUpdated,
  selectCustomerInfo,
  selectEventInfo,
} from "../../../../../redux/reducers/inStoreStandardCake";

const useStyles = makeStyles((theme) => ({
  nextButton: {
    "&.MuiButton-root": {
      color: "#f6725c",
      fontSize: "18px",
    },
    "&.Mui-disabled": {
      color: "lightgrey",
    },
  },
  backButton: {
    "&.MuiButton-root": {
      color: "#f6725c",
      fontSize: "18px",
    },
  },
  arrowsBox: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    position: "sticky !important",
    bottom: 0,
    backgroundColor: "white !important",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: "white !important",
    },
    zIndex: 10,
  },
  container: {
    minHeight: "70vh",
    padding: "0 2rem 0 6rem !important",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "0 0 0 2rem !important",
      minHeight: "60vh",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 0 2rem !important",
    },
  },
}));

function EditCollectionInfo({ setActiveStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customer = useSelector(selectCustomerInfo);
  const event = useSelector(selectEventInfo);

  const [customerInfo, setCustomerInfo] = useState(customer);
  const [eventInfo, setEventInfo] = useState(event);

  const [onFieldFocus, setOnFieldFocus] = useState({
    name: false,
    email: false,
    phone: false,
    address: false,
  });

  const handlePhoneChange = (e) => {
    let rawNumber = e.target.value.replace(/[^\d]/g, "");
    if (rawNumber.length > 10) {
      rawNumber = rawNumber.toString().slice(0, 10);
    } else if (rawNumber.length === 10) {
      const formattedNumber = `(${rawNumber.slice(0, 3)})${rawNumber.slice(
        3,
        6
      )}-${rawNumber.slice(6)}`;
      setCustomerInfo({ ...customerInfo, phone: formattedNumber });
    } else {
      setCustomerInfo({ ...customerInfo, phone: e.target.value });
    }
  };

  const generateTimeSlots = (minTime, maxTime, interval) => {
    const startTime = dayjs(minTime, "HH:mm");
    const endTime = dayjs(maxTime, "HH:mm");
    const timeSlots = [];

    let currentTime = startTime;
    while (currentTime.isBefore(endTime)) {
      const slotStart = currentTime.format("HH:mm");
      const slotEnd = currentTime.add(interval, "minutes").format("HH:mm");
      timeSlots.push(`${slotStart} - ${slotEnd}`);
      currentTime = currentTime.add(interval, "minutes");
    }

    return timeSlots;
  };

  const allFieldsFilled = () => {
    return (
      customerInfo.name !== "" &&
      customerInfo.email !== "" &&
      customerInfo.phone !== "" &&
      eventInfo.pickUpDate &&
      eventInfo.pickUpTime &&
      eventInfo.pickUpLocation &&
      validateField("email", customerInfo.email) === "" &&
      validateField("phone", customerInfo.phone) === ""
    );
  };

  const validateField = (fieldKey, fieldValue) => {
    if (fieldKey === "phone") {
      return /^\(\d{3}\)\d{3}-\d{4}$/.test(fieldValue)
        ? ""
        : "Please enter a valid phone number (e.g. (709)111-1111)";
    } else if (fieldKey === "email") {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValue)
        ? ""
        : "Please enter a valid email address";
    } else {
      return "";
    }
  };

  const now = dayjs();
  const currentTime = now.format("HH:mm");
  const minDate = currentTime > "14:00" ? now.add(2, "day") : now.add(1, "day");

  const handleNext = () => {
    dispatch(customerInfoUpdated(customerInfo));
    dispatch(eventInfoUpdated(eventInfo));
    setActiveStep(4);
  };

  const handleBack = () => {
    setActiveStep(2);
  };

  const handleDateChange = (date) => {
    setEventInfo({
      ...eventInfo,
      pickUpDate: date ? new Date(date) : "",
    });
  };

  return (
    <Container className={classes.container} data-testid="customer-info">
      <Typography variant="h6" sx={{ mt: 8, marginBottom: "20px" }}>
        Customer Information
      </Typography>
      <Grid container spacing={3}>
        {Object.keys(customerInfo).map((key) => (
          <Grid item xs={12} sm={6} key={key}>
            <TextField
              fullWidth
              required={key !== "address"}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              value={customerInfo[key]}
              onChange={(e) => {
                if (key === "phone") {
                  handlePhoneChange(e);
                } else {
                  setCustomerInfo({ ...customerInfo, [key]: e.target.value });
                }
              }}
              onBlur={() => setOnFieldFocus({ ...onFieldFocus, [key]: true })}
              sx={{
                width: "90%",
                height: "5rem",
                "& .MuiInputBase-root": { backgroundColor: "white" },
              }}
              error={
                onFieldFocus[key] &&
                validateField(key, customerInfo[key]) !== ""
              }
              helperText={
                onFieldFocus[key] && validateField(key, customerInfo[key])
              }
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6" sx={{ mt: 8, mb: 4 }}>
        Event Information
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}>
            <Typography
              variant="h6"
              sx={{ mr: 2, fontSize: "18px", color: "#5d5d5d" }}>
              Pick Up Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={
                  eventInfo.pickUpDate ? dayjs(eventInfo.pickUpDate) : null
                }
                minDate={minDate}
                maxDate={dayjs().add(2, "months")}
                onChange={(date) => {
                  handleDateChange(date);
                }}
                sx={{
                  width: "60% !important",
                  "& .MuiInputBase-root": { backgroundColor: "white" },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid sm={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              variant="h6"
              sx={{ mr: 2, fontSize: "18px", color: "#5d5d5d" }}>
              Pick Up Time
              <br />
              <span style={{ fontSize: "16px" }}>(11am-7pm)</span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Select
                value={
                  eventInfo.pickUpTime
                  // || generateTimeSlots("11:00", "19:00", 60)[3] // sets the default time to 14:00-15:00
                }
                onChange={(e) => {
                  setEventInfo({ ...eventInfo, pickUpTime: e.target.value });
                }}
                sx={{
                  width: "55% !important",
                  "& .MuiSelect-select": { backgroundColor: "white" },
                }}>
                {generateTimeSlots("11:00", "19:00", 60).map((slot, index) => (
                  <MenuItem
                    key={index}
                    value={slot}
                    sx={{
                      justifyContent: "center",
                      fontSize: "1.1rem",
                      boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
                      margin: "0.5rem",
                      "&:hover": {
                        backgroundColor: "lightgrey",
                      },
                    }}>
                    {slot}
                  </MenuItem>
                ))}
              </Select>
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid sm={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              variant="h6"
              sx={{ mr: 2, fontSize: "18px", color: "#5d5d5d" }}>
              Pick Up Location
              <br />
            </Typography>
            <Select
              value={eventInfo.pickUpLocation}
              onChange={(e) => {
                setEventInfo({
                  ...eventInfo,
                  pickUpLocation: e.target.value,
                });
              }}
              sx={{
                width: "55% !important",
                "& .MuiSelect-select": { backgroundColor: "white" },
              }}>
              <MenuItem
                key={"1"}
                value={"1483 Topsail Rd."}
                sx={{
                  justifyContent: "center",
                  fontSize: "1.1rem",
                  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
                  margin: "0.5rem",
                  "&:hover": {
                    backgroundColor: "lightgrey",
                  },
                }}>
                {"1483 Topsail Rd."}
              </MenuItem>
              <MenuItem
                key={"2"}
                value={"16 Stavanger Dr."}
                sx={{
                  justifyContent: "center",
                  fontSize: "1.1rem",
                  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
                  margin: "0.5rem",
                  "&:hover": {
                    backgroundColor: "lightgrey",
                  },
                }}>
                {"16 Stavanger Dr."}
              </MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EditCollectionInfo;
