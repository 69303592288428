import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Box, Button, Paper } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { makeStyles } from "@mui/styles";
import Cake from "./cake";
import { useDispatch, useSelector } from "react-redux";
import {
  flavorSelected,
  selectFlavorChosen,
  selectColorChosen,
  activeStepUpdated,
} from "../../../redux/reducers/inStoreStandardCake";

const useStyles = makeStyles((theme) => ({
  nextButton: {
    "&.MuiButton-root": {
      display: "flex !important",
      justifyContent: "flex-end !important",
      width: "100% !important",
      color: "#f6725c !important",
      fontSize: "18px !important",
      marginTop: "5% !important",
      position: "sticky !important",
      bottom: 0,
      textAlign: "right !important",
      backgroundColor: "white !important",
      padding: theme.spacing(4),
      "&:hover": {
        backgroundColor: "white !important",
      },
      "&.Mui-disabled": {
        color: "lightgrey !important",
      },
      [theme.breakpoints.between("sm", "md")]: {
        padding: theme.spacing(3),
      },
    },
  },
}));

export default function CakeSelection({ setActiveStep, category }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [cakesList, setCakesList] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);

  const selectedCakeDetails = {
    flavor: useSelector(selectFlavorChosen).flavor,
    color: useSelector(selectColorChosen).color,
    requiresFlavorSelection: useSelector(selectFlavorChosen).required,
    requiresColorSelection: useSelector(selectColorChosen).required,
  };

  const handleNextStepClick = () => {
    setActiveStep(1);
  };

  useEffect(() => {
    async function fetchCakes() {
      const res =
        category === "standardCake"
          ? await axios.get("/api/app/data/standardCakes/allStandardCakes")
          : await axios.get("/api/app/data/cheesecakes/allCheesecakes");
      setCakesList(res.data);
      setDataIsReady(true);
    }
    fetchCakes();
  }, [dataIsReady]);

  if (!dataIsReady) {
    return <Box>Loading...</Box>;
  } else {
    return (
      <>
        <Grid container sx={{ width: "100%" }} data-testid="cake-selection">
          {cakesList.map((cake, i) => {
            return (
              <Grid item xs={12} sm={6} lg={4} key={cake.id} sx={{ mt: 3 }}>
                <Cake cake={cake} category={category}></Cake>
              </Grid>
            );
          })}
          <Button
            className={classes.nextButton}
            onClick={handleNextStepClick}
            disabled={
              (selectedCakeDetails.requiresFlavorSelection &&
                selectedCakeDetails.flavor == null) ||
              (selectedCakeDetails.requiresColorSelection &&
                selectedCakeDetails.color == null)
            }>
            Choose Size
            <ArrowForward sx={{ ml: 1 }} />
          </Button>
        </Grid>
      </>
    );
  }
}
