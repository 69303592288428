import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { theme } from "../../theme";
import ItemCard from "./ItemCards";

export default function CompanyAnniversary() {
  const { cakes } = useSelector(({ corporateProducts }) => corporateProducts);

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center", margin: "54px 0" }}
        id="companyAnniversary"
      >
        <Typography sx={{ ...theme.titleText, ...theme.titleText.sectionText }}>
          COMPANY ANNIVERSARY
        </Typography>
      </Box>
      <Grid
        container
        spacing={{ sm: 4, lg: 6 }}
        sx={{ display: "flex", flexWrap: "wrap" }}
      >
        {cakes.map((product, i) => {
          return (
            <>
              <ItemCard product={product} key={i}></ItemCard>
            </>
          );
        })}
      </Grid>
    </>
  );
}
