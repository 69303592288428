import React, { useState } from "react";
import { 
  FormControl, 
  InputLabel, 
  Select, 
  Checkbox, 
  MenuItem, 
  OutlinedInput, 
  ListItemText 
} from "@mui/material";

import {  pinkText, selectColors, theme, selectColorsOutline } from "../../../theme";

const NUMBERS = ['0','1','2','3','4','5','6','7','8','9'];

const NumberSelect = ({ state, setState }) => {

  const currentSelectedNumbersArr = state.numbers.split(",").filter(n => n!=="")

  const handleChangeNumber = (e) => {
    const newSelectedNumbersArr = [...e.target.value]
    setState({
      ...state,
      numbers: newSelectedNumbersArr.join(",")
    })
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 220 }}>
      <InputLabel id="Select Number" sx={selectColors} >Select Number</InputLabel>
      <Select
        labelId="Select Number"
        id="demo-multiple-checkbox"
        multiple
        value={currentSelectedNumbersArr}
        onChange={handleChangeNumber}
        input={<OutlinedInput label="Choose Flavor" />}
        renderValue={(selected) => selected.join(', ')}
        sx={{...selectColorsOutline, borderRadius: "24px", backgroundColor: "#F9F9F9"}}
      >
        {NUMBERS.map((number) => (
          <MenuItem key={number} value={number}>
            <Checkbox checked={currentSelectedNumbersArr.indexOf(number) > -1} sx={{"&.Mui-checked":{color: theme.palette.primary.dark}}}/>
            <ListItemText primary={number} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default NumberSelect;