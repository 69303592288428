/** @format */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Box, Typography, Link, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import heartIcon from "../../assets/icons/favoritesIcon.png";
import filledHeartIcon from "../../assets/icons/favoritesIconFill.png";

const useStyles = makeStyles((theme) => ({
  gridContainer: { padding: "10px !important" },
  imgContainer: {
    borderRadius: "20px !important",
    overflow: "hidden !important",
    marginBottom: "5px",
  },
  cakeImg: {
    height: "500px",
    width: "100% !important",
    textStyle: { ...theme.textStyle },
  },

  cakeBlockFooter: {
    display: "flex",
    flexDirection: "row",
  },
  footerButtonBar: {
    display: "flex",
    flexDirection: "row !important",
    alignItems: "center !important",
    justifyContent: "flex-end !important",
  },

  viewCakeButton: {
    ...theme.filledButton,
    borderStyle: "solid !important",
    borderWidth: "2px !important",
    borderColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.lightButton,
      border: "none !important",
    },
    [theme.breakpoints.up("sm")]: { width: "110px !important" },
    [theme.breakpoints.up("lg")]: { width: "132px !important" },
  },
  cakeFavorites: {
    display: "flex",
    flexDirection: "row !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    ...theme.filledButton,
    padding: "0 !important",
    width: "47px !important",
    borderStyle: "solid !important",
    borderWidth: "2px !important",
    borderRadius: "50% !important",
    borderColor: theme.palette.primary.dark,
    marginLeft: "20px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

function CakeListItem({ showFilter, item }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState(false);

  return (
    <Grid
      className={classes.gridContainer}
      xs={12}
      sm={showFilter ? 12 : 6}
      md={showFilter ? 6 : 4}
      item
    >
      <Paper className={classes.itemContainer} elevation={0}>
        <Box className={classes.cakeBlockGrid}>
          <Box className={classes.imgContainer}>
            <Link
              href={`/cakes/specialty-cakes/product?cid=${item._id}`}
              underline="none"
              variant="h5"
              color="black"
            >
              <img className={classes.cakeImg} src={item.images.primary} />
            </Link>
          </Box>
          <Box className={classes.cakeBlockFooter}>
            <Box
              style={{
                width: "100%",
              }}
            >
              <Typography
                className={classes.textStyle}
                style={{ fontSize: "12px", fontWeight: "700" }}
              >
                Starting at
              </Typography>

              <Typography
                className={classes.textStyle}
                style={{ fontSize: "18px", fontWeight: "700" }}
              >
                {`$${item.startingPrice.toFixed(2)} CAD`}
              </Typography>
            </Box>
            <Box className={classes.footerButtonBar}>
              <Button
                className={classes.viewCakeButton}
                onClick={() =>
                  navigate(`/cakes/specialty-cakes/product?cid=${item._id}`)
                }
              >
                VIEW
              </Button>
              {/* <div
                className={classes.cakeFavorites}
                onClick={() => setFavorite(!favorite)}
              >
                <img src={favorite ? filledHeartIcon : heartIcon} />
              </div> */}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
}

export default CakeListItem;
