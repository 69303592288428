/** @format */

import { React } from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import strawberry from "../../assets/images/holiday/strawberry.png";
import ErrorIcon from "@mui/icons-material/Error";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
    textAlign: "center",
    marginTop: "4rem !important",
  },
  contentWrapper: {
    borderRadius: "20px !important",
    backgroundColor: "#fcefeb !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex !important",
      justifyContent: "space-between",
    },
  },
  headerWrapper: {
    padding: "15px !important",
    [theme.breakpoints.up("sm")]: {
      padding: "25px 100px 25px 25px !important",
    },
  },
  heading1: {
    color: theme.palette.header.main,
    ...theme.textStyle,
    fontSize: "40px !important",
    marginBottom: "10px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "45px !important",
    },
  },
  heading2: {
    color: theme.palette.header.main,
    ...theme.textStyle,
    fontSize: "65px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "65px !important",
    },
  },
  daysLeftWrapper: {
    display: "flex",
    marginBottom: "100px !important",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  daysLeftTextWrapper: {
    backgroundColor: theme.palette.newSecondary.light,
    borderRadius: "50% !important",
    padding: "20px 30px !important",
    marginRight: "20px !important",
  },
  daysLeftText: {
    color: theme.palette.primary.dark,
    fontSize: "50px !important",
  },
  subheading: {
    color: theme.palette.secondary.dark,
    ...theme.textStyle,
    fontSize: "18px !important",
    marginBottom: "20px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px !important",
    },
  },
  cheesecake: {
    fontSize: "50px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "85px !important",
    },
  },
  imgStyle: {
    width: "100% !important",
    height: "100% !important",
    borderTopLeftRadius: "20px !important",
    borderTopRightRadius: "20px !important",
    [theme.breakpoints.up("sm")]: {
      width: "600px !important",
      borderTopRightRadius: "0px !important",
      borderBottomLeftRadius: "20px !important",
    },
  },
  minQTYBox: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    backgroundColor: theme.palette.newSecondary.light,
    borderRadius: "40px",
    textAlign: "center",
    // marginTop: "130px !important",
    padding: "10px !important",
  },
  minQTYText: {
    color: theme.palette.primary.dark,
    fontWeight: "bold !important",
    fontSize: "13px !important",
    marginLeft: "20px !important",
  },
  warningIcon: {
    color: theme.palette.primary.dark,
  },
}));

const headers = [
  {
    type: "christmas",
    texts: ["Santa arrives in", "arrives", "Here"],
    // imgPath: christmas,
  },
  {
    type: "valentine",
    texts: ["Valentine", "IS", "Here"],
    imgPath: strawberry,
  },
];

export default function Main({ type }) {
  const classes = useStyles();

  const componentDetails = headers.find((obj) => obj.type === type);

  // Get the current date
  const currentDate = new Date();

  // Get the current year
  const currentYear = currentDate.getFullYear();

  // Set Christmas date for the current year
  const christmasDate = new Date(currentYear, 1, 14); // Note: Months are 0-based, so 11 is December

  // Calculate the difference in milliseconds
  const timeDifference = christmasDate - currentDate;

  // Calculate the number of days
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return (
    <Container>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.imgWrapper}>
              <img
                src={componentDetails.imgPath}
                className={classes.imgStyle}></img>
            </Box>
            <Box className={classes.headerWrapper}>
              <Typography className={classes.heading1}>
                {"Valentine's Day is in"}
              </Typography>
              <Box className={classes.daysLeftWrapper}>
                <Box className={classes.daysLeftTextWrapper}>
                  <Typography className={classes.daysLeftText}>
                    {daysLeft}
                  </Typography>
                </Box>
                <Typography className={classes.heading2}>{"DAYS"}</Typography>
              </Box>

              <Typography className={classes.subheading}>
                {"Get the perfect gift for your loved ones"}
              </Typography>
              <Box className={classes.minQTYBox}>
                <ErrorIcon className={classes.warningIcon} />
                <Typography className={classes.minQTYText}>
                  limited number of orders are accepted
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
