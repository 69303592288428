/** @format */

import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { querySet, selectQuery } from "../../../../redux/reducers/admin";
import SearchField from "./searchField";
import QuickSearchButton from "./quickSearchButton";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  searchFieldContainer: {
    marginBottom: "20px !important",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      margin: "0 !important",
    },
  },
  searchField: {
    margin: "0 0 25px 0 !important",
    [theme.breakpoints.up("sm")]: {
      margin: "0 15px 0 0 !important",
    },
  },
  dateSearchContainer: {
    display: "flex !important",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      display: "flex !important",
      flexDirection: "row",
      margin: "0",
    },
  },
  dateSearch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "0 0 30px 0 !important",
    [theme.breakpoints.up("sm")]: {
      margin: "0 20px 0 0 !important",
    },
  },
  dateButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "0 0 30px 0 !important",
    [theme.breakpoints.up("sm")]: {
      margin: "0 20px 0 0 !important",
    },
  },
  dateSubmit: {
    border: "solid !important",
    borderWidth: "1px !important",
    borderColor: "#fd7762 !important",
    color: "#fd7762 !important",
    margin: "0 0 0 10px !important",
  },
}));

function SearchHeader() {
  const classes = useStyles();
  const pickupDateRef = useRef();

  const dispatch = useDispatch();
  const query = useSelector(selectQuery);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className={classes.searchBar} data-testid="search-header">
        <Box className={classes.searchFieldContainer}>
          <SearchField
            labelText={"Search Order ID"}
            queryBy={query.orderId}
            queryProp={"orderId"}
          />
          <SearchField
            labelText={"Search Customer Name"}
            queryBy={query.customerName}
            queryProp={"customerName"}
          />
          <SearchField
            labelText={"Search Customer E-Mail"}
            queryBy={query.customerEmail}
            queryProp={"customerEmail"}
          />
        </Box>
        <Box className={classes.dateSearchContainer}>
          <Box className={classes.dateSearch}>
            <DatePicker
              className={classes.dateSelectField}
              label="Search by Pickup Date"
              format="YYYY-MM-DD"
              inputRef={pickupDateRef}
            />
            {query.pickupDate !== "" ? (
              <Button
                className={classes.dateSubmit}
                onClick={() => dispatch(querySet({ ...query, pickupDate: "" }))}
              >
                Reset
              </Button>
            ) : (
              <Button
                className={classes.dateSubmit}
                onClick={() =>
                  dispatch(
                    querySet({
                      ...query,
                      pickupDate: pickupDateRef.current.value,
                    })
                  )
                }
              >
                Submit
              </Button>
            )}
          </Box>
          <Box className={classes.dateButtonContainer}>
            <QuickSearchButton
              label={"today"}
              dateVal={dayjs().add(1, "day").format("YYYY-MM-DD")}
            />
            <QuickSearchButton
              label={"tomorrow"}
              dateVal={dayjs().format("YYYY-MM-DD")}
            />
            <QuickSearchButton
              label={"month"}
              dateVal={dayjs().format("YYYY-MM")}
            />
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}

export default SearchHeader;
