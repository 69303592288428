/** @format */

import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@mui/styles";
import { selectQuery } from "../../../../redux/reducers/admin";
import FetchField from "./fetchField";
import FetchFieldByDate from "./fetchFieldByDate";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    display: "flex !important",
    flexDirection: "row",
    alignItems: "flex-end",
    width: "90% !important",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  searchFieldContainer: {
    textAlign: "center",
  },
  searchField: {
    margin: "0 0 25px 0 !important",
    [theme.breakpoints.up("sm")]: {
      margin: "0 15px 0 0 !important",
    },
  },
}));

function FetchHeader() {
  const classes = useStyles();
  const query = useSelector(selectQuery);

  return (
    <Box
      className={classes.searchBar}
      data-testid="search-header"
      sx={{ mt: 3 }}
    >
      <FetchField labelText={"Fetch order by Name"} fetchParam={"name"} />
      <FetchField
        labelText={"Fetch order by customer email"}
        fetchParam={"customerEmail"}
      />
      <FetchFieldByDate />
    </Box>
  );
}

export default FetchHeader;
