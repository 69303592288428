import React from "react";
import { HashLink } from "react-router-hash-link";
import {
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { sectionLink, theme } from "../../theme";
import image from "../../assets/images/services/corporate-event.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
export default function CoroporateEventsSplash() {
  const themee = useTheme();
  const isSmallScreen = useMediaQuery(themee.breakpoints.down("md"));
  const [coroporateSection, setCoroporateSection] = React.useState("");

  const handleChange = (event) => {
    setCoroporateSection(event.target.value);
  };
  return (
    <>
      <Box style={{ paddingTop: 20 }}>
        <Box
          sx={{
            backgroundColor: "#FFFBFA",
            borderRadius: "32px",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: { xs: "0 0 24px 24px", md: "24px 0 0 24px" },
              backgroundColor: "gray",
              minHeight: {
                xs: "300px",
                sm: "300px",
                md: "500px",
              },
              width: isSmallScreen ? "100%" : "60%",
              marginTop: isSmallScreen ? "5rem" : "0",
            }}
          ></Box>
          <Box
            sx={{
              padding: !isSmallScreen ? 3 : 0,
              margin: isSmallScreen ? "auto" : "",
              alignItems: isSmallScreen ? "center" : "",
            }}
          >
            <Typography
              sx={{
                ...theme.titleText,
                ...theme.titleText.splashText,
                textAlign: isSmallScreen ? "center" : "left",
                marginTop: isSmallScreen ? "4rem" : "",
                padding: 0,
              }}
            >
              CORPORATE EVENTS
            </Typography>
            <Typography
              sx={{
                margin: isSmallScreen ? "0 auto" : "",
                marginLeft: !isSmallScreen ? "2rem" : "",
                marginTop: isSmallScreen ? "3rem" : "4rem",
                ...theme.textStyle,
                fontSize: "1.3rem",
                width: isSmallScreen ? "70%" : "80%",
                alignItems: "center",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              Indulge in our exquisite cakes and desserts for your business
              events and team treats
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            justifyContent: "center",
            width: "80%",
            margin: "0 auto",
            marginTop: "3rem",
          }}
        >
          <FormControl fullWidth sx={{ margin: "8px" }}>
            <InputLabel
              id="chooseCoroporateSection"
              sx={{ borderRadius: "98px" }}
            >
              Choose a Section
            </InputLabel>
            <Select
              labelId="chooseCoroporateSection"
              id="chooseCoroporateSection"
              value={coroporateSection}
              label="Choose a Section"
              onChange={handleChange}
              sx={{
                borderRadius: "98px",
                backgroundColor: "#F9F9F9",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "98px", // Apply border radius to the OutlinedInput
                },
              }}
            >
              <MenuItem value={1}>
                <HashLink smooth to="#companyAnniversary" style={sectionLink}>
                  Company Anniversary
                </HashLink>
              </MenuItem>
              <MenuItem value={2}>
                <HashLink smooth to="#employeeAppreciation" style={sectionLink}>
                  Employee Appreciation
                </HashLink>
              </MenuItem>
              <MenuItem value={3}>
                <HashLink smooth to="#officeSnacks" style={sectionLink}>
                  Office Snacks
                </HashLink>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}
