import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  heading: {
    color: "#ffffff !important",
    fontSize: "3rem !important",
  },
  wrapperBox: {
    backgroundColor: "#fd7762 !important",
    backgroundSize: "contain !important",
    padding: "10px 0 10px 0 !important",
    width: "100% !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
});

export default function Header() {
  const classes = useStyles();

  return (
    <Box className={classes.wrapperBox}>
      <Typography align="center" className={classes.heading}>
        IN-STORE ORDERING SYSTEM
      </Typography>
    </Box>
  );
}
