import React, { useState, useEffect } from "react";
import { Box, Checkbox, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "./header";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "310px",
    width: "100%",
    display: "grid !important",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: "0.5rem",
  },
  gridColumn: {
    gridColumn: "1 / 4",
    gridRow: "1 / 4",
  },
  standardAccessoriesImg: {
    width: "100% !important",
    height: "310px !important",
    objectFit: "contain !important",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
}));

export default function SingleImage({ accessory }) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid item className={classes.gridColumn}>
          <img
            className={classes.standardAccessoriesImg}
            src={accessory.images.primary}
            alt={accessory.subtype}
          />
        </Grid>
      </Grid>
    </>
  );
}
