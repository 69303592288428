import React from "react";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";
import Main from "./main";
import Header from "../../components/header";

const ContactUs = () => {
    return (
        <>
            <Header></Header>
            <NewTopAppBar></NewTopAppBar>
            <Main></Main>
            <Footer></Footer>
        </>

    )
}


export default ContactUs;
