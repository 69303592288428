/** @format */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { numberCakeNumberSelected } from "../../../../redux/reducers/cake.js";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    display: "block",
    marginBottom: 10,
  },
  textStyle: { ...theme.textStyle },
  inputFieldText: {
    ...theme.textStyle,
    color: "#000",
  },
}));

export default function NumberCake({ parentId, price, parentDisplayName }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [number, setNumber] = useState("");
  const [numberError, setNumberError] = useState(false);

  const pattern = new RegExp("^[0-9]+$");

  const handleTextChange = (val) => {
    if (val === "" || pattern.test(val) & (val.length === 2)) {
      setNumberError(false);
      setNumber(val);

      dispatch(
        numberCakeNumberSelected({
          number: val,
          selection: val,
          parentId,
          price,
          parentDisplayName,
        })
      );
    } else {
      setNumber(val);
      setNumberError(true);
    }
  };

  return (
    <TextField
      className={classes.input}
      label="Number"
      error={numberError}
      helperText={numberError ? "You must enter a two digit number" : ""}
      onChange={(e) => handleTextChange(e.target.value)}
      InputLabelProps={{
        className: classes.inputFieldText,
      }}
      InputProps={{ className: classes.textStyle }}
      FormHelperTextProps={{ className: classes.textStyle }}
      data-testid="number-cake"
    ></TextField>
  );
}
