import React, { useState, useEffect } from "react";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { pinkText, theme } from "../../../theme";
import Name from "../components/name";
import CompanyColors from "../components/companyColors";
import { updateCart } from "../../../redux/reducers/cart";
import {
  flavorsReset,
  selectedPackagingReset,
  selectedSizeReset,
} from "../../../redux/reducers/macarons";
import { useDispatch } from "react-redux";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
}));

export default function NameCakeModalContent({ handleClose, product }) {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  let tempEmail = localStorage.getItem("tempEmail");

  const getPrice = (companyName) => {
    const pricePerOneLetter = product.basePrices.filter(basePrice=>basePrice.name==="corporate-name-cake-price-one-letter")[0].price
    const pricePerTwoLetter = product.basePrices.filter(basePrice=>basePrice.name==="corporate-name-cake-price-two-letter")[0].price
    const nameLength = companyName.length;

    let price = 0;

    if(nameLength < 2) {
      price = pricePerOneLetter
    } else {
      price = parseInt(nameLength / 2) * pricePerTwoLetter;
      price = price + (nameLength % 2) * pricePerOneLetter;
    }

    // calculate price based on selected decorations
    const selectedDecorationsArr = state.decorations.split(",").filter(n => n!=="")
    const decorationOptValues = product.customizationOptions.filter(opt => opt.name==="corporate-name-cake-decorations")[0].values

    price = selectedDecorationsArr.reduce((accumulator, decoration) => {
      const decorationPrice = decorationOptValues.filter(value => value.name===decoration)[0].price
      return accumulator+decorationPrice
    }, price);

    return price;
  }

  const initColors = { light:[], pastel:[], normal:[], dark:[] };

  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [state, setState] = useState({
    name: "",
    size: "",
    price: "",
    flavor: "",
    color: initColors,
    companyName: "",
    quantity: 1,
    type: product.type,
    decorations: ""
  });

  const addToCart = async () => {
    const selectedColors = [
      ...state.color.light,
      ...state.color.pastel,
      ...state.color.normal,
      ...state.color.dark,
    ].join(",")

    if (!selectedColors) {
      return alert("Please select company colors");
    }

    if (!state.flavor) {
      return alert("Please select a flavor");
    }

    if (!state.companyName) {
      return alert("Please enter Company Name");
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);

      let productToAdd = {
        id: Date.now(),
        name: product.name,
        productId: product._id,
        size: state.size,
        imgPath: product.imgPath,  
        flavor: state.flavor,
        type: product.type,
        quantity: 1,
        price: state.price,
        customizations: [{
          parentDisplayName: "Colors",
          selection: selectedColors
        }, {
          parentDisplayName: "Company Name",
          selection: state.companyName
        }]
      };

      if (state.decorations) {
        productToAdd.customizations.push({
          parentDisplayName: "Decorations",
          selection: state.decorations
        })
      }

      try {
        const res = await axios.post("/api/cart/addProduct/", {
          userEmail: currentUser ? currentUser.email : tempEmail,
          product: productToAdd,
        });

        if (res.status === 200) {
          handleClose();
          window.setTimeout(() => {
            dispatch(updateCart(res.data));
            dispatch(selectedSizeReset());
            dispatch(flavorsReset());
            dispatch(selectedPackagingReset());
            setSuccess(true);
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        console.error(error)
        alert(
          "We could not add the product. Please make sure all required fields are provided."
        );
        setSuccess(false);
        setLoading(false);
      }
    }
  };

  useEffect(()=>{
    if (state.companyName) {
      setState({ ...state, price: getPrice(state.companyName)})
    }
  }, [state.companyName, state.decorations])

  return (
    <div sx={{ overflow: "hidden" }}>
      <Typography
        sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}
        onClick={handleClose}
      >
        <CloseIcon />
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ textTransform: "uppercase" }}>
          {product.name}
        </Typography>
        <Box sx={{ display: "flex" }}>
          {product.basePrices.map((basePrice, i) => {
            return (
              <>
                <Typography
                  key={i}
                  sx={{ ...pinkText, marginRight: "24px", fontWeight: "600" }}
                >
                  { `$${basePrice.price.toFixed(2)} CAD` }
                </Typography>
              </>
            );
          })}
        </Box>
        <Box sx={{ display: "flex" }}>
          {product.basePrices.map((basePrice, i) => {
            return (
              <>
                <Typography
                  key={i}
                  sx={{ color: "gray", marginRight: "24px", fontSize: "12px" }}
                >
                  {basePrice.description}
                </Typography>
              </>
            );
          })}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "row",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", margin: "24px" }}>
            <Name product={product} state={state} setState={setState}></Name>
          </Box>
          
          <Box sx={{ display: "flex", flexDirection: "column", margin: "24px" }}>
            <CompanyColors setState={setState} state={state}></CompanyColors>
          </Box>

        </Box>
      </Box>
      
      <Box sx={{ display: "flex", justifyContent: "center", margin: "24px" }}>
        <Button
          sx={{
            ...theme.secondaryButton,
            width: { xs: "100%", sm: "50%", md: "25%" },
          }}
          onClick={() => addToCart()}
          disabled={loading ? true : false}
        >
          Add To Cart
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "65px",
                color: "#fff",
              }}
            />
          )}
        </Button>
        {success && (
          <Typography className={classes.textStyle} sx={{ color: "green" }}>
            Success! The item is added to the cart
          </Typography>
        )}
      </Box>
    </div>
  );
}
