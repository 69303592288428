/** @format */

import React, { useState, useEffect } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Quantity from "./Quantity";
import { useDispatch, useSelector } from "react-redux";
import {
  orderInfoSet,
  selectOrderInfo,
} from "../../../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  itemGridContainer: {
    display: "flex !important",
    flexDirection: "row !important",
    margin: "0 0 0 1rem !important",
  },
  cell: {
    display: "flex !important",
    flexDirection: "column !important",
    margin: "0 1rem 0 0 !important",
  },

  productImg: {
    width: "190px !important",
    height: "auto !important",
    borderRadius: "20px !important",
  },

  dropDownSelect: {
    margin: "0 0 16px 0 !important",
  },

  fieldHead: {
    fontWeight: "bold !important",
  },
  flexCell: {
    display: "flex !important",
    flexDirection: "row !important",
    margin: "1rem 0 1rem 0 !important",
  },
  quantityCell: {
    display: "inline-flex !important",
    flexDirection: "column !important",
    margin: "0 0 1rem 0 !important",
  },

  quantityGrid: {
    display: "flex !important",
    paddingRight: "100px !important",
    justifyContent: "flex-end !important",
    margin: "0 0 1rem 0 !important",
  },

  submitBtn: {
    ...theme.outlinedButton,
  },
  buttonContainer: {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "center !important",
    margin: "2rem 0 0 0 !important",
  },
}));

function EditAssortments({ index }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const orderInfo = useSelector(selectOrderInfo);

  const [assortments, setAssortments] = useState([]);
  const [selectedAssortment, setSelectedAssortment] = useState(
    orderInfo.products[index].name
  );
  const [quantity, setQuantity] = useState(orderInfo.products[index].quantity);
  const [editingOrder, setEditingOrder] = useState();

  useEffect(() => {
    const getAssortments = async () => {
      try {
        const assortmentList = await axios.get(`/api/app/data/assortments`);
        setAssortments(assortmentList.data);
      } catch (err) {}
    };
    const getOrderById = async () => {
      try {
        const order = await axios.get(`/api/orders/orderId`, {
          params: { orderId: orderInfo.orderId },
        });

        setEditingOrder(order.data);
      } catch (e) {
        console.log(e);
      }
    };

    getAssortments();
    getOrderById();
  }, []);

  useEffect(() => {
    if (editingOrder) {
      editOrder();
    }
  }, [selectedAssortment, quantity]);

  const editOrder = () => {
    const selectedProduct = assortments.find(
      (a) => a.name === selectedAssortment
    );

    const newProduct = {
      id: editingOrder.products[index].id,
      imgPath: editingOrder.products[index].imgPath,
      name: selectedProduct.name,
      price: selectedProduct.price,
      quantity: quantity,
      type: editingOrder.type,
    };

    const newProductArr = editingOrder.products;
    newProductArr[index] = newProduct;

    const newOrder = { ...editingOrder, products: newProductArr };

    dispatch(orderInfoSet(newOrder));
  };

  return (
    <>
      <Box className={classes.itemGridContainer} data-testid="edit-assortments">
        <Box className={classes.cell}>
          <img
            src={orderInfo.products[index].imgPath}
            alt="cake-pic"
            className={classes.productImg}
          />
        </Box>
        <Box className={classes.cell}>
          <FormControl className={classes.dropDownSelect} variant="outlined">
            <InputLabel shrink={true} id="name">
              Name
            </InputLabel>
            <Select
              label="Name"
              labelId="name"
              value={selectedAssortment}
              onChange={(e) => setSelectedAssortment(e.target.value)}
            >
              {assortments.length !== 0
                ? assortments.map((a) => {
                    return <MenuItem value={a.name}>{a.name}</MenuItem>;
                  })
                : null}
            </Select>
          </FormControl>
          <Box className={classes.quantityCell}>
            <Quantity
              quantity={quantity}
              setQuantity={setQuantity}
              width="130px"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EditAssortments;
