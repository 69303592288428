import React from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Box, Typography, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useMediaQuery, useTheme } from "@mui/material";
import specialtyCake from "../../assets/images/productCategory/specialtyCake.jpg";
import standardCake from "../../assets/images/productCategory/standardCake.jpg";
import cheesecake from "../../assets/images/productCategory/cheesecake.jpg";
import patisserie from "../../assets/images/productCategory/patisserie.jpg";

// Define the products array
const products = [
  { id: 1, title: 'Specialty Cakes', imageUrl: specialtyCake, description: 'Elevate any occasion with our extravagant specialty cakes—crafted to make a statement and leave lasting impressions', url: "/cakes/specialty-cakes"},
  { id: 2, title: 'Standard Cakes', imageUrl: standardCake, description: 'Simple yet elegant, our standard cakes bring flavor and beauty together, offering a delightful option for any budget.', url: "/cakes/standard-cakes" },
  { id: 3, title: 'Cheesecakes', imageUrl: cheesecake, description: 'Indulge in our divine cheesecakes, featuring both baked and no-bake varieties that are smooth, rich, and utterly delicious.', url: "/cakes/cheesecakes" },
  { id: 4, title: 'Patisserie', imageUrl: patisserie, description: 'Treat yourself to our delightful selection of cookies, unique cakes, macarons, pastries, cupcakes, and more—perfect for any sweet craving.', url: "/patisserie/desserts" },
];

const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        maxWidth: 300,
        margin: 'auto',
        boxShadow: 3,
        position: 'relative',
        borderRadius: '12px',
        backgroundColor: 'white',
        [theme.breakpoints.up("lg")]: {
            top: '-300px',
        },
    },
  }));

const ProductCard = ({ title, imageUrl, description, url }) => {
    const classes = useStyles();
    let navigate = useNavigate();

  return <Card className={classes.cardWrapper}>
    <CardMedia component="img" height="100%" margin="auto" image={imageUrl} alt={title} sx={{mb: 2}} />
    <CardContent>
      <Typography variant="h6" sx={{ fontFamily: "Moul", mb: 3, textAlign: 'center' }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ fontFamily: "Inter", textAlign: 'center', mb:6 }}>
        {description}
      </Typography>
      <Button
        onClick={() => navigate(url)}
        variant="contained"
        sx={{ backgroundColor: '#fd7762', color: 'white', width: '100%', fontWeight: 'bold', fontFamily: "Inter", "&:hover":{backgroundColor: "#fd7762cc"}, borderRadius: "8px" }}
      >
        Explore Now
      </Button>
    </CardContent>
  </Card>
};

const ProductGrid = () => {
    
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg")); 

  return (
    isMobileOrTablet ? <Container maxWidth="xl" sx={{marginBottom: '2rem'}}>
            <Grid container spacing={4} justifyContent="center">
            {products.map((product) => (
                <Grid item xs={12} sm={6} md={3} key={product.id}>
                <ProductCard {...product} />
                </Grid>
            ))}
            </Grid>
        </Container> : 
        <Box sx={{ position: 'relative', marginTop: '2rem' }}>
            <Box sx={{ backgroundColor: 'white', height: '350px' }}></Box>
            
            <Box sx={{
                height: "350px",
                backgroundColor: '#fbdada',
                borderRadius: '8px',
                position: 'relative',
            }}>
                <Container maxWidth="xl" sx={{padding: 0}}>
                    <Grid container justifyContent="center">
                    {products.map((product) => (
                        <Grid item xs={12} sm={6} md={3} key={product.id}>
                            <ProductCard {...product} />
                        </Grid>
                    ))}
                    </Grid>
                </Container>
            </Box>
        </Box>
  );
};

export default ProductGrid;
