import ProductsBanner from "./banner";
import ProductGrid from "./categoryCard";

const Main = () => {
    return (
        <>
            <ProductsBanner />
            <ProductGrid />
        </>

    )
};

export default Main;