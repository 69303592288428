import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Grid } from "@mui/material";
import croissantBanner from "../../assets/images/croissant_banner.png";

const Banner = () => {
  let navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "relative",
        height: { xs: "100px", sm: "200px", md: "250px", lg: "400px" },
        backgroundImage: `url(${croissantBanner})`,
        backgroundSize: "cover",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 20px",
        textAlign: "left",
      }}>
      <Grid container justifyContent="left">
        <Grid item xs={12} sx={{ pl: { xs: 0, md: "40px" } }}>
          <Typography
            variant="h3"
            component="h1"
            sx={{
              color: "white",
              fontWeight: "bold",
              fontFamily: "Moul",
              fontSize: { xs: "18px", sm: "36px", md: "48px" },
              maxWidth: { xs: "800px", md: "1100px" },
            }}>
            Treat yourself
          </Typography>
          <Typography
            variant="h3"
            component="h1"
            sx={{
              color: "white",
              fontWeight: "bold",
              fontFamily: "Moul",
              fontSize: { xs: "18px", sm: "36px", md: "48px" },
              mb: 3,
              maxWidth: { xs: "800px", md: "900px" },
            }}>
            to our indulgent range of desserts.
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/product-categories")}
            sx={{
              backgroundColor: "#FF6F61",
              "&:hover": {
                backgroundColor: "#fd7762cc",
              },
              padding: { xs: "5px 10px", sm: "8px 10px", md: "8px 16px" },
              fontSize: { xs: "10px", sm: "14px", md: "18px" },
              fontFamily: "Inter",
              textTransform: "none",
              borderRadius: "8px",
            }}>
            Place Online Order
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Banner;
