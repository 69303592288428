/** @format */

import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../auth";
import { getAuth } from "firebase/auth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#fd776280",
    position: "relative",
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "2rem 1rem",
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "30%",
    },
  },
  title: {
    color: "#fd7762",
    fontSize: "32px !important",
    marginBottom: "1rem !important",
    ...theme.textStyle,
  },
  subtitle2: {
    fontWeight: "bold",
    marginBottom: "1rem !important",
  },
  signinBtn: {
    ...theme.outlinedButton,
    marginRight: "10px !important",
    marginTop: "40px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.light,
      borderColor: theme.palette.primary.dark,
    },
  },
  button: {
    ...theme.containedButton,
    width: "100%",
    marginTop: "20px !important",
    marginBottom: "1rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },

  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#fd7762",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd7762",
      },
    },
  },
  joinNow: {
    color: "#fd7762",
    ...theme.textStyle,
  },
}));

export default function Form() {
  initializeApp(firebaseConfig());

  const classes = useStyles();
  const timer = useRef();
  const auth = getAuth();
  let navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [dob, setDOB] = useState(null);
  const [dobError, setDOBError] = useState(false);
  const [loading, setLoading] = useState(false);
  const userEmail = localStorage.getItem("affectedUserEmail");
  let isValid = true;

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleFirstNameChange = (input) => {
    if (!input) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    setFirstName(input);
  };

  const handleLastNameChange = (input) => {
    if (!input) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    setLastName(input);
  };

  const handleDOBChange = (input) => {
    if (!input) {
      setDOBError(true);
    } else {
      setDOBError(false);
    }
    setDOB(input);
  };

  const handleSignup = async () => {
    if (!firstName) {
      isValid = false;
      setFirstNameError(true);
    }
    if (!lastName) {
      isValid = false;
      setLastNameError(true);
    }
    if (!dob) {
      isValid = false;
      setDOBError(true);
    }

    if (isValid) {
      setLoading(true);
      try {
        const res = await axios.post("/api/users", {
          firstName,
          lastName,
          email: userEmail,
          dob,
          role: "registered_user",
        });

        if (res.status === 200) {
          const affectedUser = await axios.put(
            `/api/affectedUsers/updateFirstVisitFlag?userEmail=${userEmail}`,
            null,
            {
              headers: {
                authToken: localStorage.getItem("token"),
              },
            }
          );
          if (affectedUser.status === 200) {
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.removeItem("affectedUserEmail");
            setLoading(false);
            navigate("/");
          }
        }
      } catch (error) {
        setLoading(false);
        return alert(
          "Sorry, seems something is wrong on our end! Please contact us at contact@cakeyhand.com."
        );
      }
    }
  };

  return (
    <Box className={classes.wrapper} data-testid="sign-up">
      <Paper className={classes.container} elevation={10}>
        <Box>
          <Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography className={classes.title}>
                SECURITY ENHANCEMENT
              </Typography>
              <Typography className={classes.subtitle2}>
                We are enhancing the website security and we need your help
              </Typography>
              <Typography className={classes.subtitle3}>
                In order to proceed to the website as a registered user and shop
                more securily please re-enter your info below.
              </Typography>
            </Box>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  width: "100%",
                  boxShadow: 3,
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ display: "flex", mb: 2, mt: 3 }}>
                <TextField
                  sx={{ mr: 2 }}
                  error={firstNameError}
                  fullWidth
                  id="outlined-required"
                  label="First Name"
                  defaultValue=""
                  margin="normal"
                  helperText={firstNameError ? "enter your first name" : ""}
                  className={classes.root}
                  InputLabelProps={{
                    style: { color: "grey" },
                  }}
                  onChange={(e) => {
                    handleFirstNameChange(e.target.value);
                  }}
                  data-testid="firstName"
                />
                <TextField
                  error={lastNameError}
                  fullWidth
                  id="outlined-required"
                  label="Last Name"
                  defaultValue=""
                  margin="normal"
                  helperText={lastNameError ? "enter your last name" : ""}
                  className={classes.root}
                  InputLabelProps={{
                    style: { color: "grey" },
                  }}
                  onChange={(e) => {
                    handleLastNameChange(e.target.value);
                  }}
                  data-testid="lastName"
                />
              </Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  slotProps={{ textField: { size: "large" } }}
                  className={classes.root}
                  onChange={(value) => {
                    handleDOBChange(value);
                  }}
                ></DatePicker>
              </LocalizationProvider>
              {dobError && (
                <Typography
                  sx={{ fontSize: "12px", color: "#fd7762", mt: 0.5 }}
                >
                  enter your date of birth
                </Typography>
              )}
              <Box sx={{ position: "relative" }}>
                <Button
                  size={"large"}
                  variant="contained"
                  fullwidth
                  className={classes.button}
                  onClick={handleSignup}
                  data-testid="signup-btn"
                >
                  <Typography className={classes.textStyle}>Submit</Typography>
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-6px",
                      marginLeft: "65px",
                      color: "#fff",
                    }}
                  />
                )}
              </Box>
            </Box>
            <Divider />
            <br />
            <Box
              sx={{
                display: "flex",
                color: "#828282",
                justifyContent: "center",
              }}
            >
              <Typography
                className={classes.textStyle}
                sx={{ fontSize: "14px" }}
              >
                {"CAKEYHAND DESSERT FACTORY INC."} {new Date().getFullYear()}{" "}
                {"© All rights reserved"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
