import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
} from "@mui/material";
import axios from "axios";

import { capitalizeFirstLetter } from "../../../../utils/common";
import { theme } from "../../../../theme";

// these values should match the SubscriptionProduct "recurring" values in backend
const SUBSCRIPTION_FREQUENCY = [
  "twice-a-week",
  "weekly",
  "bi-weekly",
  "monthly"
]

const SUBSCRIPTION_BUDGET= [ 100, 300, 500 ]

export default function Selections({ setModalView, modalView }) {

  const [loadingProducts, setLoadingProducts] = useState(false);

  // TODO 
  // based on selected productId, change the customization product options

  const initProductOptions = [
    { name: "Assorted Cookies" }, 
    { name: "Assorted Cupcakes" }, 
    { name:  "Scones" }, 
    { name: "Croissants" }, 
    { name:  "Danish" }, 
    { name: "Creamy Cakes", description: "need refrigination" }
  ]

  const initSelections = {
    products: "",
    frequency: "",
    budget: "",
  }

  const initSubscriptionProducts = []

  const [subscriptionProducts, setSubscriptionProducts] = useState(modalView.data?.initSubscriptionProducts || initSubscriptionProducts)
  const [productOptions, setProductOptions] = useState(modalView.data?.initProductOptions ||initProductOptions);
  const [selections, setSelections] = useState(modalView.data?.initSelections || initSelections);
  const [selectedProductsArr, setSelectedProductsArr] = useState(modalView.data?.selectedProductsArr || [])

  const handleProductSelectChange = (e) => {
    const targetProduct = e.target.name;
    const indexOfProduct = selectedProductsArr.indexOf(targetProduct)

    const tempSelectedDecorationsArr = [...selectedProductsArr]

    if (indexOfProduct !== -1) {
      tempSelectedDecorationsArr.splice(indexOfProduct, 1)
    } else {
      tempSelectedDecorationsArr.push(targetProduct)
      setSelectedProductsArr(tempSelectedDecorationsArr)
    }

    setSelectedProductsArr(tempSelectedDecorationsArr)
    setSelections({ ...selections, products: tempSelectedDecorationsArr.join(",")})
  };

  const handleFrequencyChange = (e) => {
    const targetFrequency = e.target.value;

    if (selections.frequency === targetFrequency) {
      setSelections({ ...selections, frequency: "" })
    } else {
      setSelections({ ...selections, frequency: targetFrequency })
    }
  }

  const handleBudgetChange = (e) => {
    const targetBudget = e.target.value;

    if (selections.budget === targetBudget) {
      setSelections({ ...selections, budget: "" })
    } else {
      setSelections({ ...selections, budget: parseFloat(targetBudget) })
    }
  }

  const getSubscriptionProduct = (frequency, budget) => {
    const result = subscriptionProducts.filter(product => 
      product.recurring === frequency && 
      product.price === budget &&
      product.name.startsWith("Office Snacks")
    )

    return result.length > 0 ? result[0] : null
  }

  const handleNextBtnClick = () => {

    if (loadingProducts) return;

    if (!selections.products) {
      return alert("Please select Products");
    }

    if (!selections.frequency) {
      return alert("Please select Frequency");
    }

    if (!selections.budget) {
      return alert("Please select Budget");
    }

    const subscriptionProduct = getSubscriptionProduct(selections.frequency, selections.budget)

    setModalView({ 
      view: "subscription", 
      data: { 
        selections, 
        subscriptionProduct,
        initSubscriptionProducts: subscriptionProducts,
        initProductOptions: productOptions,
        initSelections: selections,
        selectedProductsArr,
      } 
    })
  }

  useEffect(async()=>{
    if(subscriptionProducts === initSubscriptionProducts) {
      setLoadingProducts(true)
      const res = await axios.get("/api/subscriptions/product");
      setSubscriptionProducts(res.data)
      setLoadingProducts(false)
    }
  }, [subscriptionProducts])

  useEffect(()=>{
    if (!selections.products) {
      setSelectedProductsArr(selections.products.split(",").filter(n => n!==""))
    }
  }, [selections.products])

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h4">OFFICE SNACKS</Typography>
      </Box>
      <Box sx={{ margin: "14px" }}>
        <Typography
          sx={{ color: theme.palette.primary.dark, fontWeight: "600" }}
        >
          Step 1 - Choose The Product
        </Typography>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid container>
            {
              productOptions.map(product => (

                product.description 
                ? <Grid item xs={6} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ "&.Mui-checked": { color: theme.palette.primary.dark }}}
                          checked={selectedProductsArr.indexOf(product.name) !== -1}
                          onChange={handleProductSelectChange}
                          name={product.name}
                        />
                      }
                      label={
                        <Box>
                          <Typography>{product.name}</Typography>
                          <Typography sx={{ color: "gray", fontSize: "12px" }}>
                            {product.description}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>
                : <Grid item xs={6} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ "&.Mui-checked": { color: theme.palette.primary.dark }}}
                          checked={selectedProductsArr.indexOf(product.name) !== -1}
                          onChange={handleProductSelectChange}
                          name={product.name}
                        />
                      }
                      label={product.name}
                    />
                  </Grid>
                )
              )
            }
            
          </Grid>
        </FormGroup>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Typography
          sx={{ color: theme.palette.primary.dark, fontWeight: "600" }}
        >
          Step 2 - Choose The Frequency
        </Typography>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >

          <RadioGroup
            aria-labelledby="subscription-frequency-radio-group"
            name="subscription-frequency-radio-group"
          >
            <Grid container>
              {
                SUBSCRIPTION_FREQUENCY.map(frequency => 
                    <Grid item xs={6} sm={6} md={3}>
                      <FormControlLabel 
                        value={frequency} control={<Radio />} 
                        label={capitalizeFirstLetter(frequency)}
                        className="radioButton"
                        onChange={handleFrequencyChange}
                        checked={selections.frequency === frequency}
                      />
                    </Grid>
                )
              }
            </Grid>
          </RadioGroup>

        </FormGroup>
      </Box>
      <Box sx={{ margin: "24px" }}>
        <Typography
          sx={{ color: theme.palette.primary.dark, fontWeight: "600" }}
        >
          Step 3 - Choose The Budget
        </Typography>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >

          <RadioGroup
            aria-labelledby="subscription-budget-radio-group"
            name="subscription-budget-radio-group"
          >
            <Grid container>
              {
                SUBSCRIPTION_BUDGET.map((budget, i) => 
                  <Grid item xs={6} sm={6} md={4}>
                    <FormControlLabel 
                      value={budget} control={<Radio />} 
                      label={`$${budget.toFixed(2)} CAD`}
                      className="radioButton"
                      onChange={handleBudgetChange}
                      checked={parseFloat(selections.budget) === parseFloat(budget)}
                    />
                  </Grid>
                )
              }
            </Grid>
          </RadioGroup>

        </FormGroup>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", margin: "24px" }}>
        <Button
          sx={{
            ...theme.secondaryButton,
            width: { xs: "100%", sm: "50%", md: "25%" },
          }}
          onClick={handleNextBtnClick}
          disabled={loadingProducts}
        >
          Next
        </Button>
      </Box>
    </>
  );
}
