import React from "react";
import { 
  Typography, 
  Box, 
  FormControlLabel, 
  Checkbox, 
} from "@mui/material";
import {  pinkText, theme } from "../../../theme";

const DecorationSelect = ({decorationOption, state, setState}) => {

  const selectedDecorationsArr = state.decorations.split(",").filter(n => n!=="")

  const handleCheckboxChange = (e) => {
    const targetDecoration = e.target.name;
    const indexOfDecoration = selectedDecorationsArr.indexOf(targetDecoration)

    if (indexOfDecoration !== -1) {
      selectedDecorationsArr.splice(indexOfDecoration, 1)
    } else {
      selectedDecorationsArr.push(targetDecoration)
    }

    setState({ ...state, decorations: selectedDecorationsArr.join(",")})
  }

  const buildDecorationOptions = (optionValues) => {
    const optionValuesWithPrices = optionValues?.filter(option => option.price !== 0)
    const optionValuesWithoutPrices = optionValues?.filter(option => option.price === 0)

    return (
      <>
        { 
          optionValuesWithPrices.map((optionValue) => (
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    sx={{"&.Mui-checked":{color: theme.palette.primary.dark}}} 
                    checked={selectedDecorationsArr.indexOf(optionValue.name) !== -1}
                    onChange={handleCheckboxChange}
                    name={optionValue.name}
                  />
                } 
                label={optionValue.name}
              />
              <Typography sx={{...pinkText, fontWeight: "600"}}>${optionValue.price.toFixed(2)} CAD</Typography>
            </Box>
          ))
        }

        {
          optionValuesWithoutPrices.length > 0  &&
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              {
                optionValuesWithoutPrices.map((optionValue) => (
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        sx={{"&.Mui-checked":{color: theme.palette.primary.dark}}}
                        checked={selectedDecorationsArr.indexOf(optionValue.name) !== -1}
                        onChange={handleCheckboxChange}
                        name={optionValue.name}
                      />} 
                    label={optionValue.name}
                  />
                ))
              }
          </Box>
        }
      </>
    )
  }


  return (
    <Box sx={{margin: "12px"}}>
      <Typography sx={{color: "#676767"}}>{decorationOption.displayableName}</Typography>
      { buildDecorationOptions(decorationOption.values)}
    </Box>
  )

}

export default DecorationSelect