/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Grid, Box, Button, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  selectProducts,
  collectionDateChosen,
} from "../../../redux/reducers/cart";

const useStyles = makeStyles((theme) => ({
  availableDay: {
    textAlign: "center",
    backgroundColor: "#fd7762 !important",
    paddingTop: "15px !important",
    minHeight: "61.5px !important",
    marginRight: "10px !important",
    marginBottom: "10px !important",
  },
  textStyle: { ...theme.textStyle },
}));

export default function Available(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const { month, day, slots, schedule } = props;

  const monthNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "june",
    "july",
    "aug",
    "sept",
    "oct",
    "nov",
    "dec",
  ];

  const handleDateSelection = (month, day, timeSlot) => {
    const d = new Date();
    const currentYear = d.getFullYear();
    const monthSelectedByUser = schedule.find((m) => m.month === month);
    const daySelectedByUser = monthSelectedByUser.days.find(
      (d) => d.day === day
    );
    const cake = products.find((p) => p.type === "cake");
    if (cake) {
      if (daySelectedByUser.availabilityStatus != "available") {
        return alert(
          "sorry, your cart currently contains a CUSTOM cake product. We're booked for this date for CUSTOM cakes. Please remove the CUSTOM cake only to be able to purchase other type of products (i.e. predefined cakes, macarons, assorted boxes)"
        );
      }
    }

    const monthIndex = monthNames.indexOf(month);
    const d1 = new Date(currentYear, monthIndex, day);
    const orderDate = {
      date: d1,
      timeSlot,
    };
    dispatch(collectionDateChosen(orderDate));
    navigate("/cart");
  };

  return (
    <Grid item xs={12} sm={2} data-testid="available">
      <Paper className={classes.availableDay} elevation={10}>
        <Typography
          className={classes.textStyle}
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          {month.toUpperCase() + " " + day}
        </Typography>
        <Box sx={{ pb: 2 }}>
          {slots.map((slot) => {
            return (
              <Button
                sx={{
                  minWidth: "100px",
                  color: "#000",
                  backgroundColor: "#fff",
                  mr: 2,
                  mb: 1,
                  "&:hover": {
                    outline: "1px solid #000",
                  },
                }}
                onClick={() => handleDateSelection(month, day, slot)}
              >
                <Typography className={classes.textStyle}>{slot}</Typography>
              </Button>
            );
          })}
        </Box>
      </Paper>
    </Grid>
  );
}
