import React from "react";
import { Box } from "@mui/material";

import FlavorSelect from "./flavorSelect";
import SizeSelect from "./sizeSelect";

export default function BrandBottom({ setState, state, product }) {

  const flavorsOpts = product.customizationOptions.filter(opt => opt.name==="corporate-branded-cake-flavor")[0];
  const sizeOpts = product.customizationOptions.filter(opt => opt.name==="corporate-branded-cake-size")[0];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: { xs: "column", sm: "column", md: "row" },
        }}
      >

        <FlavorSelect 
          flavorOptions={flavorsOpts} 
          setState={setState} 
          state={state}
        />

        <SizeSelect 
          sizeOptions={sizeOpts} 
          setState={setState} 
          state={state}
        />
      </Box>
    </>
  );
}
