import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import firebaseConfig from "../auth";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Box,
  Divider,
  Button,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  PhotoLibrary,
  Park,
  Favorite,
} from "@mui/icons-material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DvrIcon from "@mui/icons-material/Dvr";
import ordersIcon from "../assets/images/drawerIcons/ordersIcon.png";
import MainActionButton from "./buttons/mainActionButton";
import SecondaryActionButton from "./buttons/secondaryActionButton";
import ComingSoon from "./comingSoon";

const useStyles = makeStyles((theme) => ({
  drawerSectionTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    ...theme.textStyle,
  },
  drawerProfileName: {
    color: theme.palette.primary.dark,
    fontSize: "2rem !important",
    ...theme.textStyle,
  },
  drawerProfileEmail: {
    fontSize: "0.85rem !important",
    fontWeight: "bold",
    marginBottom: "1rem !important",
    ...theme.textStyle,
  },
  listItemsContainer: {
    height: "100vh",
    boxSizing: "border-box",
  },
  secondaryBtn: {
    ...theme.containedButtonLight,
    padding: "20px",
    width: "100%",
  },
  mainBtn: {
    ...theme.containedButtonDark,
    padding: "20px",
    width: "100%",
  },
}));

const shopDropdownMenu = [
  {
    menuTitle: "cakes",
    menuItems: [
      {
        href: "/cakes/specialty-cakes",
        type: "text",
        value: "Specialty Cakes",
      },
      {
        href: "/cakes/standard-cakes",
        type: "text",
        value: "Standard Cakes",
      },
      {
        href: "/cakes/cheesecakes",
        type: "text",
        value: "Cheesecakes (whole)",
      },
    ],
  },
  {
    menuTitle: "patisserie",
    menuItems: [
      {
        href: "/patisserie/desserts",
        type: "text",
        value: "Desserts & Pastries",
      },
      {
        href: "/patisserie/cheesecakes",
        type: "text",
        value: "Cheesecakes (slice)",
      },
      { href: "/patisserie/cupcakes", type: "text", value: "Cupcakes" },
      { href: "/patisserie/macarons", type: "text", value: "Macarons" },
      { href: "/patisserie/cookies", type: "text", value: "Cookies" },
    ],
  },
  {
    menuTitle: "graze boxes",
    menuItems: [
      { href: "/graze-boxes/small", type: "text", value: "Small" },
      { href: "/graze-boxes/medium", type: "text", value: "Medium" },
      { href: "/graze-boxes/large", type: "text", value: "Large" },
    ],
  },
  {
    menuTitle: "accessories",
    menuItems: [
      { href: "/accessories/candles", type: "text", value: "Candles" },
      {
        href: "/accessories/cake-toppers",
        type: "text",
        value: "Cake Toppers",
      },
      {
        href: "/accessories/holiday-cards",
        type: "text",
        value: "Holiday Cards",
      },
      { href: "/accessories/balloons", type: "text", value: "Balloons" },
    ],
  },
];

const servicesDropdownMenu = [
  {
    menuTitle: "weddings",
    menuItems: [
      { href: "/services/wedding-packages", type: "img", value: "Weddings" },
    ],
  },
  {
    menuTitle: "corporate events",
    menuItems: [
      {
        href: "/services/corporate-events",
        type: "img",
        value: "Corporate Events",
      },
    ],
  },
];

const DrawerMenu = ({ setIsDrawerOpen }) => {
  initializeApp(firebaseConfig());

  const auth = getAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const [dataIsReady, setDataIsReady] = useState(false);
  const [user, setUser] = useState(null);

  const [openShop, setOpenShop] = useState(false);
  const [openServices, setOpenServices] = useState(false);
  const [openCakes, setOpenCakes] = useState(false);
  const [openPatisserie, setOpenPatisserie] = useState(false);
  const [openGrazeBoxes, setOpenGrazeBoxes] = useState(false);
  const [openAccessories, setOpenAccessories] = useState(false);

  const profileIcons = [ordersIcon];
  const profileTitles = [
    {
      name: "My Orders",
      url: "my-orders",
    },
  ];

  const handleClick = (menu) => {
    if (menu === "shop") {
      setOpenShop(!openShop);
      setOpenServices(false);
    } else if (menu === "services") {
      setOpenServices(!openServices);
      setOpenShop(false);
    } else if (menu === "cakes") {
      setOpenCakes(!openCakes);
      setOpenPatisserie(false);
      setOpenGrazeBoxes(false);
      setOpenAccessories(false);
    } else if (menu === "patisserie") {
      setOpenPatisserie(!openPatisserie);
      setOpenCakes(false);
      setOpenGrazeBoxes(false);
      setOpenAccessories(false);
    } else if (menu === "grazeBoxes") {
      setOpenGrazeBoxes(!openGrazeBoxes);
      setOpenCakes(false);
      setOpenPatisserie(false);
      setOpenAccessories(false);
    } else if (menu === "accessories") {
      setOpenAccessories(!openAccessories);
      setOpenCakes(false);
      setOpenPatisserie(false);
      setOpenGrazeBoxes(false);
    }
  };

  const logOut = async () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("user");
        navigate("/");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(JSON.parse(localStorage.getItem("user")));
      }

      setDataIsReady(true);
    });
  }, [dataIsReady]);

  if (!dataIsReady) {
    return <Box>Loading...</Box>;
  } else {
    return (
      <List>
        {user && (
          <Box>
            <ListItem sx={{ paddingBottom: 0 }}>
              <Typography className={classes.drawerProfileName}>
                {user.firstName + " " + user.lastName}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className={classes.drawerProfileEmail}>
                {user.email}
              </Typography>
            </ListItem>
            <Divider />
          </Box>
        )}

        <ListItem sx={{ mt: 3 }}>
          <ListItemText>
            <Typography className={classes.drawerSectionTitle}>Menu</Typography>
          </ListItemText>
        </ListItem>

        <ListItem
          button
          onClick={() => handleClick("shop")}
          sx={{
            backgroundColor: "rgba(255, 221, 216, 0.4)",
            mb: 1.5,
          }}>
          <ListItemIcon>
            <StorefrontIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: "bold" }}>SHOP</Typography>
          </ListItemText>
          {openShop ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openShop} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              key="CAKES"
              onClick={() => handleClick("cakes")}
              sx={{
                pl: 4,
                mb: 1,
              }}>
              <ListItemText>
                <Typography sx={{ fontWeight: "bold" }}>CAKES</Typography>
              </ListItemText>
              {openCakes ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCakes} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {shopDropdownMenu[0].menuItems.map((item) => (
                  <ListItem
                    key={item.value}
                    sx={{
                      pl: 6,
                      mb: 1,
                    }}>
                    <ListItemText
                      primary={item.value}
                      onClick={() => navigate(item.href)}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <ListItem
              key="PATISSERIE"
              button
              onClick={() => handleClick("patisserie")}
              sx={{
                pl: 4,
                mb: 1,
              }}>
              <ListItemText>
                <Typography sx={{ fontWeight: "bold" }}>PATISSERIE</Typography>
              </ListItemText>
              {openPatisserie ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openPatisserie} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {shopDropdownMenu[1].menuItems.map((item) => (
                  <ListItem key={item.value} sx={{ pl: 6 }}>
                    <ListItemText
                      primary={item.value}
                      onClick={() => {
                        setIsDrawerOpen(false);
                        navigate(item.href);
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <ListItem
                  key="GRAZE BOXES"
                  onClick={() => handleClick("grazeBoxes")}
                  sx={{
                    pl: 4,
                    mb: 1,
                  }}>
                  <ListItemText>
                    <Typography sx={{ fontWeight: "bold" }}>
                      GRAZE BOXES
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Box>

              <Box>
                <ListItem>
                  <ComingSoon />
                </ListItem>
              </Box>
              {/* {openGrazeBoxes ? <ExpandLess /> : <ExpandMore />} */}
            </Box>

            <Collapse in={openGrazeBoxes} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {["Small", "Medium", "Large"].map((item) => (
                  <ListItem key={item} button sx={{ pl: 6 }}>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <ListItem
                  key="ACCESSORIES"
                  onClick={() => handleClick("accessories")}
                  sx={{ pl: 4 }}>
                  <ListItemText>
                    <Typography sx={{ fontWeight: "bold" }}>
                      ACCESSORIES
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Box>

              <Box>
                <ListItem>
                  <ComingSoon />
                </ListItem>
              </Box>
              {/* {openAccessories ? <ExpandLess /> : <ExpandMore />} */}
            </Box>

            <Collapse in={openAccessories} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {["Balloons", "Candles", "Holiday Cards", "Cake Toppers"].map(
                  (item) => (
                    <ListItem key={item} button sx={{ pl: 6 }}>
                      <ListItemText primary={item} />
                    </ListItem>
                  )
                )}
              </List>
            </Collapse>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={() => handleClick("services")}
          sx={{
            backgroundColor: "rgba(255, 221, 216, 0.4)",
            mb: 1.5,
            fontWeight: "bold",
          }}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: "bold" }}>SERVICES</Typography>
          </ListItemText>
          {openServices ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openServices} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <ListItem key="WEDDINGS" sx={{ pl: 4 }}>
                  <ListItemText>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Weddings
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Box>

              <Box>
                <ListItem>
                  <ComingSoon />
                </ListItem>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <ListItem key="CORPORATE" sx={{ pl: 4 }}>
                  <ListItemText>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Corporate Events
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Box>

              <Box>
                <ListItem>
                  <ComingSoon />
                </ListItem>
              </Box>
            </Box>
          </List>
        </Collapse>

        {user ? (
          <Box
            sx={{
              width: "100%",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}>
            <ListItem sx={{ mt: 3 }}>
              <ListItemText>
                <Typography className={classes.drawerSectionTitle}>
                  Profile
                </Typography>
              </ListItemText>
            </ListItem>

            {profileTitles.map((text, index) => (
              <ListItem
                button
                key={text.name}
                onClick={() => navigate("/" + text.url)}>
                <ListItemIcon>
                  <DvrIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {text.name}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}

            <ListItem sx={{ mt: "90%" }}>
              <MainActionButton
                clickEvent={logOut}
                testId={"log-out"}
                styleObj={classes.mainBtn}>
                Log Out
              </MainActionButton>
            </ListItem>
          </Box>
        ) : (
          <Box sx={{ mt: "130%" }}>
            <ListItem>
              <MainActionButton
                clickEvent={() => navigate("/signup")}
                testId={"signup-btn"}
                styleObj={classes.mainBtn}>
                Sign Up
              </MainActionButton>
            </ListItem>
            <ListItem>
              <SecondaryActionButton
                clickEvent={() => navigate("/login")}
                testId={"signin-btn"}
                styleObj={classes.secondaryBtn}>
                Log In
              </SecondaryActionButton>
            </ListItem>
          </Box>
        )}
      </List>
    );
  }
};

export default DrawerMenu;
