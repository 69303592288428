import { React, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, FormControlLabel, Checkbox } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "block",
    backgroundColor: theme.palette.primary.main,
    textAlign: "center !important",
    padding: "5px 0 !important",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function CustomCheckbox(props) {
  const classes = useStyles();
  const {
    optionName,
    optionDisplayName,
    size,
    borderColor,
    checkedColor,
    checkedStatus,
    handleCheckboxChange,
  } = props;

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              color: borderColor,
              "&.Mui-checked": {
                color: checkedColor,
              },
              "& .MuiSvgIcon-root": { fontSize: size },
            }}
            checked={checkedStatus}
            size="medium"
            onChange={() => handleCheckboxChange(optionName)}
          />
        }
        label={optionDisplayName}
      />
    </Box>
  );
}
