/** @format */

import { React } from "react";
import { Container, Typography, Grid, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
    textAlign: "center",
    marginTop: "4rem",
  },
  heading: {
    color: theme.palette.primary.dark,
    ...theme.textStyle,
  },
  subHeading1: {
    color: theme.palette.secondary.main.main,
    margin: "20px 0 !important",
    ...theme.textStyle,
  },
  subHeading2: {
    color: theme.palette.secondary.main.main,
    marginBottom: "30px !important",
    ...theme.textStyle,
  },
}));

export default function Main() {
  const classes = useStyles();

  console.log("hello");

  return (
    <Container>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          <Typography
            sx={{ typography: { sm: "h2", xs: "h3" } }}
            className={classes.heading}
          >
            Cheesecakes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h6"} className={classes.subHeading1}>
            Choose one of our insanely delicious baked or not baked cheesecakes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"} className={classes.subHeading2}>
            They are 9" wide and serve around 10-12 people
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
