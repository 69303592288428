import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);
  const [activeCategory, setActiveCategory] = useState("ordering"); // Default category

  let navigate = useNavigate();

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const categories = [
    { id: "ordering", name: "Ordering and Customization" },
    { id: "collection", name: "Delivery and Pick-Up" },
    { id: "payment", name: "Payment and Refunds" },
    { id: "classes-events", name: "Classes and Events" },
    { id: "cake-care", name: "Cake Care and Storage" },
    { id: "products", name: "Product Information" },
  ];

  const questions = {
    ordering: [
      {
        id: "panel1",
        question: "How do I place an order for a cake?",
        answer:
          "You can place an order directly on our website by selecting a product, choosing options, and completing the checkout. For custom designs, feel free to reach out to us directly.",
      },
      {
        id: "panel2",
        question: "Can I customize a cake?",
        answer:
          "Yes! We offer options for custom colors, and designs, but we do not customize flavors. For special designs, contact us to discuss your ideas.",
      },
      {
        id: "panel3",
        question: "How much notice do you need for custom orders?",
        answer:
          "For standard cakes and cheesecakes we offer next day pickups if the order is placed by 2:00pm the day before. For custom cakes, please order at least 1-2 weeks in advance. If your timeline is shorter, contact us, and we’ll do our best to accommodate.",
      },
      {
        id: "panel4",
        question: "Do you make cakes for dietary restrictions?",
        answer:
          "We do not specialize in dietary cakes, however, we do offer some gluten free options, and an eggless cake. We work on expanding this menu to include other options as well.",
      },
      {
        id: "panel5",
        question: "Can I order a cake for same-day pickup?",
        answer:
          "Same day pickups are possible depending on our stock. Please call the respective location to check with the staff what can be done.",
      },
    ],
    collection: [
      {
        id: "panel1",
        question: "Do you offer GENERAL delivery services?",
        answer:
          "We offer delivery service via Skip The Dishes and Uber Eats apps, but we do not offer our own delivery yet. We are hoping to add this service in the neat future. Stay tuned!",
      },
      {
        id: "panel2",
        question:
          "Do you offer delivery service for weddings or corporate functions?",
        answer:
          "Yes! we offer delivery for weddings and corporate events for extra fee. The fee is to be calculated based on the exact address.",
      },
      {
        id: "panel3",
        question: "Can I pick up my order?",
        answer:
          'Yes! Choose "Pick-Up" at checkout, as well as the date and time of the pickup and we will have your order ready at that time.',
      },
      {
        id: "panel4",
        question: "What if I need to change my delivery or pickup date?",
        answer:
          "Contact us as soon as possible for changes. We’ll make adjustments based on our schedule.",
      },
    ],
    payment: [
      {
        id: "panel1",
        question: "What payment methods do you accept?",
        answer:
          "We accept all major credit cards, E-transfer, as well as secure online payments through our website. Digital wallets such as Apple Pay and Google Pay are also available",
      },
      {
        id: "panel2",
        question: "Can I pay with cash upon delivery or pickup?",
        answer: "All orders must be paid for in advance to secure your order.",
      },
      {
        id: "panel3",
        question: "Are online payments secure?",
        answer:
          "Yes, our online payments are accepted via Stripe - the leading payment gateway in the industry using industry-standard encryption to protect your information.",
      },
      {
        id: "panel4",
        question: "Can I cancel my order?",
        answer:
          "Orders canceled at least 48 hours before pickup or delivery qualify for a full refund. Unfortunately, cancellations after this period may not be eligible for refunds due to preparation costs.",
      },
      {
        id: "panel5",
        question: "Do you offer refunds if I’m unsatisfied?",
        answer:
          "If you have concerns about your order, please contact us directly via email, FB or IG. Customer satisfaction is our priority, and we’ll work with you to resolve any issues.",
      },
    ],
    "classes-events": [
      {
        id: "panel1",
        question: "What types of cake decorating classes do you offer?",
        answer:
          "We offer a variety of classes, from beginner to advanced, covering basic techniques, buttercream decorating, and more! Our classes are perfect for all ages and skill levels.",
      },
      {
        id: "panel2",
        question: "How do I register for a cake decorating class?",
        answer:
          "In order to sign up please contact us directly. We are working on creating an online registration process as well. Spaces are limited, so be sure to sign up early! ",
      },
      {
        id: "panel3",
        question: "Do I need any prior experience to attend a class?",
        answer:
          "No experience is necessary for our beginner classes. We provide all the guidance and tools you need, so just bring your creativity and excitement!",
      },
      {
        id: "panel4",
        question: "What materials are provided during the class?",
        answer:
          "We provide all the tools, ingredients, and equipment needed for the class. Just come ready to learn and have fun!",
      },
      {
        id: "panel5",
        question:
          "Can I book a private cake decorating class for a group event?",
        answer:
          "Yes! We offer private group classes for birthdays, corporate events, or any special occasion. Contact us to customize an event that suits your needs.",
      },
    ],
    "cake-care": [
      {
        id: "panel1",
        question: "How should I store my cake?",
        answer:
          "Keep your cake in a cool, dry place. For cakes with fresh fruit or cream, refrigerate until serving. Remove from the fridge about 30 minutes before serving for the best texture.",
      },
      {
        id: "panel2",
        question: "How long does my cake stay fresh?",
        answer:
          "Cakes stay fresh for 2-3 days if stored correctly. Cheesecakes and pastries are best consumed within 1-2 days.",
      },
      {
        id: "panel3",
        question: "Can I freeze leftover cake?",
        answer:
          "Yes! Wrap it tightly in plastic wrap, store it in an airtight container, and freeze for up to 3 months.",
      },
      {
        id: "panel4",
        question: "How long can cupcakes and pastries be stored?",
        answer:
          "Cupcakes and pastries are best enjoyed fresh but can be stored in an airtight container at room temperature for 1-2 days.",
      },
      {
        id: "panel5",
        question: "Can I store my cake in the fridge in its original box?",
        answer:
          "While you can keep the cake in its box, we recommend an airtight container to prevent it from drying out or absorbing odors from the fridge.",
      },
    ],
    products: [
      {
        id: "panel1",
        question: "Do you offer gluten-free or vegan options?",
        answer:
          "We do not specialize in dietary cakes, however, we do offer some gluten free options, and an eggless cake, but not vegan options. We work on expanding this menu to include other options as well.",
      },
      {
        id: "panel2",
        question: "Do your cakes contain nuts?",
        answer:
          "Some products may contain nuts or be prepared in a facility that handles nuts, but we also offer a lot of nut free options.",
      },
      {
        id: "panel3",
        question: "Are your cakes and pastries baked fresh?",
        answer:
          "Absolutely! We bake all our cakes and pastries fresh using quality ingredients",
      },
      {
        id: "panel4",
        question: "What kind of decorations can I request?",
        answer:
          "We offer a range of decoration options, including flowers, unique color themes, and personalized designs. Let us know what you envision!",
      },
      {
        id: "panel5",
        question: "Can I sample flavors before ordering a large cake?",
        answer:
          "We offer sample tastings for large orders upon request. Contact us for details.",
      },
    ],
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* FAQ Heading */}
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{
          fontFamily: "'Moul', serif",
          fontSize: "2rem",
          color: "#515151",
        }}>
        Frequently asked questions
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        gutterBottom
        sx={{ mb: 8, fontFamily: "'Inter', sans-serif", color: "#909090" }}>
        At CakeyHand, we're dedicated to providing you with an exceptional
        experience. If you don't find what you're looking for, please contact us
        directly – we’re always here to help!
      </Typography>

      <Grid container justifyContent="center" sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          gutterBottom
          textAlign="center"
          sx={{
            fontFamily: "'Moul', serif",
            fontSize: "1rem",
            color: "#515151",
          }}>
          Select from below categories
        </Typography>
      </Grid>

      {/* Category Buttons */}
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 3 }}>
        {categories.map((category) => (
          <Button
            key={category.id}
            variant={activeCategory === category.id ? "contained" : "outlined"}
            onClick={() => setActiveCategory(category.id)}
            sx={{
              mr: 1,
              mb: 2,
              borderRadius: "8px", // More rounded
              backgroundColor:
                activeCategory === category.id ? "#fd7762" : "transparent",
              color: activeCategory === category.id ? "#fff" : "#909090",
              borderColor:
                activeCategory === category.id ? "#fd7762" : "#909090",
              "&:hover": {
                backgroundColor: "#fd7762",
                borderColor: "#fd7762",
                color: "#fff",
              },
            }}>
            {category.name}
          </Button>
        ))}
      </Grid>

      {/* Questions based on active category */}
      <Box>
        {questions[activeCategory].map((faq) => (
          <Accordion
            key={faq.id}
            expanded={expanded === faq.id}
            onChange={handleAccordionChange(faq.id)}
            sx={{ mb: 2, borderRadius: "10px", boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={expanded === faq.id ? <Remove /> : <Add />} // Custom icons
              sx={{
                fontWeight: "bold",
                fontFamily: "'Inter', serif",
                color: "#515151",
              }}>
              {faq.question}
            </AccordionSummary>
            <AccordionDetails
              sx={{ fontFamily: "'Open Sans', sans-serif", color: "#909090" }}>
              <Typography dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {/* Contact Section */}
      <Box
        sx={{
          mt: 6,
          mb: 4,
          textAlign: "center",
          backgroundColor: "#fde5e3",
          p: 4,
          borderRadius: 2,
        }}>
        <Typography
          variant="h5"
          sx={{
            mb: 1,
            fontFamily: "'Moul', serif",
            color: "#515151",
            fontWeight: 600,
          }}>
          Still have questions?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "'Inter', sans-serif",
            color: "#515151",
            fontWeight: 600,
            fontSize: "14px",
          }}>
          Can’t find the answer you’re looking for? Send us a message and we'll
          get back to you with answers.
        </Typography>
        <Button
          variant="contained"
          sx={{
            mt: 3,
            backgroundColor: "#fd7762",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#fd7762cc",
            },
          }}
          endIcon={<span className="loading-spinner"></span>} // Add spinner icon
          onClick={() => navigate("/contact-us")}>
          Get in touch
        </Button>
      </Box>
    </Container>
  );
};

export default FAQ;
