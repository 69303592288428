import React, { useState } from "react";
import { Box } from "@mui/material";
import EnhancedStepper from "../CakeOrderForm/enhancedStepper";
import CakeSelection from "./cakeSelection";
import Review from "./review";
import ChooseDessert from "./chooseDessert";
import SetUpChance from "./setUpChance";
import { selectPackageType } from "../../redux/reducers/wedding";
import { useSelector } from "react-redux";

import BodyModal from "../Product/bodyModal"

export default function CustomizeDialog({ handleClose }) {
  const packageType = useSelector(selectPackageType);
  const [activeStep, setActiveStep] = useState(0)

  const premiumPackageSteps = [
    { label: "Step 1", component: CakeSelection },
    { label: "Step 2", component: BodyModal },
    { label: "Step 3", component: ChooseDessert },
    { label: "Step 4", component: SetUpChance },
    { label: "Step 5", component: Review },
  ];
  const basicPackageSteps = [
    { label: "Step 1", component: CakeSelection },
    { label: "Step 2", component: BodyModal },
    { label: "Step 3", component: Review },
  ];


  return (
    <Box 
      sx={{
        mb: "4rem !important", 
        backgroundColor: "white !important", 
        minWidth: { md: "800px", sm: "300px" },
        minHeight: { md: "800px", sm: "600px" } 
      }}>
        <EnhancedStepper 
          steps={packageType === "basic" ? basicPackageSteps : premiumPackageSteps} 
          handleClose={handleClose}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
    </Box> 
  );
}
