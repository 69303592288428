/** @format */

import React from "react";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: theme.palette.secondary.dark,
    marginBottom: "20px !important",
    textAlign: "center",
    ...theme.textStyle,
  },
  sectionSubTitle: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginBottom: "40px !important",
    ...theme.textStyle,
  },
}));

export default function SectionHeadings({ heading, subheading }) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography variant={"h5"} className={classes.sectionTitle}>
        {heading}
      </Typography>
      <Typography variant={"subtitle1"} className={classes.sectionSubTitle}>
        {subheading}
      </Typography>
    </Grid>
  );
}
