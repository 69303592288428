/** @format */

import { React, useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { Typography, Grid, TextField, Paper, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { promoCodeAmountApplied, promoCodeCancelled } from "../../redux/reducers/cart";
import SectionHeadings from "../../components/sectionHeadings";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "2rem !important",
    marginBottom: "1rem !important",
    padding: "15px 10px",
  },
  sectionTitle: {
    color: theme.palette.secondary.dark,
    marginBottom: "20px !important",
    textAlign: "center",
  },
  sectionSubTitle: {
    color: theme.palette.secondary.main.main,
    textAlign: "center",
    marginBottom: "40px !important",
  },
  applyPromoCodeButton: {
    ...theme.containedButtonDark,
    textStyle: { ...theme.textStyle },
  },
  cancelPromoCodeButton: {
    ...theme.containedButtonLight,
    textStyle: { ...theme.textStyle },
  },
}));

function Coupon(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { products, coupon } = props;

  let totalAmountOfProducts = 0;
  let promoCodeAmount = 0;

  const [promoCodes, setPromoCodes] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState(false);
  const [promoCodeErrorText, setPromoCodeErrorText] = useState("");
  const [promoCodeApplied, setPromoCodeApplied] = useState(coupon !== null ? coupon?.applied : false);

  // useEffect(() => {
  //   const fetchDiscountCodes = async () => {
  //     const result = await axios.get("/api/app/data/discountCode");
  //     const promoCodes = result.data;
  //     setPromoCodes(promoCodes);
  //   };

  //   fetchDiscountCodes();
  // }, []);

  const handleApplyBtnClick = async () => {
    if (!promoCode) {
      setPromoCodeError(true);
      setPromoCodeErrorText("promo code cannot be blank");
      return;
    }

    try {
      const res = await axios.get(`/api/app/data/discountCode?code=${promoCode}`);
      const discountCode = res.data.code;

      console.log(discountCode);

      if (!discountCode) {
        setPromoCodeError(true);
        setPromoCodeErrorText("the discount code does not exist");
      } else {
        if (discountCode.isActive) {
          const expiryDate = new Date(discountCode.validUntil);
          if (expiryDate < Date.now()) {
            setPromoCodeError(true);
            setPromoCodeErrorText("discount code is expired");
          } else {
            if(discountCode.isUsed){
              setPromoCodeError(true);
              setPromoCodeErrorText("discount code is already used");
            }
            else{
              setPromoCodeError(false);
              setPromoCodeApplied(true);
              applyCoupon(products, {...discountCode, applied: true});
            }
          }
        } else {
          setPromoCodeError(true);
          setPromoCodeErrorText("discount code is not active at the moment");
        }
      }
    } catch (error) {
      alert("something went wrong");
    }
  };

  const handleCancelBtnClick = () => {
    setPromoCode("");
    setPromoCodeApplied(false);
    setPromoCodeError(false);
    setPromoCodeErrorText("");
    dispatch(promoCodeCancelled());
  }

  const applyCoupon = (products, promoCode) => {
    if(promoCode.level === "product"){
      for (let i = 0; i < products.length; i++) {
        if (products[i].name === promoCode.productType) {
          let productPrice = 0;
          if(products[i].featuredProduct){
            if(products[i].featuredProduct.discount.type === 'percentage'){
              productPrice = products[i].price - (products[i].price * products[i].featuredProduct.discount.amount / 100);
            }
            else{
              productPrice = products[i].price - products[i].featuredProduct.discount.amount
            } 
          }
          else{
            productPrice = products[i].price;
          }
          totalAmountOfProducts += products[i].price * products[i].quantity;
        }
      }
    }
    else if(promoCode.level === "productCategory"){
      for (let i = 0; i < products.length; i++) {
        if (products[i].type === promoCode.productType) {
          let productPrice = 0;
          if(products[i].featuredProduct){
            if(products[i].featuredProduct.discount.type === 'percentage'){
              productPrice = products[i].price - (products[i].price * products[i].featuredProduct.discount.amount / 100);
            }
            else{
              productPrice = products[i].price - products[i].featuredProduct.discount.amount
            } 
          }
          else{
            productPrice = products[i].price;
          }
          totalAmountOfProducts += products[i].price * products[i].quantity;
        }
      }    
    }
    else if(promoCode.level === "order"){
      for (let i = 0; i < products.length; i++) {
        let productPrice = 0;
        if(products[i].featuredProduct){
          if(products[i].featuredProduct.discount.type === 'percentage'){
            productPrice = products[i].price - (products[i].price * products[i].featuredProduct.discount.amount / 100);
          }
          else{
            productPrice = products[i].price - products[i].featuredProduct.discount.amount
          } 
        }
        else{
          productPrice = products[i].price;
        }
        totalAmountOfProducts += productPrice * products[i].quantity;
      } 
    }
    else{
      return;
    }

    console.log(totalAmountOfProducts, promoCode.discountPercentage);

    if (promoCode.discountPercentage > 0) {
      promoCodeAmount = (totalAmountOfProducts * promoCode.discountPercentage) / 100;
    } else {
      promoCodeAmount = promoCode.amount;
    }

    console.log(promoCodeAmount);

    promoCode["appliedAmount"] = promoCodeAmount.toFixed(2);
    dispatch(promoCodeAmountApplied(promoCode));
  };

  const handleCouponCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  return (
    <Paper elevation={10} className={classes.wrapper} data-testid="coupon">
      <Grid container>
        <SectionHeadings
          heading="Promo Code"
          subheading="Do you have a promo code? Enter below"
        />
        <Grid item xs={12}>
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            label="Enter promo code here"
            value={promoCode}
            onChange={(e) => {
              handleCouponCodeChange(e);
            }}
            InputLabelProps={{
              className: classes.textStyle,
            }}
            InputProps={{ className: classes.textStyle }}
            data-testid="coupon-field"
          ></TextField>
          <Typography
            className={classes.textStyle}
            sx={{
              color: "red",
              mb: 2,
              display: promoCodeError ? "block" : "none",
            }}
          >
            {promoCodeErrorText}
          </Typography>
          <Typography
            className={classes.textStyle}
            sx={{
              color: "green",
              mb: 2,
              display: promoCodeApplied ? "block" : "none",
            }}
          >
            {"promo code is applied"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth 
                className={classes.applyPromoCodeButton}
                onClick={() => handleApplyBtnClick()}
                data-testid="coupon-apply"
              >
                <Typography className={classes.textStyle}>Apply</Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                sx={{display: promoCodeApplied ? "block" : "none"}}
                fullWidth
                className={classes.cancelPromoCodeButton}
                onClick={() => handleCancelBtnClick()}
              >
                <Typography className={classes.textStyle}>Cancel</Typography>
              </Button>
            </Grid>
          </Grid>
          
          
        </Grid>
      </Grid>
    </Paper>
  );
}

function mapStateToProps(state) {
  const { products, coupon } = state.cart;
  return { products, coupon };
}

export default connect(mapStateToProps)(Coupon);
