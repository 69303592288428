import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import AntSwitch from "./antSwitch";
import premiumCakeImage from "../../assets/images/services/wedding-package-premium.png";
import dessertTableImage from "../../assets/images/services/wedding-package-dessert-table.png";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { setSetupService, resetToInitial as weddingResetToInitial } from "../../redux/reducers/wedding";
import { resetToInitial as cakeResetToInitial } from "../../redux/reducers/cake";



// TODO - remove unused classes
const useStyles = makeStyles((theme) => ({
  package: {
      display: "flex !important",
      flexDirection: "column !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      borderRadius: "20px !important",
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      padding: "2rem !important",
      width: "100% !important",
      margin: "2rem 4rem 0 4rem !important",
      position: "relative !important"
  },
  standardCakeImg: {
      width: "100% !important",
      height: "4rem !important",
      objectFit: "contain !important",
      padding: "2rem 0 1rem 2rem"
  },
  flexStyle: {
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
  },
  icon: {
      color: "#fd7762 !important"
  },
  
  primaryBtn: {
      backgroundColor: "#fd7762 !important",
      color: "white !important",
      borderRadius: "50px !important",
      width: "20rem !important",
      padding: "1rem !important",
      textTransform: "capitalize !important",
      marginBottom: "6rem !important",
      "&:hover": {
          backgroundColor: "white !important",
          color: "#fd7762 !important",
          border: "2px solid #fd7762 !important"
      },
  },
  setup: {
      backgroundColor: "#fbfbfb !important",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      padding: "2rem 0 2rem 0",
      borderRadius: "0 0 20px 20px !important",
  },
  premium: {
      backgroundColor: "#fd7762 !important",
      position: "absolute !important",
      top: 0,
      left: 0,
      width: "100% !important",
      height: "1rem !important",
      borderRadius: "30px 30px 0 0 !important",
  },
  span: {
      fontWeight: "bold !important",
      textTransform: "uppercase !important",
      margin: "0 0.3rem 0 0.3rem !important"
  },
}));

const PremiumPackage = ({ handleOpen, setupService }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [ enableSetup, setEnableSetup ] = useState(true)

  const weddingPackage = {
    name: "premium", 
    packageDetail: [{
        imageSrc: premiumCakeImage, 
        title: "cake"
      }, {
        imageSrc: dessertTableImage, 
        title: "dessert table"
      }
    ]
  };

  const openModal = () => {
    dispatch(weddingResetToInitial());
    dispatch(cakeResetToInitial());

    if (enableSetup) {
        dispatch(setSetupService(setupService));
    }

    handleOpen(weddingPackage.name)
  };
  
  return (
    <Box className={classes.package}>
        <Box className={classes.premium}/>
        <Typography sx={{fontSize: "2rem !important", textTransform: "uppercase !important"}}>
            {weddingPackage.name}
        </Typography>
        <Box className={classes.flexStyle} sx={{borderBottom: "2px solid #d3d3d3", width: "100%", mb: "2rem"}}>
            {weddingPackage.packageDetail.map((details) => (
                <Box 
                    className={classes.flexStyle} 
                    sx={{flexDirection: "column !important", p: "1rem"}}
                >
                    <img
                        alt="cake"
                        src={details.imageSrc}
                    />  
                    <Box className={classes.flexStyle} sx={{flexDirection: "row !important", mb: "1rem", paddingTop: "16px"}}>
                        <CheckBoxOutlinedIcon className={classes.icon} sx={{pr: "5px"}}/>
                        <Typography sx={{color: "#707070 !important", textTransform: "capitalize !important"}}>
                            {details.title}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Box>
        <Box>
            <Button 
                className={classes.primaryBtn}
                onClick={openModal}
            >
                Customize
            </Button>
            <Box className={classes.setup}>
                <Box sx={{mr: "1.2rem !important"}}>
                    Add <span className={classes.span}>setup</span> Service / <span className={classes.span}>300$</span>
                </Box>
                <AntSwitch 
                    defaultChecked 
                    inputProps={{ 'aria-label': 'ant design' }}
                    checked={enableSetup}
                    onChange={()=>setEnableSetup(!enableSetup)}
                />
            </Box>
        </Box>
    </Box>
  )

}


export default PremiumPackage;