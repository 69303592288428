/** @format */

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import deleteIcon from "../../../../assets/icons/adminBooking/delete.png";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important",
    backgroundColor: "#fff !important",
    border: "1px solid #000 !important",
    borderRadius: "10px !important",
    boxShadow: "24 !important",
    height: "50% !important",
    width: "40% !important",
    padding: "20px !important",
    overflow: "scroll !important",
  },
  modalTitle: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
  },

  fieldHead: {
    fontWeight: "700 !important",
  },

  overviewText: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
  },

  customizationSelection: {
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "flex-end !important",
    padding: "16px 16px 16px 0 !important",
  },
}));

function CustomOptions({ customization, setCustomization }) {
  const classes = useStyles();
  const [allCustomization, setallCustomization] = useState([]);
  const [selectedCustomization, setSelectedCustomization] = useState("");

  const [flowerOption, setFlowerOption] = useState(
    customization.flower || "No"
  );
  const [numberCakeOption, setNumberCakeOption] = useState(
    customization.numberCake || ""
  );
  const [colorThemeOption, setColorThemeOption] = useState(
    customization.colorTheme || "None"
  );
  const [flakeColor, setFlakeColor] = useState(customization.flakes || "None");

  const flowerPrice = 20;

  useEffect(() => {
    const getCustomization = async () => {
      try {
        const customization = await axios.get(
          `/api/app/data/allCustomizationOptions`
        );
        setallCustomization(customization.data);
        setSelectedCustomization(customization.data[0]._id);
      } catch (err) {
        console.log(err);
      }
    };
    getCustomization();
  }, []);

  useEffect(() => {
    let customizationBuffer = customization;

    if (flowerOption === "Yes") {
      customizationBuffer = { ...customizationBuffer, flower: flowerOption };
    } else if (customizationBuffer.flower) {
      delete customizationBuffer.flower;
    }

    if (numberCakeOption !== "") {
      customizationBuffer = {
        ...customizationBuffer,
        numberCake: numberCakeOption,
      };
    } else if (customizationBuffer.numberCake) {
      delete customizationBuffer.numberCake;
    }

    if (colorThemeOption !== "None") {
      customizationBuffer = {
        ...customizationBuffer,
        colorTheme: colorThemeOption,
      };
    } else if (customizationBuffer.colorTheme) {
      delete customizationBuffer.colorTheme;
    }

    if (flakeColor !== "None") {
      customizationBuffer = { ...customizationBuffer, flakes: flakeColor };
    } else if (customizationBuffer.flakes) {
      delete customizationBuffer.flakes;
    }

    setCustomization(customizationBuffer);
  }, [flowerOption, numberCakeOption, colorThemeOption, flakeColor]);

  const selectedCustomizationDetails =
    allCustomization.length !== 0
      ? allCustomization.find((a) => selectedCustomization === a._id)
      : null;

  const initializeOptionStates = (id) => {
    setSelectedCustomization(id);

    if (id === "636399df51ccb6cc8add768c") {
      setColorThemeOption("pink");
    }
    if (id === "636399df51ccb6cc8add768d") {
      setFlakeColor("gold");
    }
  };

  return (
    <Box className={classes.modalStyle} data-testid="custom-options">
      <Grid container>
        <Grid item>
          <Typography className={classes.modalTitle}>
            Order Customization
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} className={classes.customizationSelection}>
          <FormControl variant="outlined">
            <InputLabel shrink={true} id="addOn">
              Add On
            </InputLabel>
            <Select
              label="Add On"
              labelId="addOn"
              value={selectedCustomization}
              onChange={(e) => {
                initializeOptionStates(e.target.value);
              }}
            >
              {allCustomization.map((list) => {
                return <MenuItem value={list._id}>{list.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} className={classes.customizationSelection}>
          <Typography className={classes.fieldHead}>Details:</Typography>
          <Typography>
            {selectedCustomizationDetails
              ? selectedCustomizationDetails.displayableName
              : ""}
          </Typography>

          <Box>
            {selectedCustomization === "636e2535cf7a6118420addbe" ? (
              <>
                <Select
                  value={flowerOption}
                  onChange={(e) => {
                    setFlowerOption(e.target.value);
                  }}
                >
                  <MenuItem value={"Yes"}>Yes</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </>
            ) : null}
            {selectedCustomization === "636e2535cf7a6118420addbf" ? (
              <TextField
                value={numberCakeOption}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;

                  if (e.target.value === "" || re.test(e.target.value)) {
                    setNumberCakeOption(e.target.value);
                  }
                }}
              ></TextField>
            ) : null}
            {selectedCustomization === "636e2535cf7a6118420addc0" ? (
              <Select
                value={colorThemeOption}
                onChange={(e) => {
                  setColorThemeOption(e.target.value);
                }}
              >
                {allCustomization
                  .find((a) => a.name === "colorTheme")
                  .values.map((ct) => {
                    return (
                      <MenuItem value={ct.colorName}> {ct.colorName} </MenuItem>
                    );
                  })}
                <MenuItem value={"None"}> None </MenuItem>
              </Select>
            ) : null}
            {selectedCustomization === "636e2535cf7a6118420addc1" ? (
              <Select
                value={flakeColor}
                onChange={(e) => {
                  setFlakeColor(e.target.value);
                }}
              >
                {allCustomization
                  .find((a) => a.name === "flakes")
                  .values.map((fc) => {
                    return (
                      <MenuItem value={fc.colorName}> {fc.colorName} </MenuItem>
                    );
                  })}
                <MenuItem value={"None"}> None </MenuItem>
              </Select>
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.overviewText}>
            <Typography style={{ fontWeight: "700", marginRight: "1rem" }}>
              Flower:
            </Typography>
            <Typography style={{ marginRight: "1rem" }}>
              {flowerOption}
            </Typography>
          </Box>
          <Box className={classes.overviewText}>
            <Typography style={{ fontWeight: "700", marginRight: "1rem" }}>
              Number Cake:
            </Typography>
            <Typography style={{ marginRight: "1rem" }}>
              {" "}
              {numberCakeOption}
            </Typography>
          </Box>
          <Box className={classes.overviewText}>
            <Typography style={{ fontWeight: "700", marginRight: "1rem" }}>
              Color Theme:
            </Typography>
            <Typography style={{ marginRight: "1rem" }}>
              {" "}
              {colorThemeOption}
            </Typography>
          </Box>
          <Box className={classes.overviewText}>
            <Typography style={{ fontWeight: "700", marginRight: "1rem" }}>
              Flake Color:
            </Typography>
            <Typography style={{ marginRight: "1rem" }}>
              {" "}
              {flakeColor}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CustomOptions;
