/** @format */

import { React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Box, Container } from "@mui/material";
import axios from "axios";
import { resetToInitial } from "../../redux/reducers/inStoreStandardCake";
import StandardCake from "./standardCake";

export default function StandardCakes() {
  const dispatch = useDispatch();
  const [dataIsReady, setDataIsReady] = useState(false);
  const [cakesList, setCakesList] = useState([]);

  useEffect(() => {
    dispatch(resetToInitial());
    async function fetchCakes() {
      const res = await axios.get(
        "/api/app/data/standardCakes/allStandardCakes"
      );
      setCakesList(res.data);
      setDataIsReady(true);
    }

    fetchCakes();
  }, []);

  return (
    <Container>
      <Grid container sx={{ width: "100%" }}>
        {!dataIsReady ? (
          <Box sx={{ mb: 3 }}>Loading...</Box>
        ) : (
          cakesList.map((cake, i) => {
            return <StandardCake cake={cake}></StandardCake>;
          })
        )}
      </Grid>
    </Container>
  );
}
