import React from "react";
import { Box } from "@mui/material";
import ColorThemeSection from "./customizationOptions/colorTheme/colorThemeSection.jsx";
import FlakeSection from "./customizationOptions/flakes/flakeSection.jsx";
import NumberCakeSection from "./customizationOptions/numberCake/numberCakeSection.jsx";
import FlowerSection from "./customizationOptions/flowers/flowerSection.jsx";

export default function CustomizationOption({ option }) {
  let customizationOptionType = null;

  if (option.name === "colorTheme") {
    customizationOptionType = "colorTheme";
  }

  if (option.name === "flakes") {
    customizationOptionType = "flakes";
  }

  if (option.name === "flower") {
    customizationOptionType = "flower";
  }

  if (option.name === "numberCake") {
    customizationOptionType = "numberCake";
  }

  return (
    <Box sx={{ mr: 2, mb: 2 }} data-testid="customization-option">
      {customizationOptionType === "colorTheme" && (
        <ColorThemeSection info={option}></ColorThemeSection>
      )}

      {customizationOptionType === "flakes" && (
        <FlakeSection info={option}></FlakeSection>
      )}

      {customizationOptionType === "numberCake" && (
        <NumberCakeSection info={option}></NumberCakeSection>
      )}

      {customizationOptionType === "flower" && (
        <FlowerSection info={option}></FlowerSection>
      )}
    </Box>
  );
}
