import React from "react";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "./header";
import OrderingProcess from "./orderingProcess";
import ChoosePackage from "./choosePackage";
import SetupService from "./setupService";
import Testimonial from "./testimonial";


const useStyles = makeStyles({
  mainContainer: {
    width: "100% !important",
    maxWidth: "100% !important",
    marginBottom: "4rem !important",
    paddingTop: 20,
  },
});

const Main = () => {
  const classes = useStyles();

  return (
    <Container className={classes.mainContainer}>
      <Header />
      <OrderingProcess />
      <ChoosePackage />
      <SetupService />
      <Testimonial />
    </Container>
  );
}

export default Main;
