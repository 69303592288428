import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Container,
} from "@mui/material";
import cake from "../../assets/images/cake_category.jpg";
import cheesecake from "../../assets/images/cheesecake_category.jpg";
import patisserie from "../../assets/images/patisserie_category.png";
import charcuterie from "../../assets/images/charcuterie_category.jpg";

export default function ProductCategories() {
  const offerings = [
    {
      title: "Cakes",
      imageUrl: cake,
    },
    {
      title: "Patisserie",
      imageUrl: patisserie,
    },
    {
      title: "Cheesecakes",
      imageUrl: cheesecake,
    },

    {
      title: "Charcuterie",
      imageUrl: charcuterie,
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ py: "40px", mb: 7 }}>
      <Typography
        variant="h3"
        component="h2"
        sx={{ mb: 1.5, fontSize: { xs: "2rem", md: "3rem" }, fontFamily: "Moul" }}>
        Products We Offer
      </Typography>
      <Typography variant="body1" paragraph sx={{fontFamily: "Inter", color: "#909090", mb: 4}}>
        Treat yourself to our indulgent range of desserts. Every bite is baked
        with love and attention to detail, from decadent cakes to delicious
        cookies.
      </Typography>
      <Grid container spacing={4}>
        {offerings.map((offering, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                borderRadius: "5px",
                boxShadow: 3,
                position: "relative",
              }}>
              <CardMedia
                component="img"
                height="300"
                image={offering.imageUrl}
                alt={offering.title}
              />
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ textAlign: "center", color: "#515151", fontFamily: "Inter", fontWeight: "bold" }}>
                  {offering.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
