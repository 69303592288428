/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  colorThemeSelected,
  selectColorThemeChosen,
} from "../../../../redux/reducers/cake.js";
import CheckIcon from "@mui/icons-material/Check";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "70px",
    height: "70px",
    border: "1px solid #000 !important",
    borderRadius: "5px",
    marginBottom: "10px !important",
    position: "relative",
  },
  colorName: {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translate(-50%)",
    ...theme.textStyle,
  },
  checkIcon: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function ColorTheme({ color, parentId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedColorTheme = useSelector(selectColorThemeChosen);

  return (
    <Box
      className={classes.wrapper}
      sx={{
        backgroundColor: color.colorCode,
        mr: 2,
      }}
      onClick={() => {
        color = { ...color, parentId: parentId, selection: color.colorName };
        dispatch(colorThemeSelected(color));
      }}
      data-testid="color-theme"
    >
      {selectedColorTheme &&
        selectedColorTheme.colorName === color.colorName && (
          <CheckIcon
            className={classes.checkIcon}
            sx={{
              color: color.colorName === "white" ? "#000" : "#fff",
            }}
          />
        )}

      <Typography className={classes.colorName}>{color.colorName}</Typography>
    </Box>
  );
}
