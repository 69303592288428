import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  productRefs: [],
  addOns: [],
  collection: {
    date: "",
    timeslot: "",
    phoneNumber: "",
    email: "",
    location: "",
  },
  guestCustomerInfo: {
    firstName: "",
    lastName: "",
  },
  coupon: null,
  additionalNotes: null,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    quantityChanged: (state, action) => {
      state.products = state.products.map((pr) => {
        if (pr.id === action.payload.id) {
          return { ...pr, quantity: action.payload.newQuantity };
        } else {
          return pr;
        }
      });

      state.productRefs = state.productRefs.map((pr) => {
        if (pr.id === action.payload.id) {
          return { ...pr, quantity: action.payload.newQuantity };
        } else {
          return pr;
        }
      });
    },
    addOnAdded: (state, action) => {
      state.addOns = [
        ...state.addOns,
        {
          name: action.payload.name,
          price: action.payload.price,
          number: action.payload.number,
          text: action.payload.text,
        },
      ];
    },
    addOnRemoved: (state, action) => {
      state.addOns = state.addOns.filter((e) => {
        return e.name !== action.payload.name;
      });
    },
    collectionTypeChosen: (state, action) => {
      state.collection.type = action.payload.type;
      state.collection.name = action.payload.name;
      state.collection.price = action.payload.price;
    },
    collectionDateChosen: (state, action) => {
      state.collection.date = action.payload;
    },
    collectionTimeChosen: (state, action) => {
      state.collection.timeslot = action.payload;
    },
    collectionPhoneNumberChosen: (state, action) => {
      state.collection.phoneNumber = action.payload.phoneNumber;
    },
    collectionEmailChosen: (state, action) => {
      state.collection.email = action.payload;
    },
    collectionLocationChosen: (state, action) => {
      state.collection.location = action.payload;
    },
    collectionDeliveryAddressChosen: (state, action) => {
      state.collection.address = action.payload.address;
    },
    guestFirstNameUpdated: (state, action) => {
      state.guestCustomerInfo.firstName = action.payload;
    },
    guestLastNameUpdated: (state, action) => {
      state.guestCustomerInfo.lastName = action.payload;
    },
    promoCodeAmountApplied: (state, action) => {
      state.coupon = action.payload;
    },
    promoCodeCancelled: (state, action) => {
      state.coupon = null
    },
    productRemoved: (state, action) => {
      state.products = state.products.filter(
        (pr) => pr.id !== action.payload.id
      );
      state.productRefs = state.productRefs.filter(
        (pr) => pr.id !== action.payload.id
      );
      if (state.products.length === 0) {
        state.addOns = [];
        state.coupon = null;
      }
    },
    addedToTheCart: (state, action) => {
      state.productRefs.push(action.payload);
    },
    updateCart: (state, action) => {
      state.productRefs = [...action.payload]; // TODO -- check what is difference of productRefs and products
      // state.products = [...action.payload];
    },
    addedFinalProductToTheCart: (state, action) => {
      if (state.products.length === 0) {
        state.products.push(action.payload);
      } else {
        const product = state.products.find((p) => p.id === action.payload.id);
        if (!product) {
          state.products.push(action.payload);
        }
        if (product && product !== action.payload) {
          state.products = state.products.filter((p) => p.id !== product.id);
          state.products.push(action.payload);
        }
      }
    },
    productsPopulatedFromDB: (state, action) => {
      state.productRefs = action.payload;
    },
    addOnsPopulatedFromDB: (state, action) => {
      state.addOns = action.payload;
    },
    productsPopulated: (state, action) => {
      state.products = action.payload;
    },
    additionalNotesInputed: (state, action) => {
      state.additionalNotes = action.payload;
    },
    resetToInitial: () => initialState,
  },
});

export const selectTotalNumberOfProducts = (state) =>
  state.cart.products.length;
export const selectTotalAmount = (state) => state.cart.total;
export const selectProductRefs = (state) => state.cart.productRefs;
export const selectProducts = (state) => state.cart.products;
export const selectAddOns = (state) => state.cart.addOns;
export const selectCollection = (state) => state.cart.collection;
export const selectGuestCustomerInfo = (state) => state.cart.guestCustomerInfo;
export const selectAdditionalNotes = (state) => state.cart.additionalNotes;

export const {
  quantityChanged,
  addOnAdded,
  addOnRemoved,
  collectionTypeChosen,
  collectionDateChosen,
  collectionTimeChosen,
  collectionLocationChosen,
  collectionPhoneNumberChosen,
  collectionEmailChosen,
  collectionDeliveryAddressChosen,
  guestFirstNameUpdated,
  guestLastNameUpdated,
  promoCodeAmountApplied,
  promoCodeCancelled,
  productRemoved,
  addedToTheCart,
  productsPopulatedFromDB,
  addOnsPopulatedFromDB,
  addedFinalProductToTheCart,
  productsPopulated,
  additionalNotesInputed,
  updateCart,
  resetToInitial,
} = cartSlice.actions;

export default cartSlice.reducer;
