import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cakeId: "",
  name: "",
  startingPrice: 0,
  currentPrice: 0,
  size: {
    label: "",
    price: 0
  },
  flavor: [],
  customizationOptions: {
    colorTheme: null,
    flakeColor: null,
    numberCakeNumber: null,
    flower: null,
  },
  imgPath: "/no-image"
}

export const cakeSlice = createSlice({
  name: 'cake',
  initialState,
  reducers: {
    sizeSelected: (state, action) => {
      state.size = action.payload;
    },
    flavorSelected: (state, action) => {
      state.flavor = action.payload
    },
    colorThemeSelected: (state, action) => {
      state.customizationOptions.colorTheme = action.payload
    },
    flakeColorSelected: (state, action) => {
      state.customizationOptions.flakeColor = action.payload
    },
    flowerOptionSelected: (state, action) => {
      state.customizationOptions.flower = action.payload
    },
    numberCakeNumberSelected: (state, action) => {
      state.customizationOptions.numberCakeNumber = action.payload;
    },
    startingPriceSet: (state, action) => {
      state.startingPrice = action.payload.startingPrice
      state.currentPrice = action.payload.startingPrice
    },
    currentPriceUpdated: (state, action) => {
      state.currentPrice = state.startingPrice + state.size?.price

      if (action.payload?.flavor) {
        console.log("action.payload.flavor", action.payload.flavor)
        state.currentPrice += action.payload.flavor.reduce((acc, current) => acc+current.price, 0)
      }

      if(state.customizationOptions.flower != null){
        state.currentPrice += state.customizationOptions.flower.price;
      };
    },
    setCakeId: (state, action) => {
      state.cakeId = action.payload.cakeId
    },
    setCakeImg: (state, action) => {
      state.imgPath = action.payload.imgPath
    },
    resetToInitial: () => initialState
  }
})

export const selectSizeChosen = state => state.cake.size;
export const selectFlavourChosen = state => state.cake.flavor;
export const selectCustomizationOptions = state => state.cake.customizationOptions;
export const selectColorThemeChosen = state => state.cake.customizationOptions.colorTheme;
export const selectFlakeColorChosen = state => state.cake.customizationOptions.flakeColor;
export const selectFlowerOptionChosen = state => state.cake.customizationOptions.flower;
export const selectPrice = state => state.cake.currentPrice;
export const selectCakeId = state => state.cake.cakeId;
export const selectCake = state => state.cake;

export const { 
  sizeSelected, 
  flavorSelected, 
  colorThemeSelected, 
  flakeColorSelected, 
  flowerOptionSelected, 
  numberCakeNumberSelected, 
  currentPriceUpdated, 
  startingPriceSet,
  setCakeId,
  setCakeImg,
  resetToInitial,
} = cakeSlice.actions

export default cakeSlice.reducer