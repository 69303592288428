import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { selectCakeInfo } from "../../redux/reducers/websiteStandardCake";

const useStyles = makeStyles(() => ({
  inputLabel: {
    color: "grey !important",
    fontSize: "14px !important",
    "&.MuiInputLabel-shrink": {
      color: "grey !important",
    },
  },
  options: {
    backgroundColor: "white",
    width: "100% !important",
    borderRadius: "10px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f6725c",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    fontSize: "0.8rem !important",
    height: "3rem",
  },
}));

export default function MultiOptionsInput({
  cake,
  label,
  options,
  action,
  selectedValue,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const [selectedOption, setSelectedOption] = useState("");
  const selectedCake = useSelector(selectCakeInfo);

  const handleChange = (option) => {
    // setSelectedOption(option.name);

    if (label === "Choose Flavor") {
      dispatch(action({ cakeName: cake.name, newFlavor: option.name }));
    }
    if (label === "Choose Size") {
      dispatch(
        action({
          cakeName: cake.name,
          size: option.name,
          price: option.price,
          img: cake.images.primary,
        })
      );
    }
    if (label === "Choose Color") {
      dispatch(action({ cakeName: cake.name, newColor: option.name }));
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <FormControl
        fullWidth
        sx={{ ml: 1, mr: 1, mt: 1 }}
        data-testid="multioptionsinput"
      >
        <InputLabel
          id="demo-simple-select-autowidth-label"
          classes={{ root: classes.inputLabel }}
          sx={{ fontSize: "0.8rem !important" }}
        >
          {label}
        </InputLabel>
        <Select
          className={classes.options}
          value={cake.name === selectedCake.name ? selectedValue : ""}
          label="Choose Option"
          data-testid="multioptions-select"
          disabled={cake.name !== selectedCake.name}
        >
          {options.map((option, idx) => {
            return (
              <MenuItem
                onClick={() => handleChange(option)}
                key={idx}
                value={option.name}
              >
                <ListItemText primary={option.name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
