import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import MultiSelectInput from "../admin/multiSelectInput";
import { dateSelected, selectEventInfo } from "../../redux/reducers/admin";

export default function EventInfo() {
  const event = useSelector(selectEventInfo);
  const dispatch = useDispatch();

  const timeSlots = [
    { name: "11:00 AM" },
    { name: "12:00 PM" },
    { name: "1:00 PM" },
    { name: "2:00 PM" },
    { name: "3:00 PM" },
    { name: "4:00 PM" },
    { name: "5:00 PM" },
    { name: "6:00 PM" },
  ];

  const pickupLocations = [
    { name: "1483 Topsail Rd." },
    { name: "16 Stavanger Dr." },
  ];

  const handleDateChange = (date) => {
    dispatch(dateSelected(date));
  };

  return (
    <Grid container data-testid="event-info">
      <Grid item xs={12}>
        <Typography variant="h6">EVENT INFORMATION</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{ mr: 2, fontSize: "18px", color: "#5d5d5d" }}
          >
            Pick Up Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={event?.date ? dayjs(event.date) : null}
              onChange={(date) => handleDateChange(date)}
              sx={{
                width: "60% !important",
                "& .MuiInputBase-root": { backgroundColor: "white" },
              }}
            />
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <MultiSelectInput
          label={"Choose Time"}
          options={timeSlots}
        ></MultiSelectInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <MultiSelectInput
          label={"Choose Pickup Location"}
          options={pickupLocations}
        ></MultiSelectInput>
      </Grid>
      <Grid sm={6}></Grid>
    </Grid>
  );
}
