/** @format */

import React from "react";
import { Box } from "@mui/material";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Body from "./body";

export default function Product() {
  return (
    <Box
      sx={{
        height: "100vh",
        margin: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      <Body></Body>
      <Box sx={{ backgroundColor: "#fd776280", marginTop: "auto" }}>
        <Footer></Footer>
      </Box>
    </Box>
  );
}
