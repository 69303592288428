import React, { useState, useEffect } from "react";
import axios from "axios";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardForm from "./cardForm";

import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";

import {
  CreditCard,
} from '@mui/icons-material';

import { capitalizeFirstLetter } from "../../../../utils/common";
import { theme } from "../../../../theme";
import config from "../../../../appConfig";

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);  

const PaymentMethods = ({ user, subscriptionProduct, setModalView, modalView }) => {

  const [customerId, setCustomerId] = useState(user.stripeCustomerId);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(user.stripeDefaultPaymentMethod);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(async()=>{
    // check if user have stripeCustomerID,
    // if none, create and update user 
    // update stripeDefaultPaymentMethod if available

    if(!customerId) {
      const resp = await axios.post("/api/users/updateCustomerId", { email: user.email })
      localStorage.setItem('user', JSON.stringify(resp.data));
      setCustomerId(resp.data.stripeCustomerId)
      setDefaultPaymentMethod(resp.data.stripeDefaultPaymentMethod)
    } else {
      const res = await axios.get(`/api/users/payment-methods?customerId=${customerId}`)
      setPaymentMethods(res.data)
    }
  }, [customerId])

  const handleSubscribeBtnClick = async() => {
    let currentUser = JSON.parse(localStorage.getItem("user"));
    try {
      const resp = await axios.put("/api/subscriptions", {
        stripeCustomerId: currentUser.stripeCustomerId,
        stripeProductId: subscriptionProduct.stripeProductId,
        stripePaymentMethodId: defaultPaymentMethod,
        selections: modalView.data.selections,
      })

      setModalView({
        ...modalView, 
        view: "success", 
        data: { 
          ...modalView.data, 
          subscription: resp.data 
        } 
      })

    } catch (error) {
      
      console.error(error)
      setModalView({
        ...modalView, 
        view: "fail",
        data: { 
          ...modalView.data, 
          error,
        }
      })
    }
    
  }

  return (
    <Box sx={{ 
      display: "flex", 
      flexFlow: "column", 
      marginTop: "24px"
    }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography>
          <h3>{ (paymentMethods.length >= 1) ? "PAYMENT METHODS" : "ADD PAYMENT METHOD" }</h3>
        </Typography>
      </Box>


      {
        (paymentMethods.length < 1) &&
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "100%" }}>
            <Elements stripe={stripePromise} style={{ width: "100%" }}>
              <CardForm 
                setDefaultPaymentMethod={setDefaultPaymentMethod} 
                setPaymentMethods={setPaymentMethods}
                user={user}
                setModalView={setModalView}
                modalView={modalView}
                style={{ width: "100%" }}
              />
            </Elements>
          </div>
          
        </Box>
      }

      {
        (paymentMethods.length >= 1) &&
        paymentMethods.map(pm => 
          <Box sx={{ 
            display: "flex", 
            justifyContent: "center", 
            margin: "8px 0",
            flexDirection: { xs: 'column', md: 'row' },
          }}>
            <TextField
              label={`Card Number - ${capitalizeFirstLetter(pm.card.brand)}`}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <CreditCard />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={`xxxx xxxx xxxx ${pm.card.last4}`}
              style={{ margin: "8px"}}
              readOnly
            />

            <TextField
              label="Expiry"
              variant="outlined"
              
              value={`${pm.card.exp_month}/${pm.card.exp_year}`}
              style={{ margin: "8px"}}
              readOnly
            />

            { 
              (pm.id === defaultPaymentMethod)
              ? <Typography style={{ width: 60, margin: "0 8px"}}>
                  <p>(default)</p>
                </Typography>
              : <div style={{ width: 60, margin: "0 8px"}}></div>
            }
          </Box>
        )
      }

      {
        (paymentMethods.length >= 1) &&
        <Box sx={{ display: "flex", justifyContent: "center", margin: "24px" }}>
          <Button
            sx={{
              ...theme.secondaryButton,
              width: { xs: "100%", sm: "50%", md: "25%" },
            }}
            onClick={handleSubscribeBtnClick}
          >
            Subscribe
          </Button>
        </Box>
      }

    </Box>
  )
}


export default PaymentMethods