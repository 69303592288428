/** @format */

import React from "react";
import { Box, Typography } from "@mui/material";
import Flake from "./flake";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
}));

export default function FlakeSection({ info }) {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.textStyle} variant="h6" sx={{ mb: 1 }}>
        {info.displayableName}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
        data-testid="flake-section"
      >
        {info.values.map((color) => {
          return <Flake flake={color} parentId={info._id}></Flake>;
        })}
      </Box>
    </div>
  );
}
