import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCakeInfo,
  selectAccessoryInfo,
} from "../../../redux/reducers/inStoreStandardCake";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: "2rem !important",
    marginBottom: "2rem !important",
    width: "100% !important",
    borderRadius: "10px !important",
    boxShadow: "none",
    backgroundColor: "white",
    padding: 0,
  },
  tableCellCenter: {
    fontSize: "1rem !important",
    textAlign: "center !important",
  },
  tableCellLeft: {
    fontSize: "1rem !important",
    textAlign: "left !important",
  },
  tableCellRight: {
    fontSize: "1rem !important",
    textAlign: "right !important",
  },
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
  cakeInfo: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
}));

export default function Order() {
  const classes = useStyles();
  const cakeInfo = useSelector(selectCakeInfo);
  const dispatch = useDispatch();

  let flavor = cakeInfo.flavorSection.flavor;
  let color = cakeInfo.colorSection.color;

  return (
    <>
      <Typography className={classes.heading} sx={{ mt: 4 }}>
        Order
      </Typography>
      <TableContainer className={classes.tableContainer} data-testid="order-review">
        <Table sx={{ textTransform: "capitalize" }}>
          <TableBody>
            <TableRow>
              <TableCell
                className={classes.tableCellLeft}
                sx={{
                  fontSize: "18px",
                  padding: "1rem 0 1rem 1rem !important",
                  width: "40%",
                }}
              >
                {cakeInfo.name}
              </TableCell>
              <TableCell
                className={classes.tableCellCenter}
                sx={{ fontSize: "18px", color: "grey", width: "30%" }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography>{`Size: ${cakeInfo.size}`}</Typography>
                  {flavor && <Typography>{`Flavour: ${flavor}`}</Typography>}
                  {color ? <Typography>{`Color: ${color}`}</Typography> : ""}
                </Box>
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold" }}
                className={classes.tableCellRight}
              >
                {`$ ${cakeInfo.price.toFixed(2)}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
