/** @format */

import { React, useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import axios from "axios";
import { Typography, Grid, Box, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  addOnAdded,
  addOnRemoved,
  selectAddOns,
} from "../../../redux/reducers/cart";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    display: "block",
    marginBottom: 10,
  },
  price: {
    color: "#828282",
    marginBottom: 20,
    ...theme.textStyle,
  },
  pic: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  warningText: {
    color: "red",
    marginBottom: 20,
    fontSize: "13px",
    ...theme.textStyle,
  },
  btn: {
    ...theme.containedButtonDark,
    width: "100px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
      color: theme.palette.secondary.light,
    },
    "&:focus": {
      backgroundColor: "#fd7762 !important",
    },
  },

  textStyle: { ...theme.textStyle },
}));

const AddOnItem = ({ addon }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [blankTextError, setBlankTextError] = useState(false);
  const [invalidCharError, setInvalidCharError] = useState(false);
  const [btnText, setBtnText] = useState("add");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const labelText = addon?.label;
  const selectedAddOns = useSelector(selectAddOns);
  const tempEmail = localStorage.getItem("tempEmail");

  useEffect(() => {
    for (let i = 0; i < selectedAddOns.length; i++) {
      let e = selectedAddOns[i];
      if (e.name === addon.name) {
        return setBtnText("remove");
      }
    }
    return setBtnText("add");
  });

  const handleAdd = async ({ type, name, price, requriesTextInput }) => {
    const userInputIsValid = validateTextfieldInput(type, requriesTextInput);
    if (userInputIsValid) {
      const addOn = { name, price, text };
      const res = await axios.post("api/cart/addAddOn", {
        email: currentUser ? currentUser.email : tempEmail,
        addOn,
      });
      if (res.status === 200) {
        setBtnText("remove");
        dispatch(addOnAdded(addOn));
      }
    }
  };

  const handleRemove = async ({ name }) => {
    const res = await axios.post("api/cart/removeAddOn", {
      email: currentUser ? currentUser.email : tempEmail,
      name,
    });
    if (res.status === 200) {
      dispatch(addOnRemoved({ name }));
    }
    setBtnText("add");
  };

  const handleTextChange = (val) => {
    setText(val);
  };

  const validateTextfieldInput = (type, requriesTextInput) => {
    if (requriesTextInput && !text) {
      setBlankTextError(true);
      setInvalidCharError(false);
      return false;
    }

    if (type !== "numberCandle" && text.length > addon.numberOfCharsAllowed) {
      setInvalidCharError(true);
      setBlankTextError(false);
      return false;
    }

    if (
      type === "numberCandle" &&
      (parseInt(text) < 1 || parseInt(text) > 99)
    ) {
      setInvalidCharError(true);
      setBlankTextError(false);
      return false;
    }

    setBlankTextError(false);
    setInvalidCharError(false);

    return true;
  };

  return (
    <Grid container sx={{ mb: 6 }} data-testid="addon-item">
      <Grid item xs={4} sm={5} md={3}>
        <img src={addon.imgPath} className={classes.pic} alt="add-on item" />
      </Grid>
      <Grid item xs={8} sm={7} md={9}>
        <Typography className={classes.textStyle} sx={{ mb: 1 }}>
          {addon.name}
        </Typography>
        <Typography className={classes.price}>
          ${addon.price.toFixed(2)}
        </Typography>
        {addon.requriesTextInput && (
          <Box>
            <TextField
              className={classes.input}
              label={labelText}
              onChange={(e) => handleTextChange(e.target.value)}
              InputLabelProps={{
                className: classes.textStyle,
              }}
              InputProps={{ className: classes.textStyle }}
              data-testid="addon-text"
            ></TextField>
            {blankTextError && (
              <Typography className={classes.warningText}>
                {addon.emptyTextWarning}
              </Typography>
            )}
            {invalidCharError && (
              <Typography className={classes.warningText}>
                {addon.maxTextLengthWarning}
              </Typography>
            )}
          </Box>
        )}
        <Button
          variant={btnText === "add" ? "contained" : "text"}
          size="small"
          onClick={
            btnText === "add"
              ? () => handleAdd(addon)
              : () => handleRemove(addon)
          }
          className={classes.btn}
          data-testid="add-remove-addon"
        >
          <Typography className={classes.textStyle}>{btnText}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  const { addOns } = state.cart;
  return { addOns };
}

export default connect(mapStateToProps)(AddOnItem);
