import React, { useState } from "react";
import { Stepper, Step, StepLabel, StepIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import customCakeBg from "../../assets/images/customCakes/customCakeBg.png";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const useStyles = makeStyles({
  stepIconActive: {
    color: "#fd7762 !important",
  },
  stepIconCompleted: {
    color: "#fd7762 !important",
    cursor: "pointer !important",
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      color: "#fd7762 !important",
    },
  },
  mainContainer: {
    backgroundImage: `url(${customCakeBg})`,
    backgroundColor: "#f8f7f8 !important",
    backgroundSize: "contain !important",
    padding: "0 !important",
  },
  sizeHeading: {
    backgroundColor: "#ffddd8 !important",
    color: "#fd7762 !important",
    width: "100% !important",
    height: "8rem !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  stickyContainer: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
});

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#fd7762",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#fd7762",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export default function EnhancedStepper({
  steps,
  activeStep,
  setActiveStep,
  handleClose,
  category,
}) {
  const classes = useStyles();
  const [orderData, setOrderData] = useState({});

  const StepComponent = steps[activeStep].component;

  return (
    <>
      <Stepper
        activeStep={activeStep}
        sx={{ mb: 2, mt: 8, minWidth: "360px" }}
        alternativeLabel
        connector={<QontoConnector />}
        data-testid="enhanced-stepper">
        {steps.map((step, index) => (
          <Step key={step.label} onClick={() => setActiveStep(index)}>
            <StepLabel
              classes={{ root: classes.stepLabel }}
              StepIconComponent={(props) => {
                const { active, completed } = props;
                return (
                  <StepIcon
                    {...props}
                    classes={{
                      root: active
                        ? classes.stepIconActive
                        : completed
                        ? classes.stepIconCompleted
                        : "",
                    }}
                  />
                );
              }}>
              <Typography
                sx={{
                  color:
                    index === activeStep
                      ? "#fd7762 !important"
                      : "#000000 !important",
                }}>
                {step.label}
              </Typography>

              {/* {index === activeStep ? step.label : ""} */}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <StepComponent
        data={orderData}
        setOrderData={setOrderData}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleClose={handleClose}
        category={category}
      />
    </>
  );
}
