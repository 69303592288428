/** @format */

import React, { useState, useRef, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  Link
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CircularProgress from "@mui/material/CircularProgress";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../auth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#fd776280",
    position: "relative",
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "2rem 1rem",
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "30%",
    },
  },
  title: {
    color: "#fd7762",
    fontSize: "32px !important",
    marginBottom: "2rem !important",
    ...theme.textStyle,
  },
  button: {
    ...theme.containedButtonDark,
    width: "100%",
    marginTop: "20px !important",
    marginBottom: "1rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },

  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#fd7762",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd7762",
      },
    },
  },
  joinNow: {
    color: "#fd7762",
    ...theme.textStyle,
  },
  textStyle: { ...theme.textStyle },
  inputFieldText: {
    ...theme.textStyle,
    color: "#000",
  },
}));

export default function Signup() {
  initializeApp(firebaseConfig());

  const classes = useStyles();
  const timer = useRef();
  const auth = getAuth();
  let navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  let isValid = true;

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleFirstNameChange = (input) => {
    if (!input) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    setFirstName(input);
  };

  const handleLastNameChange = (input) => {
    if (!input) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    setLastName(input);
  };

  const handleEmailChange = (input) => {
    if (!input) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(input.toLowerCase());
  };

  const handlePasswordChange = (input) => {
    if (!input) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    setPassword(input);
  };

  const handleSignup = async () => {
    if (!firstName) {
      isValid = false;
      setFirstNameError(true);
    }
    if (!lastName) {
      isValid = false;
      setLastNameError(true);
    }
    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      isValid = false;
      setEmailError(true);
    }
    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password)) {
      isValid = false;
      setPasswordError(true);
    }

    if (isValid) {
      setLoading(true);
      try {
        await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(auth.currentUser);
        await axios.post("/api/users", {
          firstName,
          lastName,
          email,
          role: "registered_user",
        });
        setLoading(false);
        navigate("/signup-confirm");
      } catch (error) {
        setLoading(false);
        if (error.code === "auth/email-already-in-use")
          return alert(
            "The provided email is already in use by an existing user. Each user must have a unique email"
          );
        else {
          return alert(
            "Sorry, seems something is wrong on our end! Please try later or contact us."
          );
        }
      }
    }
  };

  return (
    <Box className={classes.wrapper} data-testid="sign-up">
      <Paper className={classes.container} elevation={10}>
        <Box>
          <KeyboardBackspaceIcon
            fontSize="large"
            onClick={() => navigate(-1)}
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography className={classes.title}>Create Account</Typography>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  width: "100%",
                  boxShadow: 3,
                },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                error={firstNameError}
                fullWidth
                id="outlined-required"
                label="First Name"
                defaultValue=""
                margin="normal"
                helperText={firstNameError ? "enter your first name" : ""}
                className={classes.root}
                InputLabelProps={{
                  className: classes.textStyle,
                }}
                InputProps={{ className: classes.inputFieldText }}
                FormHelperTextProps={{ className: classes.textStyle }}
                onChange={(e) => {
                  handleFirstNameChange(e.target.value);
                }}
                data-testid="firstName"
              />
              <TextField
                error={lastNameError}
                fullWidth
                id="outlined-required"
                label="Last Name"
                defaultValue=""
                margin="normal"
                helperText={lastNameError ? "enter your last name" : ""}
                className={classes.root}
                InputLabelProps={{
                  className: classes.textStyle,
                }}
                InputProps={{ className: classes.inputFieldText }}
                FormHelperTextProps={{ className: classes.textStyle }}
                onChange={(e) => {
                  handleLastNameChange(e.target.value);
                }}
                data-testid="lastName"
              />
              <TextField
                error={emailError}
                fullWidth
                id="outlined-required"
                label="Email"
                defaultValue=""
                margin="normal"
                helperText={emailError ? "enter a valid email" : ""}
                className={classes.root}
                InputLabelProps={{
                  className: classes.textStyle,
                }}
                InputProps={{ className: classes.inputFieldText }}
                FormHelperTextProps={{ className: classes.textStyle }}
                onChange={(e) => {
                  handleEmailChange(e.target.value);
                }}
                data-testid="email"
              />
              <TextField
                error={passwordError}
                fullWidth
                id="outlined-required"
                label="Password"
                defaultValue=""
                margin="normal"
                helperText={
                  passwordError
                    ? "password must be at least 8 chars long, contain at least 1 uppercase, 1 lowercase letter, and 1 number"
                    : ""
                }
                type="password"
                className={classes.root}
                InputLabelProps={{
                  className: classes.textStyle,
                }}
                InputProps={{ className: classes.inputFieldText }}
                FormHelperTextProps={{ className: classes.textStyle }}
                onChange={(e) => {
                  handlePasswordChange(e.target.value);
                }}
                data-testid="password"
              />
              <Box sx={{ position: "relative" }}>
                <Button
                  size={"large"}
                  fullwidth
                  className={classes.button}
                  onClick={handleSignup}
                  data-testid="signup-btn"
                >
                  <Typography className={classes.textStyle}>Sign Up</Typography>
                </Button>
                <Typography sx={{fontSize: "13px", mb: 2}}>
                  By signing up, you agree to our{' '}
                  <Link component={NavLink} to="/privacy-policy">
                    privacy policy
                  </Link>{' '}
                  and{' '}
                  <Link component={NavLink} to="/terms-and-conditions">
                    terms of use
                  </Link>.
                </Typography>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-6px",
                      marginLeft: "65px",
                      color: "#fff",
                    }}
                  />
                )}
              </Box>
            </Box>
            <Divider />
            <br />
            <Typography className={classes.textStyle} sx={{ mb: 5 }}>
              Already a member?{" "}
              <span
                className={classes.joinNow}
                onClick={() => navigate("/login")}
              >
                Log in
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                color: "#828282",
                justifyContent: "center",
              }}
            >
              <Typography
                className={classes.textStyle}
                sx={{ fontSize: "14px" }}
              >
                {"CAKEYHAND DESSERT FACTORY INC."} {new Date().getFullYear()}{" "}
                {"© All rights reserved"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
