/** @format */
import { makeStyles } from "@mui/styles";
import { Box, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectOrderInfo } from "../../../../../redux/reducers/admin";
import Piping from "./piping";

const useStyles = makeStyles((theme) => ({
  sectionHeadText: {
    color: "#242424 !important",
    fontWeight: "700 !important",
    fontSize: "18px !important",
    marginTop: "15px !important",
  },
  productContainer: {
    border: "0.5px solid #E0E0E0 !important",
    padding: "1rem !important",
    borderRadius: "10px !important",
  },
  addOnGridItem: {
    marginBottom: "16px !important",
  },
  addOnList: {
    display: "flex",
    flexDirection: "row",
  },
  addOnItem: {
    border: "0.5px solid #E0E0E0 !important",
    padding: "0.7rem !important",
    borderRadius: "10px !important",
  },
  addOnDetailText: {
    fontSize: "14px !important",
    fontWeight: "700",
  },
  addOnDetailSubText: {
    fontSize: "13px !important",
    color: "#676767 !important",
  },
}));

function AccessoriesDetail({ addOn, title }) {
  const classes = useStyles();
  const orderInfo = useSelector(selectOrderInfo);

  return (
    <Grid item xs={12} className={classes.addOnGridItem} data-testid="accessories-detail">
      <Box className={classes.addOnItem}>
        <Typography className={classes.addOnDetailText}>
          {`${title} x${addOn.options.length}`}
        </Typography>
        {addOn.options.map((option) => {
          return (
            <Box sx={{ display: "flex" }}>
              <Typography className={classes.addOnDetailSubText}>
                {`color: ${option.color}`}
              </Typography>
              {/* {addOn.type === "numberCandle" && (
                <Box>
                  {addOn.options.numbers.map((number) => {
                    return (
                      <Typography className={classes.addOnDetailSubText}>
                        {`number: ${number.number} `}
                      </Typography>
                    );
                  })}
                </Box>
              )} */}
              <Typography className={classes.addOnDetailSubText}>
                {`quantity: ${option.qty}`}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Grid>
  );
}

export default AccessoriesDetail;
