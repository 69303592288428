import React, { useState, useEffect } from "react";
import axios from "axios";

import { Container, Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dessert from "./Dessert/dessert";

const useStyles = makeStyles((theme) => ({
    flexStyle: {
        display: "flex !important",
        flexDirection: "column !important",
    },
    gridContainer: {
        gap: "2rem !important",
        margin: "3rem !important",
        [theme.breakpoints.down("md")]: {
            maxWidth: "100% !important",
            gap: "2rem !important",
        },
    },
    gridItem: {
        maxWidth: "30% !important",
        [theme.breakpoints.down("md")]: {
            maxWidth: "80% !important",
        },
    },
    image: {
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        borderRadius: "10% !important",
        minWidth: "260px",
        minHeight: "260px",
        backgroundColor: "gray !important",
        [theme.breakpoints.down("md")]: {
            width: "4rem !important",
            height: "4rem !important",
            marginTop: "1rem !important",
        },
    },
    primaryBtn: {
        backgroundColor: "white !important",
        color: "#fd7762 !important",
        borderRadius: "50px !important",
        width: "100% !important",
        padding: "1rem !important",
        textTransform: "capitalize !important",
        border: "2px solid #fd7762 !important",
        marginTop: "4rem !important",
        "&:hover": {
            backgroundColor: "#fd7762 !important",
            color: "white !important",
            border: "2px solid #fd7762 !important"
        },
    },
    standardCakeImg: {
        width: "100% !important",
        height: "4rem !important",
        objectFit: "contain !important",
        padding: "2rem 0 1rem 2rem"
    },
}));

export default function ChooseDessert({ activeStep, setActiveStep}) {
    const classes = useStyles();

    const initialDesserts = []
    const [ desserts, setDesserts ] = useState(initialDesserts)

    useEffect(async() => {
        if(desserts===initialDesserts) {
            const res = await axios.get("/api/app/data/desserts");

            const weddingDesserts = res.data.map(dessert=> {
                const images = dessert.images.filter(img=>img.name==="wedding-package-img")
                const basePrices = dessert.basePrices.filter(img=>img.name==="wedding-package-price")
                return {
                    ...dessert,
                    image: images.length > 0 ? images[0].value : "/no-image",
                    basePrice: basePrices.length > 0 ? basePrices[0].price : 0,
                    itemsPerSet: dessert.productConfig.filter(config=>config.name==="wedding-package-items-per-set")[0].value
                   
                }
            })

            setDesserts(weddingDesserts)
        }
    }, [desserts])
    

    return (
        <Container sx={{
            display: "flex !important",
            flexDirection: "column !important",
            justifyContent: "center !important",
            alignItems: "center !important",
            }}>
            <Grid container className={classes.gridContainer} spacing={3}>
                {desserts.map((dessert, index) => (
                    <Grid item xs={4} key={index} className={classes.gridItem}>
                        <Box className={classes.flexStyle}>
                            <Box 
                                className={classes.image} 
                                style={{ 
                                    background: `url(${dessert.image})`, 
                                    backgroundSize: "cover"
                                }}
                            />
                            <Dessert dessert={dessert} activeStep={activeStep} setActiveStep={setActiveStep}/>
                        </Box> 
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
