import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Terms and Conditions
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" gutterBottom>
          1. Introduction
        </Typography>
        <Typography paragraph>
          Welcome to CAKEYHAND! By using our website and purchasing our products, you agree to comply with the following terms and conditions. Please read them carefully.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Orders and Payments
        </Typography>
        <Typography paragraph>
          All orders placed through our website are subject to availability and confirmation of payment. We reserve the right to cancel or refuse orders in the event of incorrect pricing, product unavailability, or other unforeseen circumstances.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Cancellations and Refunds
        </Typography>
        <Typography paragraph>
          Once an order is placed, cancellations may only be accepted until we have not started the preparation work of your order which is typically 48 hours before the order date. 
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Product Descriptions and Availability
        </Typography>
        <Typography paragraph>
          We strive to provide accurate descriptions and images of our products. However, there may be slight variations in design, color, or ingredients. Product availability is subject to change without notice.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Pricing and Promotions
        </Typography>
        <Typography paragraph>
          All prices are listed in Canadian dollars and are subject to change without notice. Any promotions, discounts, or offers are limited to the terms stated and cannot be combined unless otherwise specified.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Delivery and Pickup
        </Typography>
        <Typography paragraph>
          We offer delivery services via Skip The Dishes and Uber Eats food delivery apps. We are not responsible for the improper delivery of the goods ordered via these apps. If choosing pickup, it is your responsibility to collect your order within the agreed time.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Allergens and Special Dietary Requirements
        </Typography>
        <Typography paragraph>
          Our products may contain allergens such as nuts, dairy, eggs, and gluten. If you have specific dietary requirements or allergies, please inform us before placing your order, and we will do our best to accommodate your needs where possible.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Liability
        </Typography>
        <Typography paragraph>
          CAKEYHAND is not liable for any direct, indirect, or consequential damages arising from the use of our products or website. Our liability is limited to the cost of the product purchased.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Changes to Terms
        </Typography>
        <Typography paragraph>
          We reserve the right to update or modify these terms and conditions at any time without prior notice. It is your responsibility to review the terms periodically for changes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Governing Law
        </Typography>
        <Typography paragraph>
          These terms and conditions are governed by and construed in accordance with the laws of [Your Country/State], and you agree to submit to the exclusive jurisdiction of its courts.
        </Typography>

        <Typography variant="h6" gutterBottom>
          11. Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions or concerns about these terms, please contact us at contact@cakeyhand.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
