/** @format */

import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";
import FeaturedProducts from "./featuredProducts";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../theme";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      textAlign: "left",
      justifyContent: "space-between",
    },
  },
  headlines: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "1rem !important",
    },
  },
  heading1: {
    ...theme.textStyle,
    color: theme.palette.primary.dark,
  },
  heading2: {
    ...theme.textStyle,
    marginBottom: "1rem !important",
  },
  subHeading1: {
    ...theme.textStyle,
    color: theme.palette.secondary.main,
  },
  textStyle: { ...theme.textStyle },
}));

export default function FeaturedProductsSection() {
  const classes = useStyles();

  return (
    <Box>
      <Container maxWidth="xl">
        <Box>
          <Box className={classes.headlines}>
            <Box className={classes.heading2}>
              <Typography
                sx={{
                  fontFamily: "Moul",
                  fontSize: { xs: "2.2rem", md: "3rem" },
                }}>
                Our Featured Treats
              </Typography>

              <Typography
                className={classes.subHeading1}
                sx={{
                  fontFamily: "Inter",
                  color: "#909090",
                  fontSize: { xs: "0.875rem", md: "1rem", lg: "1.2rem" },
                }}>
                from new products to beloved sweets on sale
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 5 }}>
            <FeaturedProducts />
          </Box>
        </Box>
      </Container>
      <Box sx={{ mb: 5 }}>
        <Divider />
      </Box>
    </Box>
  );
}
