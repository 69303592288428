import React, { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";

export default function QuantitySelector({
  itemsPerSet,
  handleQuantityChange,
  setMinimumOnly = false,
  initialQuantity,
  minQTY,
  styleObj,
}) {
  const [quantity, setQuantity] = useState(initialQuantity);

  const handleIncrement = () => {
    let newQuantity;
    if (setMinimumOnly) {
      newQuantity = quantity + 1;
    } else {
      newQuantity = quantity + itemsPerSet;
    }

    if (newQuantity >= itemsPerSet) {
      setQuantity(newQuantity);
      handleQuantityChange(newQuantity);
    }
  };

  const handleDecrement = () => {
    let newQuantity;

    if (setMinimumOnly) {
      newQuantity = quantity - 1;
    } else {
      newQuantity = quantity - itemsPerSet;
    }

    if (newQuantity >= minQTY) {
      setQuantity(newQuantity);
      handleQuantityChange(newQuantity);
    } else {
      alert(`minimum required quantity is ${minQTY}`);
    }
  };

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  return (
    <Box
      sx={{
        display: "flex !important",
        flexDirection: "row !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        border: "1px solid #fd7762",
        borderRadius: "5px",
        padding: "5px 0",
      }}>
      <Button
        size="small"
        sx={{
          color: "black",
          minWidth: "20px !important",
        }}
        onClick={handleDecrement}>
        <RemoveIcon sx={styleObj.icon} />
      </Button>
      <Typography sx={styleObj.quantityText}>{quantity}</Typography>
      <Button
        size="small"
        sx={{ color: "black", minWidth: "20px !important" }}
        onClick={handleIncrement}>
        <AddIcon sx={styleObj.icon} />
      </Button>
    </Box>
  );
}
