import React from "react";
import { useDispatch } from "react-redux";
import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  priceSelected,
  nameSelected,
  emailSelected,
  phoneNumberSelected,
  addressSelected,
  textPipingSelected,
  specialNotesSelected,
} from "../../redux/reducers/admin";

export default function FreeTextInput({ label }) {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (label === "Price") {
      const price = parseInt(e.target.value.trim());
      dispatch(priceSelected(price));
    }
    if (label === "Name") {
      dispatch(nameSelected(e.target.value.trim()));
    }
    if (label === "Email") {
      dispatch(emailSelected(e.target.value.trim().toLowerCase()));
    }
    if (label === "Phone Number") {
      dispatch(phoneNumberSelected(e.target.value.trim()));
    }
    if (label === "Address") {
      dispatch(addressSelected(e.target.value.trim()));
    }
    if (label === "Piping") {
      dispatch(
        textPipingSelected({ type: "piping", val: e.target.value.trim() })
      );
    }
    if (label === "Other") {
      dispatch(specialNotesSelected(e.target.value.trim()));
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-required"
          label={label}
          onChange={handleChange}
          data-testid="freetextinput"
        />
      </div>
    </Box>
  );
}
