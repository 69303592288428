import React, { useState, useEffect } from "react";
import { Grid, Box, Checkbox, Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "../common/header";
import MultiImageShow from "../../../../components/multiImageShow";

const useStyles = makeStyles((theme) => ({
  wrapperBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem 0 1rem 1rem",
  },
  accessName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    color: "#fd7762",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      fontWeight: "bold !important",
      whiteSpace: "nowrap",
    },
  },
  checkbox: {
    "&.Mui-checked": {
      color: "#fd7762",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      width: "2.15rem !important",
      height: "2.15rem !important",
    },
  },
  price: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "black",
    marginLeft: "32px",
  },
}));

export default function GreetinCard({ accessory }) {
  const classes = useStyles();
  return (
    <>
      {accessory.content.map((accessoryItem) => {
        return (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              key={accessory.subtype}
              sx={{ mt: 8 }}
              data-testid="greeting-card"
            >
              <Paper
                elevation={10}
                sx={{
                  margin: "0 1rem 0 1rem",
                  p: 2,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "none",
                }}
              >
                <MultiImageShow images={accessoryItem.images}></MultiImageShow>
                <Header accessory={accessoryItem}></Header>
              </Paper>
            </Grid>
          </>
        );
      })}
    </>
  );
}
