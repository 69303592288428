import React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Grid, Button, Modal } from "@mui/material";
import { pinkText, theme } from "../../theme";
import Steps from "./components/steps";
import OfficeSnacksModal from "./modal-contents/officeSnacksModal";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    height: "80%",
    width: "80%",
    padding: 20,
    // overflow: "scroll",
    //[theme.breakpoints.up("sm")]: {
    //width: "40%",
    //},
  },
}));

export default function OfficeSnacks() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "54px 0",
          flexDirection: "column",
          alignItems: "center",
        }}
        id="officeSnacks"
      >
        <Box sx={{ margin: "12px" }}>
          <Typography
            sx={{ ...theme.titleText, ...theme.titleText.sectionText }}
          >
            OFFICE SNACKS
          </Typography>
        </Box>
        <Box
          sx={{
            margin: "12px",
            maxWidth: "627px",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: "800",
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            "BOOST YOUR EMPLOYEES" PRODUCTIVITY AND MOTIVATION WITH OUR FRESH
            AND DELIVIOUS GOODIES
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: theme.titleText.fontFamily,
              fontWeight: "800",
              fontSize: "20px",
              margin: "24px",
              lineHeight: "33.54px",
              color: "#242424",
            }}
          >
            HOW TO ORDER
          </Typography>
        </Box>
      </Box>
      <Grid container sx={{ display: "flex", flexWrap: "wrap" }}>
        <Steps number={"1"} word={"THE PRODUCT"}></Steps>
        <Steps number={"2"} word={"THE FREQUENCY"}></Steps>
        <Steps number={"3"} word={"THE BUDGET"}></Steps>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", margin: "12px" }}>
        <Button
          sx={{
            ...theme.secondaryButton,
            width: { xs: "100%", sm: "50%", md: "25%" },
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          Get Started
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle} sx={{ overflow: "hidden" }}>
          <OfficeSnacksModal handleClose={handleClose}></OfficeSnacksModal>
        </Box>
      </Modal>
    </>
  );
}
