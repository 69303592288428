import React, { useState } from "react";
import { Box, Container, Paper, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "./header";
import EnhancedStepper from "./enhancedStepper";
import customCakeBg from "../../assets/images/customCakes/customCakeBg.png";
import CakeSelection from "./cakeSelection";
import SizeSelection from "./sizeSelection";
import AccessoriesSelection from "./accessorySelection";
import CustomerInfo from "./customerInfo";
import Review from "./review";

const useStyles = makeStyles({
  mainContainer: {
    backgroundImage: `url(${customCakeBg})`,
    backgroundColor: "#f8f7f8 !important",
    backgroundSize: "contain !important",
    padding: "0 !important",
  },
  stickyContainer: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
});

export default function CakeOrderForm() {
  const classes = useStyles();
  const [category, setCategory] = useState("standardCake");
  const [activeStep, setActiveStep] = useState(0);

  const standardCakeSteps = [
    { label: "Cake", component: CakeSelection },
    { label: "Size", component: SizeSelection },
    { label: "Accessories", component: AccessoriesSelection },
    { label: "Customer Info", component: CustomerInfo },
    { label: "Review", component: Review },
  ];

  const cheesecakeSteps = [
    { label: "Cheesecake", component: CakeSelection },
    { label: "Size", component: SizeSelection },
    { label: "Accessories", component: AccessoriesSelection },
    { label: "Customer Info", component: CustomerInfo },
    { label: "Review", component: Review },
  ];

  const handleCategorySelection = (category) => {
    setCategory(category);
  };

  return (
    <Container className={classes.mainContainer}>
      <Box className={classes.stickyContainer}>
        <Header></Header>
      </Box>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item sm={6}>
          <Paper
            sx={{
              padding: "30px !important",
              textAlign: "center",
              bgcolor:
                category === "standardCake"
                  ? "#ffddd8 !important"
                  : "#ffffff !important",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => handleCategorySelection("standardCake")}>
            <Typography variant="h4">CAKES</Typography>
          </Paper>
        </Grid>
        <Grid item sm={6}>
          <Paper
            sx={{
              padding: "30px !important",
              textAlign: "center",
              bgcolor:
                category === "cheesecake"
                  ? "#ffddd8 !important"
                  : "#ffffff !important",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => handleCategorySelection("cheesecake")}>
            <Typography variant="h4">CHEESECAKES</Typography>
          </Paper>
        </Grid>
      </Grid>
      {category && category === "standardCake" && (
        <EnhancedStepper
          steps={standardCakeSteps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          category={category}></EnhancedStepper>
      )}
      {category && category === "cheesecake" && (
        <EnhancedStepper
          steps={cheesecakeSteps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          category={category}></EnhancedStepper>
      )}
    </Container>
  );
}
