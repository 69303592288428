/** @format */
import { makeStyles } from "@mui/styles";
import { Box, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectOrderInfo } from "../../../../../redux/reducers/admin";
import Piping from "./piping";
import AccessoriesDetail from "./accessoriesDetail";

const useStyles = makeStyles((theme) => ({
  sectionHeadText: {
    color: "#242424 !important",
    fontWeight: "700 !important",
    fontSize: "18px !important",
    marginTop: "15px !important",
  },
  productContainer: {
    border: "0.5px solid #E0E0E0 !important",
    padding: "1rem !important",
    borderRadius: "10px !important",
  },
  addOnGridItem: {
    marginBottom: "16px !important",
  },
  addOnList: {
    display: "flex",
    flexDirection: "row",
  },
  addOnItem: {
    border: "0.5px solid #E0E0E0 !important",
    padding: "0.7rem !important",
    borderRadius: "10px !important",
  },
  addOnDetailText: {
    fontSize: "14px !important",
    fontWeight: "700",
  },
  addOnDetailSubText: {
    fontSize: "13px !important",
    color: "#676767 !important",
  },
}));

function AccessoriesDetailWrapper() {
  const classes = useStyles();
  const orderInfo = useSelector(selectOrderInfo);

  return (
    <Grid container>
      <Grid item xs={12} sm={3}>
        <Typography className={classes.sectionHeadText}>Accessories</Typography>
      </Grid>
      {orderInfo.addOns.length > 0 ? (
        <Box>
          <Box>
            {orderInfo.addOns.map((addOn, i) => {
              switch (orderInfo.orderSource !== "website" && addOn.type) {
                case "piping": {
                  return <Piping addOn={addOn} />;
                }
                case "happyBirthdayCandle": {
                  return (
                    <AccessoriesDetail
                      addOn={addOn}
                      title={"Candle - Happy Birthday"}
                    ></AccessoriesDetail>
                  );
                }

                case "standardCandle": {
                  return (
                    <AccessoriesDetail
                      addOn={addOn}
                      title={"Candle - Standard"}
                    ></AccessoriesDetail>
                  );
                }

                case "numberCandle": {
                  return (
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Box className={classes.addOnItem}>
                        <Typography className={classes.addOnDetailText}>
                          {"Candle - Number"}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            className={classes.addOnDetailSubText}
                            sx={{ mr: 1 }}
                          >
                            color:
                          </Typography>
                          <Typography className={classes.addOnDetailSubText}>
                            {addOn.options.color}
                          </Typography>
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <Typography
                            className={classes.addOnDetailSubText}
                            sx={{ mr: 1 }}
                          >
                            numbers:
                          </Typography>
                          {addOn.options.numbers.map((number) => {
                            return (
                              <Typography
                                className={classes.addOnDetailSubText}
                              >
                                {` ${number.number} `}
                              </Typography>
                            );
                          })}
                        </Box>
                      </Box>
                    </Grid>
                  );
                }

                case "shapesCandle": {
                  return (
                    <AccessoriesDetail
                      addOn={addOn}
                      title={"Candle - Shapes"}
                    ></AccessoriesDetail>
                  );
                }

                case "spiralCandle": {
                  return (
                    <AccessoriesDetail
                      addOn={addOn}
                      title={"Candle - Spiral"}
                    ></AccessoriesDetail>
                  );
                }

                case "greetingCard": {
                  return (
                    <Grid item xs={12} className={classes.addOnGridItem}>
                      <Box className={classes.addOnItem}>
                        <Typography className={classes.addOnDetailText}>
                          {"Greeting Card"}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                }

                case "kissTopper": {
                  return (
                    <AccessoriesDetail
                      addOn={addOn}
                      title={"Topper - Kiss"}
                    ></AccessoriesDetail>
                  );
                }

                case "happyBirthdayTopper": {
                  return (
                    <AccessoriesDetail
                      addOn={addOn}
                      title={"Topper - Happy Birthday"}
                    ></AccessoriesDetail>
                  );
                }

                case "ringsTopper": {
                  return (
                    <AccessoriesDetail
                      addOn={addOn}
                      title={"Topper - Rings"}
                    ></AccessoriesDetail>
                  );
                }

                case "proposalTopper": {
                  return (
                    <AccessoriesDetail
                      addOn={addOn}
                      title={"Topper - Proposal"}
                    ></AccessoriesDetail>
                  );
                }
              }

              if (orderInfo.orderSource === "website") {
                return (
                  <Grid item xs={12} className={classes.addOnGridItem}>
                    <Box className={classes.addOnItem}>
                      <Typography className={classes.addOnDetailText}>
                        {addOn.name}
                      </Typography>
                      <Typography className={classes.addOnDetailSubText}>
                        {addOn.text}
                      </Typography>
                      <Typography className={classes.addOnDetailSubText}>
                        {addOn.price.toFixed(2)}$
                      </Typography>
                    </Box>
                  </Grid>
                );
              }
            })}
          </Box>
        </Box>
      ) : (
        <Box>no accessories</Box>
      )}
    </Grid>
  );
}

export default AccessoriesDetailWrapper;
