import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, Button, Typography, Box } from "@mui/material";
import { updateCart } from "../../redux/reducers/cart.js";
import QuantitySelector from "../../components/quantitySelector";

const useStyles = makeStyles((theme) => ({
  flexStyle: {
    display: "flex !important",
    flexDirection: "column !important",
    alignItems: "center !important",
  },
  primaryBtn: {
    backgroundColor: "white !important",
    color: "#fd7762 !important",
    borderRadius: "50px !important",
    width: "100% !important",
    padding: "1rem !important",
    textTransform: "capitalize !important",
    border: "2px solid #fd7762 !important",
    marginTop: "2rem !important",
    "&:hover": {
      backgroundColor: "#fd7762 !important",
      color: "white !important",
      border: "2px solid #fd7762 !important",
    },
  },
  button: {
    ...theme.containedButton,
    width: "250px !important",
    marginBottom: "0.5rem !important",
    marginTop: "2rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  price1: {
    fontSize: "1.2rem !important",
    fontWeight: "bold !important",
    marginBottom: "30px !important",
  },
  price2: {
    fontSize: "1rem !important",
  },
}));

export default function HolidayProductCard({ holidayProduct }) {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const tempEmail = localStorage.getItem("tempEmail");
  const [quantity, setQuantity] = useState(0);

  const navigate = useNavigate();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setSuccess(false);
    }, 5000);
  }, [success]);

  useEffect(() => {
    if (holidayProduct.category !== "cake") {
      setQuantity(holidayProduct.productConfig[1].value);
    }
  }, [quantity]);

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity);
  };

  const addToCart = async () => {
    let holidayProductToAdd = {
      id: Date.now(),
      productId: holidayProduct._id,
      name: holidayProduct.displayName,
      flavor: holidayProduct.flavor,
      imgPath: holidayProduct.images.primary,
      price: holidayProduct.basePrices[0].price,
      quantity: quantity,
      type: holidayProduct.holidayType,
    };

    try {
      setLoading(true);
      setSuccess(false);
      const res = await axios.post("/api/cart/addProduct/", {
        userEmail: currentUser ? currentUser.email : tempEmail,
        product: holidayProductToAdd,
      });
      if (res.status === 200) {
        window.setTimeout(() => {
          dispatch(updateCart(res.data));
          setLoading(false);
          setSuccess(true);
        }, 1000);
      }
    } catch (error) {
      alert(
        "We could not add the product. Please make sure all required fields are provided."
      );
    }
  };

  return (
    <Container
      sx={{
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        minWidth: "310px",
      }}>
      <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1, fontSize: "18px" }}>
        {holidayProduct.displayName}
      </Typography>

      <Typography sx={{ fontWeight: "bold", mt: 1, mb: 3, fontSize: "14px" }}>
        {holidayProduct.flavor}
      </Typography>

      {holidayProduct.category !== "cake" && (
        <Box
          sx={{
            display: "flex !important",
            flexDirection: "row !important",
            justifyContent: "space-between !important",
            alignItems: "center !important",
            width: "100% !important",
            mt: 2,
          }}>
          <QuantitySelector
            itemsPerSet={holidayProduct.productConfig[1].value}
            handleQuantityChange={handleQuantityChange}
            setMinimumOnly={true}
            initialQuantity={holidayProduct.productConfig[1].value}
          />
          <Box>
            <span
              className={
                classes.price1
              }>{`$${holidayProduct.basePrices[0].price.toFixed(2)}/`}</span>
            <span className={classes.price2}>each</span>
          </Box>
        </Box>
      )}

      {holidayProduct.category === "cake" && (
        <Box>
          <Typography
            className={
              classes.price1
            }>{`Starting at $${holidayProduct.startingPrice.toFixed(
            2
          )}`}</Typography>
        </Box>
      )}

      <Box
        sx={{
          position: "relative",
          width: "100%",
          marginBottom: "3rem !important",
        }}>
        {holidayProduct.category !== "cake" && (
          <Button
            size={"medium"}
            className={classes.primaryBtn}
            onClick={addToCart}>
            <Typography className={classes.textStyle}>Add to Cart</Typography>
          </Button>
        )}

        {holidayProduct.category === "cake" && (
          <Button
            size={"medium"}
            className={classes.primaryBtn}
            onClick={() =>
              navigate(
                `/cakes/specialty-cakes/product?cid=${holidayProduct._id}&type=holiday`
              )
            }>
            CUSTOMIZE
          </Button>
        )}

        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-0.1rem",
              marginLeft: "65px",
              color: "#fff",
            }}
          />
        )}

        {success && (
          <Typography
            className={classes.textStyle}
            sx={{ color: "green", textAlign: "center", marginTop: "16px" }}>
            Added!
          </Typography>
        )}
      </Box>
    </Container>
  );
}
