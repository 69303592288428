import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMediaQuery, useTheme } from "@mui/material";
import banner from "../../assets/images/productCategory/banner.png";
import bannerMobile from "../../assets/images/productCategory/bannerMobile.png";
import bannerTablet from "../../assets/images/productCategory/bannerTablet.png";

const useStyles = makeStyles((theme) => ({
  bannerWrapper: {
    display: "block",
    backgroundColor: "#fbdada",
    borderRadius: "8px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  bannerTextWrapper: {
    padding: "2rem 0 0 2rem",
  },
}));

const ProductsBanner = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));

  return (
    <Container maxWidth="xl" sx={{ padding: "0", mb: 8 }}>
      <Typography
        variant="h4"
        sx={{ fontFamily: "Moul", mb: 5, mt: 5, textAlign: "center" }}>
        Our Products
      </Typography>
      <Box className={classes.bannerWrapper}>
        <Box className={classes.bannerTextWrapper}>
          <Typography
            sx={{ fontFamily: "Moul", fontSize: { xs: "25px", sm: "35px" } }}>
            Deliciously Designed Cakes, <br /> Made Just for You
          </Typography>
          <Typography sx={{ fontFamily: "Inter", margin: "1rem 0 0 0" }}>
            Browse our collection of mouth-watering cakes, cheesecakes, and
            pastries crafted to perfection for any event.
          </Typography>
        </Box>

        <Box
          className={classes.bannerImageWrapper}
          sx={{ marginTop: isLargeTablet ? "65px" : 0 }}>
          <img
            src={isMobile ? bannerMobile : isTablet ? bannerTablet : banner}
            alt="cakes"
            style={{ width: "100%", borderRadius: "8px" }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default ProductsBanner;
