/** @format */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Container } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { makeStyles } from "@mui/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
}));

const Success = () => {
  let navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    let tempEmail = localStorage.getItem("tempEmail");
    axios
      .delete("/api/cart/removeCart/", { email: tempEmail })
      .then(() => {
        localStorage.removeItem("tempEmail");
      })
      .catch((error) => {
        console.log(error);
      });
    setTimeout(() => {
      navigate("/");
    }, 10000);
  });

  return (
    <Container>
      <Box>
        <Box marginTop={5} textAlign={"center"}>
          <CheckIcon style={{ fontSize: "7rem", color: "#28a745" }} />
          <Typography
            className={classes.textStyle}
            variant={"h3"}
            style={{ color: "#28a745" }}
          >
            Success !!!
          </Typography>

          <Typography
            className={classes.textStyle}
            variant={"h5"}
            style={{ color: "#888", marginTop: "2rem" }}
          >
            You will receive a receipt and an order confirmation to your email
            shortly. Make sure to check the spam/junk folder and Promotions tab
            as well if you don't find them in the main inbox
          </Typography>
          <Typography
            className={classes.textStyle}
            variant={"h5"}
            style={{ color: "#888", marginTop: "2rem" }}
          >
            You will now be redirected to the home page.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Success;
