/** @format */

import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  pendingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF4CD",
    padding: "4px 12px",
    height: "30px",
    borderRadius: "8px",
  },
  pendingText: {
    color: "#DD9200",
    fontWeight: "500",
    fontSize: "14px",
  },

  completeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#BAFFD6",
    padding: "4px 12px",
    height: "30px",
    borderRadius: "8px",
  },
  completeText: {
    color: "#106633",
    fontWeight: "500",
    fontSize: "14px",
  },

  cancelledContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(235, 87, 87, 0.2)",
    padding: "4px 12px",
    height: "30px",
    borderRadius: "8px",
  },
  cancelledText: {
    color: "#EB5757",
    fontWeight: "500",
    fontSize: "14px",
  },
}));

function Status({ order }) {
  const classes = useStyles();
  const currentDate = dayjs().format("YYYY-MM-DD");

  const pickupDate = dayjs(order.collectionDate).format("YYYY-MM-DD");

  if (order.orderStatus === "cancelled") {
    return (
      <Box className={classes.cancelledContainer} data-testid="status">
        <Typography className={classes.cancelledText}>Cancelled</Typography>
      </Box>
    );
  }

  if (currentDate <= pickupDate) {
    return (
      <Box className={classes.pendingContainer} data-testid="status">
        <Typography className={classes.pendingText}>Pending</Typography>
      </Box>
    );
  }
  if (currentDate > pickupDate) {
    return (
      <Box className={classes.completeContainer} data-testid="status">
        <Typography className={classes.completeText}>Complete</Typography>
      </Box>
    );
  }

  return null;
}

export default Status;
