/** @format */

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Quantity from "./Quantity";
import { useDispatch, useSelector } from "react-redux";
import {
  orderInfoSet,
  selectOrderInfo,
} from "../../../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  itemGridContainer: {
    display: "flex !important",
    flexDirection: "row !important",
    margin: "0 0 0 1rem !important",
  },
  cell: {
    display: "flex",
    flexDirection: "column",
    margin: "0 1rem 0 0",
  },
  productImg: {
    width: "190px !important",
    height: "auto !important",
    borderRadius: "20px !important",
  },
  fieldHead: {
    fontWeight: "bold !important",
    marginLeft: "2rem !important",
  },
  flexCell: {
    display: "flex !important",
    flexDirection: "row !important",
    margin: "1rem 0 1rem 0 !important",
  },
  quantityCell: {
    display: "inline-flex !important",
    flexDirection: "column !important",
    margin: "0 0 1rem 0 !important",
  },

  quantityGrid: {
    display: "flex !important",
    paddingRight: "100px !important",
    justifyContent: "flex-end !important",
    margin: "0 0 1rem 0 !important",
  },
  flavorSelect: {
    display: "flex",
    flexDirection: "row !important",
    alignItems: "center !important",
    margin: "0 0 16px 0 !important",
  },

  dropDownSelect: {
    margin: "0 0 16px 0 !important",
  },

  buttonContainer: {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "center !important",
    margin: "2rem 0 0 0 !important",
  },
}));

function EditMacaronOrder({ index }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const orderInfo = useSelector(selectOrderInfo);

  const flavors =
    orderInfo.products[index].type === "macarons"
      ? Object.keys(orderInfo.products[index].flavors)
      : {};
  const quantity =
    orderInfo.products[index].type === "macarons"
      ? Object.values(orderInfo.products[index].flavors)
      : {};

  const [macaronData, setMacaronData] = useState([]);
  const [size, setSize] = useState(orderInfo.products[index].size || "4 pk");
  const [selectedPackage, setSelectedPackage] = useState(
    orderInfo.products[index].packaging
  );
  const [flavorQuantity, setFlavorQuantity] = useState(
    Object.assign({}, quantity)
  );
  const [newFlavors, setNewFlavors] = useState(Object.assign({}, flavors));
  const [orderQuantity, setOrderQuantity] = useState(
    orderInfo.products[index].quantity
  );
  const [editingOrder, setEditingOrder] = useState();

  const boxSize = parseInt(size.substring(0, size.indexOf("pk")));

  useEffect(() => {
    const getMacaronData = async () => {
      try {
        const macarons = await axios.get(`/api/app/data/macarons`);
        setMacaronData(macarons.data);
      } catch (err) {
        console.log(err);
      }
    };

    const getOrderById = async () => {
      try {
        const order = await axios.get(`/api/orders/orderId`, {
          params: { orderId: orderInfo.orderId },
        });

        setEditingOrder(order.data);
      } catch (e) {
        console.log(e);
      }
    };

    getOrderById();
    getMacaronData();
  }, []);

  useEffect(() => {
    if (editingOrder) {
      editOrder();
    }
  }, [size, newFlavors, flavorQuantity, selectedPackage]);

  const editOrder = () => {
    const newFlavorArr = Object.values(newFlavors);
    const flavorQuantityArr = Object.values(flavorQuantity);

    const order = editingOrder;

    let newProducts = {};

    newFlavorArr.map((fa, i) => {
      newProducts = { ...newProducts, [fa]: flavorQuantityArr[i] };
    });

    const price = () => {
      let p = 0;

      flavorQuantityArr.map((fq) => {
        p = p + fq * 2.5;
      });

      p = p * orderQuantity;

      macaronData.packages.map((pk) => {
        if (pk.name === selectedPackage) p = p + pk.price;
      });

      return p;
    };

    const newProduct = {
      id: editingOrder.products[index].id,
      name: "Macaron Box",
      imgPath: editingOrder.products[index].imgPath,
      flavors: newProducts,
      size: size,
      quantity: orderQuantity,
      packaging: selectedPackage,
      type: editingOrder.products[index].type,
      price: price(),
    };

    order.products[index] = newProduct;

    dispatch(orderInfoSet(order));
  };

  return (
    <>
      <Box className={classes.itemGridContainer} data-testid="edit-macaron-order">
        <Box className={classes.cell}>
          <FormControl
            variant="outlined"
            className={classes.dropDownSelect}
            style={{ maxWidth: "70%" }}
          >
            <InputLabel shrink={true} id="size">
              Size
            </InputLabel>
            <Select
              label="Size"
              labelId="size"
              value={size}
              onChange={(e) => setSize(e.target.value)}
            >
              {macaronData.length !== 0
                ? macaronData.availableSizes.map((size) => {
                    return <MenuItem value={`${size}pk`}>{size} pk</MenuItem>;
                  })
                : null}
            </Select>
          </FormControl>

          <Box>
            <Box className={classes.flavorSelect}>
              <FormControl style={{ marginRight: "10px" }} variant="outlined">
                <Select
                  value={newFlavors[0]}
                  onChange={(e) => {
                    setNewFlavors({ ...newFlavors, 0: e.target.value });
                  }}
                >
                  {macaronData.length !== 0
                    ? macaronData.availableFlavors.map((af) => {
                        return <MenuItem value={af.name}>{af.name}</MenuItem>;
                      })
                    : null}
                </Select>
              </FormControl>
              <Typography> x </Typography>
              <TextField
                style={{ width: "15%", marginLeft: "10px" }}
                value={flavorQuantity[0]}
                id="flav1Qty"
                onChange={(e) => {
                  setFlavorQuantity({
                    ...flavorQuantity,
                    0: e.target.value,
                  });
                }}
              />
            </Box>

            <Box className={classes.flavorSelect}>
              <FormControl style={{ marginRight: "10px" }} variant="outlined">
                <Select
                  value={newFlavors[1]}
                  onChange={(e) => {
                    setNewFlavors({ ...newFlavors, 1: e.target.value });
                  }}
                >
                  {macaronData.length !== 0
                    ? macaronData.availableFlavors.map((af) => {
                        return <MenuItem value={af.name}>{af.name}</MenuItem>;
                      })
                    : null}
                </Select>
              </FormControl>
              <Typography> x </Typography>
              <TextField
                style={{ width: "15%", marginLeft: "10px" }}
                value={flavorQuantity[1]}
                id="flav2Qty"
                onChange={(e) => {
                  setFlavorQuantity({
                    ...flavorQuantity,
                    1: e.target.value,
                  });
                }}
              />
            </Box>

            <Box
              className={classes.flavorSelect}
              style={{
                display: flavors.length >= 3 || boxSize >= 6 ? "flex" : "none",
              }}
            >
              <FormControl style={{ marginRight: "10px" }} variant="outlined">
                <Select
                  value={newFlavors[2]}
                  onChange={(e) => {
                    setNewFlavors({ ...newFlavors, 2: e.target.value });
                  }}
                >
                  {macaronData.length !== 0
                    ? macaronData.availableFlavors.map((af) => {
                        return <MenuItem value={af.name}>{af.name}</MenuItem>;
                      })
                    : null}
                </Select>
              </FormControl>
              <Typography> x </Typography>
              <TextField
                style={{ width: "15%", marginLeft: "10px" }}
                value={flavorQuantity[2]}
                id="flav3Qty"
                onChange={(e) => {
                  setFlavorQuantity({
                    ...flavorQuantity,
                    2: e.target.value,
                  });
                }}
              />
            </Box>
            <Box
              className={classes.flavorSelect}
              style={{
                display: flavors.length >= 4 || boxSize >= 6 ? "flex" : "none",
              }}
            >
              <FormControl style={{ marginRight: "10px" }} variant="outlined">
                <Select
                  value={newFlavors[3]}
                  onChange={(e) => {
                    setNewFlavors({ ...newFlavors, 3: e.target.value });
                  }}
                >
                  {macaronData.length !== 0
                    ? macaronData.availableFlavors.map((af) => {
                        return <MenuItem value={af.name}>{af.name}</MenuItem>;
                      })
                    : null}
                </Select>
              </FormControl>
              <Typography> x </Typography>
              <TextField
                style={{ width: "15%", marginLeft: "10px" }}
                value={flavorQuantity[3]}
                id="flav2Qty"
                onChange={(e) => {
                  setFlavorQuantity({
                    ...flavorQuantity,
                    3: e.target.value,
                  });
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.cell}>
          <FormControl className={classes.dropDownSelect} variant="outlined">
            <InputLabel shrink={true} id="packaging">
              Packaging
            </InputLabel>
            <Select
              label="Packaging"
              labelId="packaging"
              value={selectedPackage}
              onChange={(e) => setSelectedPackage(e.target.value)}
            >
              {macaronData.length !== 0
                ? macaronData.packages.map((p) => {
                    return <MenuItem value={p.name}>{p.name}</MenuItem>;
                  })
                : null}
            </Select>
          </FormControl>

          <Quantity
            quantity={orderQuantity}
            setQuantity={setOrderQuantity}
            width="130px"
          />
        </Box>
      </Box>
    </>
  );
}

export default EditMacaronOrder;
