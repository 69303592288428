import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectCakeInfo,
  selectAccessoryInfo,
  selectCustomerInfo,
  selectEventInfo,
  selectCakeSubtotal,
  selectAccessoriesSubtotal,
  selectTax,
  selectRefundPolicyAgreed,
  orderIdUpdated,
} from "../../../redux/reducers/inStoreStandardCake";
import { Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";

const useStyles = makeStyles(() => ({
  submitButton: {
    "&.MuiButton-root": {
      backgroundColor: "transparent !important",
      color: "#f6725c !important",
      width: "20rem !important",
      height: "5rem !important",
      border: "2px solid #f6725c !important",
      fontSize: "20px !important",
      borderRadius: "10px !important",
      "&:hover": {
        backgroundColor: "#f6725c",
        color: "white",
      },
    },
  },
}));

export default function Submission() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const cakeInfo = useSelector(selectCakeInfo);
  const accessoryInfo = useSelector(selectAccessoryInfo);
  const customerInfo = useSelector(selectCustomerInfo);
  const eventInfo = useSelector(selectEventInfo);
  const cakeSubtotal = useSelector(selectCakeSubtotal);
  const accessoriesSubtotal = useSelector(selectAccessoriesSubtotal);
  const subtotal = cakeSubtotal + accessoriesSubtotal;
  const tax = useSelector(selectTax);
  const refundPolicyAgreed = useSelector(selectRefundPolicyAgreed);

  let selectedAccessories = [];
  const { candles, toppers, piping, greetingCard } = accessoryInfo;

  for (const key in candles) {
    if (candles[key].optionIsSelected) {
      selectedAccessories.push({
        type: key,
        options: candles[key].userSelection,
      });
    }
  }

  for (const key in toppers) {
    if (toppers[key].optionIsSelected) {
      selectedAccessories.push({
        type: key + "Topper",
        options: toppers[key].userSelection,
      });
    }
  }

  if (piping.optionIsSelected) {
    selectedAccessories.push({ type: "piping", options: piping.text.trim() });
  }

  if (greetingCard.optionIsSelected) {
    selectedAccessories.push({ type: "greetingCard", options: "n/a" });
  }

  const createOrder = async (orderData) => {
    try {
      const response = await axios.post(
        "/api/orders/orderByEmployee/",
        orderData
      );
      dispatch(orderIdUpdated(response.data.orderId));
      navigate("/cakes/order-form/approval");
    } catch (error) {
      console.error(error);
    }
  };

  const newOrder = {
    customerInfo: {
      email: customerInfo.email.toLowerCase(),
      address: customerInfo.address,
      phoneNumber: customerInfo.phone,
      name: customerInfo.name,
    },
    products: [
      {
        name: cakeInfo.name,
        size: cakeInfo.size,
        price: cakeInfo.price,
        flavor:
          cakeInfo.flavorSection.flavor !== null
            ? cakeInfo.flavorSection.flavor
            : cakeInfo.name,
        color:
          cakeInfo.colorSection.color !== null
            ? cakeInfo.colorSection.color
            : "n/a",
        imgPath: cakeInfo.imgPath,
        quantity: 1,
        type: "standardCake",
      },
    ],
    addOns: selectedAccessories,
    collectionDate: eventInfo.pickUpDate,
    collectionTime: eventInfo.pickUpTime,
    collectionLocation: eventInfo.pickUpLocation,
    collectionType: "pickup",
    additionalNotes: "n/a",
    coupon: {},
    orderDate: new Date(),
    total: (subtotal + tax).toFixed(2),
    email: customerInfo.email.toLowerCase(),
    paymentStatus: "pending",
    employeeID: "123",
    orderSource: "in-store",
    orderStatus: "in-progress",
    refundPolicyAgreed: refundPolicyAgreed,
    userRole: "guest",
  };

  const handleSubmit = async () => {
    await createOrder(newOrder);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
        data-testid="submission-review"
      >
        <Button
          onClick={handleSubmit}
          className={classes.submitButton}
          disabled={!refundPolicyAgreed}
          sx={{ mb: 10 }}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
