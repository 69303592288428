import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Box, Paper, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowForward,
  ArrowBack,
  Warning,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import MultiImageShow from "../../../components/multiImageShow";
import TextPiping from "./piping";
import Candle from "./candles";
import GreetingCard from "./greetingCards";
import CakeTopper from "./toppers";
import { useDispatch, useSelector } from "react-redux";
import {
  activeStepUpdated,
  selectAccessoryInfo,
} from "../../../redux/reducers/inStoreStandardCake";

const useStyles = makeStyles((theme) => ({
  nextButton: {
    "&.MuiButton-root": {
      color: "#f6725c",
      fontSize: "18px",
    },
    "&.Mui-disabled": {
      color: "lightgrey",
    },
  },
  backButton: {
    "&.MuiButton-root": {
      color: "#f6725c",
      fontSize: "18px",
    },
  },
  container: {
    // width: "100%",
    // padding: "0 3rem 3rem 3rem",
    [theme.breakpoints.between("sm", "md")]: {
      padding: 0,
    },
  },
  arrowsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: "white",
    },
    zIndex: 10,
  },
}));

const AccessoriesSelection = ({ setActiveStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accessoryInfo = useSelector(selectAccessoryInfo);
  const [accessoriesList, setAccessoriesList] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(true);

  useEffect(() => {
    if (
      accessoryInfo.piping.optionIsSelected &&
      (accessoryInfo.piping.text === null || accessoryInfo.piping.text === "")
    ) {
      return setNextButtonEnabled(false);
    } else {
      setNextButtonEnabled(true);
    }

    for (const key in accessoryInfo.candles) {
      if (key === "numberCandle") {
        if (
          accessoryInfo.candles[key].optionIsSelected &&
          (accessoryInfo.candles[key].userSelection.color === null ||
            accessoryInfo.candles[key].userSelection.numbers.length === 0)
        ) {
          return setNextButtonEnabled(false);
        } else {
          setNextButtonEnabled(true);
        }
      } else {
        if (
          accessoryInfo.candles[key].optionIsSelected &&
          accessoryInfo.candles[key].userSelection.length === 0
        ) {
          return setNextButtonEnabled(false);
        } else {
          setNextButtonEnabled(true);
        }
      }
    }

    for (const key in accessoryInfo.toppers) {
      if (
        accessoryInfo.toppers[key].optionIsSelected &&
        accessoryInfo.toppers[key].userSelection.length === 0
      ) {
        return setNextButtonEnabled(false);
      } else {
        setNextButtonEnabled(true);
      }
    }
  }, [accessoryInfo, nextButtonEnabled]);

  useEffect(() => {
    async function fetchCakes() {
      const res = await axios.get("/api/app/data/accessories");
      setAccessoriesList(res.data.accessories);
      setDataIsReady(true);
    }
    fetchCakes();
  }, [dataIsReady]);

  const handleNext = () => {
    setActiveStep(3)
  };

  const handleBack = () => {
    setActiveStep(1)
  };

  return (
    <>
      <Grid container className={classes.container} data-testid="accessory-selection">
        {dataIsReady ? (
          accessoriesList.map((accessory, i) => {
            if (accessory.type === "piping") {
              return <TextPiping accessory={accessory}></TextPiping>;
            }
            if (accessory.type === "candle") {
              return <Candle accessory={accessory}></Candle>;
            }
            if (accessory.type === "greetingCard") {
              return <GreetingCard accessory={accessory}></GreetingCard>;
            }
            if (accessory.type === "topper") {
              return <CakeTopper accessory={accessory}></CakeTopper>;
            }
          })
        ) : (
          <Box>Loading...</Box>
        )}
      </Grid>
      <Box className={classes.arrowsBox}>
        <Button className={classes.backButton} onClick={handleBack}>
          <ArrowBack sx={{ mr: 1 }} />
          Size
        </Button>
        <Button
          className={classes.nextButton}
          onClick={handleNext}
          disabled={!nextButtonEnabled}
        >
          Customer
          <ArrowForward sx={{ ml: 1 }} />
        </Button>
      </Box>
    </>
  );
};

export default AccessoriesSelection;
