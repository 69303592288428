import React from "react";
import { useSelector } from "react-redux";
import { selectUserSelection } from "../../../../redux/reducers/inStoreStandardCake";
import Header from "../common/header";
import MultiImageShow from "../../../../components/multiImageShow";
import AccessoryCustomFormControl from "../enhancedFormControls/accessoryCustomFormControl";

export default function TopperAccessory({ accessoryItem }) {
  const optionIsSelected = useSelector(
    selectUserSelection(accessoryItem.subtype)
  ).optionIsSelected;

  return (
    <>
      <MultiImageShow images={accessoryItem.images}></MultiImageShow>
      <Header accessory={accessoryItem} checked={optionIsSelected}></Header>
      <AccessoryCustomFormControl
        accessory={accessoryItem}
      ></AccessoryCustomFormControl>
    </>
  );
}
