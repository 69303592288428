import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import MultiSelectInput from "../admin/multiSelectInput";
import FreeTextInput from "../admin/freeTextInput";
import ImageUploader from "../utils/imageUploader";

const useStyles = makeStyles(() => ({}));

export default function OrderInfo({ orderType }) {
  const classes = useStyles();
  let navigate = useNavigate();

  const [sizes, setSizes] = useState([]);
  const [flavors, setFlavors] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);

  // this is done this way to match the pattern of MultiSelectInput to be able to reuse it
  const quantityOptions = [
    {
      name: "1",
    },
    {
      name: "2",
    },
    {
      name: "3",
    },
  ];

  const labels = ["Choose Flavor", "Choose Size", "Choose Quantity"];
  const options = [flavors, sizes, quantityOptions];

  useEffect(async () => {
    try {
      let sizeRes = null;
      let flavorRes = null;
      if (orderType === "standardCake") {
        sizeRes = await axios.get(
          "/api/admin/size/getSizesByApplicationType?applicationType=standardCake"
        );
      } else if (orderType === "specialtyCake") {
        sizeRes = await axios.get(
          "/api/admin/size/getSizesByApplicationType?applicationType=specialtyCake"
        );
      }

      if (sizeRes.status != 200) {
        throw new Error(`HTTP error: ${sizeRes.status}`);
      }

      setSizes(sizeRes.data);

      flavorRes = await axios.get("/api/app/data/flavors");
      if (flavorRes.status != 200) {
        throw new Error(`HTTP error: ${flavorRes.status}`);
      }
      setFlavors(flavorRes.data);

      setDataIsReady(true);
    } catch (error) {
      console.log(error);
    }
  }, [dataIsReady]);

  return (
    <Box>
      {dataIsReady ? (
        <Grid container data-testid="order-info">
          <Grid item xs={12}>
            <Typography variant="h6">ORDER INFORMATION</Typography>
          </Grid>
          {labels.map((label, i) => {
            return (
              <Grid item xs={12} sm={3} key={label}>
                <MultiSelectInput
                  label={label}
                  options={options[i]}
                ></MultiSelectInput>
              </Grid>
            );
          })}
          <Grid item xs={12} sm={3}>
            <FreeTextInput label={"Price"} val={"$0.00"}></FreeTextInput>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ImageUploader></ImageUploader>
          </Grid>
        </Grid>
      ) : (
        <Box>Loading...</Box>
      )}
    </Box>
  );
}
