/** @format */
import { makeStyles } from "@mui/styles";
import { Box, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectOrderInfo } from "../../../../../../redux/reducers/admin";

const useStyles = makeStyles((theme) => ({
  sectionHeadText: {
    color: "#242424 !important",
    fontWeight: "700 !important",
    fontSize: "18px !important",
    marginTop: "15px !important",
  },
  productContainer: {
    // margin: "0 16px 16px 70px !important",
    border: "0.5px solid #E0E0E0 !important",
    padding: "1rem !important",
    borderRadius: "10px !important",
  },
  addOnList: {
    display: "flex",
    flexDirection: "row",
  },
  addOnItem: {
    border: "0.5px solid #E0E0E0 !important",
    padding: "0.7rem !important",
    borderRadius: "10px !important",
  },
  addOnDetailText: {
    fontSize: "14px !important",
    fontWeight: "700",
  },
  addOnDetailSubText: {
    fontSize: "13px !important",
    color: "#676767 !important",
  },
}));

export default function Piping({ addOn }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Box className={classes.addOnItem}>
        <Typography className={classes.addOnDetailText}>
          {"Text Piping"}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Typography className={classes.addOnDetailSubText} sx={{ mr: 1 }}>
            {"text:"}
          </Typography>
          <Typography className={classes.addOnDetailSubText}>
            {addOn.options}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}
