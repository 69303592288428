/** @format */

import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import OrderTable from "./OrderTable";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    textAlign: "center",
      padding: "30px 0 30px 0 !important",
  },
  title: {
    fontWeight: "700 !important",
    fontSize: "24px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "36px !important",
    },
  },
}));

const BookingPage = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>BOOKING SYSTEM</Typography>
      </Box>

      <OrderTable />
    </>
  );
};

export default BookingPage;
