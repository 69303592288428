/** @format */

import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import {
  guestFirstNameUpdated,
  selectGuestCustomerInfo,
} from "../../../redux/reducers/cart";

const useStyles = makeStyles((theme) => ({
  textStyle: { ...theme.textStyle },
}));

export default function FirstName({ placeholder, icon }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const guest = useSelector(selectGuestCustomerInfo);
  const [firstName, setFirstName] = useState(guest.firstName);
  const [firstNameError, setFirstNameError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    const input = event.target.value;
    if (!input) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    dispatch(guestFirstNameUpdated(input));
    setFirstName(input);
  };

  const getLabelStyles = () => {
    if (isFocused) {
      return {
        paddingLeft: "0",
        transform: "translate(15px, -20px) scale(0.75)",
        marginTop: "10px",
      };
    } else {
      if (firstName) {
        return { paddingLeft: "0" };
      } else {
        return { paddingLeft: "35px" };
      }
    }
  };

  return (
    <FormControl>
      <TextField
        className={classes.textField}
        error={firstNameError}
        helperText={firstNameError ? "invalid first name" : ""}
        variant="outlined"
        value={firstName}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        InputProps={{
          sx: { borderRadius: "20px" },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton edge="start" onClick={() => {}}>
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: isFocused || firstName.length > 0,
          style: getLabelStyles(),
        }}
        label={placeholder}
        data-testid="firstName"
      />

      {/* 
      <TextField
      error={firstNameError}
      helperText={firstNameError ? "invalid first name" : ""}
      sx={{ mr: 5 }}
      type="text"
      label="Enter first name"
      value={firstName}
      onChange={(e) => {
        handleInput(e.target.value);
      }}
      InputLabelProps={{
        className: classes.textStyle,
      }}
      InputProps={{ className: classes.textStyle }}
      FormHelperTextProps={{ className: classes.textStyle }}
      data-testid="firstName"></TextField> */}
    </FormControl>
  );
}
