import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCustomerInfo } from "../../../redux/reducers/inStoreStandardCake";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";

const useStyles = makeStyles(() => ({
  infoItems: {
    color: "grey !important",
    marginLeft: "0.5rem !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "grey !important",
    marginBottom: "0.5rem",
  },
  heading: {
    fontSize: "18px !important",
    marginBottom: "2rem !important",
    textTransform: "uppercase !important",
    fontWeight: "bold !important",
  },
}));

export default function Customer() {
  const classes = useStyles();
  const customer = useSelector(selectCustomerInfo);

  return (
    <>
      <Typography className={classes.heading}>customer details</Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} data-testid="customer-review">
        <Grid item xs={6} className={classes.gridItem}>
          <PersonIcon />
          <Typography className={classes.infoItems}>{customer.name}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <PhoneIcon />
          <Typography className={classes.infoItems}>
            {customer.phone}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <EmailIcon />
          <Typography className={classes.infoItems}>
            {customer.email}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <LocationOnIcon />
          <Typography className={classes.infoItems}>
            {customer.address}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
