/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cake: {
    name: null,
    flavorSection: {
      flavor: null,
      required: true,
    },
    colorSection: {
      color: null,
      required: true,
    },
    imgPath: null,
    size: null,
    price: null,
  }
};

export const websiteStandardCakeSlice = createSlice({
  name: "websiteStandardCake",
  initialState,
  reducers: {
    cakeSelected: (state = initialState, action) => {
      state.cake = initialState.cake;
      const selectedCake = action.payload;
      state.cake = {
        ...state.cake, name: selectedCake.name,
        imgPath: selectedCake.images.primary, 
        flavorSection: {...state.cake.flavorSection, flavor: selectedCake.flavors.length === 0 ? selectedCake.name.toLowerCase() : null}
      }
    },
    sizeSelected: (state, action) => {
      state.cake.size = action.payload.size;
      state.cake.price = action.payload.price;
    },
    priceSelected: (state, action) => {
      state.cake.price = action.payload.price;
      state.cakeSubtotal = action.payload.price;
    },
    flavorSelected: (state, action) => {
      state.cake.flavorSection.flavor = action.payload.newFlavor;
    },
    colorSelected: (state, action) => {
      state.cake.colorSection.color = action.payload.newColor;
    },
    resetToInitial: () => initialState,
  },
});

export const selectCakeInfo = (state) => state.websiteStandardCake.cake;

export const {
  cakeSelected,
  sizeSelected,
  priceSelected,
  flavorSelected,
  colorSelected,
  resetToInitial,
} = websiteStandardCakeSlice.actions;

export default websiteStandardCakeSlice.reducer;
