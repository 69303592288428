import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Selections from "./selections"
import Subscription from "./subscription";
import Success from "./success";
import Fail from "./fail";

export default function OfficeSnacksModal({ handleClose }) {

  const initModalView = {
    view: "selections",
    data: null,
  };

  const [modalView, setModalView] = useState(initModalView);
  const showBackBtn = ["subscription", "fail"].indexOf(modalView.view) !== -1

  return (
    <div style={{ overflow: "hidden" }}>
      <div style={{ display: "flex", justifyContent: showBackBtn ? "space-between" : "end" }}>

        {
          showBackBtn &&
          <Typography
            sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}
            onClick={()=> setModalView({ ...modalView, view: "selections" })}
          >
            <ArrowBackIcon/><span style={{ marginLeft: 20 }}>Office Snacks</span>
          </Typography>
        }
        <Typography
          sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </Typography>


      </div>
      
      {
        modalView.view==="selections" &&
        <Selections setModalView={setModalView} modalView={modalView} />
      }

      {
        modalView.view==="subscription" &&
        <Subscription setModalView={setModalView} modalView={modalView}/>
      }

      {
        modalView.view==="success" &&
        <Success modalView={modalView} handleClose={handleClose}/>
      }

{
        modalView.view==="fail" &&
        <Fail modalView={modalView} handleClose={handleClose}/>
      }
    </div>
  );
}
