/** @format */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Box } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import { pastOrdersSet } from "../../../../redux/reducers/admin";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  searchField: {
    margin: "0 0 15px 0 !important",
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 25px 0 !important",
    },
  },
}));

function FetchFieldByDate() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dateValue, setDateValue] = useState(dayjs());

  const handleFetchClick = async () => {
    const dateValueAsString = dateValue.toISOString();
    const res = await axios.get(
      `/api/admin/order/getOrdersByDate?odate=${dateValueAsString}`
    );
    dispatch(pastOrdersSet(res.data));
  };

  const handleClearClick = async () => {
    setDateValue(dayjs());
    dispatch(pastOrdersSet([]));
  };

  const handleDateChange = (e) => {
    setDateValue(e);
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className={classes.searchField}
          label="Fetch by date"
          value={dateValue}
          onChange={(newValue) => handleDateChange(newValue)}
        />
      </LocalizationProvider>
      <Box sx={{ mb: 2 }}>
        <Button variant="contained" onClick={handleFetchClick} sx={{ mr: 2 }}>
          fetch
        </Button>
        <Button variant="contained" onClick={handleClearClick}>
          clear
        </Button>
      </Box>
    </Box>
  );
}

export default FetchFieldByDate;
