/** @format */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderSet, selectOrders } from "../../../redux/reducers/admin";
import { orderSorting } from "./utils/orderSorting";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

function SortProperty({ children, up, down }) {
  const [sort, setSort] = useState(up);
  const orders = useSelector(selectOrders);
  const dispatch = useDispatch();

  const sortList = () => {
    if (sort !== up) {
      setSort(up);
    } else {
      setSort(down);
    }

    dispatch(orderSet(orderSorting(sort, orders)));
  };

  return (
    <>
      {children}
      {sort === up ? <KeyboardArrowUpOutlinedIcon onClick={() => {
          sortList();
        }} data-testid="arrow-up-icon"/> : <KeyboardArrowDownOutlinedIcon onClick={() => {
          sortList();
        }} data-testid="arrow-down-icon"/> }
    </>
  );
}

export default SortProperty;
