/** @format */

import { Grid, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Main from "./main";
import Cake from "./cake";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    //marginTop: "3.2rem",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
}));

export default function Body() {
  const classes = useStyles();
  return (
    <>
      <Main></Main>
      <Container data-testid="specialty-cakes-body">
        <Grid
          container
          className={classes.wrapper}
          style={{
            backgroundColor: "#fff",
          }}
        >
          <Cake></Cake>
        </Grid>
      </Container>
    </>
  );
}
