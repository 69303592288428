/** @format */

import React, { useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CircularProgress from "@mui/material/CircularProgress";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../auth.js";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#fd776280",
    position: "relative",
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "2rem 1rem",
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "30%",
    },
  },
  title: {
    color: "#fd7762",
    fontSize: "32px !important",
    marginBottom: "2rem !important",
    ...theme.textStyle,
  },
  button: {
    ...theme.containedButtonDark,
    width: "100%",
    marginTop: "20px !important",
    marginBottom: "1rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#fd7762",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd7762",
      },
    },
  },
  joinNow: {
    color: "#fd7762",
  },
  textStyle: { ...theme.textStyle },
  inputFieldText: {
    ...theme.textStyle,
    color: "#000",
  },
}));

export default function Login() {
  initializeApp(firebaseConfig());

  const classes = useStyles();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { state } = useLocation();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (input) => {
    if (!input) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(input);
  };

  const handlePasswordChange = (input) => {
    if (!input) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    setPassword(input);
  };

  const handleLogin = async () => {
    const auth = getAuth();
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const token = await auth.currentUser.getIdToken(true);
      localStorage.setItem("token", token);

      if (userCredential.user.emailVerified) {
        const affectedUser = await axios.get(
          "/api/affectedUsers/user?userEmail=" + email.toLowerCase().trim()
        );

        if (affectedUser.data && affectedUser.data.firstVisitAfterCrash) {
          localStorage.setItem("affectedUserEmail", email);
          navigate("/update-info");
        } else {
          const signedInUser = await axios.get(
            "/api/users/user?userEmail=" + email.toLowerCase().trim()
          );
          if (signedInUser) {
            localStorage.setItem("user", JSON.stringify(signedInUser.data));
            navigate(state?.path || "/");
          } else {
            alert(
              "we could not find such user. Please screenshot this error message and email it to us at contact@cakeyhand.com along with your registered email address"
            );
          }
        }
      } else {
        setLoading(false);
        await sendEmailVerification(auth.currentUser);
        return alert(
          "You have not verified your email yet, but no worries, we just sent you a new one. Please check your email, click on the link, and try logging in again."
        );
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error)
        return alert(
          "There is no such user. Please make sure the email and password are both correct"
        );
    }
  };

  return (
    <Box className={classes.wrapper} data-testid="login">
      <Paper className={classes.container} elevation={10}>
        <KeyboardBackspaceIcon fontSize="large" onClick={() => navigate(-1)} />
        <Box sx={{ textAlign: "center" }}>
          <Typography className={classes.title}>Login</Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                width: "100%",
                boxShadow: 3,
              },
            }}
            noValidate>
            <TextField
              error={emailError}
              fullWidth
              id="outlined-required"
              label="Email"
              defaultValue=""
              margin="normal"
              helperText={emailError ? "Enter your email" : ""}
              className={classes.root}
              InputLabelProps={{
                className: classes.inputFieldText,
              }}
              InputProps={{ className: classes.textStyle }}
              FormHelperTextProps={{ className: classes.textStyle }}
              onChange={(e) => {
                handleEmailChange(e.target.value);
              }}
              data-testid="login-email"
            />
            <TextField
              error={passwordError}
              fullWidth
              id="outlined-required"
              label="Password"
              defaultValue=""
              margin="normal"
              helperText={passwordError ? "Enter your password" : ""}
              type="password"
              className={classes.root}
              InputLabelProps={{
                className: classes.inputFieldText,
              }}
              InputProps={{ className: classes.textStyle }}
              FormHelperTextProps={{ className: classes.textStyle }}
              onChange={(e) => {
                handlePasswordChange(e.target.value);
              }}
              data-testid="login-password"
            />
            <Box sx={{ position: "relative" }}>
              <Button
                size={"large"}
                variant="contained"
                fullwidth
                className={classes.button}
                onClick={handleLogin}
                data-testid="login-button">
                <Typography className={classes.textStyle}>Log In</Typography>
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-6px",
                    marginLeft: "65px",
                    color: "#fff",
                  }}
                />
              )}
            </Box>
          </Box>
          <Typography
            className={classes.textStyle}
            sx={{ fontSize: "14px", mb: 1 }}>
            Forgot the password?
            <Link
              href="/reset-password"
              underline="none"
              sx={{
                ml: 1,
                color: "#fd7762",
              }}>
              Reset it here
            </Link>
          </Typography>
          <Divider />
          <br />
          <Typography
            className={classes.textStyle}
            sx={{ mb: 5 }}
            onClick={() => navigate("/signup")}>
            Not a member? <span className={classes.joinNow}>Join Now</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              color: "#828282",
              justifyContent: "center",
            }}>
            <Typography className={classes.textStyle} sx={{ fontSize: "14px" }}>
              {"CakeyHand"} {new Date().getFullYear()} {"© All rights reserved"}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
