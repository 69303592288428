/** @format */

import { React } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  emptyCartContainer: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  emptyCartText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  },
  textStyle: { ...theme.textStyle },
}));

export default function EmptyCart() {
  const classes = useStyles();

  return (
    <Box className={classes.emptyCartContainer}>
      <Box className={classes.emptyCartText}>
        <Typography className={classes.textStyle} variant="h5">
          Your cart is empty
        </Typography>
      </Box>
    </Box>
  );
}
