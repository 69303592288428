/** @format */

import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, TextField, Paper } from "@mui/material";
import { additionalNotesInputed, selectAdditionalNotes } from "../../redux/reducers/cart";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "2rem",
    marginBottom: "1rem",
    padding: "15px 10px",
  },
  sectionTitle: {
    color: theme.palette.secondary.dark,
    marginBottom: "20px",
    textAlign: "center",
    ...theme.textStyle,
  },
  sectionSubTitle: {
    color: theme.palette.secondary.main.main,
    textAlign: "center",
    marginBottom: "40px",
    ...theme.textStyle,
  },
  textStyle: { ...theme.textStyle },
  inputFieldText: {
    ...theme.textStyle,
    height: 100,
  },
}));

export default function AdditionalNotes() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const additionalNotes = useSelector(selectAdditionalNotes);

  const handleAdditionalNotesChange = (event) => {
    dispatch(additionalNotesInputed(event.target.value));
  };

  return (
    <Paper elevation={10} className={classes.wrapper} data-testid="additional-notes">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={"h5"} className={classes.sectionTitle}>
            Additional Notes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"} className={classes.sectionSubTitle}>
            Is there aything else we must know, such as allergies?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            label="Your message here..."
            value={additionalNotes}
            onChange={(e) => {
              handleAdditionalNotesChange(e);
            }}
            InputLabelProps={{
              className: classes.inputFieldText,
            }}
            InputProps={{ className: classes.textStyle }}
          ></TextField>
        </Grid>
      </Grid>
    </Paper>
  );
}
